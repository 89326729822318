import { useState } from "react";

import { DateTime } from "luxon";

import ErrorComponent from "../../../components/ErrorComponent";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

import { CustomTooltip } from "../../../styling/StyleComponents";
import { CircularProgress, styled, Typography } from "@mui/material";
import { useGetUserPresenceActivityQuery } from "common/services/UserPresenceService";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import { ArrowLeft, ArrowRight, Circle, Diversity1 } from "@mui/icons-material";
import { blue } from "common/styling/colors";
import { useNavigate } from "react-router-dom";
import { prettyStatusString } from "common/helpers/helpers";

import { UserPrecenseStatusEnum_getColor } from "common/enums/UserPrecenseStatusEnum";
import DatePicker from "../../../components/DatePicker";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 6px 0px;
`;

interface IProps {
  staffId: string;
}

const ActivityLogTab = ({ staffId }: IProps) => {
  const navigate = useNavigate();

  const [date, setDate] = useState<DateTime>(DateTime.now().startOf("day"));

  const { data, error, isFetching } = useGetUserPresenceActivityQuery({
    staff_id: staffId,
    start: date,
    end: date.plus({ day: 1 }),
    order: "ASC"
  });

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Activity Log
        </Typography>

        {isFetching && <CircularProgress />}
        <StyledIconButton
          Icon={ArrowLeft}
          iconColor={blue[700]}
          border="transparent"
          onClick={(e) => {
            setDate((prevState) => prevState.minus({ day: 1 }));
          }}
        />
        <DatePicker
          sx={{ marginTop: 0 }}
          label="Select date"
          value={date}
          onChange={(newDate: DateTime) => setDate(newDate)}
          maxDate={DateTime.now()}
        />
        <StyledIconButton
          Icon={ArrowRight}
          iconColor={blue[700]}
          border="transparent"
          disabled={date?.toISODate() === DateTime.now().toISODate()}
          onClick={() => {
            setDate((prevState) => {
              const newDate = prevState.plus({ day: 1 });
              if (newDate > DateTime.now()) return prevState;
              else return prevState.plus({ day: 1 });
            });
          }}
        />
      </Row>

      {!isFetching && data?.length === 0 && (
        <Typography variant="h6" color="error">
          No data found for this day
        </Typography>
      )}
      {data && (
        <Timeline>
          {data.map((item) => {
            const startedAt = DateTime.fromISO(item.started_at);
            const visitId = item.visit_id;

            return (
              <TimelineItem key={item.started_at + item.ended_at}>
                <TimelineSeparator>
                  {visitId ? (
                    <CustomTooltip title={"View this task"} placement="left">
                      <div>
                        <StyledIconButton
                          Icon={Diversity1}
                          iconColor={blue[700]}
                          border="square"
                          onClick={(e) => {
                            navigate(`/visits/${visitId}`);
                          }}
                        />
                      </div>
                    </CustomTooltip>
                  ) : (
                    <Circle
                      color={UserPrecenseStatusEnum_getColor(item.status)}
                    />
                  )}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={{ paddingTop: "0px" }}>
                  <Typography variant="h6">
                    {startedAt.toFormat("hh:mm a")}{" "}
                    {prettyStatusString(item.status)}{" "}
                    {item.visit_motivation_reason &&
                      `- ${prettyStatusString(item.visit_motivation_reason)}`}
                  </Typography>
                  <Typography variant="body1">
                    Duration: {item.duration_minutes}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}

      <ErrorComponent error={error} />
    </>
  );
};

export default ActivityLogTab;
