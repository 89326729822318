import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import { useSelector } from "react-redux";
import { RootState, dispatch, useAppDispatch } from "common/redux";
import {
  checkIdValid,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import {
  ChevronLeft,
  ChevronRight,
  ErrorOutlineOutlined,
  Repeat
} from "@mui/icons-material";
import { blue, gray, warning } from "common/styling/colors";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useCreateCalendarEventMutation,
  useGetCalendarEventQuery,
  useGetCalendarEventsQuery,
  useUpdateCalendarEventMutation,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/CalendarService";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import StaffAvailabilityObject from "common/types/Calendaring/StaffAvailabilityObject";
import { useGetSortedMembersWithActiveNursesQuery } from "common/services/ReportsService";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  resetAppointmentState,
  resetAppointmentSelections,
  setAttendees,
  setCommunicationType,
  setMemberName,
  setRecurrence,
  setStaffId,
  setStaffName,
  setMemberTimezone,
  setStartEndDate,
  setEventId,
  setRecurrenceUpdateType,
  setAppointmentType,
  setAssignedNurseId,
  setXTraceId
} from "common/redux/AppointmentSlice";
import { useLocation, useNavigate } from "react-router-dom";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import AppointmentOperationTypeEnum from "common/enums/Calendaring/Appointments/AppointmentOperationTypeEnum";
import { LoadingButton } from "@mui/lab";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import replace from "lodash.replace";
import ErrorComponent from "../../../components/ErrorComponent";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import { useGetProvidersMetadataQuery } from "common/services/ProvidersMetadataService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import {
  isBusinessDay,
  plusBusinessDays
} from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import RolesEnum, {
  canScheduleNurses,
  canScheduleProviders,
  hasFourBusinessDaySchedulingRestriction
} from "common/enums/RolesEnum";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import UserStatusEnum from "common/enums/UserStatusEnum";
import {
  dateIsBlocked,
  getRecurrenceObject,
  isMemberAppointment,
  isNurseAppointment,
  isProviderAppointment,
  isSameWeek,
  recurrenceUpdateTypeValues,
  recurrenceValues
} from "common/helpers/CalendarHelper";
import { v4 as uuidv4 } from "uuid";
import { useGetStaffAvailabilityQuery } from "common/services/PanelManagementService";
import cloneDeep from "lodash.clonedeep";
import useSanitizedSearchParams from "../../../hooks/useSanitizedSearchParams";
import {
  DAYS_TO_LOOK_AHEAD,
  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
} from "./constants";
import Routes from "../../../routes/Routes";

const CreateUpdateAppointmentContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
`;

const AppointmentTypeSelectionTypographyStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px"
};

function getAppointmentTypeReadableName(appointmentType) {
  switch (appointmentType) {
    case AppointmentTypeEnum.NURSE_FOLLOWUP:
      return "Nurse Followup";
    case AppointmentTypeEnum.PROVIDER_FOLLOWUP:
      return "Provider Followup";
    case AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP:
      return "Telehealth Nurse Setup";
  }
}

/**
 * gets the start date as a week day, skipping weekends and holidays
 * @param {String} timezone the timezone of the carer
 * @param {RolesEnum} role the role of the logged in user
 * @param {string} startdate a string representation of the start date
 * @returns {DateTime} the start date
 */
function getStartDate(timezone: string, role: RolesEnum, startdate?: string) {
  // allow booking now - take the current time and subtract 61 minutes to make sure start time is before the current time slot
  const endOfHour = DateTime.now()
    .setZone(timezone)
    .minus({ minutes: 61 })
    .startOf("minute");

  if (startdate) {
    let startDateTime = DateTime.fromISO(startdate).startOf("week").toUTC();
    if (hasFourBusinessDaySchedulingRestriction(role)) {
      // add 4 business days after the current day, or 5 business days
      startDateTime = plusBusinessDays(endOfHour.startOf("day"), 5);
    }
    return startDateTime.diffNow().milliseconds > 0 ? startDateTime : endOfHour;
  }

  let startDate = endOfHour;
  if (hasFourBusinessDaySchedulingRestriction(role)) {
    // add 4 business days after the current day, or 5 business days
    startDate = plusBusinessDays(endOfHour.startOf("day"), 5);
  }

  // if it is a weekend or holiday, set the start date to the start of the next business day
  if (!isBusinessDay(startDate)) {
    startDate = plusBusinessDays(startDate.startOf("day"), 1);
  }

  return startDate.toUTC();
}

function getStartDateForMemberEvents(
  timezone: string,
  role: RolesEnum,
  startdate?: string
) {
  // we want to get the start of the day to account for any events that have happened today for the same day TN-Provider check
  return getStartDate(timezone, role, startdate).startOf("day");
}

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

function getCurrentWeekIsoDays(currentDay: DateTime) {
  const currentWeek = [];

  const startOfWeek = currentDay.startOf("week");

  for (let i = 0; i < 5; i++) {
    currentWeek.push(startOfWeek.plus({ days: i }).toISODate());
  }
  return currentWeek;
}

function hasAvailabilityInCurrentWeek(currentDay: DateTime, availabilityMap) {
  const currentWeek = getCurrentWeekIsoDays(currentDay);

  return currentWeek.some((day) => {
    return availabilityMap?.[day]?.length > 0;
  });
}

function RenderWeekdays({
  currentDay,
  startDate,
  availabilityMap,
  availabilityIsFetching,
  availabilityError,
  selectedStartDate,
  isAvailableThisWeek,
  availabilityIsUninitialized,
  timezone,
  appointmentType,
  staffScheduleError,
  memberScheduleError
}: Readonly<{
  currentDay: DateTime;
  startDate: DateTime;
  availabilityMap: any;
  availabilityIsFetching: boolean;
  selectedStartDate: DateTime;
  isAvailableThisWeek: boolean;
  availabilityIsUninitialized: boolean;
  availabilityError: unknown;
  appointmentType: AppointmentTypeEnum;
  timezone: string;
  staffScheduleError: unknown;
  memberScheduleError: unknown;
}>) {
  const currentDayIndex = currentDay.weekday - 1;

  return (
    <>
      {!availabilityIsFetching &&
        !isAvailableThisWeek &&
        // check to make sure the query has been initialized before showing this message
        !availabilityIsUninitialized &&
        !availabilityError && (
          <DelayedRender delay={500}>
            <Box textAlign="center">
              <Typography variant="body1" color="text.secondary">
                No availability this week.
              </Typography>
            </Box>
          </DelayedRender>
        )}
      {availabilityError && (
        <Box mt="12px" textAlign="center">
          <ErrorComponent error={availabilityError} />
        </Box>
      )}
      {staffScheduleError && (
        <Box mt="12px" textAlign="center">
          <ErrorComponent error={staffScheduleError} />
        </Box>
      )}
      {memberScheduleError && (
        <Box mt="12px" textAlign="center">
          <ErrorComponent error={memberScheduleError} />
        </Box>
      )}
      <Flexbox>
        {!availabilityIsFetching &&
          !availabilityError &&
          !staffScheduleError &&
          !memberScheduleError &&
          weekdays.map((weekday, index) => {
            if (currentDayIndex > index && isSameWeek(currentDay, startDate)) {
              return (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                ></Flexbox>
              );
            } else {
              const day = currentDay.plus({ days: index - currentDayIndex });
              const availableDays = availabilityMap?.[day.toISODate()];
              return (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                >
                  <Flexbox gap="12px" flexDirection="column">
                    {availableDays?.map((availability) => {
                      const selectedButton =
                        availability.start === selectedStartDate;
                      if (
                        // this is for backward compatibility with backend changes
                        typeof availability.available_appointments !==
                          "object" ||
                        // we can remove the above line once ENG-4696 is released
                        availability.available_appointments[appointmentType] > 0
                      ) {
                        const dateToMap = DateTime.fromISO(
                          availability.start
                        ).setZone(timezone);
                        return (
                          <Button
                            variant="outlined"
                            key={availability.start}
                            sx={{
                              width: "100%",
                              height: "50px",
                              ...(selectedButton && {
                                backgroundColor: blue[700],
                                color: "white"
                              }),
                              "&:hover": {
                                ...(selectedButton && {
                                  backgroundColor: blue[700],
                                  color: "white"
                                })
                              }
                            }}
                            onClick={() => {
                              dispatch(
                                setStartEndDate({
                                  startDate: availability.start,
                                  endDate: availability.end
                                })
                              );
                            }}
                          >
                            <Box
                              minWidth="max-content"
                              id={`${dateToMap.toFormat("h:mm")}-${weekday}`}
                              data-testid={dateToMap.toFormat("MM-dd")}
                              aria-label="Available Date"
                            >
                              {DateTime.fromISO(availability.start)
                                .setZone(timezone)
                                .toFormat("h:mm")}
                              &nbsp;-&nbsp;
                              {DateTime.fromISO(availability.end)
                                .setZone(timezone)
                                .toFormat("h:mm a")}
                            </Box>
                          </Button>
                        );
                      }
                    })}
                  </Flexbox>
                </Flexbox>
              );
            }
          })}
      </Flexbox>
    </>
  );
}

const appointmentTypeTimeMappings = {
  [AppointmentTypeEnum.NURSE_FOLLOWUP]: 20,
  [AppointmentTypeEnum.PROVIDER_FOLLOWUP]: 20,
  [AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP]: 40
};

// this variable is 80 because we overbook 60 minute windows for nurses
const NURSE_TIME_BLOCK_TIME = 80;

const PROVIDER_TIME_BLOCK_TIME = 60;
/**
 * Checks if we can schedule an appointment of a given type based on the number of existing appointments of that type
 * @param appointmentsInTimeBlock
 * {
 *   NURSE_FOLLOWUP: number;
 *   PROVIDER_FOLLOWUP: number;
 *   TELEHEALTH_NURSE_SETUP: number;
 * }
 * @param {AppointmentTypeEnum} appointmentType
 */

function canScheduleInTimeBlock(
  appointmentsInTimeBlock: number,
  appointmentType: AppointmentTypeEnum
) {
  if (isFalsy(appointmentsInTimeBlock)) {
    return true;
  }

  const timeBlockTime = isNurseAppointment(appointmentType)
    ? NURSE_TIME_BLOCK_TIME
    : PROVIDER_TIME_BLOCK_TIME;

  const timeLeft =
    timeBlockTime -
    appointmentsInTimeBlock[AppointmentTypeEnum.NURSE_FOLLOWUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.NURSE_FOLLOWUP] -
    appointmentsInTimeBlock[AppointmentTypeEnum.PROVIDER_FOLLOWUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.PROVIDER_FOLLOWUP] -
    appointmentsInTimeBlock[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP];
  switch (appointmentType) {
    case AppointmentTypeEnum.NURSE_FOLLOWUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.NURSE_FOLLOWUP]
      );
    case AppointmentTypeEnum.PROVIDER_FOLLOWUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.PROVIDER_FOLLOWUP]
      );
    case AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP]
      );
    // we should never hit this case - just here for completeness
    default:
      return false;
  }
}

const recurrenceFrequencyWeekMappings = {
  [RecurrenceFrequencyEnum.NEVER]: 0,
  [RecurrenceFrequencyEnum.WEEKLY]: 1,
  [RecurrenceFrequencyEnum.BIWEEKLY]: 2
};

const recurrenceUpdateTypeWeekMappings = {
  [RecurrenceUpdateTypeEnum.ONCE]: 0,
  [RecurrenceUpdateTypeEnum.THIS_EVENT_FORWARD]: 1
};

function getFinalAvailabilityMap(
  availabilityMap,
  staffScheduleMap,
  appointmentTypeMap,
  recurrence,
  recurrenceUpdateType,
  appointmentType,
  timezone,
  daysToLookAhead = DAYS_TO_LOOK_AHEAD * 2
) {
  const keys = Object.keys(availabilityMap);
  // make a clone - we don't want to mutate the original object
  const clone = cloneDeep(availabilityMap);

  // remove nurse appointments and provider appointments from being scheduled on the same day
  keys.forEach((key) => {
    clone[key].forEach((availability) => {
      const start = DateTime.fromISO(availability.start);
      const dateKey = start.setZone(timezone).toISODate();
      let canSchedule = true;

      // ENG-6802 - don't allow provider and TN appointments on the same day
      if (appointmentTypeMap?.[dateKey]) {
        // ENG-7125 only have this check for provider appointments now. In the future we may
        // add this check to nurse appointments too and would need to uncomment the below lines.

        // if (isNurseAppointment(appointmentType)) {
        //   // if it is a nurse appointment, check to make sure there are no provider appointments
        //   canSchedule =
        //     appointmentTypeMap?.[dateKey]?.[
        //       AppointmentTypeEnum.PROVIDER_FOLLOWUP
        //     ] == 0;
        // } else
        if (isProviderAppointment(appointmentType)) {
          // if it is a provider appointment, check to make sure there are no nurse appointments
          canSchedule =
            appointmentTypeMap?.[dateKey]?.[
              AppointmentTypeEnum.NURSE_FOLLOWUP
            ] == 0 &&
            appointmentTypeMap?.[dateKey]?.[
              AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP
            ] == 0;
        }
      }

      if (!canSchedule) {
        // remove from availability
        clone[key] = clone[key].filter((item) => {
          return availability.start !== item.start;
        });
      }
    });
  });
  // return clone;

  // feature flag - keep this code in case we need it - calculating recurring availabilty on the front end
  if (
    recurrence === RecurrenceFrequencyEnum.NEVER ||
    recurrenceUpdateType === RecurrenceUpdateTypeEnum.ONCE ||
    (isFalsy(recurrenceUpdateType) && isFalsy(recurrence))
  ) {
    return clone;
  } else {
    // get the number of weeks between occurrences
    const weekAmount =
      recurrenceFrequencyWeekMappings?.[recurrence] ??
      // tbd we need to know the frequency of the recurrence which we don't have yet - backend can handle this in ENG-4827
      // let's default this to recurring every ONE week for now
      recurrenceUpdateTypeWeekMappings?.[recurrenceUpdateType];
    const keys = Object.keys(availabilityMap);
    // make a clone - we don't want to mutate the original object
    const maxWeeks = Math.floor(daysToLookAhead / 7);
    keys.forEach((key) => {
      clone[key].forEach((availability) => {
        const start = DateTime.fromISO(availability.start);
        const end = DateTime.fromISO(availability.end);
        const dateKey = start.setZone(timezone).toISODate();
        let canSchedule = true;
        // if there are some appointments scheduled, we need to double check availability
        if (staffScheduleMap?.[dateKey]) {
          let iterator = 1;

          // add this check in case availability is wrong on the first occurrence
          const startISO = start
            .setZone("utc")
            .toISO({ suppressMilliseconds: true });
          const endISO = end
            .setZone("utc")
            .toISO({ suppressMilliseconds: true });
          if (staffScheduleMap?.[dateKey]?.[`${startISO}-${endISO}`]) {
            canSchedule =
              canSchedule &&
              canScheduleInTimeBlock(
                staffScheduleMap?.[dateKey]?.[`${startISO}-${endISO}`]
                  ?.appointments,
                appointmentType
              );
          }

          // check other occurrences against appointments
          while (iterator <= maxWeeks) {
            const startPlus = start
              .plus({ weeks: weekAmount * iterator })
              .setZone("utc")
              .toISO({ suppressMilliseconds: true });
            const endPlus = end
              .plus({ weeks: weekAmount * iterator })
              .setZone("utc")
              .toISO({ suppressMilliseconds: true });
            const dateKeyPlus = start
              .plus({ weeks: weekAmount * iterator })
              .setZone(timezone)
              .toISODate();
            if (staffScheduleMap?.[dateKeyPlus]?.[`${startPlus}-${endPlus}`]) {
              canSchedule =
                canSchedule &&
                canScheduleInTimeBlock(
                  staffScheduleMap?.[dateKeyPlus]?.[`${startPlus}-${endPlus}`]
                    ?.appointments,
                  appointmentType
                );
            }
            iterator++;
          }
        }
        if (!canSchedule) {
          // remove from availability
          clone[key] = clone[key].filter((item) => {
            return availability.start !== item.start;
          });
        }
      });
    });
    return clone;
  }
}

function DisplayWeekAvailability({
  currentDay,
  startDate,
  availabilityMap,
  availabilityIsFetching,
  availabilityIsUninitialized,
  availabilityError,
  appointmentType,
  recurrence,
  recurrenceUpdateType,
  timezone,
  staffScheduleMap,
  appointmentTypeMap,
  staffScheduleError,
  memberScheduleError,
  operationType
}: Readonly<{
  currentDay: DateTime;
  startDate: DateTime;
  availabilityMap: { [key: string]: any };
  availabilityIsFetching: boolean;
  availabilityIsUninitialized: boolean;
  availabilityError: unknown;
  appointmentType: AppointmentTypeEnum;
  recurrence: RecurrenceFrequencyEnum;
  recurrenceUpdateType: RecurrenceUpdateTypeEnum;
  timezone: string;
  staffScheduleMap: { [key: string]: any };
  appointmentTypeMap: { [key: string]: any };
  staffScheduleError: unknown;
  memberScheduleError: unknown;
  operationType: AppointmentOperationTypeEnum;
}>) {
  const { selectedStartDate, staffId } = useSelector(
    (state: RootState) => state.appointment
  );
  const currentDayIndex = currentDay.weekday - 1;

  // reset appointment state on mount
  useEffect(() => {
    dispatch(resetAppointmentState());
    return () => {
      dispatch(resetAppointmentState());
    };
  }, []);

  const [isCalculatingAvailability, setIsCalculatingAvailability] =
    useState<boolean>(false);

  const [finalAvailabilityMap, setFinalAvailabilityMap] = useState<any>(null);

  useEffect(() => {
    setIsCalculatingAvailability(true);
    const finalMap = getFinalAvailabilityMap(
      availabilityMap,
      staffScheduleMap,
      appointmentTypeMap,
      recurrence,
      operationType === AppointmentOperationTypeEnum.UPDATE
        ? recurrenceUpdateType
        : null,
      appointmentType,
      timezone
    );

    setFinalAvailabilityMap(finalMap);
    setIsCalculatingAvailability(false);
  }, [
    recurrence,
    recurrenceUpdateType,
    staffScheduleMap,
    availabilityMap,
    operationType
  ]);

  const isAvailableThisWeek = useMemo(
    () => hasAvailabilityInCurrentWeek(currentDay, finalAvailabilityMap),
    [currentDay, finalAvailabilityMap]
  );

  return (
    <Box>
      <Flexbox sx={{ borderBottom: `1px solid ${gray[300]}` }}>
        {weekdays.map((weekday, index) => {
          if (currentDayIndex > index && isSameWeek(currentDay, startDate)) {
            return (
              <Flexbox
                flexDirection="column"
                alignItems="center"
                padding="16px"
                key={weekday}
                flexBasis="20%"
                minWidth="143px"
              >
                <Typography variant="h5">{weekday}</Typography>
                <Typography variant="body1" color={gray[600]}>
                  {currentDay
                    .minus({ days: currentDayIndex - index })
                    .toFormat("MMM d")}
                </Typography>
              </Flexbox>
            );
          } else {
            const day = currentDay.plus({ days: index - currentDayIndex });
            return (
              <Flexbox
                flexDirection="column"
                alignItems="center"
                padding="16px"
                key={weekday}
                flexBasis="20%"
                minWidth="143px"
              >
                <Typography variant="h5">{weekday}</Typography>
                <Typography variant="body1" color={gray[600]}>
                  {day.toFormat("MMM d")}
                </Typography>
              </Flexbox>
            );
          }
        })}
      </Flexbox>
      <Box height="100%">
        {(availabilityIsFetching || isCalculatingAvailability) && (
          <LoadingFallback count={15} />
        )}
        {!availabilityIsFetching && !isCalculatingAvailability && staffId && (
          <RenderWeekdays
            appointmentType={appointmentType}
            currentDay={currentDay}
            startDate={startDate}
            selectedStartDate={selectedStartDate}
            availabilityMap={finalAvailabilityMap}
            availabilityIsFetching={availabilityIsFetching}
            availabilityError={availabilityError}
            staffScheduleError={staffScheduleError}
            memberScheduleError={memberScheduleError}
            availabilityIsUninitialized={availabilityIsUninitialized}
            isAvailableThisWeek={isAvailableThisWeek}
            timezone={timezone}
          />
        )}
      </Box>
    </Box>
  );
}

function AppointmentSidebarCreate({
  nurses,
  nursesError,
  providers,
  providersError,
  nursesIsLoading,
  defaultNurseId,
  defaultProviderId,
  defaultRecurrence,
  operationType,
  appointmentTypeFromPathname,
  memberState
}) {
  const { staffId, recurrence, appointmentType } = useSelector(
    (state: RootState) => state.appointment
  );
  const { nurseId: searchNurseId } = useSanitizedSearchParams();

  const dispatch = useAppDispatch();

  const [appointmentTypeState, setAppointmentTypeState] = useState(
    appointmentTypeFromPathname ??
      // default to NURSE_FOLLOWUP to be backwards compatible for the new-appointment route
      AppointmentTypeEnum.NURSE_FOLLOWUP
  );

  useEffect(() => {
    if (appointmentTypeState !== appointmentType) {
      dispatch(setAppointmentType(appointmentTypeState));
    }
  }, [appointmentTypeState, appointmentType]);

  useEffect(() => {
    if (
      !staffId &&
      defaultNurseId &&
      nurses &&
      appointmentType === AppointmentTypeEnum.NURSE_FOLLOWUP
    ) {
      if (nurses?.length > 0) {
        const defaultNurse = nurses?.find(
          (nurse) => nurse.value === defaultNurseId
        );
        if (defaultNurse) {
          dispatch(setStaffId(defaultNurse.value));
          dispatch(setStaffName(defaultNurse.label.displayLastNameFirst));
        }
      }
    }
  }, [nurses, defaultNurseId, appointmentType]);

  useEffect(() => {
    if (appointmentType === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP) {
      if (!staffId && defaultNurseId && nurses) {
        if (nurses?.length > 0) {
          const defaultNurse = nurses?.find(
            (nurse) => nurse.value === defaultNurseId
          );
          if (defaultNurse) {
            dispatch(setStaffId(defaultNurse.value));
            dispatch(setStaffName(defaultNurse.label.displayLastNameFirst));
          }
        }
      }

      if (recurrence !== RecurrenceFrequencyEnum.NEVER) {
        // change recurrence to "never" for setup appointments
        dispatch(setRecurrence(RecurrenceFrequencyEnum.NEVER));
      }
    }
  }, [nurses, defaultNurseId, appointmentType, recurrence]);

  useEffect(() => {
    if (
      !staffId &&
      defaultProviderId &&
      providers &&
      appointmentType === AppointmentTypeEnum.PROVIDER_FOLLOWUP
    ) {
      if (providers?.length > 0) {
        const defaultProvider = providers?.find(
          (provider) => provider.value === defaultProviderId
        );
        if (defaultProvider) {
          dispatch(setStaffId(defaultProvider.value));
          dispatch(setStaffName(defaultProvider.label));
        }
      }
    }
  }, [providers, defaultProviderId, appointmentType]);

  useEffect(() => {
    if (!recurrence && defaultRecurrence) {
      dispatch(setRecurrence(defaultRecurrence));
    }
  }, [recurrence, defaultRecurrence]);

  const mappedNurses = useMemo(() => {
    return nurses?.map((nurse) => ({
      patient_count: nurse.label.patient_count,
      fullname: nurse.label.displayLastNameFirst,
      value: nurse.value
    }));
  }, [nurses]);

  useEffect(() => {
    // If the nurseId is set in the query param, autopopulate the nurse select dropdown with this nurse
    const nurse = mappedNurses?.find((nurse) => nurse.value === searchNurseId);
    if (!isFalsy(nurse)) {
      dispatch(setStaffId(nurse.value));
      dispatch(setStaffName(nurse.fullname));
    }
  }, [searchNurseId, mappedNurses]);

  const selectedNurse = useMemo(() => {
    return mappedNurses?.find((nurse) => nurse.value === staffId);
  }, [staffId, mappedNurses]);

  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);

  const appointmentTypeOnChange = useCallback(
    (e) => {
      // if changing from one telehealth appointment type to another, keep the nurse selected
      if (
        !(
          (appointmentType === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP &&
            (e.target.value as AppointmentTypeEnum) ===
              AppointmentTypeEnum.NURSE_FOLLOWUP) ||
          (appointmentType === AppointmentTypeEnum.NURSE_FOLLOWUP &&
            (e.target.value as AppointmentTypeEnum) ===
              AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP)
        )
      ) {
        // else reset it
        dispatch(setStaffId(null));
        dispatch(setStaffName(null));
      }
      dispatch(setStartEndDate({ startDate: null, endDate: null }));
      setAppointmentTypeState(e.target.value as AppointmentTypeEnum);
    },
    [appointmentType]
  );

  return (
    <Flexbox flexDirection="column" gap="16px">
      <Flexbox
        flexDirection="column"
        padding="20px"
        borderRadius="12px"
        border={`1px solid ${gray[300]}`}
        gap="20px"
      >
        <Flexbox flexDirection="column" gap="8px">
          {(appointmentType === AppointmentTypeEnum.NURSE_FOLLOWUP ||
            appointmentType === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP) && (
            <>
              <Flexbox gap="2px">
                <Typography variant="h5" color="text.primary">
                  Nurse
                </Typography>
              </Flexbox>
              {nursesError && (
                <ErrorComponent
                  error={"Error getting nurse list, please try again later."}
                />
              )}
              {mappedNurses?.length > 0 && (
                <Autocomplete
                  open={autocompleteIsOpen}
                  onOpen={() => setAutocompleteIsOpen(true)}
                  onClose={(e, reason) => {
                    setAutocompleteIsOpen(false);
                  }}
                  options={mappedNurses}
                  componentsProps={{
                    popper: {
                      placement: "bottom",
                      sx: { maxWidth: "186px" }
                    }
                  }}
                  // this needs to be null for autocomplete to prevent a console warning https://stackoverflow.com/a/77059921
                  value={selectedNurse ?? null}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value.value
                  }
                  getOptionLabel={(label) => {
                    if (label) {
                      return `${label?.fullname}`;
                    }
                    return "";
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Flexbox
                        key={option.value}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: gray[200]
                          }
                        }}
                        padding="6px 12px"
                        onClick={() => {
                          dispatch(setStaffId(option.value));
                          dispatch(setStaffName(option.fullname));
                          setAutocompleteIsOpen(false);
                        }}
                        justifyContent="space-between"
                      >
                        <Typography
                          data-testid={option?.fullname ?? "N/A"}
                          sx={{ wordBreak: "break-all" }}
                        >
                          {option?.fullname}
                        </Typography>
                        {/* <Typography>{option?.patient_count}</Typography> */}
                      </Flexbox>
                    );
                  }}
                  onChange={(e, value) => {
                    const selectedValue = nurses?.find(
                      (nurse) => nurse.value === value?.value
                    );
                    if (selectedValue) {
                      dispatch(
                        setStartEndDate({ startDate: null, endDate: null })
                      );
                      dispatch(setStaffId(value.value));
                      dispatch(setStaffName(value.fullname));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Nurse"
                      name="selectedNurse"
                      id="selectedNurse"
                      data-testid="Select Nurse"
                    />
                  )}
                />
              )}
              {nursesIsLoading && <LoadingFallback count={1} />}
            </>
          )}
          {appointmentType === AppointmentTypeEnum.PROVIDER_FOLLOWUP && (
            <>
              <Typography variant="h5" color="text.primary">
                Provider
              </Typography>
              {providersError && (
                <ErrorComponent
                  error={"Error getting provider list, please try again later."}
                />
              )}
              {(providers?.length === 0 || isFalsy(memberState)) &&
                !nursesIsLoading && (
                  <ErrorComponent error="Error: no providers found in member's state of residence." />
                )}
              {providers?.length > 0 && (
                <TextField
                  select
                  fullWidth
                  value={staffId ?? ""}
                  disabled={
                    operationType === AppointmentOperationTypeEnum.UPDATE
                  }
                  label={"Select a provider"}
                  id="selectedProvider"
                  onChange={(e) => {
                    const selectedValue = providers?.find(
                      (items) => items.value === e.target.value
                    );
                    dispatch(
                      setStartEndDate({ startDate: null, endDate: null })
                    );
                    dispatch(setStaffId(selectedValue.value));
                    dispatch(setStaffName(selectedValue.label));
                  }}
                >
                  {providers.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {nursesIsLoading && <LoadingFallback count={1} />}
            </>
          )}
        </Flexbox>
        {/* TBD comment this back in when we want to support audio/video calls */}

        {/* <Flexbox flexDirection="column" gap="8px" minWidth="max-content">
        <Typography variant="h5" color="text.primary">
          Type/Modality
        </Typography>
        <Box sx={{ borderRadius: "8px", border: `1px solid ${gray[300]}` }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={communication_type}
            onChange={(e) => dispatch(setCommunicationType(e.target.value))}
          >
            <FormControlLabel
              sx={{
                width: "100%",
                margin: 0,
                borderBottom: `1px solid ${gray[300]}`,
              }}
              value="PHONE"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Audio Call
                  <PhoneOutlined />
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                width: "100%",
                margin: 0,
              }}
              value="VIDEO"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Video Call
                  <VideocamOutlined />
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </Flexbox> */}
        {appointmentType === AppointmentTypeEnum.NURSE_FOLLOWUP && (
          <Flexbox flexDirection="column" gap="8px">
            <Typography variant="h5" color="text.primary">
              Recurring Event
            </Typography>
            <TextField
              select
              fullWidth
              value={recurrence ?? ""}
              label={
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <Repeat />
                  Repeat
                </Typography>
              }
              id="recurrence"
              defaultValue={defaultRecurrence}
              onChange={(e) => {
                dispatch(setRecurrence(e.target.value));
              }}
            >
              {recurrenceValues.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            {operationType === AppointmentOperationTypeEnum.UPDATE && (
              <TextField
                select
                fullWidth
                value={recurrence ?? ""}
                label={
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <Repeat />
                    Repeat
                  </Typography>
                }
                id="recurrence"
                defaultValue={defaultRecurrence}
                onChange={(e) => {
                  dispatch(setRecurrence(e.target.value));
                }}
              >
                {recurrenceValues.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Flexbox>
        )}
      </Flexbox>
      {appointmentType === AppointmentTypeEnum.PROVIDER_FOLLOWUP &&
        defaultProviderId &&
        defaultProviderId !== staffId &&
        !isFalsy(staffId) && (
          <Flexbox sx={{ background: warning[100] }}>
            <Flexbox flexDirection="row" gap="8px" padding="8px">
              <ErrorOutlineOutlined />
              <Typography fontSize="12px" color="text.primary">
                This Member already has an assigned Provider, changing it may
                negatively impact the member experience.
              </Typography>
            </Flexbox>
          </Flexbox>
        )}
    </Flexbox>
  );
}

function NurseOrProviderHeading({ staff }) {
  if (
    staff?.roles?.includes("NURSE_PROVIDER") ||
    staff?.roles?.includes("MD_PROVIDER")
  ) {
    return (
      <Typography variant="h5" color="text.primary">
        Provider
      </Typography>
    );
  } else {
    return (
      <Typography variant="h5" color="text.primary">
        Nurse
      </Typography>
    );
  }
}

function AppointmentSidebarUpdate({ staff, recurring, event }) {
  const { staffId, recurrenceUpdateType } = useSelector(
    (state: RootState) => state.appointment
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!staffId && staff) {
      dispatch(setStaffId(staff?.id));
    }
  }, [staffId, staff]);

  useEffect(() => {
    if (event?.communication_type) {
      dispatch(setCommunicationType(event?.communication_type));
    }
    if (event?.startdate && event?.enddate) {
      dispatch(
        setStartEndDate({
          startDate: event?.startdate,
          endDate: event?.enddate
        })
      );
    }
  }, [event]);

  return (
    <Flexbox
      flexDirection="column"
      padding="20px"
      borderRadius="12px"
      border={`1px solid ${gray[300]}`}
      gap="20px"
    >
      <Flexbox flexDirection="column" gap="8px">
        <NurseOrProviderHeading staff={staff} />
        <Typography
          variant="body1"
          color="text.primary"
          whiteSpace={"normal"}
          sx={{ wordBreak: "break-all" }}
        >
          {getNameOrUsername(staff, false)}
        </Typography>
      </Flexbox>
      {/* <Flexbox flexDirection="column" gap="8px" minWidth="max-content">
        <Typography variant="h5" color="text.primary">
          Type/Modality
        </Typography>
        <Box sx={{ borderRadius: "8px", border: `1px solid ${gray[300]}` }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={communication_type}
            onChange={(e) => dispatch(setCommunicationType(e.target.value))}
          >
            <FormControlLabel
              sx={{
                width: "100%",
                margin: 0,
                borderBottom: `1px solid ${gray[300]}`,
              }}
              value="PHONE"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Audio Call
                  <PhoneOutlined />
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                width: "100%",
                margin: 0,
              }}
              value="VIDEO"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Video Call
                  <VideocamOutlined />
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </Flexbox> */}
      {recurring && (
        <Flexbox flexDirection="column" gap="8px">
          <Typography variant="h5" color="text.primary">
            Recurring Event
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={recurrenceUpdateType ?? ""}
            onChange={(e) => dispatch(setRecurrenceUpdateType(e.target.value))}
          >
            {recurrenceUpdateTypeValues.map(({ value, label }, i) => (
              <Flexbox marginTop="4px" key={value}>
                <FormControlLabel
                  sx={{
                    width: "100%",
                    margin: 0,
                    ...(i !== recurrenceUpdateTypeValues.length - 1 && {
                      borderBottom: `1px solid ${gray[300]}`
                    })
                  }}
                  value={value}
                  control={<Radio sx={{ padding: "4px 0" }} />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        marginLeft: "2px"
                      }}
                    >
                      {label}
                    </Typography>
                  }
                />
              </Flexbox>
            ))}
          </RadioGroup>
        </Flexbox>
      )}
    </Flexbox>
  );
}

function CreateAppointmentSubmitContainer({
  refetchStaffMemberAvailability,
  refetchCalendarEvents,
  staffScheduleMap,
  memberAppointmentTypeMap
}: Readonly<{
  refetchStaffMemberAvailability: () => void;
  refetchCalendarEvents: () => void;
  staffScheduleMap: { [key: string]: any };
  memberAppointmentTypeMap: { [key: string]: any };
}>) {
  const {
    selectedStartDate,
    selectedEndDate,
    communication_type,
    attendees,
    staffId,
    staffName,
    memberName,
    memberTimezone,
    recurrence,
    appointmentType,
    assignedNurseId,
    xTraceId
  } = useSelector((state: RootState) => state.appointment);

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const timerRef = useRef(null);

  useEffect(() => {
    if (
      !isFalsy(selectedStartDate) &&
      !isFalsy(selectedEndDate) &&
      !isFalsy(communication_type) &&
      !isFalsy(attendees) &&
      !isFalsy(staffId) &&
      recurrence
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    selectedStartDate,
    selectedEndDate,
    communication_type,
    attendees,
    staffId,
    recurrence
  ]);

  const [
    createCalendarEventMutation,
    {
      data: createCalendarEventData,
      isLoading: createCalendarEventIsLoading,
      fulfilledTimeStamp: createCalendarFulfilledTimestamp,
      error: createCalendarEventError
    }
  ] = useCreateCalendarEventMutation();

  const [assignNursePatientMutation, { error: assignNursePatientError }] =
    useUpdateMemberCarersMutation();

  const onSubmit = useCallback(() => {
    const recurrenceObject = getRecurrenceObject(
      recurrence,
      selectedStartDate,
      selectedEndDate,
      memberTimezone
    );

    let hasConflictingAppointment = false;

    const selectedYearMonthDay =
      DateTime.fromISO(selectedStartDate)?.toFormat("yyyy-MM-dd");

    const staffAvailabilityForSelectedDate =
      staffScheduleMap?.[selectedYearMonthDay];

    if (staffAvailabilityForSelectedDate) {
      const keys = Object.keys(staffAvailabilityForSelectedDate);

      keys?.forEach((key) => {
        if (staffAvailabilityForSelectedDate?.[key]?.hasEventWithMemberToday) {
          hasConflictingAppointment = true;
        }
      });
    }

    const memberAppointmentTypeForSelectedDate =
      memberAppointmentTypeMap?.[selectedYearMonthDay];

    if (
      // ENG-7125 only have this check for provider appointments now. In the future we may
      // add this check to nurse appointments too and would need to remove this line.
      isProviderAppointment(appointmentType) &&
      memberAppointmentTypeForSelectedDate?.[appointmentType] > 0
    ) {
      hasConflictingAppointment = true;
    }

    if (hasConflictingAppointment) {
      const id = "conflictingAppointmentError";
      Alert_show({
        dispatch,
        id,
        title: "Multiple Visits Detected",
        content: (
          <Box>
            <Typography variant="body1">
              This member already has an appointment scheduled for this day.
              Please select a different day.
            </Typography>
          </Box>
        ),
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Okay",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else {
      createCalendarEventMutation({
        body: {
          attendees,
          start_date: selectedStartDate,
          end_date: selectedEndDate,
          communication_type,
          appointment_type: appointmentType,
          created_by: staffId,
          ...(recurrenceObject && { recurrence: recurrenceObject })
        },
        staffId,
        xTraceId,
        patientId: attendees?.[0]?.patient_id
      });
      if (
        currentRole === "NP_NURSE" &&
        staffId !== assignedNurseId &&
        isNurseAppointment(appointmentType)
      ) {
        const assignNursePatientRequest = {
          patient_id: attendees?.[0]?.patient_id,
          carers: {
            nurse_id: staffId
          }
        };
        assignNursePatientMutation(assignNursePatientRequest);
      }
    }
  }, [
    selectedStartDate,
    selectedEndDate,
    communication_type,
    attendees,
    staffId,
    recurrence,
    memberTimezone,
    assignedNurseId,
    staffName,
    staffScheduleMap,
    memberAppointmentTypeMap
  ]);

  useEffect(() => {
    if (createCalendarEventData) {
      timerRef.current = setTimeout(() => {
        dispatch(resetAppointmentSelections());
        const membersPathPattern = ":memberId";
        const link = replace(
          Routes.MEMBER_DETAILS(""),
          membersPathPattern,
          `${attendees?.[0]?.patient_id}/appointments`
        );
        navigate(link);
        const id = "createCalendarEventSuccess";
        const dateString = `${DateTime.fromISO(selectedStartDate)
          .setZone(memberTimezone)
          .toFormat("cccc LLL, d h:mm")}
        -
        ${DateTime.fromISO(selectedEndDate)
          .setZone(memberTimezone)
          .toFormat("h:mm a ZZZZ")}`;

        const visitType = getAppointmentTypeReadableName(appointmentType);
        Alert_show({
          dispatch,
          id,
          title: "Appointment Scheduled",
          content: (
            <Box>
              <div>Type: {visitType}</div>
              <div>Carer: {staffName}</div>
              <div>Member: {memberName}</div>
              <div>Date: {dateString}</div>
            </Box>
          ),
          type: "success",
          size: "medium",
          buttons: [
            {
              text: "Close",
              onPress: () => {
                Alert_close({ dispatch, id });
              }
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [createCalendarEventData]);

  useEffect(() => {
    if (createCalendarEventError) {
      const id = "createCalendarEventError";
      refetchCalendarEvents();
      refetchStaffMemberAvailability();
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={createCalendarEventError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [createCalendarEventError]);

  const isLoading = useGetDelayedLoadingBoolean(
    createCalendarEventIsLoading,
    createCalendarFulfilledTimestamp,
    createCalendarEventData,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  return (
    <Flexbox flexDirection="column" gap="8px">
      <LoadingButton
        variant="contained"
        disabled={isButtonDisabled}
        onClick={onSubmit}
        loading={isLoading}
      >
        Submit
      </LoadingButton>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(-1);
          dispatch(resetAppointmentState());
        }}
      >
        Back
      </Button>
    </Flexbox>
  );
}

function UpdateAppointmentSubmitContainer({
  defaultEventId,
  recurring,
  patientId,
  event,
  refetchStaffMemberAvailability,
  refetchCalendarEvents,
  staffScheduleMap,
  memberAppointmentTypeMap
}: Readonly<{
  defaultEventId?: string;
  recurring?: boolean;
  patientId: string;
  event: CalendarEventResponseType;
  refetchStaffMemberAvailability: () => void;
  refetchCalendarEvents: () => void;
  staffScheduleMap: { [key: string]: any };
  memberAppointmentTypeMap: { [key: string]: any };
}>) {
  const {
    selectedStartDate,
    selectedEndDate,
    communication_type,
    attendees,
    staffId,
    recurrenceUpdateType,
    memberName,
    eventId,
    appointmentType
  } = useSelector((state: RootState) => state.appointment);

  const timerRef = useRef(null);

  useEffect(() => {
    if (!eventId && defaultEventId) {
      dispatch(setEventId(defaultEventId));
    }
  }, [eventId, defaultEventId]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    startdate: originalStartDate,
    enddate: originalEndDate,
    communication_type: originalCommunicationType
  } = event;

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  // disable button if nothing has changed
  useEffect(() => {
    if (
      (originalStartDate === selectedStartDate &&
        originalEndDate === selectedEndDate &&
        originalCommunicationType === communication_type) ||
      isFalsy(selectedStartDate) ||
      isFalsy(selectedEndDate)
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [
    originalStartDate,
    originalEndDate,
    originalCommunicationType,
    selectedStartDate,
    selectedEndDate,
    communication_type,
    selectedStartDate,
    selectedEndDate,
    communication_type
  ]);

  const [
    updateCalendarEventMutation,
    {
      data: updateCalendarEventData,
      isLoading: updateCalendarEventIsLoading,
      error: updateCalendarEventError,
      fulfilledTimeStamp: updateCalendarEventFulfilledTimestamp
    }
  ] = useUpdateCalendarEventMutation();

  const onSubmit = useCallback(() => {
    let hasConflictingAppointment = false;

    const selectedYearMonthDay =
      DateTime.fromISO(selectedStartDate)?.toFormat("yyyy-MM-dd");

    const staffAvailabilityForSelectedDate =
      staffScheduleMap?.[selectedYearMonthDay];

    if (
      // ENG-7125 only have this check for provider appointments now. In the future we may
      // add this check to nurse appointments too and would need to remove this line.
      isProviderAppointment(appointmentType) &&
      staffAvailabilityForSelectedDate
    ) {
      const keys = Object.keys(staffAvailabilityForSelectedDate);

      keys?.forEach((key) => {
        if (
          staffAvailabilityForSelectedDate?.[key]?.hasEventWithMemberToday &&
          staffAvailabilityForSelectedDate?.[key]?.eventId !== eventId
        ) {
          hasConflictingAppointment = true;
        }
      });
    }

    const memberAppointmentTypeForSelectedDate =
      memberAppointmentTypeMap?.[selectedYearMonthDay];

    if (
      (memberAppointmentTypeForSelectedDate?.[event?.appointment_type] > 1 &&
        memberAppointmentTypeForSelectedDate?.event_ids?.findIndex(
          (id) => id === eventId
        ) > -1) ||
      (memberAppointmentTypeForSelectedDate?.[event?.appointment_type] > 0 &&
        memberAppointmentTypeForSelectedDate?.event_ids?.findIndex(
          (id) => id === eventId
        ) === -1)
    ) {
      hasConflictingAppointment = true;
    }

    if (hasConflictingAppointment) {
      const id = "conflictingAppointmentError";
      Alert_show({
        dispatch,
        id,
        title: "Multiple Visits Detected",
        content: (
          <Box>
            <Typography variant="body1">
              This member already has an appointment scheduled for this day.
              Please select a different day.
            </Typography>
          </Box>
        ),
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Okay",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else {
      updateCalendarEventMutation({
        body: {
          update_type: recurrenceUpdateType,
          ...(originalStartDate !== selectedStartDate && {
            start_date: selectedStartDate,
            end_date: selectedEndDate
          }),
          ...(originalCommunicationType !== communication_type && {
            communication_type
          })
        },
        eventId,
        staffId,
        patientId
      });
    }
  }, [
    selectedStartDate,
    selectedEndDate,
    communication_type,
    attendees,
    eventId,
    event,
    recurrenceUpdateType,
    originalStartDate,
    originalEndDate,
    originalCommunicationType,
    staffScheduleMap,
    memberAppointmentTypeMap,
    appointmentType
  ]);

  useEffect(() => {
    if (updateCalendarEventData) {
      timerRef.current = setTimeout(() => {
        dispatch(resetAppointmentSelections());
        const membersPathPattern = ":memberId";
        const link = replace(
          Routes.MEMBER_DETAILS(""),
          membersPathPattern,
          `${attendees?.[0]?.patient_id}/appointments`
        );
        navigate(link, { state: { shouldRefetch: true } });
        const id = "updateCalendarEventSuccess";
        Alert_show({
          dispatch,
          id,
          title: "Success",
          content: `You have successfully updated an appointment for member ${memberName}`,
          type: "success",
          size: "small",
          buttons: [
            {
              text: "Close",
              onPress: () => {
                Alert_close({ dispatch, id });
              }
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [updateCalendarEventData]);

  useEffect(() => {
    if (updateCalendarEventError) {
      const id = "updateCalendarEventError";
      refetchCalendarEvents();
      refetchStaffMemberAvailability();
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={updateCalendarEventError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateCalendarEventError]);

  const isLoading = useGetDelayedLoadingBoolean(
    updateCalendarEventIsLoading,
    updateCalendarEventFulfilledTimestamp,
    updateCalendarEventData
  );

  return (
    <Flexbox flexDirection="column" gap="8px">
      <LoadingButton
        variant="contained"
        disabled={isButtonDisabled}
        onClick={onSubmit}
        loading={isLoading}
      >
        Edit
      </LoadingButton>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(-1);
          dispatch(resetAppointmentState());
        }}
      >
        Back
      </Button>
    </Flexbox>
  );
}

const CreateUpdateAppointment = () => {
  const { state } = useLocation();
  const eventId = state?.eventId;
  const { data: calendarEventData, isFetching } = useGetCalendarEventQuery(
    {
      event_id: eventId
    },
    { skip: !checkIdValid(eventId) }
  );

  const traceId = useMemo(() => `appointments-${uuidv4()}`, []);

  useEffect(() => {
    dispatch(setXTraceId(traceId));
  }, [traceId]);

  return (
    <>
      {isFetching ? (
        <LoadingFallback count={1} />
      ) : (
        <CreateUpdateAppointmentRender
          editEventRecurrenceFrequency={
            calendarEventData?.recurrence?.frequency
          }
        />
      )}
    </>
  );
};

const CreateUpdateAppointmentRender = ({ editEventRecurrenceFrequency }) => {
  const params = useSanitizedParams();
  const { state, pathname } = useLocation();
  const appointmentTypeFromPathname = useMemo(() => {
    if (pathname.includes("provider-appointment")) {
      return AppointmentTypeEnum.PROVIDER_FOLLOWUP;
    }
    if (pathname.includes("nurse-appointment")) {
      return AppointmentTypeEnum.NURSE_FOLLOWUP;
    }
    return null;
  }, [pathname]);

  const operationType =
    state?.operationType ?? AppointmentOperationTypeEnum.CREATE;
  const eventId = state?.eventId;
  const staff = state?.staff;
  const recurring = state?.recurring;
  const event = state?.event;
  const editAppointmentType = state?.appointmentType;

  let memberId = useMemo(() => {
    return params?.memberId ?? state?.memberId;
  }, [params?.memberId, state?.memberId]);

  const isValidId = checkIdValid(memberId);

  const dispatch = useAppDispatch();

  const { user, currentRole } = useSelector((state: RootState) => state.auth);
  const {
    staffId,
    memberTimezone,
    appointmentType,
    recurrence,
    recurrenceUpdateType,
    xTraceId
  } = useSelector((state: RootState) => state.appointment);

  const [availabilityMap, setAvailabilityMap] = useState<{
    [key: string]: StaffAvailabilityObject[];
  }>({});
  const [staffScheduleMap, setStaffScheduleMap] = useState<{
    [key: string]: any;
  }>({});
  const [appointmentTypeMap, setAppointmentTypeMap] = useState<{
    [key: string]: any;
  }>({});

  const today = useMemo(() => {
    return DateTime.now().startOf("day");
  }, []);

  const defaultMemberEventStartDate = useMemo(() => {
    return getStartDateForMemberEvents(
      user?.timezone,
      currentRole,
      event?.startdate
    );
  }, [user?.timezone, currentRole, event?.startdate]);

  const startDate = useMemo(() => {
    const start = getStartDate(user?.timezone, currentRole, event?.startdate);
    // we want to expand the window for editing provider appointments
    // https://copilotiq.atlassian.net/browse/ENG-7193?focusedCommentId=19636
    if (isProviderAppointment(editAppointmentType)) {
      return today < start
        ? // stop user from seeing dates in the past
          DateTime.now().endOf("hour")
        : start;
    }

    // else use the normal start date
    return start;
  }, [[user?.timezone, currentRole, event?.startdate]]);

  useEffect(() => {
    if (Object.keys(availabilityMap)?.length > 0) {
      const isAvailableFirstWeek = hasAvailabilityInCurrentWeek(
        startDate,
        availabilityMap
      );

      if (!isAvailableFirstWeek) {
        const nextWeek = startDate.plus({ weeks: 1 });
        setSelectedDate(nextWeek);
      }
    }
  }, [availabilityMap, staffId]);

  const endDate = useMemo(() => {
    const threeMonthsOut = DateTime.now()
      .startOf("day")
      .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });

    if (isProviderAppointment(appointmentTypeFromPathname)) {
      const providerCalculatedEndDate = startDate
        .startOf("day")
        .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });
      // we need this for intake nurses - since they have a 4 business day ahead
      // scheduling restriction, we need to limit the end date
      return threeMonthsOut < providerCalculatedEndDate
        ? threeMonthsOut
        : providerCalculatedEndDate;
    } else if (isProviderAppointment(editAppointmentType)) {
      const providerEditCalculatedEndDate = startDate
        .startOf("day")
        .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING - 7 });
      // we need this for intake nurses - since they have a 4 business day ahead
      // scheduling restriction, we need to limit the end date
      return threeMonthsOut < providerEditCalculatedEndDate
        ? threeMonthsOut
        : providerEditCalculatedEndDate;
    }
    // nurse appointments - six weeks lookahead
    return startDate.endOf("week").plus({ days: DAYS_TO_LOOK_AHEAD });
  }, [startDate, appointmentTypeFromPathname]);

  const defaultSelectedDate = useMemo(() => {
    if (isProviderAppointment(editAppointmentType)) {
      return defaultMemberEventStartDate;
    }
    // default to start date
    return startDate;
  }, [startDate, defaultMemberEventStartDate, editAppointmentType]);

  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);

  useEffect(() => {
    if (isFalsy(appointmentType) && editAppointmentType) {
      dispatch(setAppointmentType(editAppointmentType));
    }
  }, [editAppointmentType, appointmentType]);

  const { data: memberData, error: memberError } =
    useGetMemberWithUsernameQuery(
      {
        username: memberId,
        linked_entities: [
          MemberLinkedEntitiesEnum.PROVIDER,
          MemberLinkedEntitiesEnum.NURSE
        ]
      },
      { skip: !checkIdValid(memberId) }
    );

  useEffect(() => {
    if (memberData?.patient?.email) {
      dispatch(
        setAttendees([
          {
            attendee_type: "PATIENT",
            patient_id: memberId,
            email: memberData?.patient?.email
          }
        ])
      );
      dispatch(setMemberName(getNameOrUsername(memberData?.patient)));
    }
  }, [memberData]);

  // new panel management availability endpoint

  const {
    data: availabilityData,
    isFetching: availabilityIsFetching,
    error: availabilityError,
    isUninitialized: availabilityIsUninitialized,
    refetch: refetchStaffMemberAvailability
  } = useGetStaffAvailabilityQuery(
    {
      staff_id: staffId,
      startdate: startDate?.toISO(),
      enddate: endDate?.toISO(),
      xTraceId,
      // creating an appointment
      ...(recurrence !== "NEVER" && { recurrence_frequency: recurrence }),
      // recurrence when updating an existing appointment - this goes here so it overwrites the above
      ...(recurrenceUpdateType !== "ONCE" &&
        recurring &&
        editEventRecurrenceFrequency && {
          recurrence_frequency:
            editEventRecurrenceFrequency ?? RecurrenceFrequencyEnum.WEEKLY
        })
    },
    { skip: !staffId }
  );

  const {
    data: staffScheduleData,
    isFetching: staffScheduleIsFetching,
    error: staffScheduleError,
    refetch: refetchStaffCalendarEvents
  } = useGetCalendarEventsQuery(
    {
      staff_id: staffId,
      startdate: startDate,
      // get 12 weeks of events to look ahead and check availability for recurring appointments
      enddate: endDate
    },
    { skip: isFalsy(staffId) }
  );

  const {
    data: memberScheduleData,
    isFetching: memberScheduleIsFetching,
    error: memberScheduleError,
    refetch: refetchMemberCalendarEvents
  } = useGetCalendarEventsQuery(
    {
      patient_id: memberId,
      startdate: defaultMemberEventStartDate,
      // get 12 weeks of events to look ahead and check availability for recurring appointments
      enddate: endDate
    },
    { skip: isFalsy(memberId) }
  );

  const refetchCalendarEvents = useCallback(() => {
    if (isFalsy(staffId) && isFalsy(memberId)) {
      return;
    } else if (isFalsy(memberId)) {
      refetchStaffCalendarEvents();
    } else if (isFalsy(staffId)) {
      refetchMemberCalendarEvents();
    } else {
      refetchStaffCalendarEvents();
      refetchMemberCalendarEvents();
    }
  }, [
    refetchMemberCalendarEvents,
    refetchStaffCalendarEvents,
    memberId,
    staffId
  ]);

  // once we get staff schedule data, create a dictionary of events by date
  useEffect(() => {
    const memberTZ = memberData?.patient?.timezone;
    const staffScheduleMap = {};
    // create map

    for (let i = 0; i < DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING; i++) {
      const date = startDate.plus({ days: i });
      const dateKey = date.setZone(memberTZ).toISODate();
      if (!staffScheduleMap[dateKey]) {
        staffScheduleMap[dateKey] = {};
      }
    }
    if (memberTZ && staffScheduleData?.length > 0) {
      // add events to map
      staffScheduleData.forEach((item) => {
        // we aren't currently using this map for non member appointments
        // leaving this comment here - in case we need to add it in the future, remove the below if statement
        if (isMemberAppointment(item?.appointment_type)) {
          const date = DateTime.fromISO(item.startdate).setZone(memberTZ);
          const dateKey = date.toISODate();

          const startendKey = `${item.startdate}-${item.enddate}`;

          if (!staffScheduleMap[dateKey]) {
            staffScheduleMap[dateKey] = {};
          }

          if (!staffScheduleMap[dateKey][startendKey]) {
            staffScheduleMap[dateKey][startendKey] = {
              appointments: {
                NURSE_FOLLOWUP: 0,
                PROVIDER_FOLLOWUP: 0,
                TELEHEALTH_NURSE_SETUP: 0
              }
            };
          }

          const staffScheduleAccessor = staffScheduleMap[dateKey][startendKey];

          const appointmentsAccessor = staffScheduleAccessor.appointments;

          if (appointmentsAccessor?.[item.appointment_type]) {
            appointmentsAccessor[item.appointment_type] =
              appointmentsAccessor[item.appointment_type] + 1;
          } else {
            appointmentsAccessor[item.appointment_type] = 1;
          }

          if (item?.attendees?.[0]?.attendee_id === memberId) {
            staffScheduleAccessor.hasEventWithMemberToday = true;
            staffScheduleAccessor.eventId = item.event_id;
          }
        }
      });

      setStaffScheduleMap(staffScheduleMap);
    } else {
      setStaffScheduleMap(staffScheduleMap);
    }
  }, [memberData, memberId, staffScheduleData]);

  useEffect(() => {
    const memberTZ = memberData?.patient?.timezone;
    if (memberTZ && memberScheduleData?.length > 0) {
      const memberAppointmentTypeMap = {};

      // add events to map
      memberScheduleData.forEach((item) => {
        const date = DateTime.fromISO(item.startdate).setZone(memberTZ);
        const dateKey = date.toISODate();

        if (!memberAppointmentTypeMap[dateKey]) {
          memberAppointmentTypeMap[dateKey] = {
            NURSE_FOLLOWUP: 0,
            PROVIDER_FOLLOWUP: 0,
            TELEHEALTH_NURSE_SETUP: 0,
            event_ids: []
          };
        }

        if (isMemberAppointment(item?.appointment_type)) {
          memberAppointmentTypeMap[dateKey][item.appointment_type] =
            memberAppointmentTypeMap[dateKey][item.appointment_type]
              ? memberAppointmentTypeMap[dateKey][item.appointment_type] + 1
              : 1;

          memberAppointmentTypeMap[dateKey].event_ids.push(item?.event_id);
        }
      });

      setAppointmentTypeMap(memberAppointmentTypeMap);
    }
  }, [memberData, memberScheduleData]);

  useEffect(() => {
    const memberTZ = memberData?.patient?.timezone;
    if (memberTZ && memberTimezone !== memberTZ) {
      dispatch(setMemberTimezone(memberTZ));
    }
    dispatch(setAssignedNurseId(memberData?.assigned_nurse?.user_id));
    if (memberTZ && availabilityData?.length > 0) {
      const availabilityMap = {};

      // create map
      for (let i = 0; i < DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING; i++) {
        const date = startDate.plus({ days: i });
        const dateKey = date.setZone(memberData?.patient?.timezone).toISODate();
        if (!availabilityMap[dateKey]) {
          availabilityMap[dateKey] = [];
        }
      }

      // add events to map
      availabilityData.forEach((availability) => {
        const date = DateTime.fromISO(availability.start).setZone(memberTZ);

        // tbd change this to only be for TNs once we support provider appointments
        if (!dateIsBlocked(availability)) {
          const dateKey = date.toISODate();

          if (!availabilityMap[dateKey]) {
            availabilityMap[dateKey] = [];
          }

          // skip holidays - this check should be on the backend too
          if (isBusinessDay(date)) {
            availabilityMap[dateKey].push(availability);
          }
        }
      });
      setAvailabilityMap(availabilityMap);
    }
  }, [memberData, availabilityData]);

  const {
    data: nurses,
    error: nursesError,
    isLoading: nursesIsLoading
  } = useGetSortedMembersWithActiveNursesQuery(
    {
      carer_roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER],
      carer_status: [UserStatusEnum.ACTIVE],
      getNurseListForAppointments: true,
      is_excluded_from_scheduling: false
    },
    { skip: !canScheduleNurses(currentRole) }
  );

  const { data: providers, error: providersError } =
    useGetProvidersMetadataQuery(
      {
        state: memberData?.patient?.address?.state,
        linked_entities: [MemberLinkedEntitiesEnum.PROVIDER]
      },
      {
        skip:
          isFalsy(memberData?.patient?.address?.state) ||
          !canScheduleProviders(currentRole)
      }
    );

  const defaultProviderId = memberData?.patient_provider?.user_id;

  const providersList = useMemo(() => {
    return providers
      ?.map((provider) => {
        let displayName = getNameOrUsername(provider?.provider);
        const value = provider?.provider?.user_id;

        if (value === defaultProviderId) {
          displayName = `${displayName} (Assigned)`;
        }

        return { label: displayName, value };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [providers, memberData?.patient?.address?.state, defaultProviderId]);

  const defaultNurseId =
    currentRole === "NP_NURSE" || currentRole === "MEMBER_CARE_SPECIALIST"
      ? nurses?.[0]?.value
      : memberData?.assigned_nurse?.user_id;

  const defaultRecurrence = useMemo(() => {
    if (appointmentTypeFromPathname === AppointmentTypeEnum.PROVIDER_FOLLOWUP) {
      return RecurrenceFrequencyEnum.NEVER;
    } else {
      return RecurrenceFrequencyEnum.WEEKLY;
    }
  }, [appointmentTypeFromPathname]);

  const isFetching = useMemo(() => {
    return (
      availabilityIsFetching ||
      staffScheduleIsFetching ||
      memberScheduleIsFetching
    );
  }, [
    availabilityIsFetching,
    staffScheduleIsFetching,
    memberScheduleIsFetching
  ]);

  if (!isValidId) {
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );
  }

  if (memberError) {
    return <ErrorComponent error={memberError} />;
  }

  return (
    <CreateUpdateAppointmentContainer key={`${operationType}${eventId ?? ""}`}>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="scroll"
      >
        <MemberDetailsHeader />
        <Flexbox gap="8px" alignItems="center">
          <Typography variant="h2">
            {selectedDate.toFormat("MMMM y")}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.minus({ days: 7 }));
            }}
            disabled={
              startDate.toISODate() === selectedDate.toISODate() ||
              (isProviderAppointment(appointmentType) &&
                operationType === AppointmentOperationTypeEnum.UPDATE &&
                Math.abs(selectedDate.diff(startDate, "days").days) <= 7)
            }
          >
            <ChevronLeft />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.plus({ days: 7 }));
            }}
            disabled={
              (isProviderAppointment(appointmentType)
                ? Math.abs(selectedDate.diff(startDate, "days").days) >=
                  // only show 6 weeks of data into the future
                  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING - 7
                : Math.abs(selectedDate.diff(startDate, "days").days) >=
                  // only show 6 weeks of data into the future
                  DAYS_TO_LOOK_AHEAD - 7) ||
              (isProviderAppointment(appointmentType) &&
                operationType === AppointmentOperationTypeEnum.UPDATE &&
                Math.abs(selectedDate.diff(endDate, "days").days) <= 7)
            }
          >
            <ChevronRight />
          </Button>
          <Typography variant="h5">Times are in member time zone.</Typography>
        </Flexbox>
        {operationType === AppointmentOperationTypeEnum.UPDATE && (
          <Typography variant="h5" color="text.primary">
            Editing {recurring && "recurring"} appointment scheduled for{" "}
            {DateTime.fromISO(event.startdate)
              .setZone(memberData?.patient?.timezone)
              .toFormat("cccc LLL, d h:mm")}
            &nbsp;-&nbsp;
            {DateTime.fromISO(event.enddate)
              .setZone(memberData?.patient?.timezone)
              .toFormat("h:mm a")}
          </Typography>
        )}
        <Flexbox gap="15px" flexDirection="row">
          <Box
            flex="1"
            sx={{
              background: "#ffffff",
              border: `1px solid ${gray[300]}`,
              borderRadius: "12px",
              transition: "all 0.66s ease-out"
            }}
            width="100%"
            minWidth="min-content"
          >
            <DisplayWeekAvailability
              key={`appointment-${memberId}`}
              currentDay={selectedDate}
              startDate={startDate}
              appointmentType={appointmentType}
              recurrence={recurrence}
              recurrenceUpdateType={recurrenceUpdateType}
              availabilityMap={availabilityMap}
              availabilityIsFetching={isFetching}
              availabilityError={availabilityError}
              availabilityIsUninitialized={availabilityIsUninitialized}
              timezone={memberData?.patient?.timezone}
              staffScheduleMap={staffScheduleMap}
              appointmentTypeMap={appointmentTypeMap}
              staffScheduleError={staffScheduleError}
              memberScheduleError={memberScheduleError}
              operationType={operationType}
            />
          </Box>
          <Flexbox
            flexDirection="column"
            flexBasis="228px"
            width="228px"
            gap="20px"
            justifyContent="space-between"
          >
            {operationType === AppointmentOperationTypeEnum.CREATE && (
              <AppointmentSidebarCreate
                key={`appointment-${memberId}`}
                nurses={nurses}
                nursesError={nursesError}
                providers={providersList}
                providersError={providersError}
                nursesIsLoading={nursesIsLoading}
                defaultNurseId={defaultNurseId}
                defaultProviderId={defaultProviderId}
                defaultRecurrence={defaultRecurrence}
                operationType={operationType}
                appointmentTypeFromPathname={appointmentTypeFromPathname}
                memberState={memberData?.patient?.address?.state}
              />
            )}
            {operationType === AppointmentOperationTypeEnum.UPDATE && (
              <AppointmentSidebarUpdate
                key={`appointment-update-${memberId}`}
                staff={staff}
                recurring={recurring}
                event={event}
              />
            )}

            {operationType === AppointmentOperationTypeEnum.CREATE && (
              <CreateAppointmentSubmitContainer
                refetchStaffMemberAvailability={refetchStaffMemberAvailability}
                refetchCalendarEvents={refetchCalendarEvents}
                staffScheduleMap={staffScheduleMap}
                memberAppointmentTypeMap={appointmentTypeMap}
              />
            )}
            {operationType === AppointmentOperationTypeEnum.UPDATE && (
              <UpdateAppointmentSubmitContainer
                defaultEventId={eventId}
                recurring={recurring}
                patientId={memberId}
                event={event}
                refetchStaffMemberAvailability={refetchStaffMemberAvailability}
                refetchCalendarEvents={refetchCalendarEvents}
                staffScheduleMap={staffScheduleMap}
                memberAppointmentTypeMap={appointmentTypeMap}
              />
            )}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </CreateUpdateAppointmentContainer>
  );
};

export default CreateUpdateAppointment;
