import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "common/redux";
import { setSelectedSection, stopTicking } from "common/redux/VisitsSlice";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import {
  getNameOrUsername,
  FIFTEEN_SECONDS,
  isFalsy,
  isTruthy
} from "common/helpers/helpers";
import { VisitStateType } from "common/types/Visits/CareFlowStateType";

import { Modal } from "../../components/Modal";
import VisitContentSubSection from "./ui/VisitContentSubSection";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  CareFlowFieldType,
  CareFlowSubSectionType
} from "common/types/Visits/CareFlowResponseType";
import { CareFlowSectionsEnum } from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import {
  Alert_close,
  Alert_closeAll,
  Alert_show
} from "common/helpers/AlertHelper";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { useFeatureFlags } from "common/config/FeatureFlags";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { useInterval } from "common/hooks/useInterval";
import { CustomTooltip } from "../../styling/StyleComponents";
import { getElapsedTime, isDisabled } from "./VisitHelper";
import { ModalFooter, ModalFooterButtons } from "../../styling/StyleModal";
import Routes from "../../routes/Routes";

const Container = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
`;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ContentContainer = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;
`;

const CHECKBOX_CONNECTED = "Connected";
const PLEASE_REVIEW = "Please Review";
const VISIT_CONTENT_ID = "visit-content";

const VisitContent = ({
  syncCareFlowToAPI,
  isUpdateVisitSuccess,
  isUpdateVisitLoading
}: {
  syncCareFlowToAPI: (status?: VisitStateType) => Promise<void>;
  isUpdateVisitSuccess: boolean;
  isUpdateVisitLoading: boolean;
}) => {
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const { user } = useSelector((state: RootState) => state.auth);

  const { selectedSection, selectedSubSection, answers, visit } = useSelector(
    (state: RootState) => state.visits
  );

  const [completePressed, setCompletePressed] = useState<boolean>();
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [showEndCallModal, setShowEndCallModal] = useState<boolean>(false);
  const [isBillingComplete, setIsBillingComplete] = useState<boolean>(false);

  useEffect(() => {
    const hasTimeSpent = !isFalsy(
      answers[CareFlowFieldIdsEnum.POSTCALL_BILLING_ENTRY_MINUTES]
    );
    const hasVisitType = !isFalsy(
      answers[CareFlowFieldIdsEnum.POSTCALL_BILLING_ENTRY_VISIT_TYPE]
    );
    setIsBillingComplete(hasTimeSpent && hasVisitType);
  }, [answers]);

  const memberId = visit?.patient_id;
  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined }
  );

  const dispatch = useAppDispatch();

  const currentSectionIndex = visit?.care_flow?.sections?.findIndex(
    (item) => item.section_id === selectedSection
  );

  const currentSection = visit?.care_flow?.sections[currentSectionIndex];
  const currentSubSections =
    visit?.care_flow?.sections[currentSectionIndex]?.subsections;
  const currentSubSectionIndex = currentSubSections?.findIndex(
    (item) => item.section_id === selectedSubSection
  );

  const length = visit?.care_flow?.sections?.length;
  const isLastSection = currentSectionIndex >= length - 1;

  const isLastSubsection =
    currentSubSectionIndex >= currentSubSections?.length - 1;

  const isCallSection = currentSection?.section_id == CareFlowSectionsEnum.CALL;

  const section = useMemo(() => {
    return visit?.care_flow?.sections?.find(
      ({ section_id }) => section_id === selectedSection
    );
  }, [visit, selectedSection]);

  const navNextSection = () => {
    if (isLastSection) return;

    const newSection = visit?.care_flow?.sections[currentSectionIndex + 1];
    const newSubsection = newSection.subsections[0]?.disabled
      ? newSection.subsections[1]
      : newSection.subsections[0];
    dispatch(
      setSelectedSection({
        section_id: newSection.section_id,
        subsection_id: newSubsection.section_id
      })
    );
  };

  // Used by End Call to skip validation in normal goNextSection
  const forceGoNextSection = () => {
    setShowEndCallModal(false);
    navNextSection();
  };

  const goNextSection = () => {
    if (isLastSection) return;

    let firstSubsection: CareFlowSubSectionType = null;
    const unansweredFields: CareFlowFieldType[] = [];
    const currentSection = visit?.care_flow?.sections[currentSectionIndex];
    currentSection.subsections.forEach((subsection) => {
      subsection.fields.forEach((field) => {
        if (field.is_required) {
          const isAnswered = answers[field.field_id] !== undefined;
          if (!isAnswered) {
            unansweredFields.push(field);
            if (!firstSubsection) firstSubsection = subsection;
          }
        }
      });
    });

    if (
      unansweredFields.length > 0 &&
      featureFlags.CARE_FLOWS_REQUIRED_FIELD_CHECK
    ) {
      Alert_show({
        dispatch,
        title: PLEASE_REVIEW,
        hideCloseIcon: true,
        content: (
          <>
            <Typography variant="h6" color={"secondary"} textAlign={"center"}>
              The following fields are required to proceed to the next section:
            </Typography>
            {unansweredFields.map((field) => {
              return (
                <Typography
                  key={field.field_id}
                  variant="body1"
                  textAlign={"center"}
                >
                  - {field.name ? field.name : field.field_id}
                </Typography>
              );
            })}
          </>
        ),
        buttons: [
          {
            text: "Review",
            onPress: () => {
              Alert_close({ dispatch, title: PLEASE_REVIEW });
              dispatch(
                setSelectedSection({
                  section_id: currentSection.section_id,
                  subsection_id: firstSubsection.section_id
                })
              );
            }
          }
        ]
      });
      return;
    }

    navNextSection();
  };

  const validateSubSection = async (currentSubSection) => {
    if (isTruthy(currentSubSection?.fields)) {
      for (const field of currentSubSection.fields) {
        // custom check for not connected
        if (
          field.field_id === CareFlowFieldIdsEnum.CALL_CONNECTION &&
          answers[field.field_id] !== CHECKBOX_CONNECTED &&
          featureFlags.CARE_FLOWS_REQUIRED_FIELD_CHECK
        ) {
          Alert_show({
            dispatch,
            size: "small",
            hideCloseIcon: true,
            title: PLEASE_REVIEW,
            content: (
              <>
                <Typography
                  variant="h6"
                  color={"secondary"}
                  textAlign={"center"}
                >
                  Unable to continue without connecting to member.
                </Typography>
              </>
            ),
            buttons: [
              {
                text: "Review",
                onPress: () => {
                  Alert_close({ dispatch, title: PLEASE_REVIEW });
                }
              }
            ]
          });
          return false;
        }
      }
    }
    return true;
  };

  const goNextSubsection = async () => {
    if (isLastSubsection) return;

    const subSections =
      visit?.care_flow?.sections[currentSectionIndex].subsections;

    const currentSubSectionIndex = subSections.findIndex(
      (item) => selectedSubSection === item.section_id
    );
    const isValid = await validateSubSection(
      subSections[currentSubSectionIndex]
    );

    if (isValid) {
      dispatch(
        setSelectedSection({
          section_id:
            visit?.care_flow?.sections[currentSectionIndex].section_id,
          subsection_id: subSections[currentSubSectionIndex + 1].section_id
        })
      );
    }
  };

  const handleSaveAndNextSection = () => {
    syncCareFlowToAPI();
    goNextSection();
  };

  const handleSaveAndNextSubsection = () => {
    syncCareFlowToAPI();
    goNextSubsection();
  };

  const handleSaveAndNext = () => {
    if (isLastSection && isLastSubsection) return;

    if (isLastSubsection) {
      handleSaveAndNextSection();
    } else {
      handleSaveAndNextSubsection();
    }
    document.getElementById(VISIT_CONTENT_ID).scrollTop = 0;
  };

  const handleComplete = async () => {
    dispatch(stopTicking());
    setCompletePressed(true);
    await syncCareFlowToAPI({
      status: VisitStatusEnum.TIME_SUBMITTED,
      total_time: Math.round(
        getElapsedTime(
          DateTime.fromISO(visit?.created_at).setZone(user?.timezone)
        ).seconds
      )
    });

    setTimeout(() => setCompletePressed(false), 1000);
  };

  const handleEndCall = async () => {
    syncCareFlowToAPI();
    setShowEndCallModal(true);
  };

  useInterval(() => {
    syncCareFlowToAPI();
  }, FIFTEEN_SECONDS * 1000);

  useEffect(() => {
    return () => {
      syncCareFlowToAPI();
    };
  }, []);

  const goToDashboardHandler = () => {
    navigate(Routes.ROOT);
  };

  const completeVisitSuccess = isUpdateVisitSuccess && completePressed;
  const completeVisitLoading = isUpdateVisitLoading && completePressed;

  useEffect(() => {
    if (completeVisitSuccess) {
      setShowCompleteModal(true);
    }
  }, [completeVisitSuccess]);

  useEffect(() => {
    if (section) {
      setSelectedSection({
        section_id: section.section_id,
        subsection_id: section.subsections[0].section_id
      });
    }
  }, [section]);

  const { height } = useWindowDimensions();

  return (
    <Container>
      <ContentContainer height={height - 350} id={VISIT_CONTENT_ID}>
        <VisitContentSubSection
          syncCareFlowToAPI={syncCareFlowToAPI}
          subSection={section?.subsections[currentSubSectionIndex]}
          member={member}
        />
      </ContentContainer>
      <br />
      {visit?.status === VisitStatusEnum.IN_PROGRESS && (
        <RowContainer gap={"10px"} justifyContent={"space-between"}>
          {isLastSection && isLastSubsection ? (
            <CustomTooltip
              title={
                isFalsy(isBillingComplete) &&
                "Please enter Time Spent and Visit Type before completing the call"
              }
              placement="top"
            >
              <div>
                <LoadingButton
                  disabled={isFalsy(isBillingComplete)}
                  onClick={() => {
                    Alert_show({
                      dispatch,
                      content: (
                        <>
                          <Typography variant="h6" textAlign={"center"}>
                            Are you sure you want to complete the call?
                          </Typography>
                          <br />
                          <Typography
                            variant="h6"
                            color="error"
                            textAlign={"center"}
                          >
                            You will not be able to go back and edit.
                          </Typography>
                        </>
                      ),
                      size: "small",
                      buttons: [
                        {
                          text: "Yes, complete call",
                          onPress: () => {
                            handleComplete();
                            Alert_closeAll(dispatch);
                          }
                        },
                        {
                          text: "Go back",
                          onPress: () => Alert_closeAll(dispatch),
                          style: "cancel"
                        }
                      ]
                    });
                  }}
                  loading={completeVisitLoading}
                  variant="contained"
                  data-testid="CompleteCareFlow"
                >
                  Complete
                </LoadingButton>
              </div>
            </CustomTooltip>
          ) : (
            <Button
              onClick={handleSaveAndNext}
              variant="contained"
              data-testid="Next"
            >
              Next
            </Button>
          )}
          {isCallSection && (
            <Button onClick={handleEndCall} variant="contained" color="error">
              End Call
            </Button>
          )}
        </RowContainer>
      )}

      {isDisabled(visit) && (
        <RowContainer gap={"10px"}>
          <Button
            onClick={() => navigate(`/members/memberId/${visit?.patient_id}`)}
            variant="contained"
          >
            Return to Member Profile
          </Button>
          <Button
            onClick={() =>
              navigate(`/staff/staffId/${visit?.staff_id}/assignments`)
            }
            variant="contained"
          >
            Return to Staff Profile
          </Button>
        </RowContainer>
      )}

      <Modal
        isOpen={showCompleteModal}
        contentLabel="Complete CareFlow Modal"
        modalHeight="300px"
        modalWidth="400px"
        onRequestClose={() => {}}
      >
        <ModalContainer>
          <Typography variant="h6" color={"secondary"}>
            You have successfully completed your call with
          </Typography>
          <Typography variant="h4">
            {getNameOrUsername(member?.patient)}
          </Typography>
          <Typography variant="h6" color={"secondary"}>
            Total time spent:{" "}
            {getElapsedTime(
              DateTime.fromISO(visit?.created_at).setZone(user?.timezone)
            ).toFormat("mm:ss")}
          </Typography>
        </ModalContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <Button variant="contained" onClick={goToDashboardHandler}>
              Go to Dashboard
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setShowCompleteModal(false);
              }}
            >
              Dismiss
            </Button>
          </ModalFooterButtons>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showEndCallModal}
        contentLabel="CareFlow EndCall Modal"
        modalHeight="300px"
        modalWidth="400px"
      >
        <ModalContainer>
          <Typography variant="h6" color={"secondary"}>
            Are you sure you want to leave this call?
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            minWidth={"80%"}
            marginTop={"25%"}
            justifyContent={"space-evenly"}
          >
            <Button
              variant="outlined"
              onClick={() => setShowEndCallModal(false)}
              sx={{ minWidth: "120px" }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={forceGoNextSection}
              sx={{ minWidth: "120px" }}
            >
              Yes
            </Button>
          </Box>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default VisitContent;
