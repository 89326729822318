import styled from "@emotion/styled";
import { DateTime } from "luxon";
import {
  LocalizationProvider,
  DatePicker as DatePickerMUI,
  DatePickerProps
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatePicker = (props: DatePickerProps<DateTime>) => {
  // prevent user from typing in the date as this can lead to bugs
  // see ENG-3757
  const onKeyDown = (e) => {
    if (e.key === "Delete") {
      // TBD ENG-5032 fix this case when pressing fn + delete, these all don't work :(
      e.target = null;
      e.preventDefault();
      e.nativeEvent.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    e.preventDefault();
  };

  return (
    <Column>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePickerMUI
          {...props}
          slotProps={{
            field: {
              readOnly: true
            },
            textField: { onKeyDown }
          }}
        />
      </LocalizationProvider>
    </Column>
  );
};

export default DatePicker;
