import MemberType from "common/types/MemberType";
import { useEffect, useState } from "react";
import { isFalsy } from "common/helpers/helpers";
import { Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import {
  CarePlanConditionsEnum,
  hasDiabetes,
  hasHypertension
} from "common/types/Visits/CarePlanType";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import { gray } from "common/styling/colors";
import { ProcessedGoalsType, processGoals } from "common/types/GoalType";
import { FallbackText } from "../../../../../components/Table/helpers/TableHelpers";
import { ReviewCondition } from "./ReviewCondition";
import { EditCarePlanModal } from "../../../../../components/CarePlan/EditCarePlanModal";
import { StatusBadge } from "../../../../../styling/StyleComponents";

interface IProps {
  member: MemberType;
  showGoals?: boolean;
}

enum TabPanelIndex {
  diabetes = 0,
  hypertension = 1
}

const ReviewCarePlanReadings = ({ member, showGoals = true }: IProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [processedGoals, setProcessedGoals] = useState<ProcessedGoalsType>({
    diabetes: [],
    hypertension: []
  });

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const process = async () => {
      const newProcessedGoals = processGoals({
        goals: member?.goals,
        splitByType: false,
        clinicalOnly: true
      });
      setProcessedGoals(newProcessedGoals);
    };

    // If member does not have DIABETES, setTabIndex to 1
    if (
      !member?.care_plan?.conditions?.includes(CarePlanConditionsEnum.DIABETES)
    )
      setTabIndex(TabPanelIndex.hypertension);

    if (member?.goals) process();
  }, [member]);

  const renderTabIndex = (index) => {
    // Get condition to load from index
    const condition =
      index === TabPanelIndex.diabetes
        ? CarePlanConditionsEnum.DIABETES
        : CarePlanConditionsEnum.HYPERTENSION;

    return (
      <ReviewCondition
        member={member}
        condition={condition}
        processedGoals={processedGoals}
        showGoals={showGoals}
      />
    );
  };

  if (isFalsy(member)) {
    return <CircularProgress />;
  }

  return (
    <>
      {isFalsy(member?.care_plan?.conditions) ? (
        <Flexbox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            gap: "10px",
            width: "100%"
          }}
        >
          <FallbackText
            text={
              "No conditions found for member, please add them to their care plan."
            }
          />
          <EditCarePlanButton member={member} variant={"contained"} />
        </Flexbox>
      ) : (
        <>
          <Flexbox justifyContent={"space-between"}>
            <Tabs value={tabIndex} onChange={handleTabSelect}>
              {hasDiabetes(member) && (
                <Tab
                  key={CarePlanConditionsEnum.DIABETES}
                  label={
                    <StatusBadge
                      status={CarePlanConditionsEnum.DIABETES}
                      hideDot={true}
                    />
                  }
                  style={{ fontWeight: "600", marginBottom: "-15px" }}
                  iconPosition="start"
                  value={TabPanelIndex.diabetes}
                />
              )}
              {hasHypertension(member) && (
                <Tab
                  key={CarePlanConditionsEnum.HYPERTENSION}
                  label={
                    <StatusBadge
                      status={CarePlanConditionsEnum.HYPERTENSION}
                      hideDot={true}
                    />
                  }
                  style={{ fontWeight: "600", marginBottom: "-15px" }}
                  iconPosition="start"
                  value={TabPanelIndex.hypertension}
                />
              )}
            </Tabs>
            <Flexbox flexDirection={"row"} alignSelf={"flex-end"} gap={"5px"}>
              <Typography
                variant="caption"
                color={gray[900]}
                alignSelf="center"
              >
                Missing a condition?
              </Typography>
              <EditCarePlanButton member={member} />
            </Flexbox>
          </Flexbox>
          <br />
          {renderTabIndex(tabIndex)}
          <br />
        </>
      )}
    </>
  );
};

const EditCarePlanButton = ({
  member,
  variant = "outlined"
}: {
  member: MemberType;
  variant?: "outlined" | "text" | "contained";
}) => {
  const [editCarePlanModal, setEditCarePlanModal] = useState<boolean>(false);
  return (
    <>
      <Button
        variant={variant}
        onClick={() => {
          setEditCarePlanModal(true);
        }}
        sx={{ maxWidth: "150px" }}
      >
        Edit Care Plan
      </Button>
      <EditCarePlanModal
        isOpen={editCarePlanModal}
        member={member}
        onRequestClose={() => {
          setEditCarePlanModal(false);
        }}
      />
    </>
  );
};

export default ReviewCarePlanReadings;
