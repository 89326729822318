import { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import cloneDeep from "lodash.clonedeep";
import c3 from "c3";
import "c3/c3.css";
import { getFormattedDateTime } from "../../../helpers/helpers";
import { ComponentHeader } from "../../../styling/StyleComponents";
import ErrorComponent from "../../../components/ErrorComponent";
import { ErrorText } from "../../../styling";
import { transformGlucoseDataToColumns } from "../../../helpers/components/Charts/ChartHelpers";
import MemberType from "common/types/MemberType";
import { CustomDataPoint } from "../../../@types/customc3";
import { DateTime } from "luxon";
import DeviceReadingType from "common/types/DeviceReadingType";

const GlucoseChartContainer = styled.div<{
  chartId: string;
  isBlackAndWhite: boolean;
}>`
  position: ${(props) => (props.isBlackAndWhite ? "absolute" : "relative")};
  margin: 36px;
  border-radius: 4px;
  border: 1px solid #e6e7e9;
  background: #ffffff;
  padding: 18px 8px;
  height: 100%;
  transition: all 0.66s ease-out;

  ${(props) => `#${props.chartId}`} {
    width: 700px;
    height: 200px;
    margin-left: 24px;
  }

  ${(props) => `#${props.chartId}`} svg {
    width: calc(100% + 12px);
  }

  ${(props) => `#${props.chartId}`} .c3-circle {
    r: 4;
  }

  ${(props) => `#${props.chartId}`} .c3-circle._expanded_ {
    visibility: visible;
    r: 6;
  }

  ${(props) => `#${props.chartId}`} .c3-legend-background {
    stroke: none;
  }

  ${(props) => `#${props.chartId}`} .c3-legend-item {
    font-family: ${(props) => props.theme.font.family};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgba(24, 24, 25, 0.42);
  }

  .c3-axis.c3-axis-x text {
    translate: -2px;
  }

  // dashed lines
  .c3-shapes-BEFORE-BREAKFAST-R.c3-lines.c3-lines-BEFORE-BREAKFAST-R {
    stroke-dasharray: 3, 3;
  }
  .c3-shapes-OTHER-R.c3-lines.c3-lines-OTHER-R {
    stroke-dasharray: none;
  }

  .c3-line-BEFORE-BREAKFAST-R.c3-line {
    stroke-width: 2px;
  }

  .c3-line-OTHER-R.c3-line {
    stroke-width: 2px;
  }

  // hide dots

  .c3-circles-BEFORE-BREAKFAST-R {
    display: none;
  }

  .c3-circles-OTHER-R {
    display: none;
  }

  ${(props) => `#${props.chartId}`} .c3-axis-x-label {
    font: 13px "Inter";
  }

  .tick line {
    visibility: hidden;
  }
`;

const GlucoseChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
`;
const GlucoseChartLeft = styled.div``;

const GlucoseChartHeaderText = styled(ComponentHeader)``;
const GlucoseChartSubHeaderText = styled.div<{ $color?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.$color ? props.$color : "rgba(24, 24, 25, 0.42)")};
`;
interface IProps {
  glucoseData: DeviceReadingType[];
  glucoseError: unknown;
  patient?: MemberType;
  startDate: DateTime;
  endDate: DateTime;
  chartCssId: string;
  isBlackAndWhite: boolean;
  style?: React.CSSProperties;
}

const X_AXIS_MAX_TICK_COUNT = 13.1;

const generateData = ({
  data,
  isBlackAndWhite,
  filteredGlucoseData,
  chartSvgCssId,
  patientTimezone
}) => {
  if (!data) return;

  const { columns, beforeAvg, otherAvg } = data;
  const { xs } = data;
  const beforeBreakfastColumn = columns[2];
  const otherColumn = columns[3];

  let finalColumns = cloneDeep(columns);
  // remove averages
  xs["BEFORE BREAKFAST AVG"] = "BEFORE BREAKFAST AVG";
  xs["OTHER AVG"] = "OTHER AVG";
  finalColumns = finalColumns.slice(0, 4);

  const beforeAverageText = beforeAvg ? `(${beforeAvg})` : "";
  const otherAverageText = otherAvg ? `(${otherAvg})` : "";

  const names = {
    BEFORE_AVG__: `Before Average ${beforeAverageText}`,
    OTHER_AVG__: `Other Average ${otherAverageText}`,
    "BEFORE BREAKFAST R": "Before Breakfast",
    "OTHER R": "Other"
  };
  if (
    (beforeBreakfastColumn?.length === 1 && otherColumn?.length === 1) ||
    filteredGlucoseData.length === 0
  ) {
    // if no data found, display "No Data Found" chart
    c3.generate({
      bindto: `#${chartSvgCssId}`,
      data: {
        xs,
        xFormat: "%m/%d/%Y, %H:%M:%S %p",
        columns: finalColumns,
        types: {
          "BEFORE BREAKFAST R": "spline",
          "OTHER R": "spline"
        },
        names: names
      },
      legend: {
        show: false
      },
      axis: {
        x: {
          type: "category",
          label: {
            text: "No readings taken in selected time range",
            position: "inner-right"
          },
          tick: {
            outer: false
          }
        },
        y: {
          tick: {
            outer: false
          },
          label: "mg/DL"
        }
      },
      padding: {
        bottom: 20 //adjust chart padding bottom
      }
    });
  } else {
    c3.generate({
      bindto: `#${chartSvgCssId}`,
      data: {
        xs,
        xFormat: "%m/%d/%Y, %H:%M:%S %p",
        columns: finalColumns,
        types: {
          "BEFORE BREAKFAST R": "spline",
          "OTHER R": "spline"
        },
        names: names,
        color: () => (isBlackAndWhite ? "#2c2e34" : "#4aa8af")
      },
      legend: {
        show: false
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: "%b %d",
            count: X_AXIS_MAX_TICK_COUNT
          }
        },
        y: {
          tick: {
            outer: false
          },
          label: "mg/DL"
        }
      },
      padding: {
        bottom: 20 //adjust chart padding bottom
      },
      tooltip: {
        contents: function (
          d: CustomDataPoint[],
          defaultTitleFormat,
          defaultValueFormat,
          color
        ) {
          // https://stackoverflow.com/a/54463412
          const config = this.config,
            nameFormat =
              config.tooltip_format_name ||
              function (name) {
                return name;
              },
            valueFormat = config.tooltip_format_value || defaultValueFormat;

          let text, i, title, value, name, bgcolor;

          for (i = 0; i < d.length; i++) {
            if (!(d[i] && (d[i].value || d[i].value === 0))) {
              continue;
            }

            if (!text) {
              const titleValue = getFormattedDateTime(
                d[i].x as DateTime,
                patientTimezone
              );
              title = titleValue;
              text =
                "<table class='" +
                this.CLASS.tooltip +
                "'>" +
                (title || title === 0
                  ? "<tr><th>Tag</th><th>Glucose</th><th>Date</th></tr>"
                  : "");
            }
            name = nameFormat(d[i].name);
            value = valueFormat(d[i].value, d[i].name, d[i].id, d[i].index);
            bgcolor = this.levelColor
              ? this.levelColor(d[i].value)
              : color(d[i].id);
            text +=
              "<tr class='" + this.CLASS.tooltipName + "-" + d[i].id + "'>";
            text +=
              "<td class='name'><span style='background-color:" +
              bgcolor +
              "'></span>" +
              name +
              "</td>";
            text += "<td class='value'>" + value + "</td>";

            text += "<td class='value'>" + title + "</td>";

            text += "</tr>";
          }
          return text + "</table>";
        }
      }
    });
  }
};

const ReportGlucoseChart = ({
  glucoseData,
  glucoseError,
  patient,
  chartCssId,
  isBlackAndWhite,
  startDate,
  endDate
}: IProps) => {
  const chartSvgCssId = `chart${chartCssId}`;

  const DAYS = endDate.diff(startDate, "days").as("days");

  const filteredGlucoseData = useMemo(() => {
    return glucoseData?.filter((item) => {
      return (
        Math.abs(
          DateTime.fromSeconds(item.measure_timestamp)
            .diff(endDate, "days")
            .as("days")
        ) < DAYS
      );
    });
  }, [glucoseData]);

  const patientTimezone = patient?.patient?.timezone;

  const data = useMemo(() => {
    if (filteredGlucoseData === undefined) return;
    const dataCopy = cloneDeep(filteredGlucoseData);
    const columns = transformGlucoseDataToColumns(
      dataCopy,
      patientTimezone,
      true
    );
    return columns;
  }, [glucoseData]);

  useEffect(() => {
    generateData({
      data,
      isBlackAndWhite,
      filteredGlucoseData,
      chartSvgCssId,
      patientTimezone
    });
  }, [data]);

  return (
    <GlucoseChartContainer
      id={chartCssId}
      chartId={chartSvgCssId}
      isBlackAndWhite={isBlackAndWhite}
    >
      <GlucoseChartHeader>
        <GlucoseChartLeft>
          <GlucoseChartHeaderText>Glucose Chart</GlucoseChartHeaderText>
          <GlucoseChartSubHeaderText>
            {`Evaluate the member's glucose trends`}
          </GlucoseChartSubHeaderText>
        </GlucoseChartLeft>
      </GlucoseChartHeader>

      {glucoseData ? (
        <>
          <div id={chartSvgCssId} />
        </>
      ) : (
        glucoseError && (
          <ErrorText marginLeft="20px">
            <ErrorComponent error={glucoseError} />
          </ErrorText>
        )
      )}
    </GlucoseChartContainer>
  );
};

export default ReportGlucoseChart;
