import { forwardRef, Ref, useImperativeHandle, useMemo } from "react";
import { CircularProgress, Typography } from "@mui/material";

import { DateTime } from "luxon";

import { useGetTimeEnteredByRoleQuery } from "common/services/ReportsService";
import RolesEnum, { getRoleLabel } from "common/enums/RolesEnum";

import ReportRefInterface from "./ReportRefInterface";
import Table from "../../../components/Table/Table";
import { ErrorText } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";

import {
  getHoursMinutesFromMinutes,
  getNameOrUsername
} from "common/helpers/helpers";
import DropdownType from "../../../types/DropdownType";
import styled from "@emotion/styled";
import UserStatusEnum from "common/enums/UserStatusEnum";

const summaryColumns = [
  { name: "default", id: "role", accessor: "role", header: "Role" },
  {
    name: "default",
    id: "totalTime",
    accessor: "totalTime",
    header: "Total Time (in minutes)",
    size: 220
  },
  {
    name: "default",
    id: "nurseVisitTime",
    accessor: "nurseVisitTime",
    header: "Nurse Visit Time (Sync and Async)",
    size: 220
  },
  {
    name: "default",
    id: "deviceSetupTime",
    accessor: "deviceSetupTime",
    header: "Device Set Up Time",
    size: 220
  }
];

const detailedColumns = [
  {
    name: "default",
    id: "nurse",
    accessor: "fullname",
    header: "Staff Member",
    size: 200
  },
  {
    name: "fullname",
    id: "submitted_time",
    accessor: "submitted_time",
    header: "Total Time (in minutes)",
    size: 220
  },
  {
    name: "default",
    id: "nurseVisitTime",
    accessor: "nurseVisitTime",
    header: "Nurse Visit Time (Sync and Async)",
    size: 220
  },
  {
    name: "default",
    id: "deviceSetupTime",
    accessor: "deviceSetupTime",
    header: "Device Set Up Time",
    size: 220
  },
  {
    name: "reportMemberCount",
    size: 200
  }
];

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  dropdownSelection: DropdownType[];
  checkboxChecked?: boolean;
}

const ReportTimeByTNs = forwardRef(
  (
    { startDate, endDate, dropdownSelection, checkboxChecked }: IProps,
    ref: Ref<ReportRefInterface>
  ) => {
    const selectedRoles = useMemo(() => {
      if (dropdownSelection === undefined) return [];
      return dropdownSelection.map((item) => item.value as RolesEnum);
    }, [dropdownSelection]);

    const { data, isLoading, isError, isSuccess, error } =
      useGetTimeEnteredByRoleQuery(
        {
          roles: selectedRoles,
          startsBefore: endDate.setZone("local").endOf("day"),
          startsAfter: startDate.setZone("local").startOf("day"),
          // if checkbox is not checked, we want to filter out carers who are inactive
          ...(!checkboxChecked && { carer_status: UserStatusEnum.ACTIVE })
        },
        { skip: selectedRoles.length === 0 }
      );

    const sortedData = useMemo(() => {
      if (data === undefined) return undefined;
      return [...data]
        .filter(
          (item) =>
            item.device_setup_time !== 0 ||
            item.rpm_time !== 0 ||
            item.async_rpm_time !== 0
        )
        .sort((a, b) => {
          return b.submitted_time - a.submitted_time;
        })
        .map((item) => {
          return {
            ...item,
            patientCount: item.patient_count,
            totalTime:
              item.device_setup_time + item.rpm_time + item.async_rpm_time,
            nurseVisitTime: item.rpm_time + item.async_rpm_time,
            deviceSetupTime: item.device_setup_time
          };
        });
    }, [data]);

    const summaryData = useMemo(() => {
      if (data === undefined) return undefined;
      const hash = {};
      const summaryObject = {
        patientCount: 0,
        totalTime: 0,
        nurseVisitTime: 0,
        deviceSetupTime: 0
      };
      const filteredData = data.filter(
        (item) =>
          item.device_setup_time !== 0 ||
          item.rpm_time !== 0 ||
          item.async_rpm_time !== 0
      );

      filteredData.forEach((item) => {
        const {
          device_setup_time,
          rpm_time,
          async_rpm_time,
          patient_count,
          carer
        } = item;

        summaryObject.patientCount += patient_count;
        summaryObject.totalTime +=
          device_setup_time + rpm_time + async_rpm_time;
        summaryObject.nurseVisitTime += rpm_time + async_rpm_time;
        summaryObject.deviceSetupTime += device_setup_time;

        carer?.roles?.forEach((role) => {
          if (hash[role] === undefined) {
            hash[role] = {
              patientCount: patient_count,
              totalTime: device_setup_time + rpm_time + async_rpm_time,
              nurseVisitTime: rpm_time + async_rpm_time,
              deviceSetupTime: device_setup_time
            };
          } else {
            hash[role] = {
              patientCount: hash[role]?.patientCount + patient_count,
              totalTime:
                hash[role]?.totalTime +
                device_setup_time +
                rpm_time +
                async_rpm_time,
              nurseVisitTime:
                hash[role]?.nurseVisitTime + rpm_time + async_rpm_time,
              deviceSetupTime: hash[role]?.deviceSetupTime + device_setup_time
            };
          }
        });
      });

      const summaryArray = Object.keys(hash)
        .filter(
          // https://copilotiq.atlassian.net/browse/ENG-5178
          (key) => selectedRoles.includes(key as RolesEnum)
        )
        .map((key) => {
          return {
            roleEnum: key,
            role: getRoleLabel(key as RolesEnum),
            patientCount: hash[key]?.patientCount,
            totalTime: hash[key]?.totalTime,
            nurseVisitTime: hash[key]?.nurseVisitTime,
            deviceSetupTime: hash[key]?.deviceSetupTime
          };
        })
        .sort((a, b) => {
          return b.totalTime - a.totalTime;
        });

      // https://copilotiq.atlassian.net/browse/ENG-6466
      summaryObject.patientCount = filteredData?.length;

      return {
        // summaryArray has one entry per role
        summaryArray,
        // summaryObject has the total values for all roles
        // some users have both roles and are counted twice in summaryArray
        summaryObject
      };
    }, [data]);

    const { totalTime, nurseVisitTime, deviceSetupTime, patientCount } =
      useMemo(() => {
        if (summaryData?.summaryObject === undefined)
          return {
            totalTime: 0,
            nurseVisitTime: 0,
            deviceSetupTime: 0,
            patientCount: 0
          };

        return {
          totalTime: summaryData?.summaryObject?.totalTime,
          nurseVisitTime: summaryData?.summaryObject?.nurseVisitTime,
          deviceSetupTime: summaryData?.summaryObject?.deviceSetupTime,
          patientCount: summaryData?.summaryObject?.patientCount
        };
      }, [summaryData, data]);

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = sortedData?.map((item) => {
          const formattedRoles = item.carer.roles
            .map((role) => {
              return getRoleLabel(role);
            })
            .join(", ");

          return [
            getNameOrUsername(item.carer),
            item.carer.user_id,
            item.totalTime.toString(),
            item.nurseVisitTime.toString(),
            item.deviceSetupTime.toString(),
            item.patientCount.toString(),
            formattedRoles
          ];
        });

        return {
          filename: "time_entered_by_role",
          columns: [
            "Staff Member",
            "User Id",
            "Total Time (min)",
            "Nurse Visit Time (Sync and Async)",
            "Device Set Up Time",
            "Member Count",
            "Roles"
          ],
          data: formattedData
        };
      },
      getReportData: () => {
        if (selectedRoles.length === 0) return;
        if (summaryData === undefined) return;

        const summaryDataTable = summaryData?.summaryArray?.map((item) => {
          return [
            item.role,
            item.totalTime.toString(),
            item.nurseVisitTime.toString(),
            item.deviceSetupTime.toString(),
            item.patientCount.toString()
          ];
        });

        const detailedDataTable = sortedData?.map((item) => {
          return [
            getNameOrUsername(item.carer),
            item.totalTime.toString(),
            item.nurseVisitTime.toString(),
            item.deviceSetupTime.toString(),
            item.patientCount.toString()
          ];
        });

        return [
          {
            title: "Summary by Role",
            columnNames: [
              "Role",
              "Total Time (in minutes)",
              "Nurse Visit Time (Sync and Async)",
              "Device Set Up Time"
            ],
            data: summaryDataTable,
            reportSummary: [
              `Total Time: ${getHoursMinutesFromMinutes(totalTime)?.hours} hours ${getHoursMinutesFromMinutes(totalTime)?.minutes} minutes`,
              `Nurse Visit Time: ${getHoursMinutesFromMinutes(nurseVisitTime)?.hours} hours ${getHoursMinutesFromMinutes(nurseVisitTime)?.minutes} minutes`,
              `Device Setup Time: ${getHoursMinutesFromMinutes(deviceSetupTime)?.hours} hours ${getHoursMinutesFromMinutes(deviceSetupTime)?.minutes} minutes`,
              `Total Member Count: ${patientCount}`
            ]
          },
          {
            title: "Detailed",
            columnNames: [
              "Nurse",
              "Total Time (in minutes)",
              "Nurse Visit Time (Sync and Async)",
              "Device Set Up Time",
              "Member Count"
            ],
            data: detailedDataTable
          }
        ];
      }
    }));

    return (
      <>
        {selectedRoles.length > 0 &&
          sortedData &&
          sortedData.length > 0 &&
          summaryData?.summaryArray && (
            <>
              <Typography variant="h3">Summary by Role</Typography>
              <Table
                tableColumns={summaryColumns}
                data={summaryData?.summaryArray}
                tableMaxHeight={"none"}
              />
              <Typography variant="body1" color="text.secondary">
                {`Total Time: ${getHoursMinutesFromMinutes(totalTime)?.hours} hours ${getHoursMinutesFromMinutes(totalTime)?.minutes} minutes`}
                <br />
                {`Nurse Visit Time: ${getHoursMinutesFromMinutes(nurseVisitTime)?.hours} hours ${getHoursMinutesFromMinutes(nurseVisitTime)?.minutes} minutes`}
                <br />
                {`Device Setup Time: ${getHoursMinutesFromMinutes(deviceSetupTime)?.hours} hours ${getHoursMinutesFromMinutes(deviceSetupTime)?.minutes} minutes`}
                <br />
                {`Total Member Count: ${patientCount}`}
              </Typography>

              <br />
              <br />
              <Row>
                <Typography variant="h3">Detailed</Typography>
              </Row>
              <Table
                tableColumns={detailedColumns}
                data={sortedData}
                tableMaxHeight={"none"}
                estimateRowSize={() => {
                  return 45;
                }}
              />
            </>
          )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </>
    );
  }
);

ReportTimeByTNs.displayName = "ReportTimeByTNs";

export default ReportTimeByTNs;
