enum CommunicationTypeEnum {
  PHONE = "PHONE",
  VIDEO = "VIDEO",
  SMS = "SMS"
}

export const getCommunicationTypeString = (
  communication: CommunicationTypeEnum
) => {
  switch (communication) {
    case CommunicationTypeEnum.PHONE:
      return "Phone Call";
    case CommunicationTypeEnum.VIDEO:
      return "Video Call";
    case CommunicationTypeEnum.SMS:
      return "SMS";
    default:
      return "";
  }
};

export default CommunicationTypeEnum;
