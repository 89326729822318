import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
  ChangeEvent
} from "react";
import { useFormik } from "formik";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";

import { getAddress, getNameOrUsername } from "common/helpers/helpers";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useCreateOrderMutation } from "common/services/OrdersService";
import useGetRefillDataSummary from "common/hooks/useGetRefillDataSummary";
import { RootState, useAppDispatch } from "common/redux";
import { canBypassRefill } from "common/enums/RolesEnum";
import { AlertButtonType } from "common/redux/AlertSlice";
import MemberStatusEnum from "common/enums/MemberStatusEnum";

import { ErrorTextSpan, TurqoiseButton } from "../../styling";
import { FormCheckbox } from "../../helpers/components/Forms/FormHelpers";
import { getSkuItems, isPOBoxAddress } from "./../../helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  CustomTooltip,
  FormLabel,
  SuccessText
} from "../../styling/StyleComponents";

import RenderLastNOrders from "../../helpers/components/RenderLastNOrders";
import useGetOrderDevices from "../../hooks/data_loaders/useGetOrderDevices";

import ErrorComponent from "../../components/ErrorComponent";
import glucoseStrips from "../../assets/images/glucose_strips.png";
import { EditAddressModal } from "../../helpers/components/Forms/EditAddressModal/EditAddressModal";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import {
  CheckCircleIcon,
  LinkIcon,
  PencilIcon
} from "../../assets/images/icons";
import { ValidateAddressModal } from "../../helpers/components/Forms/ValidateAddressModal/ValidateAddressModal";
import { useValidateAddressMutation } from "common/services/DevicesService";
import AddressValidationStatusEnum from "common/enums/AddressValidationStatusEnum";
import { copyToClipboard } from "../../styling/CopyPatientLinkToClipboard";
import { gray } from "common/styling/colors";
import OrderType from "common/types/OrderType";
import NumberInput from "../../components/Input/NumberInput";
import FeatureFlags from "common/config/FeatureFlags";
import OrderMemberType from "common/types/OrderMemberType";

const CAN_MAKE_REFILL_ORDER_AFTER_DAYS = 15;

const RefillFormContainer = styled.div<{
  children: ReactNode | ReactNode[];
}>``;

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const SubmitButton = styled(TurqoiseButton)`
  width: 250px;
`;

const StyledTextField = styled(TextField)`
  width: 500px;
`;

const StyledLinkIcon = styled(LinkIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  position: absolute;
  margin-left: 4px;
  display: ${(props) => (props.copiedToClipboard ? "none" : "block")};
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  position: absolute;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  display: ${(props) => (props.copiedToClipboard ? "block" : "none")};
  transition: display 0.66s ease-in-out;
`;

enum RefillOptionEnum {
  OneReadingPerDay = "OneReadingPerDay",
  TwoReadingsPerDay = "TwoReadingsPerDay",
  ThreeReadingsPerDay = "ThreeReadingsPerDay"
}

const REFILL_OPTIONS = [
  {
    key: RefillOptionEnum.OneReadingPerDay,
    title: "1 reading/day",
    tooltip: "Includes 100 glucose monitor strips and 100 lancets"
  },
  {
    key: RefillOptionEnum.TwoReadingsPerDay,
    title: "2 readings/day",
    tooltip: "Includes 200 glucose monitor strips and 200 lancets"
  },
  {
    key: RefillOptionEnum.ThreeReadingsPerDay,
    title: "3 readings/day",
    tooltip: "Includes 300 glucose monitor strips and 300 lancets"
  }
];

const ImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  text-align: center;
`;

const Image = styled.img`
  height: 100%;
`;

const CopyDetailsContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

interface FormType {
  refillRadioButtons: string;
  stripsOnly: boolean;
  controlSolution: boolean;
  remainingStrips: number;
}

const RefillNotAllowedWrapper = ({
  patientEnrolledDate,
  patientName,
  patientStatus,
  lastDeliveredOrderWithRefillsDate,
  lastOrderWithRefills,
  hasGlucoseDeviceBeenDelivered
}: {
  patientEnrolledDate: string;
  patientName: string;
  patientStatus: MemberStatusEnum;
  lastDeliveredOrderWithRefillsDate: DateTime;
  lastOrderWithRefills: OrderMemberType;
  hasGlucoseDeviceBeenDelivered: boolean;
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (copiedToClipboard) {
      timerRef.current = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [copiedToClipboard]);

  const stuffToCopy = JSON.stringify({
    patientEnrolledDate,
    patientStatus,
    hasGlucoseDeviceBeenDelivered,
    lastDeliveredOrderWithRefillsDate,
    lastOrderWithRefills
  });

  return (
    <>
      <RefillNotAllowed
        patientName={patientName}
        patientStatus={patientStatus}
        patientEnrolledDate={patientEnrolledDate}
        hasGlucoseDeviceBeenDelivered={hasGlucoseDeviceBeenDelivered}
      />
      <CopyDetailsContainer>
        <div>Copy details to clipboard&nbsp;</div>
        <CustomTooltip placement="top" title={"Copy debug info"}>
          <span style={{ width: "20px", height: "20px" }}>
            <StyledLinkIcon
              copiedToClipboard={copiedToClipboard}
              onClick={async () => {
                await copyToClipboard(stuffToCopy);
                setCopiedToClipboard(true);
              }}
            />
            <StyledCheckCircleIcon copiedToClipboard={copiedToClipboard} />
          </span>
        </CustomTooltip>
      </CopyDetailsContainer>
    </>
  );
};

const RefillNotAllowed = ({
  patientEnrolledDate,
  patientName,
  patientStatus,
  hasGlucoseDeviceBeenDelivered
}: {
  patientEnrolledDate: string;
  patientName: string;
  patientStatus: MemberStatusEnum;
  hasGlucoseDeviceBeenDelivered: boolean;
}) => {
  if (
    patientStatus !== MemberStatusEnum.ACTIVE &&
    patientStatus !== MemberStatusEnum.PENDING &&
    patientStatus !== MemberStatusEnum.REEVALUATING_PATIENT
  ) {
    return (
      <>
        Refill supplies order for {patientName} is not allowed because patient
        is not in ACTIVE, PENDING or REEVALUATING status.
      </>
    );
  }
  if (patientEnrolledDate != null) {
    const enrolledDate = DateTime.fromSQL(patientEnrolledDate);

    const diff = Math.abs(enrolledDate.diffNow("days").days);
    if (diff < CAN_MAKE_REFILL_ORDER_AFTER_DAYS) {
      return (
        <>
          Refill supplies order for {patientName} is not allowed within{" "}
          {CAN_MAKE_REFILL_ORDER_AFTER_DAYS} days after sign-up date of{" "}
          {enrolledDate.toISODate()}
        </>
      );
    }
  }
  if (!hasGlucoseDeviceBeenDelivered) {
    return (
      <>
        Member {patientName} does not have any past delivered Glucose device
        orders
      </>
    );
  }

  // we should not reach this case - if we do, something went wrong or it is bad test data
  return <>Unexpected error: please contact support</>;
};

interface IProps {
  memberId: string;
  setAddressChanged?: Dispatch<SetStateAction<boolean>>;
  addressChanged?: boolean;
  setAddressSource?: Dispatch<SetStateAction<AddressSourceEnum>>;
  addressSource?: AddressSourceEnum;
}

const RefillForm = ({
  memberId,
  setAddressChanged,
  addressChanged,
  addressSource,
  setAddressSource
}: IProps) => {
  const [validateAddressModalOpen, setValidateAddressModalOpen] =
    useState<boolean>(false);
  const [refillOrderRequest, setRefillOrderRequest] = useState(null);
  const dispatch = useAppDispatch();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);
  const timerRef = useRef(null);

  const [editAddressModalOpen, setEditAddressModalOpen] =
    useState<boolean>(false);

  const [confirmOrder, setConfirmOrder] = useState<boolean>(false);
  const [bypassRefillControls, setBypassRefillControls] =
    useState<boolean>(false);

  const { from, to } = useMemo(() => {
    const now = DateTime.now();
    return {
      from: now.minus({ days: 30 }).startOf("day"),
      to: now.endOf("day")
    };
  }, []);

  const { data, isLoading, isFetching } = useGetOrderDevices(memberId, {
    from,
    to
  });
  const { patient, athena, suppliesOrdered, addresses } = data;
  const { addressesEqual, pabPatientAddress, athenaPatientAddress } = addresses;

  const refillData = useGetRefillDataSummary(memberId, 30);

  const isRefillAllowed = refillData?.isRefillAllowed;
  const {
    patientName,
    patientStatus,
    patientEnrolledDate,
    lastDeliveredOrderWithRefillsDate,
    lastOrderWithRefills,
    hasGlucoseDeviceBeenDelivered
  } = refillData;

  const [
    refillOrderMutation,
    {
      isSuccess: refillOrderIsSuccess,
      isLoading: refillOrderLoading,
      error: refillOrderError,
      reset: resetRefillOrder
    }
  ] = useCreateOrderMutation();

  const [
    validateAddressMutation,
    {
      data: validateAddressData,
      isSuccess: validateAddressIsSuccess,
      error: validateAddressError,
      isLoading: validateAddressIsLoading,
      reset: resetValidateAddress
    }
  ] = useValidateAddressMutation();

  useEffect(() => {
    if (refillOrderIsSuccess) {
      Alert_show({
        dispatch,
        id: "refillOrderSuccess",
        title: "Success! Order Created",
        content: (
          <>
            Your refill order for {getNameOrUsername(patient.patient)} was
            successfully created! <br />
          </>
        ),
        size: "small",
        type: "success",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id: "refillOrderSuccess" })
          }
        ]
      });
    }
  }, [refillOrderIsSuccess]);

  const submitAPICall = async (
    values: FormType,
    // if pabPatientAddress doesn't exist, submit with athena address
    addressSource: AddressSourceEnum = AddressSourceEnum.ATHENA
  ) => {
    const skuItems = getSkuItems({
      selectedItemsArray: [values.refillRadioButtons],
      orderType: OrderTypeEnum.REFILL,
      stripsOnly: values?.stripsOnly,
      controlSolution: values?.controlSolution
    });

    const { patient_id } = patient.patient;

    const address =
      addressSource === AddressSourceEnum.ATHENA
        ? athenaPatientAddress
        : pabPatientAddress;

    const refillOrderRequest: OrderType = {
      address,
      patient_id,
      order_type: OrderTypeEnum.REFILL,
      ordered_by: user?.user_id,
      items: skuItems,
      shipping: {
        shipping_method: "USPS"
      },
      available_test_strips: FeatureFlags().STRIPS_ON_HAND
        ? values.remainingStrips
        : undefined
    };

    if (bypassRefillControls) {
      setBypassRefillControls(false);
    }

    setRefillOrderRequest(refillOrderRequest);
    await validateAddressMutation({ address });
  };

  useEffect(() => {
    if (
      validateAddressIsSuccess &&
      validateAddressData &&
      refillOrderRequest &&
      !refillOrderIsSuccess &&
      !refillOrderLoading &&
      !refillOrderError
    ) {
      if (
        !validateAddressData?.addressesEqual ||
        validateAddressData?.status !== AddressValidationStatusEnum.VALID
      ) {
        setValidateAddressModalOpen(true);
      } else {
        refillOrderMutation(refillOrderRequest);
      }
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [
    validateAddressIsSuccess,
    validateAddressData,
    refillOrderRequest,
    refillOrderIsSuccess,
    refillOrderLoading,
    refillOrderError
  ]);

  useEffect(() => {
    if (validateAddressError) {
      Alert_show({
        dispatch,
        id: "validateAddressError",
        title: "Error",
        content: <ErrorComponent error={validateAddressError} />,
        size: "small",
        type: "warning"
      });
    }
  }, [validateAddressError]);

  function reset() {
    resetValidateAddress();
    resetRefillOrder();
  }

  const onSubmit = async (values: FormType) => {
    if (patient === undefined || athena === undefined) return;

    reset();

    // if address has already been changed, then we don't need to check if it's equal
    // this is because there is a race condition with the athena endpoint
    // when changing address, the athena endpoint is not updated immediately

    // if pabPatientAddress exists and addresses aren't equal, show Member Address Sync modal
    if (!addressesEqual && !addressChanged && !!pabPatientAddress) {
      setEditAddressModalOpen(true);
      return;
    }

    if (!isRefillAllowed && !bypassRefillControls) {
      const id = "refillNotAllowed";
      const buttons: AlertButtonType[] = [
        {
          text: "Done",
          style: "cancel",
          onPress: () => Alert_close({ dispatch, id })
        }
      ];
      if (canBypassRefill(currentRole)) {
        buttons.push({
          text: "Refill Anyway",
          style: "default",
          onPress: async () => {
            setBypassRefillControls(true);
            Alert_close({ dispatch, id });

            submitAPICall(values, addressSource);
          }
        });
      }

      Alert_show({
        dispatch,
        id,
        title: "Refill order is not allowed",
        content: (
          <RefillNotAllowedWrapper
            patientName={patientName}
            patientStatus={patientStatus}
            patientEnrolledDate={patientEnrolledDate}
            lastDeliveredOrderWithRefillsDate={
              lastDeliveredOrderWithRefillsDate
            }
            lastOrderWithRefills={lastOrderWithRefills}
            hasGlucoseDeviceBeenDelivered={hasGlucoseDeviceBeenDelivered}
          />
        ),
        type: "error",
        buttons,
        size: "large"
      });

      return;
    }

    if (
      lastOrderWithRefills?.order?.status?.sm_status &&
      // if an order is on its way right now - ENG-5829
      ["started", "accepted", "shipped"].includes(
        lastOrderWithRefills?.order?.status?.sm_status
      ) &&
      !confirmOrder
    ) {
      const orderId = lastOrderWithRefills?.order?.order_id;
      const navigateToRoute = `${window.location.origin}/orders/${orderId}`;
      const shippedDate = DateTime.fromSQL(
        lastOrderWithRefills?.order?.shipping?.shipping_date
      );

      const readableShippedDate = shippedDate?.isValid
        ? shippedDate.toFormat("MM/dd/yyyy")
        : "";

      let text = "is on its way already";

      if (readableShippedDate?.length > 0) {
        text += ` (shipped on ${readableShippedDate})`;
      }

      const id = "suppliesAlreadyOrdered";
      Alert_show({
        dispatch,
        id,
        title: "Confirm Order",
        content: (
          <div>
            Warning: a refill&nbsp;
            <b>
              <a href={navigateToRoute} rel="noreferrer" target="_blank">
                order
              </a>
            </b>
            &nbsp;{text}, are you sure you want to create this extra supplies
            order?
          </div>
        ),
        type: "warning",
        buttons: [
          {
            text: LocalizedStrings.cancel,
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          },
          {
            text: LocalizedStrings.submit,
            style: "default",
            onPress: async () => {
              setConfirmOrder(true);
              Alert_close({ dispatch, id });
              submitAPICall(values, addressSource);
            }
          }
        ]
      });
      return;
    }

    timerRef.current = setTimeout(() => {
      submitAPICall(values, addressSource);
    }, 50);
  };

  const { handleSubmit, values, setFieldValue } = useFormik<FormType>({
    initialValues: {
      refillRadioButtons: RefillOptionEnum.OneReadingPerDay,
      stripsOnly: false,
      controlSolution: false,
      remainingStrips: 0
    },
    onSubmit,
    enableReinitialize: true
  });

  const refillOrder = useCallback(
    (request) => {
      refillOrderMutation(request);
    },
    [refillOrderMutation]
  );

  return (
    <RefillFormContainer>
      {isLoading ? (
        <LoadingFallback delay={500} count={10} />
      ) : (
        <>
          {patient && athena && (
            <form onSubmit={handleSubmit}>
              {isLoading && <LoadingFallback delay={500} count={10} />}
              {patient !== undefined && isLoading === false && (
                <>
                  <br />

                  <StyledTextField
                    value={patientName}
                    label="Member Name"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />
                  <br />
                  <StyledTextField
                    value={
                      pabPatientAddress
                        ? getAddress(pabPatientAddress)
                        : getAddress(athenaPatientAddress)
                    }
                    label="Member Address"
                    disabled
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        endAdornment: (
                          <>
                            {addressChanged && isFetching && (
                              <CircularProgress size={24} />
                            )}
                            {/* Disable edit address once address has been changed once - this is to handle the Athena endpoint race condition */}
                            {!addressChanged && (
                              <Button
                                sx={{
                                  ":hover": {
                                    bgcolor: gray[300]
                                  }
                                }}
                                onClick={() => setEditAddressModalOpen(true)}
                              >
                                <PencilIcon />
                              </Button>
                            )}
                          </>
                        )
                      }
                    }}
                  />
                  <br />
                  <br />
                </>
              )}

              {suppliesOrdered && suppliesOrdered?.length > 0 && (
                <RenderLastNOrders
                  orders={suppliesOrdered}
                  deviceType="REFILL"
                  n={2}
                  showRecentOrdersHeading
                  recentOrdersHeading="Recent Member Refill Orders"
                />
              )}

              <FormLabel as="div">Refill Supplies</FormLabel>
              <ImageContainer>
                <Image src={glucoseStrips} alt={"glucose strips"} />
              </ImageContainer>

              <Typography variant="body1">
                How many readings does the member take per day?
              </Typography>
              <br />
              <TextField
                aria-labelledby="demo-radio-buttons-group-label"
                select
                label={"Readings per day"}
                placeholder={"Readings per day"}
                sx={{
                  width: 300,
                  input: {
                    color: gray[900]
                  }
                }}
                slotProps={{
                  select: {
                    variant: "outlined",
                    value: values.refillRadioButtons,
                    onChange: (event) => {
                      const value = event.target.value as string[];
                      setFieldValue("refillRadioButtons", value);
                    }
                  }
                }}
              >
                {REFILL_OPTIONS.map(({ title, key }) => (
                  <MenuItem key={key} value={key}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>

              <br />
              <br />

              {FeatureFlags().STRIPS_ON_HAND && (
                <>
                  <Typography variant="body1">
                    Please provide a rough estimate of remaining test strips the
                    member has: If unsure, leave as 0.
                  </Typography>
                  <br />
                  <NumberInput
                    id={"remainingStrips"}
                    name={"remainingStrips"}
                    data-testid="remainingStrips"
                    label={"Remaining Strips"}
                    placeholder={"Remaining Strips"}
                    value={values.remainingStrips}
                    onValueChange={(value: number) =>
                      setFieldValue("remainingStrips", value)
                    }
                    sx={{ width: 300 }}
                    min={0}
                    max={60}
                  />
                  <br />
                </>
              )}

              <FormCheckbox
                tooltip={
                  "If selected, supplies will only contain strips and control solution"
                }
                label={"Strips Only (no lancets)"}
                checked={values.stripsOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("stripsOnly", event.target.checked);
                }}
              />

              <FormCheckbox
                label={"Control Solution"}
                checked={values.controlSolution}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("controlSolution", event.target.checked);
                }}
              />

              <br />
              {refillOrderError !== undefined && (
                <>
                  <br />
                  <ErrorTextSpan>
                    Something went wrong creating your order
                  </ErrorTextSpan>
                  <br />
                  <ErrorComponent error={refillOrderError} />
                  <ErrorTextSpan>
                    Please try again or contact your administrator
                  </ErrorTextSpan>
                  <br />
                </>
              )}

              {isPOBoxAddress(addresses) && (
                <ErrorComponent error="Warning: PO Box address. Please check if a home address is available" />
              )}

              <br />
              <SubmitButton
                type="submit"
                loading={refillOrderLoading || validateAddressIsLoading}
                data-testid="submit-refill-order-button"
                disabled={
                  values.refillRadioButtons.length === 0 ||
                  refillOrderIsSuccess ||
                  !patient
                }
              >
                Submit Order
              </SubmitButton>
              <SuccessContainer>
                {refillOrderIsSuccess && (
                  <SuccessText
                    margin={"12px 0 0 0"}
                  >{`Order submitted successfully.`}</SuccessText>
                )}
              </SuccessContainer>
            </form>
          )}
          {patient && !athena && (
            <ErrorComponent
              error={{
                message: "Error: Selected patient does not have Athena account."
              }}
            />
          )}
        </>
      )}
      {refillOrderError !== undefined && (
        <>
          <br />
          <ErrorComponent error={refillOrderError} />
        </>
      )}

      <EditAddressModal
        key={`refill-edit-address-modal${memberId}`}
        patientName={patientName}
        modalOpen={editAddressModalOpen}
        setModalOpen={setEditAddressModalOpen}
        selectedPatientId={memberId}
        addresses={addresses}
        setAddressChanged={setAddressChanged}
        setAddressSource={setAddressSource}
      />
      <ValidateAddressModal
        key={`refill-validate-address-modal${memberId}`}
        modalOpen={validateAddressModalOpen}
        setModalOpen={setValidateAddressModalOpen}
        selectedPatientId={memberId}
        addresses={validateAddressData}
        makeOrder={refillOrder}
        makeOrderRequest={refillOrderRequest}
      />
    </RefillFormContainer>
  );
};

export default RefillForm;
