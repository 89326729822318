import { useEffect } from "react";

import { DateTime } from "luxon";
import DatePicker from "./DatePicker";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (startDate: DateTime) => void;
  onEndDateChange: (startDate: DateTime) => void;
  maxDate?: DateTime;
}

const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  maxDate
}: IProps) => {
  useEffect(() => {
    if (startDate > endDate) {
      onEndDateChange(startDate);
    }
  }, [startDate, endDate]);

  return (
    <>
      <DatePicker
        sx={{ marginTop: 0 }}
        label="Start date"
        value={startDate}
        onChange={(newDate: DateTime) => onStartDateChange(newDate)}
        maxDate={endDate}
      />

      <DatePicker
        sx={{ marginTop: 0 }}
        label="End date"
        value={endDate}
        onChange={(newDate: DateTime) => onEndDateChange(newDate)}
        minDate={startDate}
        maxDate={maxDate}
      />
    </>
  );
};

export default DateRangePicker;
