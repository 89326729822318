import styled from "@emotion/styled";
import { useFormik } from "formik";
import {
  FormLabel,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { TurqoiseButton, WhiteButton } from "../../../../styling";
import ErrorComponent from "../../../../components/ErrorComponent";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import SelectedEncounterType from "common/types/SelectedEncounterType";
import { useEffect, useMemo } from "react";
import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import { InfoOutlined } from "@mui/icons-material";
import { gray } from "common/styling/colors";
import NumberInput from "../../../../components/Input/NumberInput";
import { CustomTooltip } from "../../../../styling/StyleComponents";
import { TnEncounterReasons } from "common/helpers/EncounterHelper";
import { useUpdateEncounterMutation } from "common/services/EncountersService";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  selectedEncounter: SelectedEncounterType;
}

interface FormValues {
  total_time: number;
  visit_type: VisitReasonsEnum;
}

export const EditTnEncounterModal = ({
  modalOpen,
  setModalOpen,
  selectedEncounter
}: IProps) => {
  const default_total_time = useMemo(
    () => selectedEncounter?.total_time,
    [selectedEncounter]
  );
  const default_visit_type = useMemo(
    () => selectedEncounter?.visit_type,
    [selectedEncounter]
  );
  const selected_encounter_id = useMemo(
    () => selectedEncounter?.encounter_id,
    [selectedEncounter]
  );

  const dispatch = useAppDispatch();
  const [
    updateEncounterMutation,
    {
      isError: updateEncounterIsError,
      isLoading: updateEncounterIsLoading,
      error: updateEncounterError,
      isSuccess: updateEncounterIsSuccess
    }
  ] = useUpdateEncounterMutation();

  useEffect(() => {
    if (updateEncounterIsError) {
      const id = "updateError";
      Alert_show({
        dispatch,
        id,
        title: "Something went wrong updating the encounter.",
        content: (
          <ErrorComponent
            error={updateEncounterError}
            showErrorResponseMessage
            hideErrorCode
          />
        ),
        size: "small",
        type: "warning"
      });
    }
  }, [updateEncounterIsError, updateEncounterError]);

  useEffect(() => {
    if (updateEncounterIsSuccess) {
      handleModalClose();
    }
  }, [updateEncounterIsSuccess]);

  async function updateEncounterHandler(data) {
    await updateEncounterMutation({
      encounterId: selected_encounter_id,
      data
    });
  }

  const validate = (values: FormValues) => {
    const errors = {};

    if (!values.total_time) {
      errors["total_time"] = "Required";
    }

    if (!values.visit_type && !default_visit_type) {
      errors["visit_type"] = "Required";
    }
    // if we are editing, check if the values are the same before allowing submission
    if (
      values.total_time === default_total_time &&
      values.visit_type === default_visit_type
    ) {
      errors["visit_type"] = "Please make a change before submitting";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    const updateBody: any = {};
    if (values.total_time !== default_total_time) {
      updateBody.duration = values.total_time;
    }

    if (values.visit_type !== default_visit_type) {
      updateBody.reason = values.visit_type;
    }

    await updateEncounterHandler(updateBody);
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      total_time: default_total_time,
      visit_type: default_visit_type
    },
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    setModalOpen(false);
  };

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Enter Time Spent Modal"
      modalHeight="max-content"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          Edit Encounter
        </ModalHeader>
        <ModalBody>
          <Flexbox gap="20px" flexDirection="column">
            <Flexbox gap="8px" flexDirection="column">
              <InputLabel>
                <Typography variant="h6" color="text.primary">
                  Visit Type
                </Typography>
              </InputLabel>
              <TextField
                data-testid="Visit Type"
                value={formik.values?.visit_type ?? ""}
                placeholder="Select Visit Type"
                select
                fullWidth
                onChange={(event) => {
                  formik.setFieldValue("visit_type", event.target.value);
                }}
              >
                {TnEncounterReasons?.map((reason) => (
                  <MenuItem
                    key={reason.value}
                    value={reason.value}
                    data-testid={reason.label}
                  >
                    {reason.label}
                  </MenuItem>
                ))}
              </TextField>
            </Flexbox>
            <Flexbox justifyContent="space-between">
              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    <Flexbox alignItems="center" gap="2px">
                      Total Time{" "}
                      <CustomTooltip
                        backgroundColor={gray[200]}
                        title={
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            maxWidth="225px"
                          >
                            This is the total time spent in service of members
                            including call attempts, async messaging, task
                            completion, charting, etc.
                          </Typography>
                        }
                      >
                        <InfoOutlined color="primary" fontSize="small" />
                      </CustomTooltip>
                    </Flexbox>
                  </Typography>
                </FormLabel>
                <NumberInput
                  sx={{ width: "100%" }}
                  placeholder="1-60 min"
                  id={"total_time"}
                  name={"total_time"}
                  data-testid="totalTimeInput"
                  value={formik.values?.total_time ?? ""}
                  errorString={formik.errors.total_time}
                  onValueChange={(value) => {
                    formik.setFieldValue("total_time", value);
                  }}
                  min={1}
                  max={60}
                />
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={updateEncounterIsLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
              data-testid="submitButton"
            >
              Submit
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};
