import { useEffect } from "react";
import ReportedCancellations from "../ReportedCancellations/ReportedCancellations";
import RolesEnum from "common/enums/RolesEnum";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";
import { TableColumn } from "../../components/Table/TableTypes";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import Routes from "../../routes/Routes";

interface IProps {
  submitter_roles?: RolesEnum[];
  componentHeader?: string;
  tableHeader?: string;
  linked_entities?: SurveyLinkedEntitiesEnum[];
  size?: number;
  tableColumns?: TableColumn[];
  showHeader?: boolean;
}

export default function MemberCancellationRedirect({
  submitter_roles,
  componentHeader,
  tableHeader,
  linked_entities,
  size,
  tableColumns,
  showHeader
}: Readonly<IProps>) {
  const params = useSanitizedParams();
  const { memberId } = params;

  useEffect(() => {
    if (memberId) {
      window.location.href = Routes.CANCELLATIONS_HISTORY.replace(
        ":memberId",
        memberId
      );
    }
  }, [memberId]);

  return (
    <ReportedCancellations
      submitter_roles={submitter_roles}
      componentHeader={componentHeader}
      tableHeader={tableHeader}
      linked_entities={linked_entities}
      size={size}
      tableColumns={tableColumns}
      showHeader={showHeader}
    />
  );
}
