import styled from "@emotion/styled";
import { DateTime } from "luxon";
import cloneDeep from "lodash.clonedeep";
import {
  ThreeLinesIcon,
  ChartIcon,
  DarkClockIcon
} from "../../../assets/images/icons";
import { ComponentHeader } from "../../../styling/StyleComponents";
import { ReadingQualifierReasonEnum_toString } from "common/enums/ReadingQualifierReasonEnum";
import { CustomDataPoint } from "../../../@types/customc3";
import { blue } from "common/styling/colors";
import useGetMemberDataSummary from "common/hooks/useGetMemberDataSummary";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import MemberType from "common/types/MemberType";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";

type ChartColumn = [string, ...Array<DateTime | number | string | Date>];

const SEVEN_DAYS_IN_SECONDS = 7 * 24 * 60 * 60;
const ONE_DAY = 24 * 60 * 60 * 1000;

const shouldNotForwardProps = ["$showTable", "$visible"];

const ShouldForwardProp = {
  shouldForwardProp: (prop) => !shouldNotForwardProps.includes(prop)
};

const DateFilterContainer = styled.div`
  flex-direction: column;
  text-align: right;
  width: 100%;
  display: flex;
`;

const IconsContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

const ThreeLinesContainer = styled("div", ShouldForwardProp)<{
  $showTable: boolean;
}>`
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.$showTable ? blue[700] : props.theme.color.white};
  border: 1px solid
    ${(props) =>
      props.$showTable ? "rgba(35, 149, 144, 0.1)" : "rgba(2, 16, 15, 0.1)"};
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;

  transition: all 0.66s ease-out;
  &:hover {
    border: 1px solid rgba(35, 149, 144, 0.1);
    background: ${blue[700]};
    & svg {
      & g {
        opacity: 1;
        & line {
          stroke: white;
        }
      }
    }
  }
`;
const ChartIconContainer = styled("div", ShouldForwardProp)<{
  $showTable: boolean;
}>`
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.$showTable ? props.theme.color.white : blue[700]};
  border: 1px solid
    ${(props) =>
      props.$showTable ? "rgba(2, 16, 15, 0.1)" : "rgba(35, 149, 144, 0.1)"};
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;

  transition: all 0.66s ease-out;
  &:hover {
    border: 1px solid rgba(35, 149, 144, 0.1);
    background: ${blue[700]};
    & svg {
      & path {
        fill: white;
      }
    }
  }
`;
const ThreeLinesSVG = styled(ThreeLinesIcon, ShouldForwardProp)<{
  $showTable: boolean;
}>`
  & g {
    opacity: 1;
    & line {
      stroke: ${(props) =>
        props.$showTable ? props.theme.color.white : props.theme.color.black};
    }
  }
`;
const ChartIconSVG = styled(ChartIcon, ShouldForwardProp)<{
  $showTable: boolean;
}>`
  & path {
    fill: ${(props) =>
      props.$showTable ? props.theme.color.black : props.theme.color.white};
  }
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
`;
const ChartLeft = styled.div``;

const ChartRight = styled.div`
  display: flex;
`;
const ChartHeaderText = styled(ComponentHeader)``;
const ChartSubHeaderText = styled.div<{ $color?: string }>`
  font-style: normal;
  text-wrap: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.$color ? props.$color : "rgba(24, 24, 25, 0.42)")};
`;

const AveragesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AvgButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;

const AvgTextContainer = styled("div", ShouldForwardProp)<{
  margin?: string;
  $visible?: boolean;
  textalign?: string;
}>`
  // TBD remove this margin conditional when we switch over to v2 member chart
  margin: ${(props) => (props.margin ? props.margin : "0px 0px 0px 8px")};
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  ${(props) => (props.textalign ? `text-align: ${props.textalign};` : "")}
`;

const TimezoneContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const StyledDarkClockIcon = styled(DarkClockIcon)`
  margin-right: 8px;
`;

const TimezoneText = styled.div`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.5px;
  margin-top: 2px;
  color: rgba(24, 24, 25, 0.42);
`;

/**
 * Generates an array of tick values for the x axis of a chart
 * @param dataArr array of data
 * @param xAxisMaxTickCount integer representing the max ticks on the x axis
 * @returns { tickArr: string[], xAxisParams: { max: Date, min: Date }}
 */

function getXAxisTickArray(dataArr, xAxisMaxTickCount = 8) {
  let tickArr = [];
  const xAxisParams: { max?: Date; min?: Date } = {};

  const dataLength = dataArr?.length;

  // We are limiting the number of ticks shown on the x Axis (defaults to 8) by passing in a tick values array to the x axis object
  const maxTime = dataArr?.[0]?.["measure_timestamp"] * 1000;
  const minTime = dataArr?.[dataLength - 1]?.["measure_timestamp"] * 1000;
  let interval = (maxTime - minTime) / xAxisMaxTickCount;
  interval = Math.max(interval, ONE_DAY);

  const beforeLoopLength = Math.min(dataLength, xAxisMaxTickCount);
  for (let i = 0; i < beforeLoopLength; i++) {
    const tickMarker = new Date(Number(minTime + i * interval));

    const tickMarkerTime = tickMarker?.getTime();
    const tickMarkerDate = tickMarker?.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });

    // if the tick marker is within bounds of the data, add it to the tickArr
    if (
      tickMarkerTime > minTime - ONE_DAY &&
      tickMarkerTime < maxTime + ONE_DAY
    ) {
      tickArr.push(tickMarkerDate);
    }

    if (dataLength > 0) {
      // set to beginning of day
      const start = DateTime.fromJSDate(new Date(minTime)).startOf("day");
      // set to end of day
      const end = DateTime.fromJSDate(new Date(maxTime))
        .plus({ days: 1 })
        .endOf("day");

      // adds the final tick marker if needed since we are extending xAxisParams.max by an extra day
      let tickMarkerTest = new Date(Number(tickMarker.getTime() + interval));
      if (
        !isNaN(tickMarkerTest?.getTime()) &&
        tickMarkerTest.getTime() < end?.toMillis()
      ) {
        while (tickMarkerTest.getTime() < end?.toMillis()) {
          if (tickMarkerTest.getTime() < end?.toMillis()) {
            tickArr.push(
              tickMarkerTest?.toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit"
              })
            );
            tickMarkerTest = new Date(tickMarkerTest.getTime() + interval);
          }
        }
      }
      // extend x and y axis by one day
      xAxisParams.max = end.toJSDate();
      xAxisParams.min = start.toJSDate();
    }
  }

  // de-dupe
  tickArr = [...new Set(tickArr)];
  tickArr = tickArr.map((item) => new Date(item).toLocaleString());

  return { tickArr, xAxisParams };
}

/**
 * Transforms response data from API to chart data
 * @param  {Array} data Input data
 * @return {Array[]}  Returns an array of arrays with transformed data for the chart, x axis data, and x axis tick data
 */
const transformGlucoseDataToColumns = (
  data,
  timezone,
  isReadingsHistory = false,
  xAxisMaxTickCount = 8
) => {
  if (!data) {
    return {
      xs: {
        "BEFORE BREAKFAST": "beforeDate",
        OTHER: "otherDate",
        "BEFORE REASON": "BEFORE REASON",
        "OTHER REASON": "OTHER REASON"
      },
      dateArr: [],
      columns: [[], [], [], [], [], [], [], [], []],
      beforeAvg: null,
      afterAvg: null,
      otherAvg: null,
      xAxisParamsBefore: {},
      xAxisParamsOther: {}
    };
  }
  const dateArr = [];
  const beforeDateArr: ChartColumn = ["beforeDate"];
  const beforeArr: ChartColumn = [
    isReadingsHistory ? "BEFORE BREAKFAST R" : "BEFORE BREAKFAST"
  ];
  const beforeReason: ChartColumn = ["BEFORE REASON"];

  const otherDateArr: ChartColumn = ["otherDate"];
  const otherArr: ChartColumn = [isReadingsHistory ? "OTHER R" : "OTHER"];
  const otherReason: ChartColumn = ["OTHER REASON"];
  const glucoseBeforeSimpleMovingAvgArr: ChartColumn = [
    "GLUCOSE FASTING SMA 7 DAY"
  ];
  const glucoseOtherSimpleMovingAvgArr: ChartColumn = [
    "GLUCOSE NON FASTING SMA 7 DAY"
  ];

  // use these to calculate SMAs
  const glucoseBeforeDataArr = [];
  const glucoseOtherDataArr = [];

  let beforeSum = 0;
  let otherSum = 0;

  data?.forEach((item, index) => {
    const { measure_timestamp, reading_qualifier, glucose, c_is_fasting_v1 } =
      item;
    const date = DateTime.fromSeconds(measure_timestamp).setZone(timezone);
    const qualifierReason =
      ReadingQualifierReasonEnum_toString(reading_qualifier) || "Other";

    if (c_is_fasting_v1) {
      beforeArr.push(glucose);
      beforeDateArr.push(date);
      beforeReason.push(qualifierReason);
      beforeSum += glucose;
      glucoseBeforeDataArr.push(item);
    } else {
      otherArr.push(glucose);
      otherDateArr.push(date);
      otherReason.push(qualifierReason);
      otherSum += glucose;
      glucoseOtherDataArr.push(item);
    }
  });

  const { xAxisParams: xAxisParamsBefore, tickArr: beforeTickArr } =
    getXAxisTickArray(glucoseBeforeDataArr, xAxisMaxTickCount);
  const { xAxisParams: xAxisParamsOther, tickArr: otherTickArr } =
    getXAxisTickArray(glucoseOtherDataArr, xAxisMaxTickCount);

  glucoseBeforeDataArr.forEach((item, index) => {
    glucoseBeforeSimpleMovingAvgArr.push(
      getMovingAverage(
        index,
        "glucose",
        glucoseBeforeDataArr,
        SEVEN_DAYS_IN_SECONDS
      )
    );
  });

  glucoseOtherDataArr.forEach((item, index) => {
    glucoseOtherSimpleMovingAvgArr.push(
      getMovingAverage(
        index,
        "glucose",
        glucoseOtherDataArr,
        SEVEN_DAYS_IN_SECONDS
      )
    );
  });

  const glucoseBeforeSmaCount =
    glucoseBeforeSimpleMovingAvgArr.filter((item) => item !== null).length - 1;

  const glucoseOtherSmaCount =
    glucoseOtherSimpleMovingAvgArr.filter((item) => item !== null).length - 1;

  const beforeAvg = !isNaN(Math.round(beforeSum / (beforeArr.length - 1)))
    ? Math.round(beforeSum / (beforeArr.length - 1))
    : "N/A";

  const otherAvg = !isNaN(Math.round(otherSum / (otherArr.length - 1)))
    ? Math.round(otherSum / (otherArr.length - 1))
    : "N/A";

  let finalColumns, xs;

  if (isReadingsHistory) {
    finalColumns = [
      beforeDateArr,
      otherDateArr,
      beforeArr,
      otherArr,
      beforeReason,
      otherReason
    ];
    xs = {
      "BEFORE BREAKFAST R": "beforeDate",
      "OTHER R": "otherDate",
      "BEFORE REASON": "BEFORE REASON",
      "OTHER REASON": "OTHER REASON"
    };
  } else {
    finalColumns = [
      beforeDateArr,
      otherDateArr,
      beforeArr,
      otherArr,
      beforeReason,
      otherReason,
      glucoseBeforeSmaCount > 3
        ? glucoseBeforeSimpleMovingAvgArr
        : "GLUCOSE FASTING SMA 7 DAY",
      glucoseOtherSmaCount > 3
        ? glucoseOtherSimpleMovingAvgArr
        : "GLUCOSE NON FASTING SMA 7 DAY"
    ];

    xs = {
      "BEFORE BREAKFAST": "beforeDate",
      OTHER: "otherDate",
      "BEFORE REASON": "BEFORE REASON",
      "OTHER REASON": "OTHER REASON"
    };
  }

  return {
    xs,
    dateArr: dateArr,
    columns: finalColumns,
    beforeAvg,
    otherAvg,
    xAxisParamsBefore,
    xAxisParamsOther,
    otherTickArr,
    beforeTickArr
  };
};

const getGlucoseColorFromDataIndex = (
  color: string,
  dataSeries: CustomDataPoint
) => {
  const { id, value, index } = dataSeries;

  if (value === undefined) {
    const id = dataSeries?.id || dataSeries;

    switch (id) {
      case "BEFORE BREAKFAST":
        return "#1f77b4";
      case "OTHER":
        return "#2ca02c";
    }

    return color;
  }

  const NORMAL_COLOR = "#000000";
  const ABNORMAL_COLOR = "#FF5364";

  if (value < 70 || value >= 140) {
    return ABNORMAL_COLOR;
  } else {
    return NORMAL_COLOR;
  }
};

const getBloodPressureColorFromDataIndex = (
  color: string,
  dataSeries: CustomDataPoint,
  systolicArr: number[],
  diastolicArr: number[]
) => {
  const { index } = dataSeries;
  if (index === undefined) return color;

  const NORMAL_COLOR = "#000000b3";
  const ABNORMAL_COLOR = "#FF5364";

  const systolicValue = systolicArr[index + 1];
  const diastolicValue = diastolicArr[index + 1];

  if (
    systolicValue >= 180 ||
    diastolicValue >= 120 ||
    systolicValue < 90 ||
    diastolicValue < 55
  ) {
    return ABNORMAL_COLOR;
  } else {
    return NORMAL_COLOR;
  }
};

function getMovingAverage(index, accessor, data, windowSize) {
  const dataPoint = data[index];

  let sum = 0;
  let count = 0;
  // data is sorted in descending order
  // the newest reading is at index 0
  // the oldest reading is at the end of the array
  for (let i = index; i < data?.length; i++) {
    const prevDataPoint = data?.[i + 1];
    if (
      dataPoint.measure_timestamp - prevDataPoint?.measure_timestamp <=
      windowSize
    ) {
      // tbd remove this type coercion once we return numbers from the backend ENG-4177
      const prevDataPointValue =
        typeof prevDataPoint?.[accessor] === "string"
          ? Number.parseFloat(prevDataPoint?.[accessor])
          : prevDataPoint?.[accessor];
      sum += prevDataPointValue;
      count++;
    } else {
      break;
    }
  }

  return count === 0 ? null : Math.round(sum / count);
}

/**
 * Transforms response data from API to chart data
 * @param  {Array} data Input data
 * @return {Array[]}  Returns an array of arrays with transformed data for the chart, x axis data, and x axis tick data
 */
const transformBloodPressureDataToColumns = (
  data,
  timezone,
  isReadingsHistory = false,
  xAxisMaxTickCount = 8
) => {
  const dateArr: ChartColumn = ["date"];
  const systolicArr: ChartColumn = ["SYSTOLIC"];
  const diastolicArr: ChartColumn = ["DIASTOLIC"];
  const systolicSimpleMovingAvgArr: ChartColumn = ["SYSTOLIC SMA 7 DAY"];
  const diastolicSimpleMovingAvgArr: ChartColumn = ["DIASTOLIC SMA 7 DAY"];
  const pulseSimpleMovingAvgArr: ChartColumn = ["PULSE SMA 7 DAY"];

  const pulseArr = [];

  let diastolicSum = 0;
  let systolicSum = 0;
  let pulseSum = 0;

  const dataLength = data?.length;

  data?.forEach((item, index) => {
    const { diastolic, systolic, pulse, measure_timestamp } = item;
    const date = DateTime.fromSeconds(measure_timestamp)
      .setZone(timezone)
      .toFormat("L/d/yyyy, h:mm:ss a");
    dateArr.push(date);

    systolicArr.push(systolic);
    diastolicArr.push(diastolic);
    pulseArr.push(pulse);

    systolicSimpleMovingAvgArr.push(
      getMovingAverage(index, "systolic", data, SEVEN_DAYS_IN_SECONDS)
    );
    diastolicSimpleMovingAvgArr.push(
      getMovingAverage(index, "diastolic", data, SEVEN_DAYS_IN_SECONDS)
    );

    pulseSimpleMovingAvgArr.push(
      getMovingAverage(index, "pulse", data, SEVEN_DAYS_IN_SECONDS)
    );

    diastolicSum += diastolic;
    systolicSum += systolic;
    pulseSum += pulse;
  });

  const diastolicAvg = !isNaN(Math.round(diastolicSum / dataLength))
    ? Math.round(diastolicSum / dataLength)
    : "N/A";
  const systolicAvg = !isNaN(Math.round(systolicSum / dataLength))
    ? Math.round(systolicSum / dataLength)
    : "N/A";

  const pulseAvg = !isNaN(Math.round(pulseSum / dataLength))
    ? Math.round(pulseSum / dataLength)
    : "N/A";

  const diastolicY = cloneDeep(diastolicArr);
  diastolicY.shift();
  const systolicY = cloneDeep(systolicArr);
  systolicY.shift();

  let finalColumns;

  const systolicSmaCount =
    systolicSimpleMovingAvgArr.filter((item) => item !== null).length - 1;
  const diastolicSmaCount =
    diastolicSimpleMovingAvgArr.filter((item) => item !== null).length - 1;
  const pulseSmaCount =
    pulseSimpleMovingAvgArr.filter((item) => item !== null).length - 1;

  const finalPulseSmaArr =
    pulseSmaCount > 3 ? pulseSimpleMovingAvgArr : ["PULSE SMA 7 DAY"];

  const { xAxisParams, tickArr } = getXAxisTickArray(data, xAxisMaxTickCount);

  if (isReadingsHistory) {
    finalColumns = [dateArr, systolicArr, diastolicArr];
  } else {
    finalColumns = [
      dateArr,
      systolicArr,
      diastolicArr,
      systolicSmaCount > 3
        ? systolicSimpleMovingAvgArr
        : ["SYSTOLIC SMA 7 DAY"],
      diastolicSmaCount > 3
        ? diastolicSimpleMovingAvgArr
        : ["DIASTOLIC SMA 7 DAY"]
    ];
  }

  return {
    columns: finalColumns,
    tickArr,
    pulseArr,
    pulseSimpleMovingAvgArr: finalPulseSmaArr,
    xAxisParams,
    systolicAvg,
    diastolicAvg,
    pulseAvg
  };
};

/**
 * Transforms response data from API to chart data
 * @param  {Array} data Input data
 * @return {Array[]}  Returns an array of arrays with transformed data for the chart, x axis data, and x axis tick data
 */
const transformOximeterDataToColumns = (
  data,
  timezone,
  xAxisMaxTickCount = 8
) => {
  const dateArr: ChartColumn = ["date"];

  const oxygenArr: ChartColumn = ["SPO2"];
  const pulseArr = [];
  const timestampArr = [];

  let oxygenSum = 0;

  const dataLength = data?.length;

  data?.forEach((item) => {
    const { spo2, pulse, measure_timestamp } = item;
    timestampArr.push(measure_timestamp);
    const date = DateTime.fromSeconds(measure_timestamp).setZone(timezone);
    dateArr.push(date);

    const oxygen = Number(spo2);
    oxygenArr.push(oxygen);
    pulseArr.push(pulse);

    oxygenSum += oxygen;
  });

  const oxygenAvg = !isNaN(Math.round(oxygenSum / dataLength))
    ? Math.round(oxygenSum / dataLength)
    : "N/A";

  const oxygenY = cloneDeep(oxygenArr);
  oxygenY.shift();

  const dataArrayOxygen: ChartColumn = ["SPO2 SMA 7 DAY"];
  timestampArr.forEach((item, index) => {
    dataArrayOxygen.push(
      getMovingAverage(index, "spo2", data, SEVEN_DAYS_IN_SECONDS)
    );
  });

  const oxygenSmaCount =
    dataArrayOxygen.filter((item) => item !== null).length - 1;

  const { xAxisParams, tickArr } = getXAxisTickArray(data, xAxisMaxTickCount);

  return {
    columns: [
      dateArr,
      oxygenArr,
      oxygenSmaCount > 3 ? dataArrayOxygen : ["SPO2 SMA 7 DAY"]
    ],
    pulseArr,
    xAxisParams,
    tickArr,
    oxygenAvg
  };
};

/**
 * Transforms response data from API to chart data
 * @param  {Array} data Input data
 * @return {Array[]}  Returns an array of arrays with transformed data for the chart, x axis data, and x axis tick data
 */
const transformWeightScaleDataToColumns = (
  data,
  timezone,
  xAxisMaxTickCount = 8
) => {
  const dateArr: ChartColumn = ["date"];
  const weightArr: ChartColumn = ["WEIGHT"];

  const timestampArr = [];

  let weightSum = 0;

  const dataLength = data?.length;

  data?.forEach((item) => {
    const { weight, measure_timestamp } = item;
    timestampArr.push(measure_timestamp);
    const date = DateTime.fromSeconds(measure_timestamp).setZone(timezone);
    dateArr.push(date);
    const formattedWeight = parseFloat(weight).toFixed(1);
    weightArr.push(formattedWeight);

    weightSum += Number(formattedWeight);
  });

  const weightAvg = !isNaN(Math.round(weightSum / dataLength))
    ? Math.round(weightSum / dataLength)
    : "N/A";

  const weightY = cloneDeep(weightArr);
  weightY.shift();
  const dataX = cloneDeep(timestampArr);

  const dataArrayWeight: ChartColumn = ["WEIGHT SMA 7 DAY"];
  dataX.forEach((item, index) => {
    dataArrayWeight.push(
      getMovingAverage(index, "weight", data, SEVEN_DAYS_IN_SECONDS)
    );
  });

  const weightSmaCount =
    dataArrayWeight.filter((item) => item !== null).length - 1;

  const { xAxisParams, tickArr } = getXAxisTickArray(data, xAxisMaxTickCount);

  return {
    columns: [
      dateArr,
      weightArr,
      weightSmaCount > 3 ? dataArrayWeight : ["WEIGHT SMA 7 DAY"]
    ],

    xAxisParams,
    tickArr,
    weightAvg
  };
};

function useGetBloodPressureStats(patient: MemberType, dateFilter: number) {
  const { bloodPressure } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.BLOOD_PRESSURE]
  });
  return {
    text: "Systolic/Diastolic markers",
    data: bloodPressure.data,
    highest: `${
      bloodPressure?.max
        ? bloodPressure?.max?.systolic + "/" + bloodPressure?.max?.diastolic
        : "N/A"
    }`,
    highestDate:
      bloodPressure?.max?.measure_timestamp &&
      DateTime.fromSeconds(bloodPressure?.max?.measure_timestamp),
    lowest: `${
      bloodPressure?.min
        ? bloodPressure?.min?.systolic + "/" + bloodPressure?.min?.diastolic
        : "N/A"
    }`,
    lowestDate:
      bloodPressure?.min?.measure_timestamp &&
      DateTime.fromSeconds(bloodPressure?.min?.measure_timestamp),
    latest: `${
      bloodPressure?.lastReading
        ? bloodPressure?.lastReading?.systolic +
          "/" +
          bloodPressure?.lastReading?.diastolic
        : "N/A"
    }`,
    latestDate:
      bloodPressure?.lastReading?.measure_timestamp &&
      DateTime.fromSeconds(bloodPressure?.lastReading?.measure_timestamp),
    average: bloodPressure?.average ?? "N/A",
    loading: bloodPressure?.loading
  };
}

function useGetGlucoseStats(patient: MemberType, dateFilter: number) {
  const { glucose } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.GLUCOSE_CATEGORY]
  });
  return {
    text: "Glucose markers (mg/DL)",
    data: glucose.data,
    highest: glucose?.max?.glucose ?? "N/A",
    highestDate:
      glucose?.max?.measure_timestamp &&
      DateTime.fromSeconds(glucose?.max?.measure_timestamp),
    lowest: glucose?.min?.glucose ?? "N/A",
    lowestDate:
      glucose?.min?.measure_timestamp &&
      DateTime.fromSeconds(glucose?.min?.measure_timestamp),
    latest: `${glucose?.lastReading ? glucose?.lastReading?.glucose : "N/A"}`,
    latestDate:
      glucose?.lastReading?.measure_timestamp &&
      DateTime.fromSeconds(glucose?.lastReading?.measure_timestamp),
    average: glucose?.average ?? "N/A",
    loading: glucose?.loading
  };
}

function useGetPulseStats(patient: MemberType, dateFilter: number) {
  const { bloodPressure: pulse } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.BLOOD_PRESSURE]
  });
  return {
    text: "Pulse",
    data: pulse.data,
    highest: pulse?.maxPulse?.pulse ?? "N/A",
    highestDate:
      pulse?.max?.measure_timestamp &&
      DateTime.fromSeconds(pulse?.max?.measure_timestamp),
    lowest: pulse?.minPulse?.pulse ?? "N/A",
    lowestDate:
      pulse?.min?.measure_timestamp &&
      DateTime.fromSeconds(pulse?.min?.measure_timestamp),
    latest: `${pulse?.lastReading ? pulse?.lastReading?.pulse : "N/A"}`,
    latestDate:
      pulse?.lastReading?.measure_timestamp &&
      DateTime.fromSeconds(pulse?.lastReading?.measure_timestamp),
    average: pulse?.averagePulse ?? "N/A",
    loading: pulse?.loading
  };
}

function useGetWeightScaleStats(patient: MemberType, dateFilter: number) {
  const { weightScale } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.WEIGHT_SCALE]
  });
  return {
    text: "Weight markers (lbs)",
    data: weightScale.data,
    highest: weightScale?.max
      ? // @ts-ignore
        Number.parseFloat(weightScale?.max?.weight)?.toFixed(1)
      : "N/A",
    highestDate:
      weightScale?.max?.measure_timestamp &&
      DateTime.fromSeconds(weightScale?.max?.measure_timestamp),
    lowest: weightScale?.min
      ? // @ts-ignore
        Number.parseFloat(weightScale?.min?.weight)?.toFixed(1)
      : "N/A",
    lowestDate:
      weightScale?.min?.measure_timestamp &&
      DateTime.fromSeconds(weightScale?.min?.measure_timestamp),
    latest: `${
      weightScale?.lastReading
        ? // @ts-ignore
          Number.parseFloat(weightScale?.lastReading?.weight)?.toFixed(1)
        : "N/A"
    }`,
    latestDate:
      weightScale?.lastReading?.measure_timestamp &&
      DateTime.fromSeconds(weightScale?.lastReading?.measure_timestamp),
    average: weightScale?.average ?? "N/A",
    loading: weightScale?.loading
  };
}

function useGetOximeterStats(patient: MemberType, dateFilter: number) {
  const { oximeter } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.OXIMETER]
  });
  return {
    text: "Oximeter markers (SpO2)",
    data: oximeter.data,
    highest: oximeter?.max?.spo2 ?? "N/A",
    highestDate:
      oximeter?.max?.measure_timestamp &&
      DateTime.fromSeconds(oximeter?.max?.measure_timestamp),
    lowest: oximeter?.min?.spo2 ?? "N/A",
    lowestDate:
      oximeter?.min?.measure_timestamp &&
      DateTime.fromSeconds(oximeter?.min?.measure_timestamp),
    latest: `${oximeter?.lastReading ? oximeter?.lastReading?.spo2 : "N/A"}`,
    latestDate:
      oximeter?.lastReading?.measure_timestamp &&
      DateTime.fromSeconds(oximeter?.lastReading?.measure_timestamp),
    average: oximeter?.average ?? "N/A",
    loading: oximeter?.loading
  };
}

function useGetReadingData(
  readingType: DeviceTrendParam,
  patient: MemberType,
  dateFilter: number
) {
  let data;
  // TBD implement these as an endpoint on the backend ENG-4177
  switch (readingType) {
    case DeviceTrendParam.BLOOD_PRESSURE: {
      data = useGetBloodPressureStats(patient, dateFilter);
      break;
    }
    case DeviceTrendParam.GLUCOSE: {
      data = useGetGlucoseStats(patient, dateFilter);
      break;
    }
    case DeviceTrendParam.PULSE: {
      data = useGetPulseStats(patient, dateFilter);
      break;
    }
    case DeviceTrendParam.OXIMETER: {
      data = useGetOximeterStats(patient, dateFilter);
      break;
    }
    case DeviceTrendParam.WEIGHT_SCALE: {
      data = useGetWeightScaleStats(patient, dateFilter);
      break;
    }
  }
  return data;
}

export {
  DateFilterContainer,
  IconsContainer,
  ThreeLinesContainer,
  ChartIconContainer,
  ThreeLinesSVG,
  ChartIconSVG,
  ChartHeader,
  ChartLeft,
  ChartRight,
  ChartHeaderText,
  ChartSubHeaderText,
  AveragesContainer,
  AvgButtonContainer,
  AvgTextContainer,
  TimezoneContainer,
  StyledDarkClockIcon,
  TimezoneText,
  transformGlucoseDataToColumns,
  getGlucoseColorFromDataIndex,
  getBloodPressureColorFromDataIndex,
  transformBloodPressureDataToColumns,
  transformOximeterDataToColumns,
  transformWeightScaleDataToColumns,
  useGetReadingData
};
