import { useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, MenuItem, Typography } from "@mui/material";

import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../styling/StyleModal";
import { TurqoiseButton } from "../../../styling";
import { formatName, isFalsy } from "common/helpers/helpers";
import {
  useCreateCalendarEventMutation,
  useUpdateCalendarEventMutation,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/CalendarService";
import { v4 as uuidv4 } from "uuid";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import DropdownNurseType from "common/types/DropdownNurseType";
import DatePicker from "../../../components/DatePicker";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { gray } from "common/styling/colors";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import { resetPtoState } from "common/redux/PtoSlice";
import { affectedBusinessDays } from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import { TimeOffReasons } from "common/helpers/CalendarHelper";

interface IProps {
  isVisible: boolean;
  onRequestClose: (refetch: boolean) => void;
  nurses?: DropdownNurseType[];
  teamId?: string;
  isEdit?: boolean;
  selectedCarerId?: string;
  modalKey: string;
}

interface IPropsRender extends IProps {
  event?: any;
  eventId?: string;
  carerTimezone?: string;
}

function getStartTime(date: DateTime, nurseTimezone: string) {
  return date
    .setZone(nurseTimezone)
    .set({ day: date.day, hour: 8, minute: 30, second: 0, millisecond: 0 });
}

function getEndTime(date: DateTime, nurseTimezone: string) {
  return date.setZone(nurseTimezone).set({
    day: date.day,
    hour: 17,
    minute: 0,
    second: 0,
    millisecond: 0
  });
}

const AddTimeOffModalRender = ({
  isVisible,
  onRequestClose,
  nurses,
  teamId,
  isEdit = false,
  event,
  eventId,
  selectedCarerId,
  carerTimezone,
  modalKey
}: IPropsRender) => {
  const dispatch = useAppDispatch();
  const [selectedNurseObject, setSelectedNurseObject] =
    useState<DropdownNurseType | null>(null);
  const [userSelectedNurseId, setUserSelectedNurseId] = useState<string>(
    selectedCarerId ?? teamId
  );
  const [selectedTimeOffReason, setSelectedTimeOffReason] =
    useState<AppointmentTypeEnum>(
      event?.appointmentType as AppointmentTypeEnum
    );
  const [selectedNurseTimezone, setSelectedNurseTimezone] =
    useState<string>(carerTimezone);

  const [startDate, setStartDate] = useState<DateTime | null>(
    event?.visitsRequest?.calendar_event_start
      ? DateTime.fromISO(event?.visitsRequest?.calendar_event_start)
      : null
  );
  const [endDate, setEndDate] = useState<DateTime | null>(
    event?.visitsRequest?.calendar_event_end
      ? DateTime.fromISO(event?.visitsRequest?.calendar_event_end)
      : null
  );

  const hasChanges = useMemo(() => {
    if (isEdit) {
      return (
        selectedTimeOffReason !== event?.appointmentType ||
        startDate?.toISODate() !==
          DateTime.fromISO(
            event?.visitsRequest?.calendar_event_start
          ).toISODate() ||
        endDate?.toISODate() !==
          getEndTime(
            DateTime.fromISO(event?.visitsRequest?.calendar_event_end),
            selectedNurseTimezone
          )?.toISODate()
      );
    }
    return false;
  }, [event, isEdit, selectedTimeOffReason, startDate, endDate]);

  const changes = useMemo(() => {
    const changesObj = {};
    changesObj["update_type"] = RecurrenceUpdateTypeEnum.ONCE;
    if (isEdit) {
      if (selectedTimeOffReason !== event?.appointmentType) {
        changesObj["appointment_type"] = selectedTimeOffReason;
      }

      if (
        startDate?.toISODate() !==
          DateTime.fromISO(
            event?.visitsRequest?.calendar_event_start
          ).toISODate() ||
        endDate?.toISODate() !==
          getEndTime(
            DateTime.fromISO(event?.visitsRequest?.calendar_event_end),
            selectedNurseTimezone
          )?.toISODate()
      ) {
        changesObj["start_date"] = startDate?.toUTC()?.toISO();
        changesObj["end_date"] = endDate?.toUTC()?.toISO();
      }

      return changesObj;
    }
    return {};
  }, [event, isEdit, selectedTimeOffReason, startDate, endDate]);

  const { user } = useSelector((state: RootState) => state.auth);

  const resetSelectedNurse = useCallback(() => {
    setSelectedNurseObject(null);
    setUserSelectedNurseId("");
    setSelectedTimeOffReason(undefined);
    setSelectedNurseTimezone("");
  }, [
    setSelectedNurseObject,
    setUserSelectedNurseId,
    setSelectedTimeOffReason,
    setSelectedNurseTimezone
  ]);

  const handleAddTimeOffModalClose = (refetch: boolean = false) => {
    onRequestClose(refetch);
    resetCreateCalendarEvent();
    resetUpdateCalendarEvent();
    resetSelectedNurse();
    setStartDate(null);
    setEndDate(null);
    dispatch(resetPtoState());
  };

  const [
    createCalendarEventMutation,
    {
      data: createCalendarEventData,
      isLoading: createCalendarEventIsLoading,
      fulfilledTimeStamp: createCalendarFulfilledTimestamp,
      error: createCalendarEventError,
      reset: resetCreateCalendarEvent
    }
  ] = useCreateCalendarEventMutation();

  const [
    updateCalendarEventMutation,
    {
      data: updateCalendarEventData,
      isLoading: updateCalendarEventIsLoading,
      fulfilledTimeStamp: updateCalendarFulfilledTimestamp,
      error: updateCalendarEventError,
      reset: resetUpdateCalendarEvent
    }
  ] = useUpdateCalendarEventMutation();

  useEffect(() => {
    if (createCalendarEventData) {
      setTimeout(() => {
        handleAddTimeOffModalClose(true);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [createCalendarEventData]);

  useEffect(() => {
    if (updateCalendarEventData) {
      setTimeout(() => {
        handleAddTimeOffModalClose(true);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [updateCalendarEventData]);

  const xTraceId = useMemo(() => `add-pto-${uuidv4()}`, []);

  const onSubmit = useCallback(() => {
    const start = getStartTime(startDate, selectedNurseTimezone);
    const end = getEndTime(endDate, selectedNurseTimezone);

    if (isEdit && changes) {
      updateCalendarEventMutation({
        body: changes,
        staffId: selectedCarerId,
        eventId
      });
    } else {
      createCalendarEventMutation({
        body: {
          start_date: start?.toUTC()?.toISO(),
          end_date: end?.toUTC()?.toISO(),
          appointment_type: selectedTimeOffReason,
          created_by: user?.user_id
        },
        staffId: userSelectedNurseId,
        xTraceId,
        managerId: teamId
      });
    }
  }, [
    selectedCarerId,
    userSelectedNurseId,
    selectedNurseTimezone,
    selectedTimeOffReason,
    startDate,
    endDate,
    changes
  ]);

  const isLoading = useGetDelayedLoadingBoolean(
    isEdit ? updateCalendarEventIsLoading : createCalendarEventIsLoading,
    isEdit
      ? updateCalendarFulfilledTimestamp
      : createCalendarFulfilledTimestamp,
    isEdit ? updateCalendarEventData : createCalendarEventData,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  const submitButtonDisabled = useMemo(() => {
    if (isEdit) {
      return (
        isFalsy(selectedTimeOffReason) ||
        !startDate?.isValid ||
        !endDate?.isValid ||
        startDate > endDate ||
        Math.abs(endDate?.diff(startDate, "months")?.months) > 1 ||
        !hasChanges
      );
    }
    return (
      isFalsy(userSelectedNurseId) ||
      isFalsy(selectedTimeOffReason) ||
      !startDate?.isValid ||
      !endDate?.isValid ||
      startDate > endDate
    );
  }, [
    selectedCarerId,
    teamId,
    userSelectedNurseId,
    selectedTimeOffReason,
    event,
    hasChanges,
    startDate,
    endDate
  ]);

  const closeModal = useCallback(
    () => handleAddTimeOffModalClose(false),
    [handleAddTimeOffModalClose]
  );

  return (
    <StyledModal
      key={modalKey}
      isOpen={isVisible}
      onRequestClose={closeModal}
      modalHeight="70vh"
      contentLabel="Change Role"
    >
      <ModalHeader onRequestClose={closeModal}>
        {isEdit ? "Edit" : "Add"} Time Off
      </ModalHeader>
      <ModalBody>
        {!isEdit &&
          // hide this if we have a selected nurse id already (if we are adding time off from the nurse schedule view)
          !selectedCarerId && (
            <Autocomplete
              options={nurses}
              value={selectedNurseObject}
              getOptionLabel={(nurse) => {
                return nurse?.label?.displayLastNameFirst;
              }}
              onChange={(e, value) => {
                setSelectedNurseObject(value);
                setUserSelectedNurseId(value?.value);
                setSelectedNurseTimezone(value?.label?.timezone);
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  aria-label="Select Nurse"
                  label="Select Nurse"
                  variant="outlined"
                />
              )}
            />
          )}
        <br />
        <StyledTextField
          select
          aria-label="Select Reason"
          label="Select Reason"
          SelectProps={{
            variant: "outlined",
            value: selectedTimeOffReason,
            defaultValue: "",
            onChange: (event) => {
              setSelectedTimeOffReason(event.target.value);
            },
            MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
          }}
        >
          {TimeOffReasons?.map((reason) => {
            return (
              <MenuItem key={reason} value={reason}>
                {formatName(reason)}
              </MenuItem>
            );
          })}
        </StyledTextField>
        <br />
        <Flexbox alignItems="center" justifyContent="space-between">
          <DatePicker
            label={"Start date"}
            name="startDate"
            value={startDate}
            minDate={DateTime.now().startOf("day")}
            maxDate={DateTime.now().plus({ years: 1 })}
            onChange={(dateTime: DateTime) => {
              if (dateTime?.isValid) {
                setStartDate(dateTime);
              }
            }}
            slotProps={{
              field: {
                readOnly: true
              },
              textField: {
                error:
                  startDate &&
                  endDate &&
                  (startDate > endDate ||
                    Math.abs(endDate?.diff(startDate, "months").months) > 1),
                helperText:
                  (startDate &&
                    endDate &&
                    startDate > endDate &&
                    "Start date must be less than end date") ||
                  (startDate &&
                    endDate &&
                    Math.abs(endDate?.diff(startDate, "months").months) > 1 &&
                    "The maximum PTO period is 1 month"),
                // prevent user from typing in the date as this can lead to bugs
                // see ENG-3757
                // the below code needs to be here instead of in DateTimePicker.tsx
                // until this PR is merged https://github.com/mui/material-ui/pull/35088
                onKeyDown: (e) => {
                  e.preventDefault();
                }
              }
            }}
          />
          <Typography margin="0 24px" color={gray[500]}>
            to
          </Typography>
          <DatePicker
            label={"End date"}
            name="endDate"
            value={endDate}
            minDate={startDate ?? DateTime.now().startOf("day")}
            maxDate={
              startDate?.isValid
                ? // QA-176 I discussed with Barny, we will add a one month restriction to PTO requests
                  startDate.plus({ months: 1 })
                : DateTime.now().plus({ years: 1 })
            }
            onChange={(dateTime: DateTime) => {
              if (dateTime?.isValid) {
                setEndDate(dateTime);
              }
            }}
            slotProps={{
              field: {
                readOnly: true
              },
              textField: {
                error:
                  startDate &&
                  endDate &&
                  (startDate > endDate ||
                    Math.abs(endDate?.diff(startDate, "months").months) > 1),
                helperText:
                  (startDate &&
                    endDate &&
                    startDate > endDate &&
                    "End date must be greater than start date") ||
                  (startDate &&
                    endDate &&
                    Math.abs(endDate?.diff(startDate, "months").months) > 1 &&
                    "End date must be within one month of start date"),
                // prevent user from typing in the date as this can lead to bugs
                // see ENG-3757
                // the below code needs to be here instead of in DateTimePicker.tsx
                // until this PR is merged https://github.com/mui/material-ui/pull/35088
                onKeyDown: (e) => {
                  e.preventDefault();
                }
              }
            }}
          />
        </Flexbox>
        <br />
        <Typography color={gray[500]}>
          Affected business days: {affectedBusinessDays(startDate, endDate, 0)}
        </Typography>
        {createCalendarEventError && (
          <ErrorComponent error={createCalendarEventError} />
        )}
        {updateCalendarEventError && (
          <ErrorComponent
            error={updateCalendarEventError}
            showErrorResponseMessage
            hideErrorCode
          />
        )}
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton
            onClick={() => {
              onSubmit();
            }}
            disabled={submitButtonDisabled}
            loading={isLoading}
          >
            Submit
          </TurqoiseButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

const NewAddTimeOffModal = ({
  isVisible,
  onRequestClose,
  nurses,
  teamId,
  selectedCarerId,
  isEdit = false,
  modalKey
}: IProps) => {
  const { event, eventId, carerTimezone } = useSelector(
    (state: RootState) => state.pto
  );
  if (!isVisible) {
    return null;
  }
  if (isEdit) {
    if (event && eventId) {
      return (
        <AddTimeOffModalRender
          modalKey={modalKey}
          isVisible={isVisible}
          onRequestClose={onRequestClose}
          nurses={nurses}
          teamId={teamId}
          isEdit={isEdit}
          event={event}
          eventId={eventId}
        />
      );
    }
    return <></>;
  } else if (carerTimezone && selectedCarerId) {
    return (
      <AddTimeOffModalRender
        modalKey={modalKey}
        isVisible={isVisible}
        onRequestClose={onRequestClose}
        nurses={nurses}
        teamId={teamId}
        isEdit={isEdit}
        selectedCarerId={selectedCarerId}
        carerTimezone={carerTimezone}
      />
    );
  } else {
    if (!carerTimezone && !selectedCarerId) {
      return (
        <AddTimeOffModalRender
          modalKey={modalKey}
          isVisible={isVisible}
          onRequestClose={onRequestClose}
          nurses={nurses}
          teamId={teamId}
          isEdit={isEdit}
        />
      );
    }

    return <></>;
  }
};

export default NewAddTimeOffModal;
