import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import MemberDetailsLayout from "../MemberDetailsLayout";
import { Box, Typography, styled } from "@mui/material";
import { Flexbox, OverviewCard } from "../../../styling/NewStyleComponents";
import {
  canSeeMemberDetailsOverview,
  canSeeTasks
} from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import Tasks from "../../Tasks/Tasks";
import { RootState } from "common/redux";
import { gray } from "common/styling/colors";
import { useGetLatestReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../../../components/ErrorComponent";
import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { checkIdValid, formatName } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import ComplianceBanner from "./ComplianceBanner";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import OverviewCards from "./OverviewCards";
import LicensedStates from "../Header/LicensedStates";
import { useNavigate } from "react-router-dom";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";
import { useFeatureFlags } from "common/config/FeatureFlags";

const dateFormat = "MM/dd/yyyy";

const MemberDetailsOverviewContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LatestReadingsContainer = styled("div")`
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: white;
`;

function getReadingsSummaryData(readingDataKey, readingsRawData) {
  switch (readingDataKey) {
    case DeviceTrendParam.BLOOD_PRESSURE:
      return {
        date: DateTime.fromSeconds(readingsRawData.measure_timestamp).toFormat(
          dateFormat
        ),
        reading: `${readingsRawData.systolic}/${readingsRawData.diastolic}`,
        readingLabel: "Systolic/Diastolic"
      };
    case DeviceTrendParam.GLUCOSE:
      return {
        date: DateTime.fromSeconds(readingsRawData.measure_timestamp).toFormat(
          dateFormat
        ),
        reading: `${readingsRawData.glucose}`,
        readingLabel: "Blood sugar (mg/DL)"
      };
    case DeviceTrendParam.PULSE:
      return {
        date: DateTime.fromSeconds(readingsRawData.measure_timestamp).toFormat(
          dateFormat
        ),
        reading: `${readingsRawData.pulse}`,
        readingLael: "BPM"
      };
    case DeviceTrendParam.OXIMETER:
      return {
        date: DateTime.fromSeconds(readingsRawData.measure_timestamp).toFormat(
          dateFormat
        ),
        reading: `${readingsRawData.spo2}`,
        readingLabel: "SPO2"
      };
    case DeviceTrendParam.WEIGHT_SCALE:
      return {
        date: DateTime.fromSeconds(readingsRawData.measure_timestamp).toFormat(
          dateFormat
        ),
        reading: `${readingsRawData.weight}`,
        readingLabel: "Weight (lbs)"
      };
    default:
      return;
  }
}

function LatestReadingsCards({ memberId, latestReadings, latestReadingsKeys }) {
  return (
    <LatestReadingsContainer
      sx={{ borderRadius: "12px", border: `1px solid ${gray[300]}` }}
    >
      {latestReadingsKeys.length > 0 &&
        latestReadingsKeys.map((key, index) => {
          const readingData = getReadingsSummaryData(key, latestReadings[key]);
          return (
            <Flexbox
              flexDirection="column"
              alignItems="flex-start"
              padding="20px"
              borderBottom={
                index !== latestReadingsKeys.length - 1 &&
                `1px solid ${gray[300]}`
              }
              key={`${memberId}-latest-reading-${key}`}
            >
              <Typography variant="h6" mb="8px">
                {formatName(key)}
              </Typography>
              <Flexbox gap="8px" justifyContent="space-between" width="100%">
                <OverviewCard flexBasis="50%" background={gray[50]}>
                  <Flexbox justifyContent="space-between">
                    <Typography variant="h6" fontWeight={400}>
                      Date
                    </Typography>
                    <Typography variant="h6">{readingData.date}</Typography>
                  </Flexbox>
                </OverviewCard>
                <OverviewCard flexBasis="50%" background={gray[50]}>
                  <Flexbox justifyContent="space-between">
                    <Typography variant="h6" fontWeight={400}>
                      {readingData.readingLabel}
                    </Typography>
                    <Typography variant="h6">{readingData.reading}</Typography>
                  </Flexbox>
                </OverviewCard>
              </Flexbox>
            </Flexbox>
          );
        })}
    </LatestReadingsContainer>
  );
}

const MemberDetailsOverview = ({
  athenaRedirect = false,
  hideReadings = false
}: {
  athenaRedirect?: boolean;
  hideReadings?: boolean;
}) => {
  const navigate = useNavigate();
  const params = useSanitizedParams();
  const { memberId } = params;

  const isValidId = checkIdValid(memberId);

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const pathname = useMemo(() => {
    // Account for cases where the user has a trailing / on the URL
    const paths = location.pathname.split("/");
    if (paths?.length === 6) paths.pop();

    return paths.join("/");
  }, [location]);

  const {
    data: latestReadings,
    isFetching: latestReadingsIsFetching,
    error: latestReadingsError
  } = useGetLatestReadingsByMemberQuery(
    {
      memberId,
      devices: [
        DeviceTrendParam.BLOOD_PRESSURE,
        DeviceTrendParam.GLUCOSE,
        DeviceTrendParam.OXIMETER,
        DeviceTrendParam.WEIGHT_SCALE
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const latestReadingsKeys = useMemo(() => {
    if (!latestReadings) return [];
    return Object.keys(latestReadings);
  }, [latestReadings]);

  const lastReadingDate = useMemo(() => {
    if (!latestReadings || latestReadingsKeys.length === 0) return null;
    let latestReadingDate;
    latestReadingsKeys.forEach((key) => {
      if (
        !latestReadingDate ||
        (latestReadings[key].measure_timestamp > latestReadingDate &&
          latestReadings[key].measure_timestamp !== null)
      ) {
        latestReadingDate = latestReadings[key].measure_timestamp;
      }
    });
    return DateTime.fromSeconds(latestReadingDate).toFormat(dateFormat);
  }, [latestReadings, latestReadingsKeys]);

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY,
        MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS_FULL,
        MemberLinkedEntitiesEnum.METADATA
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const [gotoathenaLinkOpened, setGotoathenaLinkOpened] =
    useState<boolean>(false);

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (!canSeeMemberDetailsOverview(currentRole) && memberId) {
      navigate(`/members/memberId/${memberId}/appointments`);
    }
  }, [memberId, featureFlags]);

  useEffect(() => {
    if (
      athenaRedirect &&
      patient?.patient?.external_accounts?.athena &&
      !gotoathenaLinkOpened
    ) {
      setGotoathenaLinkOpened(true);
      window.open(
        getAthenaPatientUrl(patient?.patient?.external_accounts?.athena),
        "_blank"
      );
    }
  }, [patient, gotoathenaLinkOpened]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsOverviewContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-orders`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-orders`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <Flexbox flexDirection="column" gap="8px">
            <LicensedStates key={memberId} />
            <OverviewCards
              memberId={memberId}
              lastReadingDate={lastReadingDate}
            />
          </Flexbox>
          {/* {patient?.missing_legal_forms_full?.length > 0 && (
            <div style={{ position: "relative" }}>
              <br />
              <Card>
                <LeftWarningLine />
                <StyledCardRow>
                  <Typography variant="body1" color="text.secondary">
                    <Flexbox alignItems={"center"} gap={"8px"}>
                      <WarningAmber color="warning" />
                      Member has missing consents and agreements
                    </Flexbox>
                  </Typography>
                  <StyledOutlineLoadingButton
                    variant="outlined"
                    onClick={() => {
                      navigate(
                        pathname.replace("/overview", "") +
                          "/profile/agreements"
                      );
                    }}
                  >
                    Review
                  </StyledOutlineLoadingButton>
                </StyledCardRow>
              </Card>
            </div>
          )} */}
          {patient && <ComplianceBanner patient={patient} />}
          {/* {patient?.metadata?.validation_errors?.map(({ type, value }, i) => {
            return (
              <>
                <MemberValidationErrorBanner
                  key={type + value}
                  type={type}
                  value={value}
                  containerMargin={"0"}
                />
                {i < patient.metadata.validation_errors.length - 1 ? (
                  <br />
                ) : null}
              </>
            );
          })} */}
          {canSeeTasks(currentRole) && (
            <Box mt="32px">
              <Tasks
                tasksStatuses={[TaskStatusEnum.TODO]}
                componentHeader="To Do Tasks"
                showWhenNoTasks={false}
                noTasksMessage=""
                showResolveButton={true}
                showActionButtons={true}
                tooltipContent="Important and urgent tasks are shown in this module."
                outerContainerMargin="0px"
              />
            </Box>
          )}
          {latestReadingsKeys.length > 0 && !hideReadings && (
            <>
              <Typography variant="h3" mt="16px">
                Latest readings
              </Typography>
              <LatestReadingsCards
                memberId={memberId}
                latestReadings={latestReadings}
                latestReadingsKeys={latestReadingsKeys}
              />
            </>
          )}
          {latestReadingsIsFetching && <LoadingFallback count={10} />}
          {latestReadingsError && (
            <ErrorComponent error={latestReadingsError} />
          )}
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsOverviewContainer>
  );
};

export default MemberDetailsOverview;
