import TaskTypeEnum from "../enums/TaskTypeEnum";
import TaskAction from "./TaskAction";
import UserTypeBase from "../types/common/UserTypeBase";
import TaskStatusEnum from "../enums/TaskStatusEnum";
import { DateTime } from "luxon";

interface TaskNotificationType {
  notification_id: string;
  contactee_id: string;
  contactee_type: string;
  occurred_at: string;
  status: string;
}

interface TaskActivity {
  actions: TaskAction[];
  number_of_contact_attempts: number;
  most_recent_contact_attempt: TaskAction;
}

interface TaskInfo {
  task_id: string;
  assignee_ids: string[];
  patient_id: string;
  priority: string;
  type: TaskTypeEnum;
  status: string;
  detail_attributes: any;
  notifications: TaskNotificationType[];
  created_at: string;
  modified_at: string;
  read_date?: string;
  activity: TaskActivity;
}

export interface TaskType {
  task: TaskInfo;
  patient: TaskPatient;
  possible_actions: string[];
}

export enum TaskPriority {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH"
}

/** TaskPatient is composed differently on backend than @type{MemberTypeInner}
 *
 * Will rename to TaskMember when backend returns `.member` instead of `.patient` */
interface TaskPatient extends UserTypeBase {
  patient_id: string;
  email: string;
  first: string;
  last: string;
  phone: string;
  mobile: string;
  athena_id: string;
  timezone: string;
}

export interface TaskParams {
  assignee_id?: string;
  patient_ids?: string;
  status?: TaskStatusEnum[];
  shouldShowBillingTasks?: boolean;
  modified_at_after?: DateTime;
  modified_at_before?: DateTime;
}
