import { DateTime } from "luxon";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import RolesEnum, {
  StaffRoles,
  canSeeEncounters,
  canSeeMemberOrders,
  canSeeReadings
} from "common/enums/RolesEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberType from "common/types/MemberType";

import { TableColumn } from "../components/Table/TableTypes";
import DateFilterFieldEnum from "../enums/DateFilterFieldEnum";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";

import TaskStatusEnum from "common/enums/TaskStatusEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import FeatureFlags from "common/config/FeatureFlags";
import StaffList from "../pages/AllUserList/StaffList";
import CustomSearchContainer from "../components/CustomSearchTables/CustomSearchContainer";
import PatientActivityList from "../pages/AllUserList/PatientActivityList";
import MemberDetailsOverview from "../pages/MemberDetails/Overview/MemberDetailsOverview";
import MemberDetailsEncounters from "../pages/MemberDetails/MemberDetailsEncounters";
import MemberDetailsOrders from "../pages/MemberDetails/MemberDetailsOrders";
import MemberDetailsProfile from "../pages/MemberDetails/Profile/MemberDetailsProfile";
import PatientDetailsRedirect from "../pages/MemberDetails/PatientDetailsRedirect";
import MemberCancellationRedirect from "../pages/MemberDetails/MemberCancellationRedirect";
import MemberDetailsReadings from "../pages/MemberDetails/Readings/MemberDetailsReadings";
import MemberDetailsAppointments from "../pages/MemberDetails/Appointments/MemberDetailsAppointments";
import MemberDetailsGoals from "../pages/MemberDetails/MemberDetailsGoals";
import ReportedCancellationsByMember from "../pages/ReportedCancellations/ReportedCancellationsByMember";
import CreateUpdateAppointment from "../pages/MemberDetails/Appointments/CreateUpdateAppointment";
import AllOrdersTable from "../pages/OrderDevices/AllOrdersTable";
import OrderDevicesDetails from "../pages/OrderDevices/OrderDevicesDetails";
import StaffDetails from "../pages/AllUserList/StaffDetails/StaffDetails";
import CreateTeam from "../pages/Teams/CreateTeam";
import AllTeams from "../pages/Teams/AllTeams";
import SelectedTeam from "../pages/Teams/SelectedTeam";
import LogoutPage from "../pages/LogoutPage";
import CarerPatientList from "../pages/CarerPatientList/CarerPatientList";
import Intakes from "../pages/Intakes/Intakes";
import ReportListPage from "../pages/Reports/ReportListPage";
import ReportedCancellations from "../pages/ReportedCancellations/ReportedCancellations";
import ReportPage from "../pages/Reports/ReportPage";
import InactivePatientList from "../pages/AllUserList/InactivePatientList";
import { RegisterPatient } from "../pages/RegisterPatient";
import RequestConsents from "../pages/RequestConsents/RequestConsents";
import PatientList from "../pages/AllUserList/PatientList";
import NewScheduleToDoContainer from "../components/ScheduleToDoContainer/NewScheduleToDoContainer";
import SameDayTimeOff from "../pages/AllUserList/NursesSchedules/SameDayTimeOff";
import TimeOff from "../pages/AllUserList/NursesSchedules/TimeOff";
import ManualReassignAppointment from "../pages/MemberDetails/Appointments/ManualReassignAppointment";
import NewNurseSchedules from "../pages/AllUserList/NursesSchedules/NewNursesSchedules";
import VisitFlow from "../pages/Visits/VisitFlow";
import ArcOfDayDashboard from "../pages/ArcOfDay/ArcOfDayDashboard";
import ArcOfDayDetails from "../pages/ArcOfDay/ArcOfDayDetails";
import Tasks from "../pages/Tasks/Tasks";
import MemberDetailsStartIntake from "../pages/MemberDetails/StartIntake/MemberDetailsStartIntake";
import { Profile } from "../pages/Profile";
import OrderDevices from "../pages/OrderDevices/OrderDevices";
import MemberDetailsRedirect from "../pages/MemberDetails/MemberDetailsRedirect";
import Help from "../pages/Help";
import Routes from "./Routes";

const USER_COUNT_INSERT = "{{COUNT}}";

const USER_FULLNAME_ACCESSOR = "user.fullname";
const MEMBER_FULLNAME_ACCESSOR = "patient.fullname";
const MEMBER_TIMEZONE = "patient.timezone";
const JOINED_ACCESSOR = "user.joined";
const MEMBER_INACTIVITY_INACTIVATED_DATE =
  "patient_inactivity.inactivated_date";

// This is temporal and should be done on the backend.
const MEMBER_RETENTION_SORTING = (a: MemberType, b: MemberType) => {
  if (
    a.patient_retention_actions === undefined ||
    a.patient_retention_actions.length === 0
  )
    return -1;
  if (
    b.patient_retention_actions === undefined ||
    b.patient_retention_actions.length === 0
  )
    return 1;

  const lastRetentionA =
    a.patient_retention_actions[a.patient_retention_actions.length - 1];
  const lastRetentionB =
    b.patient_retention_actions[b.patient_retention_actions.length - 1];

  const dateTimeA = DateTime.fromSQL(lastRetentionA.created_at);
  const dateTimeB = DateTime.fromSQL(lastRetentionB.created_at);

  if (dateTimeA < dateTimeB) return -1;
  else return 1;
};

const getTableHeaderText = (splitByMemberStatus: boolean) => {
  if (splitByMemberStatus) {
    return `${USER_COUNT_INSERT} members ({{MemberStatusEnum.ACTIVE}} active and {{MemberStatusEnum.PENDING}} pending)`;
  } else {
    return `${USER_COUNT_INSERT} members`;
  }
};

export const MEMBER_DATA = ({ hideReadings = true }) => {
  return {
    path: Routes.MEMBER_DETAILS(),
    components: <MemberDetailsOverview hideReadings={hideReadings} />
  };
};

export const MEMBER_CHART = (role: RolesEnum) => {
  const hideEncounters = !canSeeEncounters(role);
  const hideOrders = !canSeeMemberOrders(role);
  const hideReadings = !canSeeReadings(role);

  return [
    {
      path: Routes.MEMBER_DETAILS("overview"),
      components: <MemberDetailsOverview hideReadings={hideReadings} />
    },
    !hideEncounters && {
      path: Routes.MEMBER_DETAILS("encounters"),
      components: <MemberDetailsEncounters hideReadings={hideReadings} />
    },
    !hideOrders && {
      path: Routes.MEMBER_DETAILS("orders"),
      components: <MemberDetailsOrders hideReadings={hideReadings} />
    },
    {
      path: Routes.MEMBER_DETAILS("profile"),
      components: <MemberDetailsProfile hideReadings={hideReadings} />
    },
    {
      path: Routes.MEMBER_DETAILS("profile/:tab"),
      components: <MemberDetailsProfile hideReadings={hideReadings} />
    },
    {
      // redirect for athena router
      path: Routes.ATHENA_REDIRECT,
      components: (
        <MemberDetailsOverview hideReadings={hideReadings} athenaRedirect />
      )
    },
    {
      // redirect for patientId
      path: Routes.MEMBER_DETAILS(),
      components: <PatientDetailsRedirect />
    },
    {
      // redirect for patient cancellations
      path: Routes.CANCELLATIONS_HISTORY,
      components: (
        <MemberCancellationRedirect
          linked_entities={[SurveyLinkedEntitiesEnum.SUBMITTER]}
          componentHeader={"Cancellation Request History"}
          tableColumns={[
            { name: "date", accessor: "patient_survey.created_at" },
            { name: "name", accessor: "submitter" },
            {
              name: "roles",
              accessor: "submitter.roles",
              header: "Role"
            },
            {
              name: "default",
              id: "reasonCategory",
              accessor: "category",
              header: "Category"
            },
            {
              name: "default",
              id: "subCategory",
              accessor: "subCategory",
              header: "Sub Category"
            },
            {
              name: "notes",
              id: "notes",
              accessor: "notes",
              header: "Notes"
            }
          ]}
        />
      )
    },
    MEMBER_DATA({ hideReadings }),
    !hideReadings && {
      path: Routes.MEMBER_READINGS,
      components: <MemberDetailsReadings />
    },
    {
      path: Routes.MEMBER_APPOINTMENTS,
      components: <MemberDetailsAppointments hideReadings={hideReadings} />
    },
    !hideEncounters && {
      path: Routes.MEMBER_DETAILS("goals"),
      components: <MemberDetailsGoals />
    },
    {
      path: Routes.CANCELLATIONS_HISTORY,
      components: (
        <ReportedCancellationsByMember
          linked_entities={[SurveyLinkedEntitiesEnum.SUBMITTER]}
          componentHeader={"Cancellation Request History"}
          tableColumns={[
            { name: "date", accessor: "patient_survey.created_at" },
            { name: "name", accessor: "submitter" },
            {
              name: "roles",
              accessor: "submitter.roles",
              header: "Role"
            },
            {
              name: "default",
              id: "reasonCategory",
              accessor: "category",
              header: "Category"
            },
            {
              name: "default",
              id: "subCategory",
              accessor: "subCategory",
              header: "Sub Category"
            },
            {
              name: "notes",
              id: "notes",
              accessor: "notes",
              header: "Notes"
            }
          ]}
        />
      )
    }
  ];
};

export const PROVIDER_CALENDARING_ONLY = [
  {
    path: Routes.NEW_PROVIDER_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.EDIT_APPOINTMENT,
    components: <CreateUpdateAppointment />
  }
];

export const MEMBER_CHART_CALENDARING = [
  {
    // let's keep the old route for a bit for backward compatibility
    path: Routes.NEW_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.NEW_PROVIDER_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.NEW_NURSE_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.EDIT_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.NEW_PROVIDER_APPOINTMENT,
    components: <CreateUpdateAppointment />
  },
  {
    path: Routes.NEW_PROVIDER_APPOINTMENT,
    components: <CreateUpdateAppointment />
  }
];

export const RECENT_ORDERS = () => {
  return (
    <AllOrdersTable
      tableColumns={[
        { name: "orderPatientName" },
        { name: "orderPatientStatus" },
        { name: "ordered", size: 100 },
        {
          name: "orderStatus"
        },
        { name: "orderShipped", size: 100 },
        { name: "orderDelivered", size: 120 },
        { name: "tracking" },
        { name: "orderItems", type: "dataList" }
      ]}
      externalLink={Routes.MEMBER_DETAILS()}
    />
  );
};

export const ALL_RECENT_ORDERS = () => {
  return (
    <AllOrdersTable
      externalLink={Routes.MEMBER_DETAILS()}
      tableColumns={[
        { name: "order_id", size: 250 },
        { name: "orderPatientName" },
        { name: "orderPatientStatus" },
        { name: "ordered", size: 100 },
        {
          name: "orderType",
          filterEnabled: true,
          filterFn: "orderType"
        },
        {
          name: "orderStatus",
          filterEnabled: true,
          filterFn: "orderStatus"
        },
        { name: "orderShipped", size: 100 },
        { name: "orderDelivered", size: 120 },
        { name: "tracking" },
        { name: "ordered_by" },
        { name: "orderItems", type: "dataList" }
      ]}
    />
  );
};

export const ORDER_DETAILS = () => {
  return {
    path: Routes.ORDERS_DETAILS,
    components: <OrderDevicesDetails />
  };
};

export const ALL_MEMBERS = ({
  splitByMemberStatus = true,
  tableColumns = [
    { name: "name" },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate" },
    { name: "nurseAssigned" }
  ],
  externalLink = Routes.MEMBER_DETAILS()
}: {
  splitByMemberStatus?: boolean;
  tableColumns?: TableColumn[];
  externalLink?: string;
}) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);

  return (
    <CustomSearchContainer
      userListFilters={{
        status: [
          MemberStatusEnum.PENDING,
          MemberStatusEnum.ACTIVE,
          MemberStatusEnum.INACTIVE,
          MemberStatusEnum.NEVER_ENGAGED
        ],
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      }}
      tableHeader={tableHeader}
      externalLink={externalLink}
      tableColumns={tableColumns}
      componentHeader={"Find Members"}
    />
  );
};

export const UNASSIGNED_MEMBERS = (
  <PatientList
    userListFilters={{
      status: [
        MemberStatusEnum.ACTIVE,
        MemberStatusEnum.PENDING,
        MemberStatusEnum.ELIGIBLE,
        MemberStatusEnum.INACTIVE,
        MemberStatusEnum.NEVER_ENGAGED,
        MemberStatusEnum.REEVALUATING_PATIENT
      ]
    }}
    tableColumns={[
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy",
        size: 180
      },
      { name: "status" },
      { name: "phone" },
      { name: "birthdate" },
      { name: "patientActions", size: 75 }
    ]}
    externalLink={Routes.MEMBER_DETAILS()}
    tableHeader={getTableHeaderText(true)}
    componentHeader={"Unassigned Members"}
    loadReadingsEncounters={false}
  />
);

export const AWAITING_PROVIDER = ({
  tableColumns = [{ name: "name" }, { name: "phone" }, { name: "birthdate" }]
}: {
  tableColumns?: TableColumn[];
}) => {
  return (
    <CustomSearchContainer
      userListFilters={{
        status: [MemberStatusEnum.QUALIFIED, MemberStatusEnum.ELIGIBLE],
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      }}
      externalLink={Routes.MEMBER_DETAILS()}
      tableColumns={tableColumns}
      componentHeader={"Awaiting Provider"}
    />
  );
};

export const INACTIVE_MEMBERS = (hasActions: boolean) => {
  const tableHeader = getTableHeaderText(false);
  const tableColumns: TableColumn[] = [
    { name: "name" },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate" },
    { name: "nurseAssigned" },
    { name: "patientActivityLastNurseEncounter" },
    { name: "patientActivityLastReading" },
    { name: "patientSince", size: 140 }
  ];
  if (hasActions) tableColumns.push({ name: "patientActions", size: 75 });

  return (
    <PatientActivityList
      userListFilters={{
        status: [MemberStatusEnum.INACTIVE],
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      }}
      tableHeader={tableHeader}
      tableColumns={tableColumns}
      externalLink={Routes.MEMBER_DETAILS()}
      componentHeader={"All Inactive Members"}
    />
  );
};

export const REGISTER_MEMBER = <RegisterPatient />;

export const REQUEST_CONSENTS = <RequestConsents />;

export const MY_CONVERSIONS = (
  <PatientList
    userListFilters={{
      status: [MemberStatusEnum.PENDING, MemberStatusEnum.ACTIVE],
      linked_entities: [MemberLinkedEntitiesEnum.NURSE]
    }}
    tableColumns={[
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy"
      },
      { name: "status" },
      { name: "phone" },
      { name: "birthdate" },
      { name: "patientSince", size: 140 },
      { name: "nurseAssigned" }
    ]}
    dateFilterField={DateFilterFieldEnum.ENROLLED}
    componentHeader={"My Conversions"}
    tableHeader={`${USER_COUNT_INSERT} member conversions ({{MemberStatusEnum.ACTIVE}} active and {{MemberStatusEnum.PENDING}} pending)`}
    loadReadingsEncounters={false}
  />
);

export const MY_REGISTRATIONS = (
  <PatientList
    userListFilters={{
      status: [MemberStatusEnum.QUALIFIED]
    }}
    tableColumns={[
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy"
      },
      { name: "phone" },
      { name: "birthdate" },
      { name: "joined", size: 105 }
    ]}
    dateFilterField={DateFilterFieldEnum.CREATED_AT}
    componentHeader={"My Registrations"}
    tableHeader={`${USER_COUNT_INSERT} lead registrations`}
    loadReadingsEncounters={false}
  />
);

export const NURSE_LIST = ({
  externalLink = Routes.STAFF_DETAILS(":userId", "assignments"),
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "teamName",
      accessor: "team.name",
      externalLink: Routes.TEAM_DETAILS(":userId")
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  componentHeader = "Nurses List",
  roles = [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER]
}: {
  externalLink?: string;
  tableColumns?: TableColumn[];
  roles?: RolesEnum[];
  componentHeader?: string;
}) => {
  const tableHeader = `${USER_COUNT_INSERT} users`;
  return (
    <StaffList
      userListFilters={{
        roles
      }}
      tableColumns={tableColumns}
      tableHeader={tableHeader}
      externalLink={externalLink}
      componentHeader={componentHeader}
      filterByAuthenticatedUser={false}
    />
  );
};

export const PROVIDER_LIST = ({
  componentHeader = "All Providers",
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  roles = [
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ],
  filterByAuthenticatedUser = false
}: {
  componentHeader?: string;
  tableColumns?: TableColumn[];
  roles?: RolesEnum[];
  filterByAuthenticatedUser?: boolean;
}) => {
  const tableHeader = `${USER_COUNT_INSERT} providers`;

  return (
    <StaffList
      userListFilters={{
        roles
      }}
      externalLink={Routes.STAFF_DETAILS(":staffId", "assignments")}
      tableColumns={tableColumns}
      tableHeader={tableHeader}
      componentHeader={componentHeader}
      filterByAuthenticatedUser={filterByAuthenticatedUser}
    />
  );
};

export const PROVIDER_SCHEDULES = ({
  componentHeader = "Providers Schedules",
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  roles = [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER],
  filterByAuthenticatedUser = false
}: {
  componentHeader?: string;
  tableColumns?: TableColumn[];
  roles?: RolesEnum[];
  filterByAuthenticatedUser?: boolean;
}) => {
  const tableHeader = `${USER_COUNT_INSERT} providers`;

  return (
    <StaffList
      userListFilters={{
        roles
      }}
      externalLink={Routes.STAFF_DETAILS(":staffId", "schedule")}
      tableColumns={tableColumns}
      tableHeader={tableHeader}
      componentHeader={componentHeader}
      filterByAuthenticatedUser={filterByAuthenticatedUser}
    />
  );
};

export const NPS_LIST = (
  <StaffList
    userListFilters={{
      roles: [RolesEnum.NPS]
    }}
    tableColumns={[
      { name: "fullname" },
      { name: "roles", size: 200, type: "dataList" },
      { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
    ]}
    componentHeader="NMS List"
  />
);

// ONLY ADMIN & TECH SUPPORT should be able to reset user password
// https://copilotiq.atlassian.net/browse/ENG-2807

export const STAFF_MEMBERS = (
  <StaffList
    userListFilters={{
      roles: StaffRoles
    }}
    tableHeader={`${USER_COUNT_INSERT} users`}
    externalLink={Routes.STAFF_DETAILS(":staffId", "assignments")}
    filterByAuthenticatedUser={false}
    componentHeader={"Staff Members"}
    tableColumns={[
      {
        name: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy",
        size: 300
      },
      {
        name: "roles",
        size: 200,
        type: "dataList",
        filterEnabled: true,
        filterFn: "roles"
      },
      { name: "joined", accessor: JOINED_ACCESSOR, size: 105 },
      { name: "staffActions", size: 75 }
    ]}
  />
);

export const MEMBERS_WITH_NO_ACTIVITY = ({
  tableColumns = [
    {
      name: "name",
      accessor: MEMBER_FULLNAME_ACCESSOR,
      filterEnabled: false,
      filterFn: "fuzzy",
      size: 180
    },
    { name: "carerPatientTableStatus", size: 200 },
    { name: "phone" },
    { name: "carerPatientTableBirthdate", size: 130 },
    { name: "carerPatientTableReadings", size: 130 },
    { name: "nurseReportDataNextNurseAppointment", size: 220 },
    // TBD - comment this in when we enable scheduling appointments for providers calendaring
    // { name: "nurseReportDataNextProviderAppointment" },
    { name: "nurseReportDataLastEncounter", size: 140 },
    // remove for now, per Yuly's feedback - comment back in if needed
    // { name: "connectedMTD", size: 150 },
    { name: "nonDeviceSetupMTD", size: 160 },
    { name: "carerPatientTableDelivered", size: 120 },
    { name: "carerPatientSince", size: 140 },
    { name: "carerPatientTableProviderLastEncounter", size: 220 }
  ],

  splitByMemberStatus = false,
  searchEnabled = false
}: {
  tableColumns?: TableColumn[];
  splitByMemberStatus?: boolean;
  searchEnabled?: boolean;
}) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);

  return (
    <CarerPatientList
      tableHeader={tableHeader}
      componentHeader="Members With No Activity"
      externalLink={Routes.MEMBER_DETAILS()}
      searchEnabled={searchEnabled}
      ComponentTooltip={
        <div>
          Members that haven’t taken readings in the last 7 days
          <br />
          or that haven’t had a nurse encounter in the last 14 days
        </div>
      }
      isNoActivityList
      tableColumns={tableColumns}
    />
  );
};

export const ASSIGNED_MEMBERS = ({
  tableColumns = [
    {
      name: "name",
      accessor: MEMBER_FULLNAME_ACCESSOR,
      filterEnabled: false,
      filterFn: "fuzzy",
      size: 180
    },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate", size: 130 },
    { name: "carerPatientTableReadings", size: 130 },
    { name: "nurseReportDataNextNurseAppointment", size: 220 },
    // TBD - comment this in when we enable scheduling appointments for providers calendaring
    // { name: "nurseReportDataNextProviderAppointment" },
    { name: "nurseReportDataLastEncounter" },
    // remove for now, per Yuly's feedback - comment back in if needed
    // { name: "connectedMTD", size: 150 },
    { name: "nonDeviceSetupMTD", size: 160 },
    { name: "carerPatientTableDelivered", size: 120 },
    { name: "carerPatientSince", size: 140 },
    { name: "carerPatientTableProviderLastEncounter", size: 220 }
  ],
  componentHeader = "Assigned Members",
  splitByMemberStatus = false
}: {
  tableColumns?: TableColumn[];
  componentHeader?: string;
  splitByMemberStatus?: boolean;
}) => {
  return (
    <CarerPatientList
      tableHeader={getTableHeaderText(splitByMemberStatus)}
      componentHeader={componentHeader}
      searchEnabled
      isNoActivityList={false}
      tableColumns={tableColumns}
      externalLink={Routes.MEMBER_DETAILS()}
    />
  );
};

export const NPN_LIST = ({
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  componentHeader = "Intake Nurses"
}: {
  tableColumns?: TableColumn[];
  componentHeader?: string;
}) => {
  const tableHeader = `${USER_COUNT_INSERT} users`;

  return {
    path: Routes.NMNS,
    components: (
      <StaffList
        userListFilters={{
          roles: [RolesEnum.NP_NURSE]
        }}
        tableColumns={tableColumns}
        tableHeader={tableHeader}
        externalLink={Routes.NPN_DETAILS}
        componentHeader={componentHeader}
        filterByAuthenticatedUser={false}
      />
    )
  };
};

export const NPN_DETAILS = {
  path: Routes.NPN_DETAILS,
  components: (
    <Intakes
      componentHeader="Intakes"
      externalLink={Routes.MEMBER_DETAILS()}
      linked_entities={[MemberLinkedEntitiesEnum.NURSE]}
    />
  )
};

export const REPORT_LIST = {
  path: Routes.REPORT,
  components: <ReportListPage />
};

export const REPORT_DETAILS = {
  path: Routes.REPRORT_DETAILS,
  components: <ReportPage />
};

export const CANCELED_MEMBERS = {
  path: Routes.CANCELLED_MEMBERS,
  components: (
    <ReportedCancellations
      submitter_roles={[RolesEnum.RETENTION_SPECIALIST, RolesEnum.ADMIN]}
      componentHeader={"Cancellations History"}
      tableHeader={`${USER_COUNT_INSERT} members`}
      size={350}
    />
  )
};

export const REPORT_DETAILS_BY_MEMBER = {
  path: Routes.REPORT_DETAILS_BY_MEMBER,
  components: <ReportPage />
};

export const ALL_TEAMS = ({
  types = undefined
}: {
  types?: TeamTypeEnum[];
}) => {
  return {
    path: Routes.ALL_TEAMS,
    components: <AllTeams types={types} />
  };
};

export const TEAM_DETAILS = {
  path: Routes.TEAM_DETAILS(":userId"),
  components: (
    <SelectedTeam externalLink={"/staff/staffId/:userId/assignments"} />
  )
};

export const RETENTION = ({
  splitByMemberStatus = false,
  filterByAssignedNurse = false
}) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);

  return {
    path: Routes.RETENTION,
    components: (
      <InactivePatientList
        tableHeader={tableHeader}
        filterByAssignedNurse={filterByAssignedNurse}
        userListFilters={{
          status: [MemberStatusEnum.INACTIVE],
          linked_entities: [
            MemberLinkedEntitiesEnum.PATIENT_INACTIVITY,
            MemberLinkedEntitiesEnum.RETENTION_ACTIONS,
            MemberLinkedEntitiesEnum.NURSE,
            MemberLinkedEntitiesEnum.LATEST_ATTRITION_REASONS
          ]
        }}
        loadReadingsEncounters={false}
        sortBy={(a, b) => MEMBER_RETENTION_SORTING(a, b)}
        externalLink={Routes.MEMBER_DETAILS()}
        tableColumns={[
          { name: "contactAttempts" },
          { name: "fullname", accessor: MEMBER_FULLNAME_ACCESSOR },
          {
            name: "phone"
          },
          {
            name: "timezone",
            id: "timezone",
            accessor: MEMBER_TIMEZONE,
            header: "Timezone"
          },
          {
            name: "date",
            id: "inactive_date",
            accessor: MEMBER_INACTIVITY_INACTIVATED_DATE,
            header: "Date Inactive",
            sortingFn: "dateTimeSortingISO"
          },
          { name: "lastContact" },
          { name: "latestAttritionReasonCategory" },
          { name: "latestAttritionReasonSubCategory" },
          { name: "latestAttritionReasonNotes" },
          { name: "lastContactAction", size: 100 }
        ]}
        ComponentTooltip={
          <div>
            Members that haven’t taken any readings or had
            <br />
            any encounters for 30 to 60 days
          </div>
        }
        componentHeader={`${
          filterByAssignedNurse ? "My " : ""
        }Inactive Members`}
      />
    )
  };
};

export const CREATE_TEAM = {
  path: Routes.CREATE_TEAM,
  components: <CreateTeam />
};

export const MY_TODOS = {
  path: Routes.TASKS,
  components: (
    <Tasks
      tasksStatuses={[TaskStatusEnum.TODO, TaskStatusEnum.IN_PROGRESS]}
      componentHeader={"To Dos"}
      showWhenNoTasks
      noTasksMessage="You have no pending tasks that require imminent action."
      tableHeight="90%"
      showResolveButton={true}
      showActionButtons={true}
      link={{
        text: "View Completed",
        route: Routes.COMPLETED_TODOS
      }}
    />
  )
};

export const COMPLETED_TODOS = {
  path: Routes.COMPLETED_TODOS,
  components: (
    <Tasks
      tasksStatuses={[TaskStatusEnum.COMPLETED]}
      componentHeader={"Completed Tasks"}
      datePickerEnabled={true}
      noTasksMessage={
        "You haven't completed any tasks yet. This is where you’ll see your completed tasks"
      }
      showResolveButton={false}
      showActionButtons={false}
      tableHeight={"90%"}
    />
  )
};

export const STAFF_DETAILS = {
  path: Routes.STAFF_DETAILS(":staffId", ":tab"),
  components: <StaffDetails />
};

export const SCHEDULE_TODO = (isDashboard = false) => {
  return (
    <NewScheduleToDoContainer hasRightComponent isDashboard={isDashboard} />
  );
};

export const NURSE_SCHEDULE = {
  path: Routes.NURSE_SCHEDULE,
  components: [SCHEDULE_TODO(), ASSIGNED_MEMBERS({})]
};

export const NURSE_SCHEDULES = {
  path: Routes.NURSE_SCHEDULES,
  components: NURSE_LIST({
    componentHeader: "Nurse Schedules",
    roles: [RolesEnum.TH_NURSE],
    externalLink: Routes.STAFF_DETAILS(":nurseId", "schedule"),
    tableColumns: [
      {
        name: "fullname",
        accessor: USER_FULLNAME_ACCESSOR,
        filterEnabled: true,
        filterFn: "fuzzy",
        size: 300
      }
    ]
  })
};

export const NURSES_SCHEDULES = [
  // let's keep the old path for a bit to deprecate it before removing it
  {
    path: Routes.NURSE_SCHEDULE_SAME_DAY_TIME_OFF,
    components: <SameDayTimeOff />
  },
  {
    path: Routes.NURSE_SCHEDULE_TIME_OFF,
    components: <TimeOff />
  },
  {
    path: Routes.NURSE_SCHEDULE_MANUAL_REASSIGNMENT,
    components: <ManualReassignAppointment />
  },
  {
    path: Routes.NURSE_SCHEDULE_DETAILS(":tab"),
    components: <NewNurseSchedules />
  }
];

export const START_INTAKE = {
  path: Routes.MEMBER_DETAILS("start-intake"),
  components: <MemberDetailsStartIntake />
};

export const VISITS = {
  path: Routes.VISITS,
  components: <VisitFlow />
};

export const ARC_OF_DAY_DASHBOARD = FeatureFlags().ARC_OF_DAY && {
  path: Routes.ARC_OF_DAY,
  components: <ArcOfDayDashboard />
};

export const ARC_OF_DAY_DETAILS = FeatureFlags().ARC_OF_DAY && {
  path: Routes.ARC_OF_DAY_DETAILS,
  components: <ArcOfDayDetails />
};

export const LOGOUT = {
  path: Routes.LOGOUT,
  components: <LogoutPage />
};

// Routes that are used in all roles
export const COMMON_ROUTES = [
  {
    path: Routes.MY_PROFILE,
    components: <Profile />
  },
  {
    path: Routes.HELP,
    components: <Help />
  },
  LOGOUT
];

export const PROVIDER_ROUTES = [
  {
    path: Routes.ROOT,
    components: [SCHEDULE_TODO(true), ALL_MEMBERS({})]
  },
  {
    path: Routes.NURSE_REDIRECT,
    components: <MemberDetailsRedirect />
  },
  STAFF_DETAILS,
  {
    path: Routes.ALL_MEMBERS,
    components: ALL_MEMBERS({})
  },
  {
    path: Routes.AWAITING_PROVIDER,
    components: AWAITING_PROVIDER({
      tableColumns: [
        { name: "name" },
        { name: "phone" },
        { name: "birthdate" },
        { name: "nurseAssigned" }
      ]
    })
  },
  { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },
  ...MEMBER_CHART_CALENDARING,
  {
    path: Routes.ASSIGNED_MEMBERS(":nurseId"),
    components: [
      ASSIGNED_MEMBERS({
        tableColumns: [
          {
            name: "name",
            accessor: "patient.fullname",
            filterEnabled: false,
            filterFn: "fuzzy",
            size: 180
          },
          { name: "status" },
          { name: "phone" },
          { name: "birthdate", size: 130 }
        ],
        componentHeader: "My Members"
      })
    ]
  },
  {
    path: Routes.ORDERS,
    components: <OrderDevices />
  },
  {
    path: Routes.DEVICES_BY_MEMBER,
    components: <OrderDevices />
  },
  ORDER_DETAILS(),

  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  {
    path: Routes.PROVIDER_SCHEDULES,
    components: [PROVIDER_SCHEDULES({})]
  },
  {
    path: Routes.PROVIDERS,
    components: [
      PROVIDER_LIST({
        filterByAuthenticatedUser: false
      })
    ]
  }
];
