import { MenuItem, SxProps, TextField } from "@mui/material";
import { Flexbox } from "../../styling/NewStyleComponents";
import {
  getPrettyGoalAssessmentStatus,
  GoalAssessmentStatusEnum,
  GoalAssessmentType,
  GoalType
} from "common/types/GoalType";
import { useFormik } from "formik";
import { isFalsy, isTruthy } from "common/helpers/helpers";
import { GoalStyle } from "./Goals";
import {
  Circle,
  ControlPoint,
  ErrorOutline,
  NotInterested
} from "@mui/icons-material";
import { blue, error } from "common/styling/colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { useMemo } from "react";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import {
  getGoalAssessmentToString,
  getGoalAssessmentFromString,
  parseGoalAssessmentString
} from "../../pages/Visits/VisitHelper";
import { setAnswer, setDirtiedGoals } from "common/redux/VisitsSlice";
import { ObjectId } from "bson";

const ASSESMENT_VALUES = Object.keys(GoalAssessmentStatusEnum).filter(
  (item) => item !== GoalAssessmentStatusEnum.COMPLETED
);

export const GoalAssessmentIcon = ({
  status,
  sx = {}
}: {
  status: GoalAssessmentStatusEnum;
  sx?: SxProps;
}) => {
  if (
    status === GoalAssessmentStatusEnum.ON_TRACK ||
    status === GoalAssessmentStatusEnum.COMPLETED
  )
    return <ControlPoint sx={{ ...sx, color: blue[400] }} />;
  if (status === GoalAssessmentStatusEnum.OFF_TRACK)
    return <ErrorOutline sx={{ ...sx, color: error[400] }} />;

  if (status === GoalAssessmentStatusEnum.NOT_STARTED)
    return <NotInterested color={"disabled"} sx={sx} />;
  return <Circle color={"disabled"} sx={sx} />;
};

export const syncGoalsAssessmentsToAPI = async ({
  upsertMutation,
  answers,
  patient_id
}) => {
  if (
    isTruthy(answers) &&
    isTruthy(answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT])
  ) {
    const value = answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT];

    Object.entries(
      parseGoalAssessmentString({ input: value as string })
    ).forEach((item) => {
      const goal_id = item[0];
      const assessment = item[1];

      if (isTruthy(assessment?.assessment_status)) {
        upsertMutation({
          goalAssessments: [
            {
              goal_id: goal_id,
              assessment_id: assessment?.assessment_id,
              assessment_status: assessment?.assessment_status,
              current_value: assessment?.current_value,
              notes: assessment?.notes
            }
          ],
          member_id: patient_id
        });
      }
    });
  }
};

export const GoalAssessment = ({ goal }: { goal: GoalType }) => {
  const { answers } = useSelector((state: RootState) => state.visits);
  const dispatch = useAppDispatch();

  // check if visit is active; if so we should check answers to populate the assessment
  const initialValues = useMemo(() => {
    if (
      isTruthy(answers) &&
      isTruthy(answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT])
    ) {
      const value = answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT];

      const res = getGoalAssessmentFromString({
        input: value as string,
        goal_id: goal?.goal_id
      });
      if (isTruthy(res)) return res;
    }
    return {
      assessment_id: new ObjectId().toString(),
      assessment_status: undefined,
      current_value: "",
      notes: ""
    };
  }, [answers]);

  const appendToAnswers = (assessment: GoalAssessmentType) => {
    const assessmentStr = getGoalAssessmentToString({
      input: answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT] as string,
      goal_id: goal?.goal_id,
      assessment
    });
    dispatch(setDirtiedGoals({ dirtiedGoals: true }));
    dispatch(
      setAnswer({
        id: CareFlowFieldIdsEnum.GOAL_MANAGEMENT,
        value: assessmentStr
      })
    );
  };

  const formik = useFormik<GoalAssessmentType>({
    initialValues: initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.assessment_status) {
        errors["assessment_status"] = "Mandatory field";
      }

      if (!values.current_value) {
        errors["current_value"] = "Mandatory field";
      }
      appendToAnswers(values);
      return errors;
    },
    onSubmit: () => {
      formik.resetForm();
    },
    enableReinitialize: true
  });

  return (
    <Flexbox
      flexDirection={"column"}
      sx={{ ...GoalStyle, border: 0 }}
      gap={"10px"}
    >
      <br />
      <Flexbox flexDirection={"row"} gap={"10px"}>
        <GoalAssessmentIcon
          status={formik.values.assessment_status}
          sx={{ alignSelf: "center" }}
        />
        <TextField
          label={"Status"}
          select={true}
          slotProps={{
            select: {
              variant: "outlined",
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              onChange: (event) => {
                formik.setFieldValue("assessment_status", event.target.value);
              }
            }
          }}
          value={formik.values.assessment_status ?? ""}
          style={{ width: 200 }}
          error={!isFalsy(formik.errors["assessment_status"])}
          helperText={formik.errors["assessment_status"]}
          placeholder={"Select"}
        >
          {ASSESMENT_VALUES.map((item) => (
            <MenuItem key={item} value={item}>
              {getPrettyGoalAssessmentStatus(item)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Current Value"
          value={formik.values.current_value ?? ""}
          onChange={(event) =>
            formik.setFieldValue("current_value", event.target.value)
          }
        />
      </Flexbox>
      <Flexbox>
        <TextField
          label="Notes"
          placeholder="Add note"
          multiline={true}
          rows={2}
          fullWidth={true}
          value={formik.values.notes ?? ""}
          onChange={(event) =>
            formik.setFieldValue("notes", event.target.value)
          }
          error={!isFalsy(formik.errors["notes"])}
          helperText={formik.errors["notes"]}
          sx={{ marginLeft: "34px" }}
        />
      </Flexbox>
    </Flexbox>
  );
};
