import { useMemo, MouseEvent } from "react";
import {
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { LightenDarkenColor } from "lighten-darken-color";
import { isFalsy } from "common/helpers/helpers";

interface IProps {
  color?: string;
  testId?: string;
  iconColor?: string;
  Icon: SvgIconComponent;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disabledWithNoOpacity?: boolean;
  disableHoverColor?: boolean;
  border?: "transparent" | "circle" | "square";
  sx?: SxProps<Theme>;
  size?: "small" | "medium" | "large";
  captionText?: string;
}

const StyledIconButton = ({
  color = "transparent",
  iconColor = "white",
  onClick,
  testId,
  Icon,
  disabled,
  disabledWithNoOpacity,
  disableHoverColor,
  border,
  sx = {},
  size,
  captionText
}: IProps) => {
  const theme = useTheme();

  const hoverColor = useMemo(
    () => (!disableHoverColor ? LightenDarkenColor(color, -40) : color),
    [color]
  );

  const borderStyles = useMemo(() => {
    if (border === undefined) return {};
    else if (border === "circle")
      return {
        border: 1,
        borderColor: iconColor,
        borderRadius: "50%"
      };
    else if (border === "square")
      return {
        border: 1,
        borderColor: iconColor,
        borderRadius: 2
      };
    else if (border === "transparent") {
      return {
        border: 0
      };
    }
  }, [border, iconColor]);

  const disabledBackgroundColor = useMemo(() => {
    if (border === "transparent") return "transparent";
    if (color === "transparent") return theme.palette.grey[800];
    return disabledWithNoOpacity ? color : color + "88";
  }, [color]);

  const iconColorWithDisabledState = useMemo(() => {
    if (border === "transparent" && disabled) {
      return theme.palette.grey[300];
    }
    return iconColor;
  }, [iconColor, border, disabled]);

  return (
    <IconButton
      sx={{
        ...borderStyles,
        "&:disabled": {
          backgroundColor: disabledBackgroundColor
        },
        "&:hover": { backgroundColor: hoverColor, pointer: "cursor" },
        backgroundColor: color,
        ...sx
      }}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled || disabledWithNoOpacity}
      size={size}
    >
      {!isFalsy(captionText) && (
        <Typography
          variant="caption"
          color={iconColorWithDisabledState}
          align="center"
          marginTop={"2px"}
        >
          {captionText}
        </Typography>
      )}
      <Icon sx={{ color: iconColorWithDisabledState }} fontSize={size} />
    </IconButton>
  );
};

export default StyledIconButton;
