import { forwardRef, Ref, useImperativeHandle } from "react";
import { DateTime } from "luxon";

import { ErrorText } from "../../../styling/StyleComponents";
import Table from "../../../components/Table/Table";
import { useGetDeviceUsageByMemberQuery } from "common/services/RemoteIQService";
import { CircularProgress, Typography } from "@mui/material";

import ReportRefInterface from "./ReportRefInterface";
import MemberType from "common/types/MemberType";
import ErrorComponent from "../../../components/ErrorComponent";
import { isFalsy } from "common/helpers/helpers";

const readingColumns = [
  { name: "reportReadingsDate" },
  { name: "reportReadingsDevices" },
  { name: "reportReadingsCount" }
];

interface IProps {
  patient: MemberType;
  startDate: DateTime;
  endDate: DateTime;
}

const SummaryComponent = ({
  days,
  count
}: {
  days?: number;
  count?: number;
}) => {
  const isDaysDefined = days !== undefined;
  const isCountDefined = count !== undefined;
  return (
    <>
      <Typography variant="body1" color="text.secondary">
        {isDaysDefined && `Total Reading Days: ${days} days`}
        {isDaysDefined && isCountDefined && <br />}
        {isCountDefined && `Total Readings: ${count}`}
      </Typography>
    </>
  );
};

const ReportReadings = forwardRef(
  ({ patient, startDate, endDate }: IProps, ref: Ref<ReportRefInterface>) => {
    const start = Math.floor(startDate.toSeconds());
    const end = Math.floor(endDate.toSeconds());
    const { data, isLoading, isError, isSuccess, error } =
      useGetDeviceUsageByMemberQuery(
        {
          memberId: patient?.patient?.patient_id,
          paramsObject: {
            start: start,
            end: end
          }
        },
        { skip: isFalsy(patient) }
      );

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = data.summary?.map((item) => {
          return [
            item.date,
            item.device_types.join(", "),
            //"id",
            `${item.count}`
          ];
        });

        return {
          filename: "device_usage_patient_id",
          // TODO: Missing API
          // https://copilotiq.slack.com/archives/C03G56CLFT2/p1702665511904539?thread_ts=1702664502.315629&cid=C03G56CLFT2
          columns: ["Date", "Device Type", /*"Device Id",*/ "Readings Count"],
          data: formattedData
        };
      },
      getReportData: () => {
        const formattedData = data.summary?.map((item) => {
          return [item.date, item.device_types.join(", "), `${item.count}`];
        });
        return [
          {
            columnNames: ["Date", "Device Type", "Readings Count"],
            data: formattedData,
            reportSummary: [
              `Total Reading Days: ${data.days} days`,
              `Total Readings: ${data.count}`
            ]
          }
        ];
      }
    }));

    return (
      <>
        {data && data.summary?.length > 0 && (
          <>
            <Table
              tableColumns={readingColumns}
              data={data.summary}
              tableMaxHeight={"500px"}
            />
            <br />
            <SummaryComponent days={data.days} count={data.count} />
          </>
        )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.count === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </>
    );
  }
);

export default ReportReadings;
