import { CustomTooltip } from "../../styling/StyleComponents";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";
import styled from "@emotion/styled";
import { gray } from "common/styling/colors";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useCreateVisitMutation } from "common/services/VisitsService";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "common/redux";
import { isFalsy } from "common/helpers/helpers";
import replace from "lodash.replace";
import { useNavigate } from "react-router-dom";
import { setInitialState } from "common/redux/VisitsSlice";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import { RIQSnackbar } from "../Snackbar/Snackbar";
import Routes from "../../routes/Routes";
import { LoadingButton } from "@mui/lab";

export const StyledButton = styled(Button)`
  background: white;
  padding: 5px;
  &:hover: {
    backgroundColor: ${gray[25]}
    pointer: cursor;
  }
  lineHeight: 1.3;
`;

const StartCareFlow = ({
  isSameDay = true,
  confirmMessage = "",
  disabled = false,
  visitsRequest
}: {
  isSameDay?: boolean;
  confirmMessage?: string;
  disabled?: boolean;
  visitsRequest: any;
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { data: currentVisit, isLoading } = useGetCurrentVisit({
    staffId: user?.user_id
  });
  const { visitId: existingVisitId } = useSanitizedParams();

  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const [
    createVisitMutation,
    {
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      isError,
      data: createVisitResult
    }
  ] = useCreateVisitMutation();

  useEffect(() => {
    if (isError) setErrorOpen(true);
  }, [isError]);

  // if visit is created with care flow, navigate to the care flow
  useEffect(() => {
    if (createVisitIsSuccess && createVisitResult.care_flow) {
      const careFlowLink = replace(
        Routes.VISITS,
        ":visitId",
        createVisitResult?.visit_id
      );
      dispatch(setInitialState());
      navigate(careFlowLink);
    }
  }, [createVisitIsSuccess]);

  if (isFalsy(visitsRequest.motivation_reason))
    visitsRequest.motivation_reason = VisitMotivationTypesEnum.APPOINTMENT;

  return (
    <>
      <RIQSnackbar
        open={errorOpen}
        severity={"error"}
        handleClose={() => setErrorOpen(false)}
        content={`Unable to start a new visit. Please check the member's insurance or
          provider/nurse assignment.`}
      />
      {isSameDay && (
        <CustomTooltip
          title={
            currentVisit &&
            "Cannot start a new visit because you already have one in progress"
          }
          placement="bottom"
        >
          <div>
            <LoadingButton
              variant="outlined"
              color="primary"
              style={{ background: "white" }}
              loading={createVisitLoading}
              disabled={
                disabled ||
                !isFalsy(currentVisit) ||
                !isFalsy(existingVisitId) ||
                isLoading ||
                createVisitLoading
              }
              onClick={async () => {
                const req = {
                  staff_id: user?.user_id,
                  patient_id: visitsRequest.patient_id,
                  body: {
                    ...visitsRequest,
                    staff_id: user?.user_id
                  },
                  with_care_flow: true
                };
                if (isFalsy(confirmMessage)) {
                  await createVisitMutation(req);
                } else {
                  Alert_show({
                    dispatch,
                    title: "Confirm Visit",
                    content: (
                      <Typography
                        variant="h6"
                        color={"secondary"}
                        textAlign={"center"}
                      >
                        {confirmMessage}
                      </Typography>
                    ),
                    size: "small",
                    hideCloseIcon: true,
                    buttons: [
                      {
                        text: "Confirm",
                        onPress: () => {
                          Alert_closeAll(dispatch);

                          createVisitMutation(req);
                        }
                      },
                      {
                        style: "cancel",
                        text: "Cancel",
                        onPress: () => Alert_closeAll(dispatch)
                      }
                    ]
                  });
                }
              }}
            >
              Start
            </LoadingButton>
          </div>
        </CustomTooltip>
      )}
    </>
  );
};

export default StartCareFlow;
