import { useMemo } from "react";

import RolesEnum from "common/enums/RolesEnum";
import { useGetMemberSurveysQuery } from "common/services/SurveyService";

import {
  HeaderComponent,
  TableComponentContainer
} from "../../styling/StyleComponents";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";
import ErrorComponent from "../../components/ErrorComponent";
import { TableColumn } from "../../components/Table/TableTypes";
import { Typography } from "@mui/material";
import { getNameOrUsername } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

import useSanitizedParams from "../../hooks/useSanitizedParams";
import { useNavigate } from "react-router-dom";
import Routes from "../../routes/Routes";

interface IProps {
  submitter_roles?: RolesEnum[];
  componentHeader?: string;
  tableHeader?: string;
  linked_entities?: SurveyLinkedEntitiesEnum[];
  size?: number;
  tableColumns?: TableColumn[];
  showHeader?: boolean;
}

const DEFAULT_TABLE_COLUMNS = [
  { name: "name" },
  { name: "status" },
  { name: "phone" },
  { name: "birthdate" },
  {
    id: "submitter_name",
    name: "name",
    accessor: "submitter",
    header: "Submitter"
  },
  {
    name: "roles",
    accessor: "submitter.roles",
    header: "Submitter Role"
  },
  {
    name: "default",
    id: "reasonCategory",
    accessor: "category",
    header: "Category"
  },
  {
    name: "default",
    id: "subCategory",
    accessor: "subCategory",
    header: "Sub Category"
  }
];

const ReportedCancellations = ({
  submitter_roles,
  componentHeader,
  tableHeader,
  linked_entities = [
    SurveyLinkedEntitiesEnum.PATIENT,
    SurveyLinkedEntitiesEnum.SUBMITTER
  ],
  size,
  tableColumns = DEFAULT_TABLE_COLUMNS,
  showHeader = true
}: IProps) => {
  const params = useSanitizedParams();
  const navigate = useNavigate();

  const { memberId } = params;

  const { data, isLoading, error } = useGetMemberSurveysQuery({
    patient_id: memberId,
    submitter_roles,
    linked_entities,
    size
  });

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined }
  );

  const processedData = useMemo(() => {
    if (data === undefined) return undefined;
    return data.map((item) => {
      const answerCategory = item.patient_survey_answers.find(
        (answer) => answer.category !== "General"
      );

      const notes = item.patient_survey_answers.find(
        (item) => item.category === "General" && item.question === "Notes"
      );

      return {
        ...item,
        category: answerCategory?.category,
        subCategory: answerCategory?.answer,
        notes: notes?.answer
      };
    });
  }, [data]);

  const tableHeaderProcessed = useMemo(() => {
    if (tableHeader === undefined || processedData === undefined)
      return undefined;
    return tableHeader.replaceAll("{{COUNT}}", "" + processedData.length);
  }, [tableHeader, processedData]);

  return (
    <TableComponentContainer hideBorder={!showHeader} margin="0px">
      {showHeader && (
        <>
          <HeaderComponent componentHeader={componentHeader} />

          <Typography variant="h6">
            {getNameOrUsername(patient?.patient)}
          </Typography>
        </>
      )}
      {isLoading && <LoadingFallback delay={500} count={10} />}

      {processedData && (
        <Table
          tableColumns={tableColumns}
          tableProps={{
            externalLink: Routes.MEMBER_DETAILS(""),
            navigate
          }}
          tableHeader={tableHeaderProcessed}
          data={processedData}
        />
      )}

      {error && <ErrorComponent error={error} />}
    </TableComponentContainer>
  );
};

export default ReportedCancellations;
