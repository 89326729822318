import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Platform } from "react-native";

interface StateType {
  code: string;
  tabId: string;
  startDate: DateTime;
  connectedDate: DateTime;
  endDate: DateTime;
}

export const CURRENT_TAB_ID = Platform.OS === "web" ? uuidv4() : "";

const initialState: StateType = {
  tabId: null,
  code: null,
  startDate: null,
  connectedDate: null,
  endDate: null
};

export const videoCallSlice = createSlice({
  name: "videoCall",
  initialState,
  reducers: {
    joinVideoCallRoom: (
      state,
      action: {
        payload: { code: string; start_datetime?: string; tabId: string };
      }
    ) => {
      const { code, start_datetime, tabId } = action.payload;

      state.tabId = tabId;
      state.code = code;
      state.startDate = start_datetime
        ? DateTime.fromISO(start_datetime)
        : DateTime.now();
      state.endDate = null;
    },
    connectVideoCall: (state) => {
      state.connectedDate = DateTime.now();
    },
    endVideoCall: (state) => {
      state.code = null;
      state.tabId = null;
      state.endDate = DateTime.now();
    },
    resetStateVideoCall: (state) => {
      state.tabId = initialState.tabId;
      state.code = initialState.code;
      state.startDate = initialState.startDate;
      state.connectedDate = initialState.connectedDate;
      state.endDate = initialState.endDate;
    }
  }
});

export const {
  joinVideoCallRoom,
  connectVideoCall,
  endVideoCall,
  resetStateVideoCall
} = videoCallSlice.actions;

export default videoCallSlice.reducer;
