import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

import { replaceVariables } from "../../helpers/helpers";

import { blue, gray } from "common/styling/colors";
import { Flexbox } from "../../styling/NewStyleComponents";
import { ROUTES_BY_ROLE } from "../../routes/Router";

export const SIDEBAR_MARGIN = 10;
const ALIGNMENT = "left";

const SidebarContainer = styled.div`
  margin: 50px ${SIDEBAR_MARGIN}px;
`;

const SectionContainer = styled.div``;

const SectionName = styled.div`
  margin-top: 15px;
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-align: ${ALIGNMENT};
  color: ${gray[75]};
`;

const SectionItem = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${ALIGNMENT};
  margin-top: 12px;

  background: ${({ selected }) => (selected ? blue[50] : "#FFFFFF")};
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  display: flex;

  &:hover {
    background: ${blue[50]};
    a {
      color: ${blue[700]};
    }
  }
`;

const SectionLink = styled(Link)`
  text-decoration: none;
`;

const SectionText = styled.div<{ pathname: string; to: string }>`
  color: ${({ pathname, to }) => (pathname === to ? blue[700] : gray[600])};
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.family};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-left: 3px;
  display: flex;
  align-items: ${ALIGNMENT};
  text-align: ${ALIGNMENT};
`;

const SectionIcon = styled.div<{ selected: boolean }>`
  & svg {
    & path {
      fill: ${({ selected }) => (selected ? blue[700] : "#C2CCD9")};
    }
  }
  display: flex;
`;

const isSectionSelected = (itemLink, pathname) => {
  // Added exception for retention https://copilotiq.atlassian.net/browse/ENG-5644
  if (itemLink === "/retention/notset" && pathname.includes("/retention/"))
    return true;

  // added exception for PTO epic
  if (
    itemLink === "/nurses-schedules/schedules" &&
    pathname.includes("/nurses-schedules/")
  ) {
    return true;
  }

  return itemLink === pathname;
};

const Sidebar = () => {
  const { user, currentRole } = useSelector((state: RootState) => state.auth);

  const location = useLocation();

  const roleConfig = ROUTES_BY_ROLE[currentRole];

  return (
    <SidebarContainer>
      {roleConfig?.sidebar &&
        // don't show sidebar if we don't have user info
        // there is a race condition where we get user info after we get role config
        // and the sidebar renders with an empty user id
        user?.user_id &&
        roleConfig?.sidebar?.map((item) => (
          <SectionContainer key={item.sectionName}>
            <SectionName key={item.sectionName}>{item.sectionName}</SectionName>
            {item.items.map((i) => {
              const { itemName, ItemIcon } = i;
              let { itemLink } = i;
              if (itemLink) {
                // replace {{userId}} in itemLink from config.json
                itemLink = replaceVariables(itemLink, {
                  userId: user?.user_id
                });
              }

              const sectionSelected = isSectionSelected(
                itemLink,
                location?.pathname
              );

              return (
                <SectionLink key={itemName} to={itemLink}>
                  <SectionItem selected={sectionSelected} key={itemName}>
                    <Flexbox alignItems="center" gap="2px">
                      {ItemIcon && (
                        <SectionIcon selected={sectionSelected}>
                          <ItemIcon style={{ height: 24, width: 24 }} />
                        </SectionIcon>
                      )}
                      <SectionText
                        pathname={location?.pathname}
                        to={itemLink}
                        id={itemName}
                      >
                        {itemName}
                      </SectionText>
                    </Flexbox>
                  </SectionItem>
                </SectionLink>
              );
            })}
          </SectionContainer>
        ))}
    </SidebarContainer>
  );
};

export default Sidebar;
