enum CareFlowFieldIdsEnum {
  // CONNECTION
  CALL_CONNECTION = "CALL_CONNECTION",
  PRECALL_CHECK_CONNECTION_NEEDED = "PRECALL_CHECK_CONNECTION_NEEDED",

  // BILLING ENTRY
  POSTCALL_BILLING_ENTRY_MINUTES = "POSTCALL_BILLING_ENTRY_MINUTES",
  POSTCALL_BILLING_ENTRY_VISIT_TYPE = "POSTCALL_BILLING_ENTRY_VISIT_TYPE",

  // REVIEW READINGS
  PRECALL_GLUCOSE_7D_AVG = "PRECALL_GLUCOSE_7D_AVG",
  PRECALL_GLUCOSE_30D_AVG = "PRECALL_GLUCOSE_30D_AVG",
  PRECALL_BLOOD_PRESSURE_7D_AVG = "PRECALL_BLOOD_PRESSURE_7D_AVG",
  PRECALL_BLOOD_PRESSURE_30D_AVG = "PRECALL_BLOOD_PRESSURE_30D_AVG",

  // CLINICAL TOPICS: ASSESS READINGS
  PRECALL_CLINICAL_TOPICS_ASSESS_BP_READINGS = "PRECALL_CLINICAL_TOPICS_ASSESS_BP_READINGS",
  PRECALL_CLINICAL_TOPICS_ASSESS_GM_READINGS = "PRECALL_CLINICAL_TOPICS_ASSESS_GM_READINGS",

  // MEMBER HEALTH UPDATES
  CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_FT = "CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_FT",
  CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_IN = "CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_IN",
  CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_WEIGHT = "CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_WEIGHT",
  CALL_MEMBER_HEALTH_UPDATE_DID_MED_RECONCILIATION = "CALL_MEMBER_HEALTH_UPDATE_DID_MED_RECONCILIATION",

  // MEMBER NEEDS
  CALL_MEMBER_NEEDS_SYMPTOMS_CHEST_PAIN = "CALL_MEMBER_NEEDS_SYMPTOMS_CHEST_PAIN",
  CALL_MEMBER_NEEDS_SYMPTOMS_SHORTNESS_OF_BREATH = "CALL_MEMBER_NEEDS_SYMPTOMS_SHORTNESS_OF_BREATH",
  CALL_MEMBER_NEEDS_SYMPTOMS_SEVERE_HEADACHE = "CALL_MEMBER_NEEDS_SYMPTOMS_SEVERE_HEADACHE",
  CALL_MEMBER_NEEDS_SYMPTOMS_CONFUSION = "CALL_MEMBER_NEEDS_SYMPTOMS_CONFUSION",
  CALL_MEMBER_NEEDS_SYMPTOMS_LOSS_OF_CONSCIOUSNESS = "CALL_MEMBER_NEEDS_SYMPTOMS_LOSS_OF_CONSCIOUSNESS",
  CALL_MEMBER_NEEDS_SYMPTOMS_FACIAL_DROOP = "CALL_MEMBER_NEEDS_SYMPTOMS_FACIAL_DROOP",
  CALL_MEMBER_NEEDS_SYMPTOMS_SEIZURE = "CALL_MEMBER_NEEDS_SYMPTOMS_SEIZURE",
  CALL_MEMBER_NEEDS_SYMPTOMS_GI_ISSUES = "CALL_MEMBER_NEEDS_SYMPTOMS_GI_ISSUES",
  CALL_MEMBER_NEEDS_SYMPTOMS_SUICIDAL_THOUGHTS = "CALL_MEMBER_NEEDS_SYMPTOMS_SUICIDAL_THOUGHTS",
  CALL_MEMBER_NEEDS_SYMPTOMS_ABUSE = "CALL_MEMBER_NEEDS_SYMPTOMS_ABUSE",
  CALL_MEMBER_NEEDS_SYMPTOMS_OTHER = "CALL_MEMBER_NEEDS_SYMPTOMS_OTHER",

  CALL_MEMBER_NEEDS_DEVICE_USAGE_QUESTIONS = "CALL_MEMBER_NEEDS_DEVICE_USAGE_QUESTIONS",
  CALL_MEMBER_NEEDS_DEVICE_ISSUE_QUESTIONS = "CALL_MEMBER_NEEDS_DEVICE_ISSUE_QUESTIONS",

  CALL_MEMBER_NEEDS_NEEDS_REFILLS = "CALL_MEMBER_NEEDS_NEEDS_REFILLS",
  CALL_MEMBER_NEEDS_NEEDS_MEDICATIONS = "CALL_MEMBER_NEEDS_NEEDS_MEDICATIONS",

  CALL_MEMBER_NEEDS_HAS_CLINICAL_QUESTION = "CALL_MEMBER_NEEDS_HAS_CLINICAL_QUESTION",
  CALL_MEMBER_NEEDS_REQUESTS_CLINICAL_COACHING = "CALL_MEMBER_NEEDS_REQUESTS_CLINICAL_COACHING",

  CALL_REFERRALS_NEXT_NP_APPOINTMENT = "CALL_REFERRALS_NEXT_NP_APPOINTMENT",
  CALL_REFERRALS_NEXT_NP_NAME = "CALL_REFERRALS_NEXT_NP_NAME",

  // GOALS
  GOAL_MANAGEMENT = "GOAL_MANAGEMENT",

  // CARE PLAN WEEKLY VISITS
  CALL_CARE_PLAN_WEEKLY_VISIT_DID_CONVERT = "CALL_CARE_PLAN_WEEKLY_VISIT_DID_CONVERT",
  CALL_CARE_PLAN_NEEDS_TO_STAY_WITH_NURSE = "CALL_CARE_PLAN_NEEDS_TO_STAY_WITH_NURSE",
  CALL_CARE_PLAN_WEEKLY_VISITS = "CALL_CARE_PLAN_WEEKLY_VISITS",

  // NEXT STEPS
  CALL_NEXT_STEPS_ACTION_ITEMS = "CALL_NEXT_STEPS_ACTION_ITEMS",

  // NOT CONNECTED: CARE MESSAGE
  POST_CALL_CARE_MESSAGE_ORIGINAL = "POST_CALL_CARE_MESSAGE_ORIGINAL",
  POST_CALL_CARE_MESSAGE_SENT = "POST_CALL_CARE_MESSAGE_SENT",
  POST_CALL_CARE_MESSAGE_TIME_SENT = "POST_CALL_CARE_MESSAGE_TIME_SENT",
}

export default CareFlowFieldIdsEnum;
