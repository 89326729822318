import { FormikProps } from "formik";
import styled from "@emotion/styled";
import { ChangeEvent } from "react";
import SurveyAnswerTypeEnum from "common/enums/SurveyAnswerTypeEnum";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import SurveyFieldType from "common/types/SurveyFieldType";
import { blue } from "common/styling/colors";

const RadioButtonContainer = styled.label<{ $active: boolean }>`
  display: flex;
  flex: 1;
  background: ${(props) => (props?.$active ? "#e5f8f9" : "#fff")};
  border: 1px solid ${(props) => (props?.$active ? blue[700] : "#C2CCD9")};
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0.66s ease-out;
  padding: 0px 10px;
  margin: 2px 0px;
`;

interface IProps {
  field: SurveyFieldType;
  formik: FormikProps<{ [key: string]: string }>;
}

const SurveyField = ({ field, formik }: IProps) => {
  const values = formik.values;
  const { type, answers, id, question } = field;

  //const [freeText, setFreeText] = useState<string>("");

  const value = values[id] || "";

  switch (type) {
    case SurveyAnswerTypeEnum.FREE_TEXT:
      return (
        <TextField
          name={"form-" + id}
          value={value}
          fullWidth
          required
          placeholder={question}
          multiline
          onChange={(e) => {
            formik.setFieldValue(id, e.target.value);
          }}
        />
      );
    case SurveyAnswerTypeEnum.SINGLE_SELECTION:
      return (
        <RadioGroup
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue(id, event.target.value)
          }
        >
          {answers.map((answer) => {
            return (
              <RadioButtonContainer
                key={id + answer}
                $active={value === answer}
              >
                <FormControlLabel
                  value={answer}
                  control={<Radio checked={value === answer} />}
                  label={answer}
                />
              </RadioButtonContainer>
            );
          })}
        </RadioGroup>
      );
  }
};

export default SurveyField;
