import { styled, Typography } from "@mui/material";
import { blue } from "common/styling/colors";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";

interface IProps {
  field: CareFlowFieldType;
}

const QuoteTypography = styled(Typography)`
  text-align: left;
  font-style: italic;
`;

const QuoteContainer = styled("div")`
  display: flex;
  background-color: ${blue[50]};
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
`;

const VisitFieldQuote = ({ field }: IProps) => {
  // Changed from using field.value to field.placeholder with ENG-7699
  // Adding this code to support rendering careflows from prior to this change
  const text =
    field?.placeholder?.length > 0 ? field?.placeholder : field?.value;

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
      <QuoteContainer>
        <QuoteTypography variant="body1">{text}</QuoteTypography>
      </QuoteContainer>
    </div>
  );
};

export default VisitFieldQuote;
export { QuoteTypography, QuoteContainer };
