import MemberType from "../MemberType";

enum CarePlanPhaseEnum {
  ONBOARDING = "ONBOARDING",
  CONCIERGE = "CONCIERGE",
  FOCUSED_IMPROVEMENT = "FOCUSED_IMPROVEMENT",
  MAINTENANCE = "MAINTENANCE"
}

enum CarePlanConditionsEnum {
  DIABETES = "DIABETES",
  HYPERTENSION = "HYPERTENSION"
}

enum CarePlanVisitFrequencyEnum {
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY"
}

interface CarePlanType {
  patient_id: string;
  conditions?: CarePlanConditionsEnum[];
  member_journey_phase?: CarePlanPhaseEnum;
  target_time_per_month?: number;
  visit_frequency?: CarePlanVisitFrequencyEnum;
  medication_reconciliation?: {
    last_performed?: string; // ISO Date
    next_due?: string; // ISO Date
  }
}

const hasDiabetes = (member: MemberType) => {
  return member?.care_plan?.conditions?.includes(
    CarePlanConditionsEnum.DIABETES
  );
};

const hasHypertension = (member: MemberType) => {
  return member?.care_plan?.conditions?.includes(
    CarePlanConditionsEnum.HYPERTENSION
  );
};

export default CarePlanType;

export {
  CarePlanConditionsEnum,
  CarePlanVisitFrequencyEnum,
  CarePlanPhaseEnum,
  hasDiabetes,
  hasHypertension
};
