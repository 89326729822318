import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberType from "common/types/MemberType";

import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import {
  HeaderComponent,
  IHeaderProps,
  Spinner,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { TableColumn } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DateFilterFieldEnum from "../../enums/DateFilterFieldEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import ErrorComponent from "../../components/ErrorComponent";
import MemberStatusReasonEnum from "common/enums/MemberStatusReasonEnum";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import DebouncedInput from "../../components/Input/DebouncedInput";
import { firstLastUsernameSearch } from "../../helpers/helpers";
import { useGetMemberActivityByStatusQuery } from "common/services/RemoteIQService";
import { blue } from "common/styling/colors";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";

const TABLE_INPUT_WIDTH = "clamp(200px, 250%, 350px)";

const StyledInput = styled(DebouncedInput)`
  background: ${blue[50]};
  width: ${TABLE_INPUT_WIDTH};
`;

const StyledSpinner = styled(Spinner)`
  left: unset;
  right: 40px;
  top: 12px;
`;

interface IProps extends IHeaderProps {
  userListFilters: {
    status: MemberStatusEnum[];
    linked_entities?: MemberLinkedEntitiesEnum[];
    status_reason?: MemberStatusReasonEnum[];
  };
  externalLink?: string;
  tableColumns: TableColumn[];
  tableHeader: string;
  dateFilterField?: DateFilterFieldEnum;
  filterBy?: (item: MemberType) => boolean;
  sortBy?: (a: MemberType, b: MemberType) => number;
}

const PatientActivityList = ({
  userListFilters,
  componentHeader,
  ComponentTooltip,
  tableColumns,
  externalLink,
  tableHeader,
  dateFilterField,
  filterBy,
  sortBy
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [assignNurseModalOpen, setAssignNurseModalOpen] =
    useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);

  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  const startDate = useMemo(() => {
    const now = DateTime.now();
    return dateFilterField != undefined ? now.minus({ days: 30 }) : undefined;
  }, [dateFilterField]);

  const endDate = useMemo(() => {
    const now = DateTime.now();
    return dateFilterField != undefined ? now : undefined;
  }, [dateFilterField]);

  const setModalStateCallback = (value: string) => {
    setAssignNurseModalOpen(value === "open");
  };

  const setProviderModalStateCallback = (value: string) => {
    setProviderModalOpen(value === "open");
  };

  const hasActions = tableColumns?.find(
    (column) => column.name === "patientActions"
  );

  const { data, isLoading, isError, error } = useGetMemberActivityByStatusQuery(
    {
      ...userListFilters
    }
  );

  const componentHeaderWithDays =
    dateFilterField && endDate && startDate
      ? `${componentHeader} (${Math.floor(
          endDate.diff(startDate, "days").days
        )} Days)`
      : componentHeader;

  const [searchText, setSearchText] = useState<string>("");
  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const trimmedText = useMemo(() => {
    const trimmedText = searchText
      // remove special characters
      ?.replaceAll(/[^a-zA-Z0-9-\s]/g, "")
      .trim();

    return trimmedText;
  }, [searchText]);

  const processedData = useMemo(() => {
    if (data === undefined) return undefined;

    let copiedData = [...data];
    if (filterBy) {
      copiedData = data.filter((item) => filterBy(item));
    }
    if (sortBy) {
      copiedData = data.sort(sortBy);
    }
    return copiedData;
  }, [data, filterBy, sortBy]);

  // filter original data by search text
  const originalFilteredData = useMemo(() => {
    if (trimmedText.length === 0) return processedData;
    else
      return firstLastUsernameSearch(
        trimmedText,
        processedData,
        "patient.username",
        "fullname"
      );
  }, [processedData, trimmedText]);

  const { data: searchData, isFetching: isSearchFetching } =
    useGetMemberActivityByStatusQuery(
      {
        search: trimmedText,
        sort: "lastName,ASC,firstName,ASC",
        ...userListFilters
      },
      {
        // if we don't find any results in the original data, then we need to fetch results from api
        skip: trimmedText.length === 0 || originalFilteredData?.length > 0,
        refetchOnMountOrArgChange: true
      }
    );

  const searchResults = useMemo(() => {
    if (trimmedText.length === 0) return [];
    else return searchData;
  }, [searchData, trimmedText]);

  const finalData = useMemo(() => {
    if (originalFilteredData?.length === 0 && trimmedText?.length > 0) {
      return searchResults;
    } else {
      return originalFilteredData;
    }
  }, [originalFilteredData, trimmedText, searchResults]);

  return (
    <TableComponentContainer>
      <HeaderComponent
        componentHeader={componentHeaderWithDays}
        ComponentTooltip={ComponentTooltip}
        key={componentHeader}
      />
      <StyledInput
        fullWidth
        debounceTimeout={300}
        value={searchText}
        onChange={handleText}
        type="text"
        placeholder="Search members"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment
                position="end"
                sx={{ backgroundColor: "transparent" }}
              >
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ backgroundColor: "transparent" }}
              >
                <StyledSpinner loading={isSearchFetching.toString()} />
              </InputAdornment>
            )
          }
        }}
      />
      {isLoading && <LoadingFallback delay={500} count={10} />}
      {!isLoading && finalData && finalData?.length >= 0 && (
        <Table
          tableHeader={tableHeader}
          tableColumns={tableColumns}
          tableProps={{
            currentRole,
            setSelectedPatientIdCallback: setSelectedPatientId,
            setModalStateCallback,
            setProviderModalStateCallback,
            externalLink
          }}
          data={finalData}
        />
      )}

      {isError && <ErrorComponent error={error} />}

      {hasActions !== undefined && (
        <>
          <AssignNursePatientModal
            modalOpen={assignNurseModalOpen}
            setModalStateCallback={setModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
          <AssignProviderPatientModal
            key={`assignprovider_${selectedPatientId}`}
            modalOpen={providerModalOpen}
            setModalStateCallback={setProviderModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
        </>
      )}
    </TableComponentContainer>
  );
};

export default PatientActivityList;
