import styled from "@emotion/styled";
import { Flexbox } from "../../styling/NewStyleComponents";
import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { Goals, GoalStyle } from "../../components/Goals/Goals";
import { useEffect, useState } from "react";
import {
  GoalTypeEnum,
  ProcessedGoalsType,
  processGoals
} from "common/types/GoalType";
import { useGetGoalsQuery } from "common/services/MemberGoalsRelationshipService";
import { Button, CircularProgress, Typography } from "@mui/material";
import {
  CarePlanConditionsEnum,
  hasDiabetes,
  hasHypertension
} from "common/types/Visits/CarePlanType";
import { CustomTooltip, StatusBadge } from "../../styling/StyleComponents";
import { DateTime } from "luxon";
import { EditCarePlanModal } from "../../components/CarePlan/EditCarePlanModal";
import { isFalsy } from "common/helpers/helpers";
import { InfoOutlined } from "@mui/icons-material";
import { gray } from "common/styling/colors";
import { StatCard } from "../Visits/ui/PreCall/ReviewCarePlan/ReadingStatCards";

const MemberDetailsGoalsContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CarePlanContainerStyle = {
  ...GoalStyle,
  width: "690px",
  paddingBottom: "20px"
};

const MemberDetailsGoals = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;
  const [processedGoals, setProcessedGoals] = useState<ProcessedGoalsType>({
    clinical: [],
    member: []
  });
  const [editCarePlanModal, setEditCarePlanModal] = useState<boolean>(false);

  const { data: member } = useGetGoalsQuery(
    {
      member_id: memberId
    },
    { skip: memberId === undefined }
  );

  useEffect(() => {
    const process = async () => {
      const newProcessedGoals = processGoals({
        goals: member?.goals,
        splitByType: true
      });
      setProcessedGoals(newProcessedGoals);
    };

    if (member?.goals) process();
  }, [member]);

  return (
    <MemberDetailsGoalsContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-goals`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-goals`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <>
            <Flexbox flexDirection={"column"} gap={"10px"}>
              <Flexbox flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant={"h5"}>Care Plan</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditCarePlanModal(true);
                  }}
                  sx={{ maxWidth: "150px" }}
                >
                  Edit Care Plan
                </Button>
              </Flexbox>

              <Flexbox
                sx={CarePlanContainerStyle}
                gap="20px"
                flexDirection={"column"}
              >
                {isFalsy(member) ? (
                  <CircularProgress />
                ) : (
                  <Flexbox
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Flexbox
                      flexDirection={"column"}
                      gap={"10px"}
                      flexBasis={"50%"}
                    >
                      <Typography variant={"h6"}>Conditions</Typography>
                      <Flexbox flexDirection={"row"} gap={"20px"}>
                        {!isFalsy(member?.care_plan?.conditions) ? (
                          <>
                            {hasDiabetes(member) && (
                              <StatusBadge
                                status={CarePlanConditionsEnum.DIABETES}
                                hideDot={true}
                              />
                            )}
                            {hasHypertension(member) && (
                              <StatusBadge
                                status={CarePlanConditionsEnum.HYPERTENSION}
                                hideDot={true}
                              />
                            )}
                          </>
                        ) : (
                          <Typography variant={"body2"}>
                            No Conditions
                          </Typography>
                        )}
                      </Flexbox>
                    </Flexbox>
                    <Flexbox
                      flexDirection={"column"}
                      gap={"10px"}
                      flexBasis={"50%"}
                    >
                      <Flexbox>
                        <Typography variant={"h6"} marginRight={"10px"}>
                          Medication Reconciliation
                        </Typography>
                        <CustomTooltip
                          backgroundColor={gray[200]}
                          title={
                            <Typography
                              variant="body1"
                              color="text.secondary"
                              maxWidth="225px"
                            >
                              Medication Reconciliations should be done before
                              the next provider appointment. The system should
                              automatically set this but if you feel we should
                              perform this sooner, please adjust via the{" "}
                              <b>Edit Care Plan</b> button.
                            </Typography>
                          }
                        >
                          <InfoOutlined color="primary" fontSize="small" />
                        </CustomTooltip>
                      </Flexbox>
                      <Flexbox flexDirection={"row"} gap={"5px"}>
                        {member?.care_plan?.medication_reconciliation && (
                          <>
                            <StatCard
                              header={"Last Performed"}
                              text={
                                isFalsy(
                                  member?.care_plan?.medication_reconciliation
                                    .last_performed
                                )
                                  ? "No Data"
                                  : DateTime.fromISO(
                                      member?.care_plan
                                        ?.medication_reconciliation
                                        .last_performed
                                    ).toFormat("MM/dd/yyyy")
                              }
                            />
                            <StatCard
                              header={" Next Due"}
                              text={
                                isFalsy(
                                  member?.care_plan?.medication_reconciliation
                                    .next_due
                                )
                                  ? "No Data"
                                  : DateTime.fromISO(
                                      member?.care_plan
                                        ?.medication_reconciliation.next_due
                                    ).toFormat("MM/dd/yyyy")
                              }
                            />
                          </>
                        )}
                      </Flexbox>
                    </Flexbox>
                  </Flexbox>
                )}
              </Flexbox>
            </Flexbox>

            <br />
            <Flexbox flexDirection={"column"} gap={"20px"}>
              <Goals
                goals={processedGoals.clinical}
                member={member}
                title={"Clinical Goals"}
                showAssessment={false}
              />

              <Goals
                goals={processedGoals.member}
                member={member}
                title={"Member Goals"}
                showAssessment={false}
                goalType={GoalTypeEnum.MEMBER}
              />
            </Flexbox>
            <EditCarePlanModal
              isOpen={editCarePlanModal}
              member={member}
              onRequestClose={() => {
                setEditCarePlanModal(false);
              }}
            />
          </>
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsGoalsContainer>
  );
};

export default MemberDetailsGoals;
