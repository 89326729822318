import Bowser from "bowser";
import { useIdleTimer } from "react-idle-timer";
import * as Sentry from "@sentry/react";

import Router from "./routes/Router";

import chromeIcon from "./assets/images/chrome_icon.png";
import { RootState, useAppDispatch } from "common/redux";
import { logOut } from "common/redux/AuthSlice";
import styled from "@emotion/styled";
import { CopilotIQFullLogo } from "./assets/images";
import { Alert_closeAll } from "common/helpers/AlertHelper";
import { useSelector } from "react-redux";

const CopilotLogoImg = styled(CopilotIQFullLogo)``;

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  chrome: ">50"
});

export const INACTIVITY_TIMEOUT_MINUTES = 15;
const IDLE_TIMER = INACTIVITY_TIMEOUT_MINUTES * 60 * 1000;

const App = () => {
  const dispatch = useAppDispatch();

  const { code } = useSelector((state: RootState) => state.videoCall);

  const onIdle = () => {
    // We don't want to logout if staff is on an ongoing call.
    if (code !== null) return;

    Alert_closeAll(dispatch);
    dispatch(logOut(false));
  };
  useIdleTimer({ onIdle, timeout: IDLE_TIMER, crossTab: true });

  return (
    <>
      {isValidBrowser ? (
        <Router />
      ) : (
        <div style={{ position: "relative" }}>
          <CopilotLogoImg
          //alt="CopilotIQ Logo"
          />
          <h1 style={{ color: "red" }}>
            Your browser is not currently supported. Please use the{" "}
            <a href="https://www.google.com/chrome/" rel="noreferrer noopener">
              latest version of Google Chrome
            </a>
            <img
              src={chromeIcon}
              alt="Google Chrome Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </h1>
        </div>
      )}
    </>
  );
};

export default Sentry.withProfiler(App);
