import MemberType from "common/types/MemberType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import { useGetCareFlowAnswerQuery } from "common/services/VisitsService";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { isFalsy } from "common/helpers/helpers";
import { isDisabled } from "../../../VisitHelper";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { CARE_FLOW_CHARACTER_LIMIT } from "../../../../../helpers/components/Forms/FormHelpers";
import { gray } from "common/styling/colors";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import { setAnswer } from "common/redux/VisitsSlice";
import { useMemo } from "react";

const UnitNumberField = ({
  memberId,
  fieldId,
  label,
  disabled,
  unit
}: {
  memberId: string;
  fieldId: string;
  label: string;
  disabled: boolean;
  unit: string;
}) => {
  const dispatch = useAppDispatch();
  const { answers } = useSelector((state: RootState) => state.visits);
  // Check for previous values
  const { data: previousValueData } = useGetCareFlowAnswerQuery(
    {
      field_id: fieldId,
      patient_id: memberId
    },
    {
      skip: isFalsy(memberId) || disabled
    }
  );

  const previousValue = useMemo(() => {
    if (answers[fieldId]) return Number(answers[fieldId]);

    if (!isFalsy(previousValueData?.value)) {
      setAnswer({ id: fieldId, value: previousValueData?.value });
      return Number(previousValueData?.value);
    }

    return undefined;
  }, [previousValueData, answers]);

  return (
    <TextField
      type={"number"}
      aria-label={label}
      label={label}
      fullWidth
      slotProps={{
        htmlInput: { maxLength: CARE_FLOW_CHARACTER_LIMIT },
        input: {
          endAdornment: <InputAdornment position="start">{unit}</InputAdornment>
        }
      }}
      value={previousValue ?? ""}
      disabled={disabled}
      sx={{
        input: {
          color: gray[900]
        }
      }}
      onChange={(event) => {
        const value = event.target.value;

        dispatch(
          setAnswer({
            id: fieldId,
            value: value
          })
        );
      }}
    />
  );
};

export const HeightAndWeight = ({
  member,
  visit
}: {
  member: MemberType;
  visit: GetVisitResponseType;
}) => {
  const disabled = isDisabled(visit);

  return (
    <>
      <Typography variant="body1" color={gray[900]} marginBottom={"10px"}>
        {`What's the member's height and weight?`}
      </Typography>
      <Flexbox flexDirection={"row"} gap={"20px"}>
        <Flexbox flexDirection={"row"} gap={"5px"} width={"200px"}>
          <UnitNumberField
            memberId={member?.patient?.patient_id}
            label={"Height"}
            fieldId={
              CareFlowFieldIdsEnum.CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_FT
            }
            unit={"ft"}
            disabled={disabled}
          />
          <UnitNumberField
            memberId={member?.patient?.patient_id}
            label={"Height"}
            fieldId={
              CareFlowFieldIdsEnum.CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_HEIGHT_IN
            }
            unit={"in"}
            disabled={disabled}
          />
        </Flexbox>
        <Flexbox flexDirection={"row"} width={"120px"}>
          <UnitNumberField
            memberId={member?.patient?.patient_id}
            label={"Weight"}
            fieldId={
              CareFlowFieldIdsEnum.CALL_CRITICAL_ISSUES_MEMBER_HEALTH_UPDATES_WEIGHT
            }
            unit={"lbs"}
            disabled={disabled}
          />
        </Flexbox>
      </Flexbox>
    </>
  );
};
