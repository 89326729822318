import { gray } from "common/styling/colors";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import { CircularProgress, SxProps, Typography } from "@mui/material";
import { useGetReadingsTrendsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { isFalsy, isTruthy } from "common/helpers/helpers";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import DeviceTrendType, {
  BloodPressureTrendType,
  GlucoseTrendType
} from "common/types/DeviceTrendType";
import { getTaskColor } from "../../../../Tasks/helpers";
import { TaskPriority } from "common/types/TaskType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { setAnswers } from "common/redux/VisitsSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { copyToClipboard } from "../../../../../styling/CopyPatientLinkToClipboard";
import { CustomTooltip } from "../../../../../styling/StyleComponents";
import { AppDispatch } from "common/redux";

export const StatCard = ({
  text,
  header,
  sx
}: {
  text: string;
  header: string;
  sx?: SxProps;
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (copiedToClipboard) {
      timerRef.current = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [copiedToClipboard]);
  return (
    <CustomTooltip
      placement="top"
      title={copiedToClipboard ? "Copied!" : "Click to copy to clipboard"}
    >
      <Flexbox
        flexDirection={"column"}
        alignItems={"center"}
        onClick={async () => {
          const copyToClipboardResult = await copyToClipboard(text);
          if (copyToClipboardResult) {
            setCopiedToClipboard(true);
          }
        }}
        sx={{
          padding: "8px",
          minWidth: "150px",
          border: `1px solid ${gray[300]}`,
          borderRadius: "8px",
          cursor: "pointer",
          ...sx
        }}
      >
        <Typography variant={"caption"} sx={{ fontWeight: 400 }}>
          {header}
        </Typography>
        <Typography variant={"h6"}>{text}</Typography>
      </Flexbox>
    </CustomTooltip>
  );
};

const getBPText = ({ data }: { data: BloodPressureTrendType }) => {
  return isFalsy(data)
    ? "No data"
    : `${data.average_systolic}/${data.average_diastolic} mmHg`;
};

const getGlucoseText = ({
  data,
  isFasting
}: {
  data: GlucoseTrendType;
  isFasting: boolean;
}) => {
  return isFalsy(data)
    ? "No data"
    : `${isFasting ? data.average_fasting : data.average_non_fasting} mg/DL`;
};

const getValue = ({
  type,
  days,
  trends
}: {
  type: DeviceTrendParam;
  days: number;
  trends: GlucoseTrendType & BloodPressureTrendType;
}) => {
  if (type === DeviceTrendParam.GLUCOSE) {
    const avgFasting =
      trends?.average_fasting < 0 ? "No data" : trends?.average_fasting;
    const avgNonFasting =
      trends?.average_non_fasting < 0 ? "No data" : trends?.average_non_fasting;

    return `Glucose ${days.toString()}-day fasting average: ${avgFasting}, non-fasting average: ${avgNonFasting}`;
  } else if (type === DeviceTrendParam.BLOOD_PRESSURE) {
    const bp =
      isFalsy(trends?.average_systolic) || isFalsy(trends.average_diastolic)
        ? "No data"
        : `${trends?.average_systolic} / ${trends?.average_diastolic}`;

    return `Blood Pressure ${days.toString()}-day average: ${bp}`;
  }
  return "";
};

export const forceTrendAnswers = ({
  data,
  dispatch
}: {
  data: DeviceTrendType;
  dispatch: AppDispatch;
}) => {
  const res = [];

  // PRECALL_GLUCOSE_7D_AVG
  if (isTruthy(data?.glucose) && isTruthy(data?.glucose?.trends[7])) {
    res.push({
      id: CareFlowFieldIdsEnum.PRECALL_GLUCOSE_7D_AVG,
      value: getValue({
        type: DeviceTrendParam.GLUCOSE,
        days: 7,
        trends: data?.glucose?.trends[7]
      })
    });
  }

  // PRECALL_GLUCOSE_30D_AVG
  if (isTruthy(data?.glucose) && isTruthy(data?.glucose?.trends[30])) {
    res.push({
      id: CareFlowFieldIdsEnum.PRECALL_GLUCOSE_30D_AVG,
      value: getValue({
        type: DeviceTrendParam.GLUCOSE,
        days: 30,
        trends: data?.glucose?.trends[30]
      })
    });
  }

  // PRECALL_BLOOD_PRESSURE_7D_AVG
  if (
    isTruthy(data?.blood_pressure) &&
    isTruthy(data?.blood_pressure?.trends[7])
  ) {
    res.push({
      id: CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_7D_AVG,
      value: getValue({
        type: DeviceTrendParam.BLOOD_PRESSURE,
        days: 7,
        trends: data?.blood_pressure?.trends[7]
      })
    });
  }

  // PRECALL_BLOOD_PRESSURE_30D_AVG
  if (
    isTruthy(data?.blood_pressure) &&
    isTruthy(data?.blood_pressure?.trends[30])
  ) {
    res.push({
      id: CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_30D_AVG,
      value: getValue({
        type: DeviceTrendParam.BLOOD_PRESSURE,
        days: 30,
        trends: data?.blood_pressure?.trends[30]
      })
    });
  }

  dispatch(setAnswers(res));
};

export const ReadingStatCards = ({
  memberId,
  deviceType
}: {
  memberId: string;
  deviceType: DeviceTypeEnum;
}) => {
  const dispatch = useDispatch();
  const deviceTrend =
    deviceType === DeviceTypeEnum.GLUCOSE_CATEGORY
      ? DeviceTrendParam.GLUCOSE
      : DeviceTrendParam.BLOOD_PRESSURE;

  const { data, isFetching } = useGetReadingsTrendsByMemberQuery(
    {
      memberId: memberId,
      paramsObject: {
        device_type: [deviceTrend],
        trend_days: [7, 30]
      }
    },
    { skip: isFalsy(memberId) }
  );

  useEffect(() => {
    forceTrendAnswers({ data, dispatch });
  }, [data]);

  const gap = "8px";

  return (
    <Flexbox
      flexDirection={"column"}
      marginTop={"55px"}
      gap={gap}
      justifyContent={"space-around"}
    >
      {isFetching && <CircularProgress />}
      {!isFetching && (
        <>
          {isTruthy(data?.glucose) && !isFalsy(data?.glucose?.trends[7]) && (
            <Flexbox flexDirection={"row"} gap={gap}>
              <StatCard
                text={`${data?.glucose?.trends[7]?.num_alertable_readings ?? 0}`}
                header={"7d Urgent Alerts"}
                sx={
                  data?.glucose?.trends[30]?.num_alertable_readings > 0
                    ? { backgroundColor: getTaskColor(TaskPriority.CRITICAL) }
                    : {}
                }
              />
              <StatCard
                text={getGlucoseText({
                  data: data?.glucose?.trends[7],
                  isFasting: false
                })}
                header={"7d Non-Fasting Average"}
              />
              <StatCard
                text={getGlucoseText({
                  data: data?.glucose?.trends[7],
                  isFasting: true
                })}
                header={"7d Fasting Average"}
              />
            </Flexbox>
          )}
          {isTruthy(data?.glucose) && !isFalsy(data?.glucose?.trends[30]) && (
            <Flexbox flexDirection={"row"} gap={gap}>
              <StatCard
                text={`${data?.glucose?.trends[30]?.num_alertable_readings ?? 0}`}
                header={"30d Urgent Alerts"}
                sx={
                  data?.glucose?.trends[30]?.num_alertable_readings > 0
                    ? { backgroundColor: getTaskColor(TaskPriority.CRITICAL) }
                    : {}
                }
              />
              <StatCard
                text={getGlucoseText({
                  data: data?.glucose?.trends[30],
                  isFasting: false
                })}
                header={"30d Non-Fasting Average"}
              />
              <StatCard
                text={getGlucoseText({
                  data: data?.glucose?.trends[30],
                  isFasting: true
                })}
                header={"30d Fasting Average"}
              />
            </Flexbox>
          )}
          {isTruthy(data?.blood_pressure) &&
            !isFalsy(data?.blood_pressure?.trends[7]) && (
              <Flexbox flexDirection={"row"} gap={gap}>
                <StatCard
                  text={`${data?.blood_pressure?.trends[7]?.num_alertable_readings ?? 0}`}
                  header={"7d Urgent Alerts"}
                  sx={
                    data?.blood_pressure?.trends[7]?.num_alertable_readings > 0
                      ? { backgroundColor: getTaskColor(TaskPriority.CRITICAL) }
                      : {}
                  }
                />
                <StatCard
                  text={getBPText({ data: data?.blood_pressure?.trends[7] })}
                  header={"7d Average"}
                />
              </Flexbox>
            )}
          {isTruthy(data?.blood_pressure) &&
            !isFalsy(data?.blood_pressure?.trends[30]) && (
              <Flexbox flexDirection={"row"} gap={gap}>
                <StatCard
                  text={`${data?.blood_pressure?.trends[30]?.num_alertable_readings ?? 0}`}
                  header={"7d Urgent Alerts"}
                  sx={
                    data?.blood_pressure?.trends[30]?.num_alertable_readings > 0
                      ? { backgroundColor: getTaskColor(TaskPriority.CRITICAL) }
                      : {}
                  }
                />
                <StatCard
                  text={getBPText({ data: data?.blood_pressure?.trends[30] })}
                  header={"30d Average"}
                />
              </Flexbox>
            )}
        </>
      )}
    </Flexbox>
  );
};
