import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  PROVIDER_LIST,
  COMPLETED_TODOS,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  MY_TODOS,
  SCHEDULE_TODO,
  PROVIDER_SCHEDULES,
  COMMON_ROUTES,
  VISITS
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import { MyProvidersIcon, TodoIcon } from "../../assets/images/icons";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import MemberDetailsRedirect from "../../pages/MemberDetails/MemberDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import Routes from "../Routes";

const providerSidebar = () => {
  const sidebarItems = [
    {
      itemName: "My Providers",
      itemLink: Routes.MY_PROVIDERS,
      ItemIcon: MyProvidersIcon
    }
  ];

  sidebarItems.push(PROVIDER_SCHEDULES_SIDEBAR);

  sidebarItems.push({
    itemName: "To Do",
    itemLink: Routes.TASKS,
    ItemIcon: TodoIcon
  });
  sidebarItems.push(SIDEBAR_CLINICAL_TEAMS);

  return sidebarItems;
};

const PROVIDER_MANAGER = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: [
        SCHEDULE_TODO(true),
        NURSE_LIST({
          componentHeader: "Providers List",
          tableColumns: [
            {
              name: "fullname",
              accessor: "user.fullname",
              filterEnabled: true,
              filterFn: "fuzzy"
            },
            {
              name: "roles",
              size: 200,
              type: "dataList"
            }
          ],
          externalLink: null,
          roles: [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER]
        })
      ]
    },
    {
      path: Routes.NURSES,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSE_REDIRECT,
      components: <MemberDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },
    ...MEMBER_CHART(RolesEnum.PROVIDER_MANAGER),
    ...MEMBER_CHART_CALENDARING,
    STAFF_DETAILS,
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: Routes.ORDERS,
      components: <OrderDevices />
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),

    {
      path: Routes.MY_PROVIDERS,
      components: PROVIDER_LIST({
        componentHeader: "My Providers",
        filterByAuthenticatedUser: true
      })
    },
    COMPLETED_TODOS,
    VISITS,
    MY_TODOS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: [PROVIDER_SCHEDULES({})]
    }
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.providers,
      items: providerSidebar()
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default PROVIDER_MANAGER;
