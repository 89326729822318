import { DateTime } from "luxon";

import { apiUserPresence } from "./AxiosService";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import UserPrecenseType from "../types/UserPrecenseType";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import UserPrecenseStatusEnum from "../enums/UserPrecenseStatusEnum";
import { userService } from "./UserService";

const onQueryStartedClearUserService = async (
  props,
  { dispatch, queryFulfilled }
) => {
  const result = await queryFulfilled;

  dispatch(
    userService.util.invalidateTags([
      {
        type: ReduxTagEnum.User,
        id: result?.data?.user_id
      }
    ])
  );
};

const userPresenceService = apiUserPresence
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Messaging]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserPresenceActivity: builder.query<
        UserPrecenseType[],
        {
          staff_id: string;
          start: DateTime;
          end: DateTime;
          order?: "ASC" | "DESC";
          limit?: number;
        }
      >({
        query: ({ staff_id, start, end, order, limit }) => {
          const queryParams = QueryString_stringify({
            start: start?.toISODate(),
            end: end?.toISODate(),
            order,
            limit
          });

          return {
            url: `/staff/${staff_id}/activity?${queryParams}`,
            method: "GET"
          };
        }
      }),

      // Mutations
      postHeartbeat: builder.query({
        query: () => ({
          url: "/heartbeat",
          method: "POST"
        })
      }),
      postLogout: builder.mutation({
        query: () => ({
          url: "/logout",
          method: "POST"
        })
      }),
      setUserPrecenseStatus: builder.mutation<
        UserPrecenseStatusType,
        { status: UserPrecenseStatusEnum }
      >({
        query: ({ status }: {}) => ({
          url: `/status/${status}`,
          method: "PUT"
        }),
        onQueryStarted: onQueryStartedClearUserService
      }),
      deleteUserPrecenseStatus: builder.mutation<UserPrecenseStatusType>({
        query: () => ({
          url: `/status`,
          method: "DELETE"
        }),
        onQueryStarted: onQueryStartedClearUserService
      })
    })
  });

export const {
  useGetUserPresenceActivityQuery,
  usePostHeartbeatQuery,
  usePostLogoutMutation,
  useSetUserPrecenseStatusMutation,
  useDeleteUserPrecenseStatusMutation
} = userPresenceService;

export default userPresenceService;
