import RolesEnum from "common/enums/RolesEnum";
import {
  REGISTER_MEMBER,
  NPS_LIST,
  REQUEST_CONSENTS,
  MEMBER_CHART,
  ALL_TEAMS,
  TEAM_DETAILS,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  COMMON_ROUTES
} from "../RouteComponents";
import {
  SIDEBAR_DASHBOARD,
  SIDEBAR_MEMBER_MANAGEMENT,
  SIDEBAR_REPORTS
} from "../RouteSidebar";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import Routes from "../Routes";

const NPS_MANAGER = {
  routes: [
    ...COMMON_ROUTES,
    { path: Routes.ROOT, components: REGISTER_MEMBER, NPS_LIST },

    { path: Routes.REQUEST_CONSENTS, components: REQUEST_CONSENTS },
    { path: Routes.REGISTER_MEMBER, components: REGISTER_MEMBER },
    ...MEMBER_CHART(RolesEnum.NPS_MANAGER),
    ALL_TEAMS({
      types: [TeamTypeEnum.NPS]
    }),
    TEAM_DETAILS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    SIDEBAR_MEMBER_MANAGEMENT(false),
    SIDEBAR_REPORTS
  ]
};

export default NPS_MANAGER;
