import { Typography } from "@mui/material";
import { QuoteContainer, QuoteTypography } from "../../GeneralFields/Quote";

export const MedicationsReconciliationFAQ = () => {
  return (
    <>
      <Typography variant="h6">Introduction</Typography>
      <QuoteContainer
        style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
      >
        <QuoteTypography variant="body1">
          {`In order to best support you, we need to go over the medications
          you're currently taking to ensure our records are up-to-date.`}
        </QuoteTypography>
      </QuoteContainer>
      <br />
      <Typography variant="h6">Review Medications in Athena</Typography>
      <QuoteContainer
        style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
      >
        <QuoteTypography variant="body1">
          {`Let's begin with reviewing the medications we have on file for you.
          I'll read them off one by one, and you can let me know if you're still
          taking them, if the dose has changed, or if you've stopped using them.`}
        </QuoteTypography>
      </QuoteContainer>
      <br />
      <Typography variant="h6">
        Supplements and Over-the-Counter Medications
      </Typography>
      <QuoteContainer
        style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
      >
        <QuoteTypography variant="body1">
          {`Are you taking any other supplements, vitamins, or over-the-counter
          medications?`}
        </QuoteTypography>
      </QuoteContainer>
      <br />
      <Typography variant="h6">Side Effects & Missed Doses</Typography>
      <QuoteContainer
        style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
      >
        <QuoteTypography variant="body1">
          {`Are there any medications you've had difficulty taking as prescribed?
          For example, have you missed doses or experienced any side effects?`}
        </QuoteTypography>
      </QuoteContainer>
    </>
  );
};
