import { CheckCircle, PendingActions } from "@mui/icons-material";
import { success, warning } from "common/styling/colors";
import { Column } from "../../../styling/StyleComponents";
import { grey } from "@mui/material/colors";
import { Box, Card, styled, Typography } from "@mui/material";

import { SvgIconComponent } from "@mui/icons-material";

const Row = styled(Box)`
  display: flex;
  gap: 10px;
`;

const Circle = styled(Box)`
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CARDS = ({ todoTaskCount, completedTaskCount }) => [
  {
    backgroundColor: warning[100],
    iconColor: warning[600],
    Icon: PendingActions,
    title: "Tasks Remaining Today",
    value: todoTaskCount
  },
  {
    backgroundColor: success[100],
    iconColor: success[700],
    Icon: CheckCircle,
    title: "Tasks Completed Today",
    value: completedTaskCount
  }
  /*{
    backgroundColor: grey[400],
    title: "Average minutes per task",
    value: "15"
  }*/
];

interface MaterialIconProps {
  backgroundColor: string;
  iconColor?: string;
  Icon?: SvgIconComponent;
}

const MaterialIcon = ({
  backgroundColor,
  iconColor,
  Icon
}: MaterialIconProps) => {
  return (
    <Circle sx={{ backgroundColor }}>
      {Icon && <Icon htmlColor={iconColor} fontSize="small" />}
    </Circle>
  );
};

interface TaskCardProps extends MaterialIconProps {
  title: string;
  value: string;
}

const TaskCard = ({ title, value, ...iconProps }: TaskCardProps) => {
  return (
    <Card sx={{ padding: 2, width: 250 }}>
      <Row alignItems={"center"}>
        <MaterialIcon {...iconProps} />
        <Typography variant="body1" color="secondary">
          {title}
        </Typography>
      </Row>

      <br />
      <Typography variant="h1" color="secondary">
        {value}
      </Typography>
    </Card>
  );
};

interface IProps {
  todoTaskCount: number;
  completedTaskCount: number;
}

const ArcOfDaySidebar = ({ todoTaskCount, completedTaskCount }: IProps) => {
  return (
    <Column>
      {CARDS({
        todoTaskCount,
        completedTaskCount
      }).map((card) => {
        return <TaskCard {...card} />;
      })}
    </Column>
  );
};

export default ArcOfDaySidebar;
