import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import cloneDeep from "lodash.clonedeep";
import c3 from "c3";
import "c3/c3.css";
import "react-loading-skeleton/dist/skeleton.css";
import useGetMemberDataSummary from "common/hooks/useGetMemberDataSummary";
import {
  AvgTextContainer,
  ChartIconContainer,
  ChartIconSVG,
  ChartSubHeaderText,
  ThreeLinesContainer,
  ThreeLinesSVG,
  transformWeightScaleDataToColumns
} from "../../../helpers/components/Charts/ChartHelpers";
import ErrorComponent from "../../../components/ErrorComponent";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import { gray } from "common/styling/colors";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { Button, Typography } from "@mui/material";
import { canDownloadChart } from "common/enums/RolesEnum";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { FileDownloadOutlined } from "@mui/icons-material";
import { getFormattedDateTime } from "../../../helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import WeightScaleTable from "./WeightScaleTable";
import { DateTime } from "luxon";
import ReportEnum from "../../../enums/ReportEnum";
import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import { Column } from "../../../styling/StyleComponents";
import ReadingChartPropsType from "common/types/common/ReadingChartPropsType";
import ChartControls from "./ChartControls";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";

const WeightScaleChartContainer = styled.div`
  margin: 12px 0;
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: #ffffff;
  padding: 18px 8px;
  height: 100%;
  transition: all 0.66s ease-out;

  #weightScaleChart {
    height: 450px;
    margin-left: 18px;
  }

  #weightScaleChart svg {
    width: calc(100%);
  }

  #weightScaleChart .c3-circle {
    r: 3;
  }

  #weightScaleChart .c3-circle._expanded_ {
    visibility: visible;
    r: 4;
  }

  #weightScaleChart .c3-legend-background {
    stroke: none;
  }

  // hide lines

  .c3-shapes-WEIGHT.c3-lines.c3-lines-WEIGHT > path {
    stroke: none !important;
  }

  // hide dots

  .c3-circles-WEIGHT-SMA-7-DAY {
    display: none !important;
  }

  .c3-line-WEIGHT-SMA-7-DAY {
    stroke-width: 4px;
  }

  #weightScaleChart .c3-legend-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgba(24, 24, 25, 0.42);
  }

  .tick line {
    visibility: hidden;
  }
`;

const WeightScaleChart = ({
  patient,
  deviceIds,
  handleDataChange,
  showAverages,
  dateFilter,
  defaultDays = 30
}: ReadingChartPropsType) => {
  const navigate = useNavigate();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [showTable, setShowTable] = useState<boolean>(true);

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.CHART_SHOW_TABLE + "_weight_scale").then(
      (item) => {
        if (item) setShowTable(item === "true");
      }
    );
  }, []);

  const toggleTable = () => {
    setShowTable(!showTable);

    StorageHelper.setItem(
      StorageEnum.CHART_SHOW_TABLE + "_weight_scale",
      "" + !showTable
    );
  };

  const { weightScale } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.WEIGHT_SCALE]
  });

  const serialNumbers = useMemo(() => {
    if (!weightScale?.data) return;

    const array = weightScale?.data.map((reading) => reading.device_id);
    return array?.filter((item, index) => array.indexOf(item) === index);
  }, [weightScale?.data]);

  const patientTimezone = useMemo(() => patient?.patient?.timezone, [patient]);

  const data = useMemo(() => {
    if (weightScale?.data === undefined) return;
    const dataCopy = cloneDeep(weightScale?.data);
    return transformWeightScaleDataToColumns(
      deviceIds?.length > 0
        ? dataCopy.filter((item) => deviceIds.includes(item.device_id))
        : dataCopy,
      patientTimezone
    );
  }, [weightScale?.data, patientTimezone, deviceIds]);

  useEffect(() => {
    if (data && !showTable) {
      const { columns, xAxisParams, tickArr } = data;

      let finalColumns = columns;
      const [dateArr, weightArr] = columns;

      // remove averages
      if (!showAverages) {
        finalColumns = finalColumns.slice(0, 2);
      }

      // possible future enhancement could be a dropdown to toggle between different modes, like line, spline, area, bar, etc.
      let dataTypes: any = {
        WEIGHT: "spline"
      };

      if (showAverages) {
        dataTypes = {
          WEIGHT: "spline",
          "WEIGHT SMA 7 DAY": "line"
        };
      }

      if (dateArr && weightArr) {
        if (weightScale?.data?.length === 0) {
          // if no data found, display "No Data Found" chart
          c3.generate({
            bindto: "#weightScaleChart",
            data: {
              x: "date",
              xFormat: "%m/%d/%Y %H:%M",
              // @ts-ignore
              columns: finalColumns
            },
            axis: {
              x: {
                type: "category",
                // @ts-ignore
                label: {
                  text: "No readings taken in selected time range"
                },
                tick: {
                  outer: false
                },
                ...xAxisParams
              },
              y: {
                tick: {
                  outer: false
                },
                label: "lbs"
              }
            },
            padding: {
              bottom: 20 //adjust chart padding bottom
            },
            legend: {
              hide: ["WEIGHT"]
            }
          });
        } else {
          c3.generate({
            bindto: "#weightScaleChart",
            data: {
              x: "date",
              xFormat: "%m/%d/%Y, %H:%M:%S %p",
              // @ts-ignore
              columns: finalColumns,
              types: dataTypes
            },
            axis: {
              x: {
                type: "timeseries",
                tick: {
                  outer: false,
                  format: "%b %d",
                  values: tickArr,
                  fit: true,
                  rotate: 0
                },
                ...xAxisParams
              },
              y: {
                tick: {
                  outer: false
                },
                label: "lbs"
              }
            },
            padding: {
              bottom: 20 //adjust chart padding bottom
            },
            legend: {
              hide: ["WEIGHT"]
            },
            tooltip: {
              contents: function (
                d,
                defaultTitleFormat,
                defaultValueFormat,
                color
              ) {
                let $$: any = this,
                  config = $$.config,
                  nameFormat =
                    config.tooltip_format_name ||
                    function (name: any) {
                      return name;
                    },
                  valueFormat =
                    config.tooltip_format_value || defaultValueFormat,
                  text,
                  i,
                  title,
                  value,
                  name,
                  bgcolor;
                bgcolor = $$.levelColor;

                for (i = 0; i < d.length; i++) {
                  if (!(d[i] && (d[i].value || d[i].value === 0))) {
                    continue;
                  }

                  if (!text) {
                    let titleValue = getFormattedDateTime(
                      // @ts-ignore
                      d[i].x,
                      patientTimezone
                    );
                    title = titleValue;
                    text =
                      "<table class='" +
                      $$.CLASS.tooltip +
                      "'>" +
                      (title
                        ? "<tr><th colspan='2'>" + title + "</th></tr>"
                        : "");
                  }

                  // @ts-ignore
                  name = nameFormat(d[i].name);
                  value = valueFormat(
                    d[i].value,
                    // @ts-ignore
                    d[i].name,
                    d[i].id,
                    d[i].index
                  );
                  bgcolor = $$.levelColor
                    ? $$.levelColor(d[i].value)
                    : color(d[i].id);
                  text +=
                    "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                  text +=
                    "<td class='name'><span style='background-color:" +
                    bgcolor +
                    "'></span>" +
                    name +
                    "</td>";
                  text += "<td class='value'>" + value + "</td>";

                  text += "</tr>";
                }

                text += "</tr>";

                return text + "</table>";
              }
            }
          });
        }
      }
    }
  }, [data, showTable, showAverages]);

  const lastUpdatedString = weightScale?.fulfilledTimeStamp
    ? "Last Updated: " +
      DateTime.fromMillis(weightScale?.fulfilledTimeStamp).toFormat(
        "MM/dd/yyyy hh:mm a ZZZZ"
      )
    : undefined;
  return (
    <Column gap="0px">
      <ChartControls
        readingType={DeviceTrendParam.WEIGHT_SCALE}
        handleDataChange={handleDataChange}
        defaultDays={defaultDays}
        member_id={patient?.patient?.patient_id}
        serialNumbers={serialNumbers}
      >
        <AvgTextContainer $visible={showAverages} margin="8px 0">
          <>
            {data?.weightAvg && (
              <ChartSubHeaderText $color={"#1f77b4"}>
                Average Weight: {data?.weightAvg}
              </ChartSubHeaderText>
            )}
          </>
        </AvgTextContainer>
        <br />
        <Flexbox flexDirection="row" ml={"25%"} mr={"10px"}>
          <ThreeLinesContainer
            $showTable={showTable}
            aria-label="tableIcon"
            onClick={toggleTable}
          >
            <ThreeLinesSVG $showTable={showTable} />
          </ThreeLinesContainer>
          <ChartIconContainer
            $showTable={showTable}
            aria-label="gridIcon"
            onClick={toggleTable}
          >
            <ChartIconSVG $showTable={showTable} />
          </ChartIconContainer>
        </Flexbox>
      </ChartControls>
      <WeightScaleChartContainer>
        {weightScale?.fetching && <LoadingFallback delay={50} />}
        {weightScale?.data ? (
          showTable ? (
            <WeightScaleTable
              weightScaleData={
                deviceIds?.length > 0
                  ? weightScale.data.filter((item) =>
                      deviceIds.includes(item.device_id)
                    )
                  : weightScale.data
              }
              patientTimezone={patientTimezone}
            />
          ) : (
            <div id="weightScaleChart" />
          )
        ) : (
          !weightScale?.fetching &&
          weightScale?.error && (
            <div style={{ margin: "0 36px" }}>
              <ErrorComponent error={weightScale?.error} />
            </div>
          )
        )}
      </WeightScaleChartContainer>
      <Flexbox justifyContent="space-between">
        <Typography variant="body1">{lastUpdatedString}</Typography>
        {canDownloadChart(currentRole) && (
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/reports/${ReportEnum.MEMBER_CHART_SHARE}/memberId/${patient.patient.patient_id}`
              )
            }
            startIcon={<FileDownloadOutlined />}
          >
            Download
          </Button>
        )}
      </Flexbox>
    </Column>
  );
};

export default WeightScaleChart;
