import { Duration, DateTime } from "luxon";
import UserTypeBase from "../types/common/UserTypeBase";
import AddressType from "../types/AddressType";

// https://www.bls.gov/respondents/mwr/electronic-data-interchange/appendix-d-usps-state-abbreviations-and-fips-codes.htm
import stateDropdownOptions from "../config/states.json";
import DeviceTypeEnum from "../enums/DeviceTypeEnum";
import DeviceTrendParam from "../enums/DeviceTrendParamEnum";
import EnvVars from "../config/EnvVars";
import LocalizedStrings from "../localizations/LocalizedStrings";
import VisitDispositionEnum from "../enums/Calendaring/Visits/VisitDispositionEnum";
import VisitStatusEnum from "../enums/Calendaring/Visits/VisitStatusEnum";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import ErrorType from "../types/ErrorType";
import GetVisitResponseType from "../types/Visits/GetVisitResponseType";

const EMAIL_VALIDATION_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const ID_VALIDATION_HEXADECIMAL_REGEXP = /^[A-Fa-f0-9]+$/i;

const upperOrLowerCaseWordRegex = /(\b[A-Z]+$\b|\b[a-z]+$\b)/;

/**
 * this function takes in any parameter and can tell if it's "falsy" or not.
 * falsy means it's either false, 0, null, undefined, NaN, or an empty string/array/object
 * see the test cases at the bottom for a clearer picture
 *  */

function isFalsy(item) {
  try {
    if (
      !item || // handles most, like false, 0, null, etc
      (typeof item == "object" &&
        Object.keys(item).length == 0 && // for empty objects, like {}, []
        !(typeof item.addEventListener == "function")) // omit webpage elements
    ) {
      return true;
    }
  } catch (err) {
    return true;
  }

  return false;
}

function isTruthy(item) {
  return !isFalsy(item);
}

/**
 * Given an all UPPERCASE or all lowercase string input, capitalizes the first letter of words and removes extra whitespace
 * @param  {String} str Input
 * @param  {Boolean} changeCasing should we change the casing of the words?
 * @return {String} the string with extra whitespace removed and the first character of each word capitalized
 */

function formatName(
  str: string,
  changeCasing: boolean = true,
  // if the entire word is "tn" or "ooo", capitalize it. This is for ENG-6243
  capitalizeAllWordsArray = ["tn", "ooo"]
) {
  if (!str) {
    return str;
  }
  let removedExtraSpacesCommasUnderscores = str
    ?.replace(/\s+|_+|,+/g, " ")
    .trim();

  if (
    upperOrLowerCaseWordRegex.test(removedExtraSpacesCommasUnderscores) &&
    changeCasing
  ) {
    let titleCasedWords = removedExtraSpacesCommasUnderscores
      ?.toLowerCase()
      ?.replaceAll(/\w+/g, (match) => {
        if (capitalizeAllWordsArray.includes(match)) {
          return match.toUpperCase();
        }
        return match;
      })
      ?.replaceAll(/(^\w{1})|(\s+\w{1})/g, (match) => match.toUpperCase());

    return titleCasedWords;
  } else {
    return removedExtraSpacesCommasUnderscores;
  }
}

function getNameOrUsername(
  user: Partial<UserTypeBase> | undefined,
  displayLastNameFirst = true,
  showMiddleName = true,
  displayLastNameFirstLetter = false
) {
  if (user === undefined) return "";
  const { first, middle, last, username, email } = user;
  let name = "";
  if (!first && !last) {
    return username ?? email;
  } else {
    let lastName = formatName(last);
    const firstName = formatName(first);
    const middleName = formatName(middle);

    if (displayLastNameFirstLetter && lastName) {
      lastName = lastName.substring(0, 1) + ".";
    }

    if (displayLastNameFirst) {
      if (last) {
        name += `${lastName}, `;
      }
      if (first) {
        name += `${firstName}`;
      }
      if (middle && showMiddleName) {
        name += ` ${middleName}`;
      }
    } else {
      if (first) {
        name += `${firstName}`;
      }
      if (middle && showMiddleName) {
        name += ` ${middleName}`;
      }
      if (last) {
        name += ` ${lastName}`;
      }
    }

    return name;
  }
}

function getUsernameFromEmail(email: string | undefined) {
  if (!email) return null;
  // https://stackoverflow.com/a/7266635
  let usernameMatch = email.match(/^([^@]*)@/);
  let username = usernameMatch ? usernameMatch[1] : null;
  return username;
}

interface ErrorProps {
  showErrorResponseMessage?: boolean;
  hideErrorCode?: boolean;
}

function getErrorMessage(
  error: ErrorType,
  { showErrorResponseMessage, hideErrorCode }: ErrorProps = {
    showErrorResponseMessage: true,
    hideErrorCode: true
  }
) {
  const errorCode = "status" in error && error?.status;

  let errorMessage = undefined;

  const errorResponseMessage =
    // @ts-ignore
    error?.response?.data?.message ?? error?.message;

  if (
    !isFalsy(errorResponseMessage) &&
    !isFalsy(showErrorResponseMessage) &&
    // we want to be more specific about the error message if it is "Internal server error" and instead
    // show one of our defaults from common/localizations/languages/en.ts
    errorResponseMessage !== "Internal server error" &&
    EnvVars.REACT_APP_PRODUCT === "REMOTEIQ"
  ) {
    errorMessage = errorResponseMessage;
  }

  if (isFalsy(errorMessage)) {
    if (errorCode !== undefined) {
      errorMessage = LocalizedStrings.error[errorCode];
    } else {
      errorMessage = LocalizedStrings.error.default;
    }
  }

  if (isFalsy(hideErrorCode) && errorCode) {
    errorMessage +=
      " " +
      LocalizedStrings.error.code.replace(
        "{{CODE}}",
        errorCode ? errorCode.toString() : ""
      );
  }
  return errorMessage;
}

/**
 * Formats phone number to XXXXXXXXXX format
 * @param  {String} phoneNumberString Masked phone number as a string in (XXX)-XXX-XXXX format
 * @return {String} Returns a String representing the phone number in XXXXXXXXXX format or undefined
 */

function unmaskPhoneNumber(phoneNumber: string) {
  return (
    phoneNumber
      ?.replace(/[^a-zA-Z0-9]/g, "")
      .trim()
      // remove country code
      .slice(-10)
  );
}

/**
 * Formats phone number to (XXX)-XXX-XXXX format
 * @param  {String} phoneNumberString Phone number as a string
 * @return {String}  Returns a String representing the phone number in (XXX)-XXX-XXXX format or undefined
 * https://stackoverflow.com/a/8358141
 */

// https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
function maskPhoneNumber(phoneNumber: string) {
  // If it has country code, remove
  if (phoneNumber === null || phoneNumber === undefined) return "";
  if (
    phoneNumber.length > 10 &&
    (phoneNumber.charAt(0) === "1" || phoneNumber.charAt(0) === "+")
  ) {
    phoneNumber = phoneNumber.slice(-10);
  }

  const input = phoneNumber.replace(/\D/g, ""); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `(${areaCode}`;
  }
}

const FIFTEEN_SECONDS = 15;
const THIRTY_SECONDS = 30;
const ONE_MINUTE = 60;
const ONE_HOUR = 3600;
const ONE_DAY = 86400;
const ONE_MONTH = 2620800;
const ONE_YEAR = 31449600;

const getRelativeDateTimeString = (dateTime: DateTime) => {
  const difference = DateTime.now().toSeconds() - dateTime.toSeconds();

  if (difference < ONE_MINUTE) {
    return `${Math.floor(difference)}s`;
  } else if (difference < ONE_HOUR) {
    return `${Math.floor(difference / ONE_MINUTE)}m`;
  } else if (difference < ONE_DAY) {
    return `${Math.floor(difference / ONE_HOUR)}h`;
  } else if (difference < ONE_MONTH) {
    return `${Math.floor(difference / ONE_DAY)}d`;
  } else if (difference < ONE_YEAR) {
    return `${Math.floor(difference / ONE_MONTH)}mo`;
  } else {
    return `${Math.floor(difference / ONE_YEAR)}y`;
  }
};

const getAddress = (
  patient: AddressType,
  fields = ["street1", "street2", "city", "state", "postal_code", "country"]
) => {
  if (patient === undefined) return undefined;

  const filteredArray = fields
    .filter((item) => !isFalsy(patient[item]))
    .map((item) => patient[item]);

  return filteredArray.join(", ");
};

function areAddressesEqual(address1: AddressType, address2: AddressType) {
  if (!address1 || !address2) return false;

  return (
    // localeCompare doesn't handle nulls
    (address1?.street1?.localeCompare(address2?.street1, "en", {
      sensitivity: "base"
    }) === 0 ||
      address1?.street1 === address2?.street1) &&
    (address1?.street2?.localeCompare(address2?.street2, "en", {
      sensitivity: "base"
    }) === 0 ||
      address1?.street2 === address2?.street2) &&
    (address1?.city?.localeCompare(address2?.city, "en", {
      sensitivity: "base"
    }) === 0 ||
      address1?.city === address2?.city) &&
    (address1?.state?.localeCompare(address2?.state, "en", {
      sensitivity: "base"
    }) === 0 ||
      address1?.state === address2?.state) &&
    (address1?.postal_code?.localeCompare(address2?.postal_code, "en", {
      sensitivity: "base"
    }) === 0 ||
      address1?.postal_code === address2?.postal_code)
  );
}

const PASSWORD_VALIDATION_REGEXP =
  /^.*(?=.{12,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const PHONE_VALIDATION_REGEXP =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

/**
 * This is a regex that matches any number or parenthesis. This is used to validate input fields.
 */
const PHONE_NUMBER_REGEXP = /[\d()]+/;

const validateFieldLength = (min: number, max: number, value: string) => {
  return value?.length >= min && value?.length <= max;
};

function isValidUSPostalCode(postal_code: string) {
  return /^\d{5}(-\d{4})?$/.test(postal_code);
}

function isStateAbbreviation(state: string) {
  return (
    state.length == 2 &&
    stateDropdownOptions.findIndex((item) => item.value === state) > -1
  );
}

function getStates(showFullName: boolean = false) {
  return stateDropdownOptions.map((item) => {
    return {
      label: item[showFullName ? "fullName" : "abbreviation"],
      value: item.value
    };
  });
}

/**
 * Determines if a string is included in another string, ignoring diacritics
 * @param value The string to search for
 * @param target The string to search in
 * @returns {boolean} True if the value is included in the target, false otherwise
 * @see https://stackoverflow.com/a/69623589
 */

function localeIncludes(value: string, target: string) {
  if (value === "") return true;
  if (!value || !target?.length) return false;
  // coerce to string if number
  value = "" + value;
  if (value.length > target.length) return false;

  // remove diacritics
  let ascii = (s) =>
    s
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  return ascii(target).includes(ascii(value));
}

// Needed for refreshing the site.
// https://copilotiq.atlassian.net/browse/ENG-3471
export const DeviceType_url_encode = (deviceType: DeviceTypeEnum) => {
  return deviceType.toLowerCase().replaceAll(" ", "_");
};

export const DeviceType_url_decode = (deviceTypeEncoded: string) => {
  const encodedArray = deviceTypeEncoded.split("_");

  let deviceType = "";
  encodedArray.forEach((item) => {
    if (item === "" || item === undefined) return;
    const firstLetter = item.charAt(0).toUpperCase();
    const remainingLetters = item.substring(1);

    deviceType = deviceType + " " + firstLetter + remainingLetters;
  });

  return deviceType.trim() as DeviceTypeEnum;
};

function formatMTD(minutes: number) {
  if (!minutes) return "0 minutes";
  return `${Duration.fromObject({ minutes }).toFormat("m")} minutes`;
}

function sanitizeId(id: string) {
  return id?.replace(/[^\w\d]/g, "")?.trim();
}

function checkIdValid(id?: string) {
  if (id === undefined) return false;
  const sanitizedId = sanitizeId(id);
  return (
    ID_VALIDATION_HEXADECIMAL_REGEXP.test(sanitizedId) &&
    sanitizedId.length === 24
  );
}

function mapDeviceTrendToDeviceType(type: DeviceTrendParam) {
  switch (type) {
    case DeviceTrendParam.GLUCOSE:
      return DeviceTypeEnum.GLUCOSE_CATEGORY;
    case DeviceTrendParam.BLOOD_PRESSURE:
      return DeviceTypeEnum.BLOOD_PRESSURE;
    case DeviceTrendParam.OXIMETER:
      return DeviceTypeEnum.OXIMETER;
    case DeviceTrendParam.WEIGHT_SCALE:
      return DeviceTypeEnum.WEIGHT_SCALE;
  }
}

function mapDeviceTypetoDeviceTrend(type: DeviceTypeEnum) {
  switch (type) {
    case DeviceTypeEnum.GLUCOSE_CATEGORY:
      return DeviceTrendParam.GLUCOSE;
    case DeviceTypeEnum.BLOOD_PRESSURE:
      return DeviceTrendParam.BLOOD_PRESSURE;
    case DeviceTypeEnum.OXIMETER:
      return DeviceTrendParam.OXIMETER;
    case DeviceTypeEnum.WEIGHT_SCALE:
      return DeviceTrendParam.WEIGHT_SCALE;
  }
}

function getHoursMinutesFromMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return { hours, minutes: remainingMinutes };
}

function getDispositionIndexInVisits(
  visits: GetVisitResponseType[],
  disposition: VisitDispositionEnum
) {
  return visits?.findIndex((visit) => visit?.disposition === disposition);
}

/**
 * This function takes an array of visits and finds the status or disposition
 * @param visits array of visits
 * @param {string} startDateISOString the start date as an iso string. We need to use this because the visit is created with the original start date and not updated when the calendar event changes
 * @param {Boolean} considerStatus whether we should consider visit status or only look at disposition. For the Upcoming and Past Appointments tables, we only want disposition. For the "Schedule Today" view, we also want to consider status.
 * @returns {string | undefined} returns a string if a status or disposition is found
 */
function getStatusOrDispositionFromMultipleVisits(
  visits: GetVisitResponseType[],
  startDateISOString: string,
  considerStatus = true
) {
  // this is in the format "YYYY-MM-DD"
  const today = DateTime.now().toISODate().slice(0, 10);
  const indexOfFirstInProgressStatusVisitToday = visits?.findIndex((visit) => {
    const eventStart = DateTime.fromISO(startDateISOString)
      // we need to set the timezone because the event start is in UTC
      // there is an edge case where the 4-5 pm PT slot ends up starting on the next day at 12 am UTC
      .setZone(visit?.staff?.timezone)
      ?.toISODate()
      ?.slice(0, 10);
    return (
      visit?.status === VisitStatusEnum.IN_PROGRESS &&
      // this is in the format "YYYY-MM-DD"
      eventStart ===
        // We have this check because we only want to consider the status of the visit if it's scheduled for today
        // this handles the edge case where a visit gets started on a different day,
        // the appointment gets rescheduled, and the visit is still in progress
        today
    );
  });

  const hasInProgressStatus = indexOfFirstInProgressStatusVisitToday > -1;
  const hasCompletedStatus =
    visits?.findIndex((visit) => visit?.status === VisitStatusEnum.COMPLETED) >
    -1;
  const hasCompletedDisposition =
    getDispositionIndexInVisits(visits, VisitDispositionEnum.COMPLETED) > -1;
  const hasNoShowDisposition =
    getDispositionIndexInVisits(visits, VisitDispositionEnum.NO_SHOW) > -1;
  const hasTnOooDisposition =
    getDispositionIndexInVisits(visits, VisitDispositionEnum.TN_OOO) > -1;
  const hasNoCallDisposition =
    getDispositionIndexInVisits(visits, VisitDispositionEnum.NO_CALL) > -1;

  let outcome;

  if (
    considerStatus &&
    hasInProgressStatus &&
    !hasCompletedStatus &&
    !(
      hasCompletedDisposition ||
      hasNoShowDisposition ||
      hasTnOooDisposition ||
      hasNoCallDisposition
    )
  ) {
    outcome = visits?.[indexOfFirstInProgressStatusVisitToday]?.status;
  } else if (
    considerStatus &&
    hasCompletedStatus &&
    !(
      hasCompletedDisposition ||
      hasNoShowDisposition ||
      hasTnOooDisposition ||
      hasNoCallDisposition
    )
  ) {
    outcome =
      visits?.[
        visits?.findIndex(
          (visit) => visit?.status === VisitStatusEnum.COMPLETED
        )
      ]?.status;
  } else if (
    hasCompletedDisposition &&
    (hasNoShowDisposition || hasTnOooDisposition || hasNoCallDisposition)
  ) {
    const sortedVisits = [...visits]?.sort((a, b) => {
      // sort by modified_date with the most recent value first

      const dateA = a?.modified_date ? DateTime.fromISO(a?.modified_date) : 0;
      const dateB = b?.modified_date ? DateTime.fromISO(b?.modified_date) : 0;

      if (dateA === dateB) return 0;
      return dateA < dateB ? 1 : -1;
    });

    // use the most recent one
    const mostRecentlyUpdatedDisposition = sortedVisits?.find(
      (visit) =>
        visit?.disposition === VisitDispositionEnum.COMPLETED ||
        visit?.disposition === VisitDispositionEnum.NO_SHOW ||
        visit?.disposition === VisitDispositionEnum.TN_OOO ||
        visit?.disposition === VisitDispositionEnum.NO_CALL
    );

    outcome = mostRecentlyUpdatedDisposition?.disposition;
  } else if (hasCompletedDisposition) {
    outcome =
      visits?.[
        getDispositionIndexInVisits(visits, VisitDispositionEnum.COMPLETED)
      ]?.disposition;
  } else if (hasNoShowDisposition) {
    outcome =
      visits?.[
        getDispositionIndexInVisits(visits, VisitDispositionEnum.NO_SHOW)
      ]?.disposition;
  } else if (hasTnOooDisposition) {
    outcome =
      visits?.[getDispositionIndexInVisits(visits, VisitDispositionEnum.TN_OOO)]
        ?.disposition;
  } else if (hasNoCallDisposition) {
    outcome =
      visits?.[
        getDispositionIndexInVisits(visits, VisitDispositionEnum.NO_CALL)
      ]?.disposition;
  }

  return outcome;
}

const prettyStatusString = (status: string) => {
  if (status === undefined) return undefined;

  // Standardize statuses for display to end-users
  if (status === MemberStatusEnum.REEVALUATING_PATIENT) return "Reevaluating";
  if (status === MemberStatusEnum.AUTO_CANCELED) return "Canceled";

  const splittedString = status.split("_");
  const splittedStringLowerCase = splittedString.map((item) => {
    return item.charAt(0) + item.slice(1).toLowerCase();
  });

  return splittedStringLowerCase.join(" ");
};

const prettyStatusGraphQLString = (status: string) => {
  // Replaces _ for a space and capitalizes each word.
  return status.replace(/([A-Z])/g, " $1").trim();
};

// https://stackoverflow.com/a/57593674
// https://www.ascii.cl/htmlcodes.htm
/*
 * Remove all characters that are not in the ASCII range of 0-127 or in the range of 160-255
 */
function cleanString(input) {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    if (
      input.charCodeAt(i) <= 127 ||
      (input.charCodeAt(i) >= 160 && input.charCodeAt(i) <= 255)
    ) {
      output += input.charAt(i);
    }
  }
  return output;
}
// https://stackoverflow.com/a/69220565
/*
 * Replace emojis with the string ":emoji:"
 */
function emojiParse(input) {
  return input?.replace(
    /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu,
    ":emoji:"
  );
}

// https://stackoverflow.com/a/37511463
/*
 * Remove diacritics from a string. use this function to sanitize strings with emojis and special characters
 */
function removeDiacritics(str) {
  return str?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "");
}

function sanitizeUserTextInput(input) {
  return cleanString(removeDiacritics(emojiParse(input)));
}

const trimText = (text: string) => {
  if (text === undefined) return undefined;
  if (text === null) return null;
  return (
    text
      // remove comma and any extra whitespace after comma
      ?.replaceAll(/,+\s+/g, " ")
      // replace any extra whitespace
      ?.replaceAll(/\s+/g, " ")
      // remove special characters
      ?.replaceAll(/[^a-zA-Z0-9-\s]/g, "")
      .trim()
  );
};

export {
  EMAIL_VALIDATION_REGEXP,
  PASSWORD_VALIDATION_REGEXP,
  PHONE_VALIDATION_REGEXP,
  PHONE_NUMBER_REGEXP,
  FIFTEEN_SECONDS,
  THIRTY_SECONDS,
  ONE_MINUTE,
  ONE_HOUR,
  ONE_DAY,
  formatName,
  getNameOrUsername,
  getUsernameFromEmail,
  getErrorMessage,
  maskPhoneNumber,
  unmaskPhoneNumber,
  getRelativeDateTimeString,
  getAddress,
  areAddressesEqual,
  getStates,
  validateFieldLength,
  isValidUSPostalCode,
  isStateAbbreviation,
  localeIncludes,
  isFalsy,
  isTruthy,
  sanitizeId,
  formatMTD,
  checkIdValid,
  mapDeviceTrendToDeviceType,
  mapDeviceTypetoDeviceTrend,
  getHoursMinutesFromMinutes,
  getDispositionIndexInVisits,
  getStatusOrDispositionFromMultipleVisits,
  prettyStatusString,
  prettyStatusGraphQLString,
  sanitizeUserTextInput,
  trimText
};
