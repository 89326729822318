import {
  Box,
  Button,
  MenuItem,
  styled,
  TextField,
  Typography
} from "@mui/material";
import replace from "lodash.replace";

import { Modal } from "../../../components/Modal";
import { StatusBadge } from "../../../styling/StyleComponents";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../../styling/StyleModal";
import { isFalsy, prettyStatusString } from "common/helpers/helpers";
import { useEffect, useState } from "react";
import { useCreateVisitMutation } from "common/services/VisitsService";
import { LoadingButton } from "@mui/lab";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useNavigate } from "react-router-dom";
import { AttendeeType } from "common/types/Calendaring/AttendeeType";
import Routes from "../../../routes/Routes";

const Row = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

interface IProps {
  onRequestClose: () => void;
  attendee: AttendeeType;
  visitsRequest: any;
  staff_id: string;
}

// TODO - remove; we will likely keep using Appointment care flow type for Device Setup; eventually the system
// will determine what kind of visit type will be presented
const VisitTypeModal = ({
  onRequestClose,
  attendee,
  visitsRequest,
  staff_id
}: IProps) => {
  const navigate = useNavigate();

  const [selectedVisitType, setSelectedVisitType] =
    useState<VisitMotivationTypesEnum>(null);

  const [createVisitMutation, { isLoading, isSuccess, data }] =
    useCreateVisitMutation();

  const handleStart = () => {
    createVisitMutation({
      staff_id,
      patient_id: attendee.attendee_id,
      body: {
        ...visitsRequest,
        staff_id,
        motivation_reason: selectedVisitType
      },
      with_care_flow: true
    });
  };

  useEffect(() => {
    if (isSuccess && data?.care_flow) {
      const careFlowLink = replace(Routes.VISITS, ":visitId", data?.visit_id);

      navigate(careFlowLink);

      onRequestClose();
    }
  }, [isSuccess, data]);

  return (
    <Modal
      contentLabel="VisitTypeModal"
      isOpen={attendee !== null}
      onRequestClose={onRequestClose}
      modalHeight="400px"
      modalWidth="600px"
    >
      <ModalHeader onRequestClose={onRequestClose}>Visit Type</ModalHeader>
      <ModalBody style={{ alignItems: "center" }}>
        <Row>
          <Typography variant="h6">This member is still</Typography>
          <StatusBadge status={MemberStatusEnum.PENDING} />
        </Row>

        <br />
        <Typography variant="h6">
          Would you like to start a Device Setup or a normal Nurse Visit?
        </Typography>

        <br />

        <TextField
          label={"Visit Type"}
          select
          sx={{ width: 200 }}
          value={selectedVisitType ?? ""}
          onChange={(event) => {
            setSelectedVisitType(
              event.target.value as VisitMotivationTypesEnum
            );
          }}
        >
          {[
            VisitMotivationTypesEnum.DEVICE_SETUP,
            VisitMotivationTypesEnum.APPOINTMENT
          ].map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {prettyStatusString(item)}
              </MenuItem>
            );
          })}
        </TextField>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            onClick={handleStart}
            disabled={isFalsy(selectedVisitType)}
            loading={isLoading}
          >
            Start
          </LoadingButton>
          <Button variant="outlined" onClick={onRequestClose}>
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};

export default VisitTypeModal;
