import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CarePlanConditionsEnum } from "common/types/Visits/CarePlanType";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import { ProcessedGoalsType } from "common/types/GoalType";
import { SlimReadingsTable } from "../.././RecentReadings";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import DropdownDateRangePicker from "../../../../../components/DropdownDateRangePicker";
import { DateTime } from "luxon";
import { SidebarContainerStyle } from "../../../VisitSections";
import { Goals } from "../../../../../components/Goals/Goals";
import { ReadingStatCards } from "./ReadingStatCards";
import MemberType from "common/types/MemberType";

export const ReviewCondition = ({
  member,
  condition,
  processedGoals,
  showGoals = true
}: {
  member: MemberType;
  condition: CarePlanConditionsEnum;
  processedGoals: ProcessedGoalsType;
  showGoals?: boolean;
}) => {
  const conditionGoals =
    condition === CarePlanConditionsEnum.DIABETES
      ? processedGoals?.diabetes
      : processedGoals?.hypertension;

  const deviceType =
    condition === CarePlanConditionsEnum.DIABETES
      ? DeviceTypeEnum.GLUCOSE_CATEGORY
      : DeviceTypeEnum.BLOOD_PRESSURE;

  return (
    <Flexbox flexDirection={"column"} gap={"10px"}>
      <Flexbox>
        <ReadingSummary
          memberId={member?.patient?.patient_id}
          deviceType={deviceType}
        />
      </Flexbox>
      {showGoals && (
        <Flexbox flexDirection={"column"} gap={"10px"}>
          <Goals goals={conditionGoals} member={member} />
        </Flexbox>
      )}
    </Flexbox>
  );
};

const ReadingSummary = ({
  memberId,
  deviceType
}: {
  memberId: string;
  deviceType: DeviceTypeEnum;
}) => {
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();

  return (
    <Flexbox flexDirection={"column"}>
      <Typography variant={"h6"}>Reading Summary</Typography>
      <Flexbox flexDirection={"row"} gap={"20px"}>
        <Flexbox flexDirection={"column"}>
          <Flexbox
            flexDirection={"row"}
            alignContent={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              variant={"body1"}
              marginTop={"auto"}
              marginBottom={"15px"}
            >
              {deviceType} Readings
            </Typography>
            <DropdownDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(date) => setStartDate(date)}
              onEndDateChange={(date) => setEndDate(date)}
              small
            />
          </Flexbox>

          <Box
            sx={{
              ...SidebarContainerStyle,
              height: "180px",
              width: "340px",
              overflow: "scroll"
            }}
          >
            <SlimReadingsTable
              memberId={memberId}
              deviceType={deviceType}
              startDate={startDate}
              endDate={endDate}
              limit={-1}
            />
          </Box>
        </Flexbox>
        <ReadingStatCards memberId={memberId} deviceType={deviceType} />
      </Flexbox>
    </Flexbox>
  );
};
