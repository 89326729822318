import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  ORDER_DETAILS,
  CANCELED_MEMBERS,
  RETENTION,
  REPORT_LIST,
  REPORT_DETAILS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  COMMON_ROUTES
} from "../RouteComponents";

import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import Routes from "../Routes";

const RETENTION_SPECIALIST = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: ALL_MEMBERS({})
    },

    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(false) },
    ...MEMBER_CHART(RolesEnum.RETENTION_SPECIALIST),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ORDERS,
      components: <OrderDevices />
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),
    CANCELED_MEMBERS,
    RETENTION({}),
    REPORT_LIST,
    REPORT_DETAILS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.inactives,
      items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
    },
    SIDEBAR_REPORTS
  ]
};

export default RETENTION_SPECIALIST;
