enum UserPrecenseStatusEnum {
  BUSY = "BUSY",
  AVAILABLE = "AVAILABLE",
  OFFLINE = "OFFLINE",
  LUNCH = "LUNCH",
  SCHEDULED_MEETING = "SCHEDULED_MEETING",
  BREAK = "BREAK"
}

export const UserPrecenseStatusEnum_getColor = (
  value: UserPrecenseStatusEnum
) => {
  switch (value) {
    case UserPrecenseStatusEnum.BUSY:
    case UserPrecenseStatusEnum.SCHEDULED_MEETING:
      return "error";
    case UserPrecenseStatusEnum.AVAILABLE:
      return "success";
    case UserPrecenseStatusEnum.OFFLINE:
      return "disabled";
    default:
      return "warning";
  }
};

export default UserPrecenseStatusEnum;
