import mixPanel from "mixpanel-browser";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { isDesktop, isTablet } from "react-device-detect";

import EnvVars from "../../config/EnvVars";
import { AnalyticsHelper_getSessionID } from "./AnalyticsHelper.common";
import { Dimensions } from "react-native";

const segmentClient =
  EnvVars.REACT_APP_SEGMENT_ENABLED &&
  AnalyticsBrowser.load({
    writeKey: EnvVars.REACT_APP_SEGMENT_API_KEY
  });

mixPanel.init(EnvVars.REACT_APP_MIXPANEL_TOKEN, {
  track_pageview: true,
  persistence: "localStorage"
});

export const AnalyticsHelper_setIsTest = (isTest: boolean) => {
  if (isTest === true) {
    mixPanel.disable();
  }
};

export const AnalyticsHelper_logEvent = (
  name: string,
  params?: { [key: string]: any }
) => {
  mixPanel.track(name, params);
};

export const AnalyticsHelper_logSegmentEvent = (
  name: string,
  params?: { [key: string]: any }
) => {
  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.track(name, params);
};

export const AnalyticsHelper_logScreenView = (name: string) => {
  mixPanel.track(name);
};

export const AnalyticsHelper_identify = async (member_id: string) => {
  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.identify(member_id);

  // https://github.com/mixpanel/mixpanel-js/issues/295#issuecomment-2023239738
  if (!mixPanel.people) return;

  const screen = Dimensions.get("screen");
  const { height, width } = screen;

  let device_type = "phone";
  if (isTablet) device_type = "tablet";
  if (isDesktop) device_type = "desktop";

  if (member_id) {
    mixPanel.identify(member_id);
    mixPanel.people.set("member_id", member_id);
    mixPanel.people.set("user_id", member_id);
    mixPanel.people.set("name", member_id);
    mixPanel.register({ member_id, name: member_id });
  }

  const session_id = await AnalyticsHelper_getSessionID();
  mixPanel.people.set("device_id", session_id);
  mixPanel.people.set("screen_width_dp", width);
  mixPanel.people.set("screen_height_dp", height);
  mixPanel.people.set("device_type", device_type);
  mixPanel.register({
    member_id,
    name: member_id,
    device_id: session_id,
    screen_width_dp: width,
    screen_height_dp: height,
    device_type
  });
};

export const AnalyticsHelper_optOutTracking = () => {
  mixPanel.optOutTracking();
};

export const AnalyticsHelper_reset = () => {
  mixPanel.reset();
  mixPanel.people.unset("member_id");
  mixPanel.people.unset("user_id");

  AnalyticsHelper_getSessionID().then((session_id) => {
    mixPanel.people.set("device_id", session_id);
  });

  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.identify(null);
};
