import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Modal } from "../Modal";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";
import { useFormik } from "formik";
import {
  GoalCategoryEnum,
  GoalStatusEnum,
  GoalType,
  GoalTypeEnum,
  getGoalCategoriesByType
} from "common/types/GoalType";
import { isFalsy, prettyStatusString } from "common/helpers/helpers";
import { LoadingButton } from "@mui/lab";
import { useUpsertGoalsMutation } from "common/services/MemberGoalsRelationshipService";
import { Flexbox } from "../../styling/NewStyleComponents";
import { StatusBadge } from "../../styling/StyleComponents";
import MemberType from "common/types/MemberType";
import { hasDiabetes, hasHypertension } from "common/types/Visits/CarePlanType";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  member: MemberType;
  newGoalType: GoalTypeEnum;
  goalForEdit?: GoalType;
}

interface AddGoalType {
  type: GoalTypeEnum;
  category: GoalCategoryEnum;
  description: string;
  targetValue: string;
}

enum RecommendedGoalsDescription {
  LowerFastingGlucose = "Fasting glucose less than",
  LowerNonFastingGlucose = "Non-fasting glucose less than",
  LowerBloodPressure = "Blood pressure less than"
}

enum RecommendedGoalsTarget {
  LowerFastingGlucose = "130 mg/DL",
  LowerNonFastingGlucose = "180 mg/DL",
  LowerBloodPressure = "130/80 mmHg"
}

const AddGoalModal = ({
  isOpen,
  onRequestClose,
  member,
  newGoalType,
  goalForEdit
}: IProps) => {
  const [upsertMutation, { isLoading }] = useUpsertGoalsMutation();

  const getRecommendedGoals = (): AddGoalType[] => {
    // If editing goal, do not return any recommended goals
    if (goalForEdit) return [];

    // If goal type is not clinical, do not return any recommended goals (for now)
    if (newGoalType !== GoalTypeEnum.CLINICAL) return [];

    const res: AddGoalType[] = [];
    // Base the recommended goals on the member's current goals + conditions
    if (hasDiabetes(member)) {
      // If goals do not already contain the description, add
      const hasLowerFastingGlucose = member?.goals?.find(
        (goal) =>
          goal.goal_description.includes(
            RecommendedGoalsDescription.LowerFastingGlucose
          ) && goal.goal_status === GoalStatusEnum.ACTIVE
      );

      if (!hasLowerFastingGlucose) {
        res.push({
          type: GoalTypeEnum.CLINICAL,
          category: GoalCategoryEnum.DIABETES,
          description: `${RecommendedGoalsDescription.LowerFastingGlucose} ${RecommendedGoalsTarget.LowerFastingGlucose}`,
          targetValue: `${RecommendedGoalsTarget.LowerFastingGlucose}`
        });
      }

      const hasLowerNonFastingGlucose = member?.goals?.find(
        (goal) =>
          goal.goal_description.includes(
            RecommendedGoalsDescription.LowerNonFastingGlucose
          ) && goal.goal_status === GoalStatusEnum.ACTIVE
      );
      if (!hasLowerNonFastingGlucose) {
        res.push({
          type: GoalTypeEnum.CLINICAL,
          category: GoalCategoryEnum.DIABETES,
          description: `${RecommendedGoalsDescription.LowerNonFastingGlucose} ${RecommendedGoalsTarget.LowerNonFastingGlucose}`,
          targetValue: `${RecommendedGoalsTarget.LowerNonFastingGlucose}`
        });
      }
    }
    if (hasHypertension(member)) {
      const hasLowerBloodPressure = member?.goals?.find(
        (goal) =>
          goal.goal_description.includes(
            RecommendedGoalsDescription.LowerBloodPressure
          ) && goal.goal_status === GoalStatusEnum.ACTIVE
      );
      if (!hasLowerBloodPressure) {
        res.push({
          type: GoalTypeEnum.CLINICAL,
          category: GoalCategoryEnum.HYPERTENSION,
          description: `${RecommendedGoalsDescription.LowerBloodPressure} ${RecommendedGoalsTarget.LowerBloodPressure}`,
          targetValue: `${RecommendedGoalsTarget.LowerBloodPressure}`
        });
      }
    }
    return res;
  };

  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors["type"] = "Mandatory field";
    }
    if (!values.category) {
      errors["category"] = "Mandatory field";
    }
    if (values.description < 4) {
      errors["description"] = "Mandatory field";
    }
    if (values.targetValue < 2) {
      errors["targetValue"] = "Mandatory field";
    }

    return errors;
  };

  const formik = useFormik<AddGoalType>({
    validate,
    initialValues: {
      type: goalForEdit?.goal_type ?? newGoalType,
      category: goalForEdit?.goal_category ?? null,
      description: goalForEdit?.goal_description ?? "",
      targetValue: goalForEdit?.target_value ?? ""
    },
    onSubmit: (values) => {
      upsertMutation({
        goals: [
          {
            goal_id: goalForEdit?.goal_id,
            goal_type: values.type,
            goal_description: values.description,
            goal_status: GoalStatusEnum.ACTIVE,
            target_value: values.targetValue,
            goal_category: values.category
          }
        ],
        member_id: member.patient?.patient_id
      });
      handleClose();
    },
    enableReinitialize: true
  });

  const handleClose = () => {
    formik.resetForm();
    onRequestClose();
  };

  const modalHeader = goalForEdit ? "Edit Goal" : "Add New Goal";
  const submitButtonText = goalForEdit ? "Edit Goal" : "Add Goal";
  return (
    <Modal isOpen={isOpen} contentLabel="Add Modal Visible" modalHeight="75vh">
      <ModalHeader onRequestClose={handleClose}>{modalHeader}</ModalHeader>
      <ModalBody>
        {getRecommendedGoals().length > 0 && (
          <>
            <Typography variant="h5" mb={"5px"}>
              Recommended Goals
            </Typography>
            <Flexbox flexDirection={"column"} gap={"10px"}>
              {getRecommendedGoals()?.map((item) => {
                return (
                  <Flexbox
                    key={JSON.stringify(item)}
                    flexDirection={"row"}
                    alignItems={"center"}
                    alignContent={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flexbox alignItems={"center"}>
                      <div style={{ width: "160px" }}>
                        <StatusBadge status={item.category} hideDot={true} />
                      </div>
                      <Typography
                        variant="body1"
                        alignContent={"left"}
                        textAlign={"left"}
                      >
                        {item.description}
                      </Typography>
                    </Flexbox>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        formik.setValues(item);
                      }}
                    >
                      Use
                    </Button>
                  </Flexbox>
                );
              })}
            </Flexbox>
          </>
        )}
        {newGoalType === GoalTypeEnum.MEMBER && (
          <>
            <Typography variant="h6">SMART Goals</Typography>
            <Typography variant="body1">
              SMART goals are Specific, Measurable, Achievable, Relevant, and
              Time-bound. They provide clear objectives and deadlines for
              achievement
            </Typography>
            <br />
          </>
        )}
        <br />
        <Flexbox flexDirection={"row"} alignItems={"left"} gap={"20px"}>
          <Flexbox flexDirection={"column"} gap={"5px"}>
            <Typography variant={"h6"}>Category</Typography>
            <br />
            <TextField
              label="Select goal category"
              select
              placeholder="Select"
              value={formik.values.category ?? ""}
              onChange={(event) =>
                formik.setFieldValue("category", event.target.value)
              }
              error={!isFalsy(formik.errors["category"])}
              helperText={formik.errors["category"]}
              sx={{ minWidth: "230px" }}
            >
              {getGoalCategoriesByType(formik.values.type).map((key) => (
                <MenuItem key={key} value={key}>
                  {prettyStatusString(key)}
                </MenuItem>
              ))}
            </TextField>
          </Flexbox>
          <Flexbox flexDirection={"column"} gap={"5px"}>
            <Typography variant={"h6"}>Target Values</Typography>
            <Typography variant={"caption"} style={{ fontStyle: "italic" }}>
              {newGoalType === GoalTypeEnum.CLINICAL &&
                `Example: 130/80 mmHg, 130 mg/DL`}
              {newGoalType === GoalTypeEnum.MEMBER &&
                `Example: 5 nights a week`}
            </Typography>
            <TextField
              label="Target"
              value={formik.values.targetValue ?? ""}
              onChange={(event) =>
                formik.setFieldValue("targetValue", event.target.value)
              }
              error={!isFalsy(formik.errors["targetValue"])}
              helperText={formik.errors["targetValue"]}
              sx={{ minWidth: "230px" }}
            />
          </Flexbox>
        </Flexbox>

        <br />
        <Flexbox flexDirection={"column"} gap={"5px"}>
          <Typography variant={"h6"}>Description</Typography>
          <Typography variant={"caption"} style={{ fontStyle: "italic" }}>
            {newGoalType === GoalTypeEnum.MEMBER &&
              `Example: Take a 10 minute walk after dinner at least 5 nights a week`}
          </Typography>
          <TextField
            label="Goal description"
            placeholder="Add description"
            multiline={true}
            rows={1}
            value={formik.values.description ?? ""}
            onChange={(event) =>
              formik.setFieldValue("description", event.target.value)
            }
            error={!isFalsy(formik.errors["description"])}
            helperText={formik.errors["description"]}
          />
        </Flexbox>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            loading={isLoading}
          >
            {submitButtonText}
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};

export default AddGoalModal;
