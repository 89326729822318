import { useUpsertCarePlanMutation } from "common/services/VisitsService";
import MemberType from "common/types/MemberType";
import CarePlanType, {
  CarePlanConditionsEnum
} from "common/types/Visits/CarePlanType";
import { useFormik } from "formik";
import { Modal } from "../Modal";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";
import { Flexbox } from "../../styling/NewStyleComponents";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { StatusBadge } from "../../styling/StyleComponents";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";
import DatePicker from "../DatePicker";
import { isFalsy } from "common/helpers/helpers";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  member: MemberType;
}
export const EditCarePlanModal = ({
  isOpen,
  onRequestClose,
  member
}: IProps) => {
  const [upsertMutation, { isLoading }] = useUpsertCarePlanMutation();

  const handleClose = () => {
    formik.resetForm();
    onRequestClose();
  };

  const formik = useFormik<CarePlanType>({
    validate: () => {},
    initialValues: {
      conditions: member?.care_plan?.conditions,
      medication_reconciliation: {
        next_due: member?.care_plan?.medication_reconciliation?.next_due
      },
      patient_id: member?.patient?.patient_id
    },
    onSubmit: (values) => {
      upsertMutation({
        body: {
          patient_id: values?.patient_id,
          conditions: values?.conditions,
          medication_reconciliation: {
            next_due: values?.medication_reconciliation?.next_due
          }
        }
      });
      handleClose();
    },
    enableReinitialize: true
  });

  return (
    <Modal isOpen={isOpen} contentLabel="EditCarePlanModal" modalHeight="60vh">
      <ModalHeader onRequestClose={handleClose}>Edit Care Plan</ModalHeader>
      <ModalBody>
        <Flexbox flexDirection={"column"} gap={"5px"}>
          <Typography variant={"h6"}>Conditions</Typography>
          <br />
          <TextField
            label="Select conditions"
            select
            placeholder="Select"
            slotProps={{
              select: {
                variant: "outlined",
                multiple: true,
                value: formik.values?.conditions ?? [],
                onChange: (event) => {
                  // const value = event.target.value as string[];
                  formik.setFieldValue("conditions", event.target.value);
                }
              }
            }}
            sx={{ minWidth: "230px" }}
          >
            {[
              CarePlanConditionsEnum.DIABETES,
              CarePlanConditionsEnum.HYPERTENSION
            ].map((item) => (
              <MenuItem key={item} value={item}>
                <StatusBadge
                  status={item}
                  hideDot={true}
                  style={{ marginRight: "10px" }}
                />
              </MenuItem>
            ))}
          </TextField>
          <br />
          <Typography variant={"h6"}>Medication Reconciliation</Typography>
          <br />
          <DatePicker
            label="Next Due"
            minDate={DateTime.now()}
            value={
              isFalsy(formik.values?.medication_reconciliation?.next_due)
                ? null
                : DateTime.fromISO(
                    formik.values?.medication_reconciliation?.next_due
                  )
            }
            onChange={(value: DateTime) => {
              formik.setFieldValue(
                "medication_reconciliation.next_due",
                value.toUTC().toISO()
              );
            }}
          />
        </Flexbox>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            loading={isLoading}
          >
            Edit Care Plan
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};
