import { useEffect, useState } from "react";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import SmsIcon from "@mui/icons-material/Sms";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";

import MemberType from "common/types/MemberType";
import { useSendJoinVideoCallMessageMutation } from "common/services/MemberEngagementService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { setAnswer } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { gray } from "common/styling/colors";
import { callPatientModalSelector } from "../../../helpers/helpers";
import { Phone } from "@mui/icons-material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import {
  ConfirmThenGoToSection,
  RenderStep,
  StepHorizontalSpacing,
  StepRowContainer
} from "./VisitContentField";
import { CareFlowSectionsEnum } from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import { isDisabled, toggleNotConnectedCareMessage } from "../VisitHelper";
import { RIQSnackbar } from "../../../components/Snackbar/Snackbar";
import { Flexbox } from "../../../styling/NewStyleComponents";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 10px 0px; 0px; 0px;
`;

const BubbleContainer = styled("div")`
  display: flex;
  align-self: start;
  background-color: #007aff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  max-width: 450px;
`;

interface IProps {
  member: MemberType;
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const CHECKBOX_CONNECTED = "Connected";
const CHECKBOX_NOT_CONNECTED = "Not Connected";

const VisitFieldConnectWithMember = ({ member, field, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const [sentSmsDate, setSentSmsDate] = useState<DateTime>(null);
  const [step, setStep] = useState<number>(0);
  const [sendJoinVideoCallMessageMutation, { data, isLoading, isSuccess }] =
    useSendJoinVideoCallMessageMutation();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  useEffect(() => {
    if (isSuccess) setSnackbarOpen(true);
  }, [isSuccess]);

  const handleSmsText = async () => {
    await sendJoinVideoCallMessageMutation({
      member_id: member?.patient?.patient_id,
      action: "ON_DEMAND_APPOINTMENT_REMINDER"
    });

    setSentSmsDate(DateTime.now());
  };

  const disabled = isDisabled(visit);

  return (
    <Container>
      <Typography variant="body1">
        To start the appointment, click <b>{`'Call'`}</b> to contact the member.
        {`If they don't answer, click `}
        <b>{`'Send Text'`}</b> to send a message, then click <b>{`'Call'`}</b>{" "}
        to retry the call.
      </Typography>
      <br />
      <StepRowContainer>
        <RenderStep step={1} disabled={disabled || step < 0} />
        <CustomTooltip title={"Calls the member from Zoom"} placement="top">
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setStep(1);
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
              disabled={disabled || step < 0}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>

        <StepHorizontalSpacing />

        <RenderStep step={2} disabled={disabled || step < 1} />
        <CustomTooltip
          title={
            "Click to automatically send an appointment reminder to the member"
          }
          placement="top"
        >
          <div>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={disabled || step < 1}
              onClick={() => {
                setStep(2);
                handleSmsText();
              }}
              startIcon={<SmsIcon />}
            >
              Send Text
            </LoadingButton>
          </div>
        </CustomTooltip>

        <StepHorizontalSpacing />

        <RenderStep step={3} disabled={disabled || step < 2} />
        <CustomTooltip
          title={
            sentSmsDate &&
            `Successfully sent the below text message ${sentSmsDate.toRelative()}`
          }
          placement="top"
        >
          <div>
            <Button
              variant="contained"
              disabled={disabled || step < 2}
              onClick={() => {
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>
      </StepRowContainer>

      <Flexbox alignContent={"center"} marginTop={"10px"} justifyContent={"space-around"} maxWidth={"590px"}>
        {sentSmsDate && (
          <Typography variant="caption" color={gray[900]}>
            Sent {disabled ? sentSmsDate?.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : sentSmsDate?.toRelative()}
          </Typography>
        )}
      </Flexbox>
      <br />

      <RIQSnackbar
        open={snackbarOpen}
        severity={"success"}
        handleClose={() => setSnackbarOpen(false)}
        content={`Sent text message!`}
      />

      {data?.MessageBody && (
        <BubbleContainer>
          <Typography color="white">{data?.MessageBody}</Typography>
        </BubbleContainer>
      )}
      <br />
      <Typography variant="body1" color={gray[900]}>
        Were you able to connect?
      </Typography>

      <RadioGroup
        name="radio-buttons-group"
        value={answers[field.field_id] ?? ""}
        row
        onChange={(event) => {
          const value = event.target.value;
          if (!value) return;
          const isNotConnected = value === CHECKBOX_NOT_CONNECTED;
          dispatch(setAnswer({ id: field.field_id, value: value }));

          toggleNotConnectedCareMessage(dispatch, isNotConnected);

          if (isNotConnected) {
            ConfirmThenGoToSection({
              modalBody:
                "Were you not able to connect with the member? If so, confirm below to proceed to Post-Call.",
              gotoId: CareFlowSectionsEnum.POST_CALL,
              dispatch,
              visit
            });
          }
        }}
      >
        <FormControlLabel
          key={CHECKBOX_CONNECTED}
          value={CHECKBOX_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_CONNECTED}
          disabled={disabled}
        />

        <FormControlLabel
          key={CHECKBOX_NOT_CONNECTED}
          value={CHECKBOX_NOT_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_NOT_CONNECTED}
          disabled={disabled}
        />
      </RadioGroup>
    </Container>
  );
};

export default VisitFieldConnectWithMember;
