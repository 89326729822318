import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosLib, {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";
import APIConstants from "../config/APIConstants";
import EnvVars from "../config/EnvVars";
import ProductEnum from "../enums/ProductEnum";

import { Alert_show } from "../helpers/AlertHelper";
import { getErrorMessage } from "../helpers/helpers";
import { SentryHelper_captureException } from "../helpers/SentryHelper";
import LocalizedStrings from "../localizations/LocalizedStrings";
import {
  finishRefreshLoading,
  logOut,
  startRefreshLoading
} from "../redux/AuthSlice";
import { AuthService_RefreshToken } from "./AuthService";
import StorageHelper from "../helpers/StorageHelper";
import StorageEnum from "../enums/StorageEnum";
import { apiGraphQL } from "./GraphQLService";
import ErrorType from "../types/ErrorType";

let dispatch;
const setHeaders = (
  config: InternalAxiosRequestConfig,
  accessToken: string | null | undefined
) => {
  let headers = {
    ...config.headers,
    "Content-Type": "application/json"
  };

  if (accessToken !== null)
    headers = {
      ...headers,
      Authorization: "Bearer " + accessToken
    };

  for (let key in headers) {
    config.headers[key] = headers[key];
  }
};

const init = (baseURL: string) => {
  const axios = axiosLib.create({
    baseURL
  });

  axios.interceptors.request.use(
    async function (config) {
      if (!dispatch) dispatch = require("../redux").dispatch;
      // If already set Authorization header use that one (/login endpoint)
      if (
        config.url?.includes("/login") &&
        config.headers?.Authorization != undefined
      ) {
        return config;
      }

      const credentialsJson = await StorageHelper.getItem(
        StorageEnum.LOGIN_CREDENTIALS
      );
      if (credentialsJson !== undefined) {
        try {
          const credentials = JSON.parse(credentialsJson);
          if (config.url?.includes("/refresh-token")) {
            setHeaders(config, credentials?.refresh_token);
            return config;
          }

          if (credentials) {
            setHeaders(config, credentials.access_token);
          } else {
            setHeaders(config, null);
          }
        } catch (error) {
          SentryHelper_captureException(error);
          setHeaders(config, null);
        }
      } else setHeaders(config, null);

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (EnvVars.REACT_APP_PRODUCT === ProductEnum.CopilotIQ) {
        const errorMessageString = getErrorMessage(error, {
          hideErrorCode: true
        });

        if (
          error.request.responseURL.includes("/pre-login/code") ||
          error.request.responseURL.includes("/login/code") ||
          (error.request.status === 404 &&
            error.request.responseURL.includes("/patients-metadata/") &&
            error.request.responseURL.includes("/preferences"))
        ) {
          // don't show modal for this endpoint
        } else if (
          error.request.status === 404 &&
          error.request.responseURL.includes("/video")
        ) {
          Alert_show({
            dispatch,
            title: LocalizedStrings.error.title,
            content: LocalizedStrings.error.video_room_not_found,
            type: "error"
          });
        } else {
          // show modal. Useful, specially on mobile
          Alert_show({
            dispatch,
            title: LocalizedStrings.error.title,
            content: errorMessageString,
            type: "error"
          });
        }
      }

      return Promise.reject(error);
    }
  );

  return axios;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Not using redux because of a delay when app restarts
// and calls several APIs at the same time
let isRefreshingToken = false;
let refreshTokenExpired = false;

const setRefreshTokenExpired = () => {
  refreshTokenExpired = true;
  setTimeout(() => {
    // Need to reset this otherwise app will remain unusable.
    refreshTokenExpired = false;
  }, 1000);

  throw new Error("RefreshToken is expired");
};

const handleRefreshToken = async () => {
  const { store } = require("../redux");

  const credentialsJson = await StorageHelper.getItem(
    StorageEnum.LOGIN_CREDENTIALS
  );
  if (credentialsJson === undefined)
    throw new Error("No credentials stored. Logging user out.");

  const credentials = JSON.parse(credentialsJson);

  if (
    store.getState().auth.isLoggedIn &&
    credentials?.access_token === undefined
  ) {
    store.dispatch(logOut(false));
    throw new Error("No credentials stored. Logging user out.");
  }
  if (credentials === null || credentials.expires === undefined) return;

  const refreshTokenJwt = jwtDecode(credentials.refresh_token);
  const refreshTokenExpiresAt = DateTime.fromSeconds(refreshTokenJwt.exp);
  if (DateTime.now() > refreshTokenExpiresAt) {
    await store.dispatch(logOut(false));
    setRefreshTokenExpired();
    throw new Error("RefreshToken Expired");
  }

  const tokenExpiresAt = DateTime.fromSQL(credentials.expires);
  const isTokenExpired = tokenExpiresAt < DateTime.now();

  if (isTokenExpired) {
    let count = 0;
    while (isRefreshingToken && count < 100) {
      await sleep(100);
      count++;
    }
    if (count > 0) {
      if (refreshTokenExpired) {
        throw new Error("RefreshToken API failed");
      }
      const { store } = require("../redux");
      const isLoggedIn = store.getState().auth.isLoggedIn;
      if (isLoggedIn) return;
      else {
        throw new Error("RefreshToken API failed");
      }
    }

    isRefreshingToken = true;
    store.dispatch(startRefreshLoading());
    const { response, error } = await AuthService_RefreshToken();
    isRefreshingToken = false;
    store.dispatch(finishRefreshLoading());

    if (response?.status !== 200 && error?.message !== "Network Error") {
      await store.dispatch(logOut(false));
      setRefreshTokenExpired();
    }

    if (response) {
      await StorageHelper.setItem(
        StorageEnum.LOGIN_CREDENTIALS,
        JSON.stringify(response.data)
      );
      isRefreshingToken = false;
      store.dispatch(finishRefreshLoading());
    }
  }
};

const AxiosService_getBaseQuery =
  (
    axiosInstance: AxiosInstance
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosHeaders;
      validateStatus?: (response: AxiosResponse<any, any>) => boolean;
    },
    unknown,
    ErrorType
  > =>
  async ({ url, method, data, params, headers, validateStatus }) => {
    try {
      await handleRefreshToken();

      const result = await axiosInstance({
        url: url,
        method,
        data,
        params,
        headers
      });

      const valid = validateStatus && validateStatus(result);
      if (valid === false) {
        return {
          error: {
            code: result.status.toString(),
            status: result.status,
            data: result.data,
            message:
              result.data?.message ||
              "Something went wrong. Please try again later."
          }
        };
      }

      return { data: result.data };
    } catch (axiosError) {
      const errorMessageString = getErrorMessage(axiosError);
      return {
        error: {
          code: axiosError?.status?.toString(),
          status: axiosError?.status,
          data: axiosError?.data,
          message: errorMessageString
        }
      };
    }
  };

const AxiosService_createAPI = (
  axiosInstance: AxiosInstance,
  reducerPath: string
) => {
  return createApi({
    reducerPath,
    // https://stackoverflow.com/a/70734352
    keepUnusedDataFor: process.env.JEST_WORKER_ID === undefined ? 60 : 0,
    refetchOnReconnect: true,
    refetchOnFocus: EnvVars.REACT_APP_PRODUCT === ProductEnum.CopilotIQ,
    baseQuery: AxiosService_getBaseQuery(axiosInstance),
    endpoints: () => ({})
  });
};

export const AxiosService_MOCK_QUERY = {
  data: undefined,
  isFetching: false,
  isLoading: false,
  error: undefined,
  isSuccess: false
};

export const axiosRemoteIQ: AxiosInstance = init(APIConstants.REMOTEIQ);
export const axiosLogin: AxiosInstance = init(APIConstants.LOGIN);
export const axiosPAB: AxiosInstance = init(APIConstants.PAB);
export const axiosTasking: AxiosInstance = init(APIConstants.TASKING);
export const axiosDevices: AxiosInstance = init(APIConstants.DEVICES);
export const axiosPartners: AxiosInstance = init(APIConstants.PARTNERS);
export const axiosOrders: AxiosInstance = init(APIConstants.ORDERS);
export const axiosReadings: AxiosInstance = init(APIConstants.READINGS);
export const axiosMemberRegistration: AxiosInstance = init(
  APIConstants.PATIENT_REGISTRATION
);
export const axiosMemberConsents: AxiosInstance = init(
  APIConstants.PATIENT_CONSENTS
);
export const axiosTwilio: AxiosInstance = init(APIConstants.TWILIO);
export const axiosMemberDocumentsS3: AxiosInstance = init(
  APIConstants.PATIENT_DOCUMENTS_S3
);
export const axiosMemberDocuments: AxiosInstance = init(
  APIConstants.PATIENT_DOCUMENTS
);
export const axiosVideo: AxiosInstance = init(APIConstants.VIDEO);
export const axiosCalendar: AxiosInstance = init(APIConstants.CALENDAR);
export const axiosVisits: AxiosInstance = init(APIConstants.VISITS);
export const axiosAppointments: AxiosInstance = init(APIConstants.APPOINTMENTS);
export const axiosMemberEngagement: AxiosInstance = init(
  APIConstants.PATIENT_ENGAGEMENT
);
export const axiosPanelManagement: AxiosInstance = init(
  APIConstants.PANEL_MANAGEMENT
);

export const axiosMessaging: AxiosInstance = init(APIConstants.MESSAGING);
export const axiosUserPresence: AxiosInstance = init(
  APIConstants.USER_PRESENCE
);
export const axiosMedusaAuth: AxiosInstance = init(APIConstants.MEDUSA_AUTH);

export const apiRemoteIQ = AxiosService_createAPI(axiosRemoteIQ, "apiRemoteIQ");
export const apiPAB = AxiosService_createAPI(axiosPAB, "apiPAB");
export const apiTasking = AxiosService_createAPI(axiosTasking, "apiTasking");
export const apiDevices = AxiosService_createAPI(axiosDevices, "apiDevices");
export const apiPartners = AxiosService_createAPI(axiosPartners, "apiPartners");
export const apiOrders = AxiosService_createAPI(axiosOrders, "apiOrders");
export const apiReadings = AxiosService_createAPI(axiosReadings, "apiReadings");
export const apiMemberConsents = AxiosService_createAPI(
  axiosMemberConsents,
  "apiMemberConsents"
);
export const apiMemberRegistration = AxiosService_createAPI(
  axiosMemberRegistration,
  "apiMemberRegistration"
);
export const apiTwilio = AxiosService_createAPI(axiosTwilio, "apiTwilio");

export const apiMemberDocuments = AxiosService_createAPI(
  axiosMemberDocuments,
  "apiMemberDocuments"
);

export const apiVideo = AxiosService_createAPI(axiosVideo, "apiVideo");
export const apiCalendar = AxiosService_createAPI(axiosCalendar, "apiCalendar");
export const apiVisits = AxiosService_createAPI(axiosVisits, "apiVisits");
export const apiAppointments = AxiosService_createAPI(
  axiosAppointments,
  "apiAppointments"
);
export const apiMemberEngagement = AxiosService_createAPI(
  axiosMemberEngagement,
  "apiVideo"
);

export const apiPanelManagement = AxiosService_createAPI(
  axiosPanelManagement,
  "apiPanelManagement"
);
export const apiMessaging = AxiosService_createAPI(
  axiosMessaging,
  "apiMessaging"
);

export const apiUserPresence = AxiosService_createAPI(
  axiosUserPresence,
  "apiUserPresence"
);

export const apiMedusaAuth = AxiosService_createAPI(
  axiosMedusaAuth,
  "apiMedusaAuth"
);
export const APIS = [
  apiRemoteIQ,
  apiPAB,
  apiPartners,
  apiDevices,
  apiOrders,
  apiReadings,
  apiMemberConsents,
  apiMemberRegistration,
  apiTwilio,
  apiMemberDocuments,
  apiTasking,
  apiVideo,
  apiCalendar,
  apiMemberEngagement,
  apiVisits,
  apiPanelManagement,
  apiMessaging,
  apiMedusaAuth,
  apiGraphQL
];

export const AxiosService_onFocus = () => {
  const { store } = require("../redux");
  APIS.forEach((api) => {
    store.dispatch(api.internalActions.onFocus());
  });
};

export const AxiosService_onFocusLost = () => {
  const { store } = require("../redux");
  APIS.forEach((api) => {
    store.dispatch(api.internalActions.onFocusLost());
  });
};
export const AxiosService_onOffline = () => {
  const { store } = require("../redux");
  APIS.forEach((api) => {
    store.dispatch(api.internalActions.onOffline());
  });
};
export const AxiosService_onOnline = () => {
  const { store } = require("../redux");
  APIS.forEach((api) => {
    store.dispatch(api.internalActions.onOnline());
  });
};
