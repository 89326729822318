import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { Flexbox } from "../../../styling/NewStyleComponents";
import StartCareFlow, {
  StyledButton
} from "../../../components/ScheduleToday/StartCareFlow";
import { canSeeCareFlow } from "common/enums/RolesEnum";
import { isFalsy } from "common/helpers/helpers";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { blue } from "common/styling/colors";
import { useNavigate } from "react-router-dom";
import AppointmentOperationTypeEnum from "common/enums/Calendaring/Appointments/AppointmentOperationTypeEnum";

interface IProps {
  upcomingAppointments: any;
  staffTimezone?: string;
  memberId: string;
}

const RescheduleButton = ({
  memberId,
  data
}: {
  memberId: string;
  data: any;
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <StyledButton
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate(`/members/memberId/${memberId}/edit-appointment`, {
            state: {
              operationType: AppointmentOperationTypeEnum.UPDATE,
              memberId: memberId,
              eventId: data?.event_id,
              event: data,
              staff: data?.staff,
              recurring: data?.recurring,
              appointmentType: data?.appointment_type
            }
          });
        }}
      >
        Reschedule
      </StyledButton>
    </div>
  );
};

export const Appointment = ({
  visitRequest,
  primaryText,
  secondaryText,
  showReschedule,
  showStart,
  data,
  visit = null
}: {
  visitRequest?: any;
  primaryText: string;
  secondaryText: string;
  showReschedule: boolean;
  showStart: boolean;
  data?: any;
  visit?: GetVisitResponseType;
}) => {
  let confirmMessage = "";
  if (!isFalsy(visit)) {
    const createdAt = DateTime.fromISO(visit?.created_date).toFormat(
      "h:mm a ZZZZ"
    );
    const info = `${visit?.staff?.first} ${visit?.staff?.last} at ${createdAt}`;
    secondaryText += ` (${info})`;
    confirmMessage += `A visit for this appointment was already started by ${info}. Do you want still want to start a new visit?`;
  }
  return (
    <Flexbox
      flexDirection={"column"}
      sx={{
        backgroundColor: blue[50],
        padding: "10px",
        border: 1,
        borderColor: "#4c97ca",
        borderRadius: "4px",
        marginBottom: "10px"
      }}
    >
      <Flexbox
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Flexbox flexDirection={"column"}>
          <Typography variant="body1" fontWeight="700" color="primary.main">
            {primaryText}
          </Typography>
          <Typography variant="body1" color="primary.main">
            {secondaryText}
          </Typography>
        </Flexbox>

        <Flexbox flexDirection={"row-reverse"} gap={"10px"}>
          {showStart && (
            <StartCareFlow
              visitsRequest={visitRequest}
              confirmMessage={confirmMessage}
            />
          )}
          {showReschedule && (
            <RescheduleButton memberId={visitRequest?.patient_id} data={data} />
          )}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const TodaysAppointments = ({
  upcomingAppointments,
  staffTimezone,
  memberId
}: IProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <>
      <Typography variant="h4" color="text.secondary" mb="12px">
        Today's Appointments
      </Typography>
      {isFalsy(upcomingAppointments) && (
        <>
          <Typography variant="h6" color="text.secondary" mb="12px">
            The member has no appointment scheduled for today. Do you want to
            start an on-demand appointment?
          </Typography>
          <Appointment
            visitRequest={{
              staff_id: user?.user_id,
              patient_id: memberId,
              motivation_reason: VisitMotivationTypesEnum.PATIENT_INITIATED
            }}
            primaryText={`${user?.first} ${user?.last}`}
            secondaryText={`On-Demand`}
            showStart={true}
            showReschedule={false}
          />
        </>
      )}
      {upcomingAppointments?.map((item) => {
        const tz = staffTimezone ?? item.attendees[0]?.timezone;

        const startDate = DateTime.fromISO(item.startdate).setZone(tz);
        const endDate = DateTime.fromISO(item.enddate).setZone(tz);

        // If not today, show the date in addition to the start - end
        const secondaryTextDate = startDate.hasSame(DateTime.local(), "day")
          ? ""
          : `${startDate.toFormat("cccc LLL d")} `;

        return (
          <Appointment
            key={item?.calendar_id}
            visitRequest={{
              calendar_event_start: startDate,
              calendar_event_end: endDate,
              staff_id: item.staff.id,
              patient_id: item.attendees[0].attendee_id,
              calendar_id: item.event_id,
              motivation_reason: VisitMotivationTypesEnum.APPOINTMENT
            }}
            data={item}
            primaryText={`${item.staff.first} ${item.staff.last}`}
            secondaryText={`${secondaryTextDate}${startDate.toFormat("h:mm")} - ${endDate.toFormat("h:mm a ZZZZ")}`}
            visit={item.visits?.length > 0 ? item.visits[0] : null}
            showStart={true}
            showReschedule={true}
          />
        );
      })}
    </>
  );
};

export default TodaysAppointments;
