import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Theme,
  Typography,
  styled,
  useTheme
} from "@mui/material";

import isEqual from "lodash.isequal";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { LoadingButton, TabPanel } from "@mui/lab";
import { AssignmentInd, Block } from "@mui/icons-material";

import TaskStatusEnum from "common/enums/TaskStatusEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  PHONE_VALIDATION_REGEXP,
  getNameOrUsername,
  isFalsy,
  unmaskPhoneNumber,
  validateFieldLength
} from "common/helpers/helpers";
import {
  useAddMemberMutation,
  useDeleteMemberMutation,
  useGetTeamsQuery
} from "common/services/TeamsService";
import TeamType from "common/types/TeamType";
import UserType from "common/types/UserType";
import {
  useChangeUserStatusMutation,
  useGetUserScheduleTypesQuery,
  useGetUserWithUsernameQuery,
  useUpdateMetadataMutation,
  useUpdateUserByUsernameMutation
} from "common/services/UserService";
import timezones from "common/config/timezones.json";
import { RootState, useAppDispatch } from "common/redux";
import UserTypeInner from "common/types/UserTypeInner";
import RolesEnum, {
  userCanAllowDisallowScheduling,
  canEditUserDetails,
  canSeeAssignmentsOnStaffProfile,
  staffMemberRoleCanSubmitEncounters,
  staffMemberCanHaveOrders,
  canSeeScheduleOnStaffProfile,
  canSeeTasks,
  canUpdateStaffTeam,
  hasStateLicensure,
  isProviderRole,
  userCanBeScheduled,
  canSeeEncountersOnStaffDetailsCurrentRole,
  canUpdateMobileNumber,
  canSeeTeamsOnProfileInfo,
  staffMemberCanHaveSchedule,
  canSeeOrdersOnStaffDetailsCurrentRole,
  canSeeScheduleTypeOnProfileInfo,
  userCanHaveScheduleType,
  canEditScheduleTypeOnProfileInfo,
  getRoleLabel,
  hasProviderRole,
  canSeeCareFlow,
  canSeeActivityLog
} from "common/enums/RolesEnum";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";

import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { CustomTooltip, StatusBadge } from "../../../styling/StyleComponents";
import ErrorComponent from "../../../components/ErrorComponent";
import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import {
  ASSIGNED_MEMBERS,
  MEMBERS_WITH_NO_ACTIVITY,
  RECENT_ORDERS
} from "../../../routes/RouteComponents";
import PhoneInputField from "../../../components/Input/PhoneInputField";
import StyledIconButton from "../../../components/Button/StyledIconButton";

import UpcomingAppointments from "../../MemberDetails/Appointments/UpcomingAppointments";
import PastAppointments from "../../MemberDetails/Appointments/PastAppointments";
import ChangeRoleModal from "./ChangeRoleModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EncountersTab from "./EncountersTab";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import {
  DAYS_TO_LOOK_AHEAD,
  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
} from "../../MemberDetails/Appointments/constants";
import OrdersTab from "./OrdersTab";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useGetLicensedStatesQuery } from "common/services/MemberService";
import { HelpIcon } from "../../../assets/images/icons";
import {
  canEditTeam,
  TeamTypeEnum,
  getAllowedTeamTypesForMemberRole
} from "common/enums/TeamTypeEnum";
import {
  DELAY_AFTER_UPDATE_SCHEDULE_REQUEST_COMPLETED,
  useUpdateStaffScheduleTypeMutation
} from "common/services/CalendarService";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import Tasks from "../../Tasks/Tasks";
import NewScheduleToDoContainer from "../../../components/ScheduleToDoContainer/NewScheduleToDoContainer";
import ActivityLogTab from "./ActivityLogTabV2";
import { useFeatureFlags } from "common/config/FeatureFlags";

const Container = styled("div")`
  margin: 20px 2.5%;
  overflow: scroll;
`;

const StyledTabs = styled(Tabs)`
  margin: 0px;
`;

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ValuesContainer = styled("div")`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px;
`;

const ChipContainer = styled("div")`
  flex-wrap: wrap;
`;

enum TabPanelIndex {
  assignments = 0,
  tasks = 1,
  schedule = 2,
  profile = 3,
  encounters = 4,
  orders = 5,
  activity_log = 6
}

type FormType = Partial<UserTypeInner>;

const RenderZoomInfo = ({ user }: { user: UserType }) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [
    updateMetadataMutation,
    {
      isLoading: isUpdateMetadataLoading,
      isSuccess: updateMetadataSuccess,
      error: updateMetadataError
    }
  ] = useUpdateMetadataMutation();

  const validate = (values) => {
    const errors = {};

    const validZoomPhone =
      values["zoom_phone"] &&
      PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values?.["zoom_phone"]));
    const validZoomUserId = validateFieldLength(2, 50, values["zoom_user_id"]);

    if (!validZoomPhone) {
      errors["zoom_phone"] = "Please enter a valid 10-digit mobile number";
    }
    if (!validZoomUserId) {
      errors["zoom_user_id"] =
        "Please enter a value between 2 and 50 characters.";
    }

    return errors;
  };

  const { setFieldValue, values, errors, handleSubmit, dirty, isValid } =
    useFormik<{ zoom_user_id: string; zoom_phone: string }>({
      validate,
      initialValues: {
        zoom_phone: user?.metadata?.extra?.zoom_phone || "",
        zoom_user_id: user?.metadata?.extra?.zoom_user_id || ""
      },
      onSubmit: async (values) => {
        await updateMetadataMutation({
          email: user.user.email,
          extra: {
            zoom_phone: unmaskPhoneNumber(values?.zoom_phone),
            zoom_user_id: values?.zoom_user_id
          }
        });
      },
      enableReinitialize: true
    });

  const canEditForm = canUpdateMobileNumber(currentRole);

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Zoom Info
        </Typography>
        <>
          {canEditForm && (
            <LoadingButton
              onClick={() => handleSubmit()}
              loading={isUpdateMetadataLoading}
              disabled={!dirty || !isValid}
            >
              Update Zoom Info
            </LoadingButton>
          )}
        </>
      </Row>
      <Card>
        <ValuesContainer>
          <PhoneInputField
            sx={defaultFormStyles}
            disabled={!canEditForm}
            value={values?.zoom_phone || ""}
            label={"Phone number"}
            onChange={(event) =>
              setFieldValue("zoom_phone", event.target.value)
            }
            error={!isFalsy(errors["zoom_phone"])}
            helperText={errors["zoom_phone"]}
            placeholder={"Phone Number"}
          />

          <StyledTextField
            sx={defaultFormStyles}
            disabled={!canEditForm}
            value={values?.zoom_user_id || ""}
            onChange={(e) => {
              setFieldValue("zoom_user_id", e.target.value);
            }}
            label="User Id"
            placeholder={"User Id"}
            error={errors["zoom_user_id"] !== undefined}
            helperText={errors["zoom_user_id"]}
          />
        </ValuesContainer>

        <ErrorComponent error={updateMetadataError} />

        {updateMetadataSuccess && (
          <Typography variant="body1" color="green" margin={"10px"}>
            Zoom info has been updated successfully
          </Typography>
        )}
      </Card>
    </>
  );
};

const RenderStateLicensure = ({ user }: { user: UserType }) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [
    updateMetadataMutation,
    {
      isLoading: isUpdateMetadataLoading,
      isSuccess: updateMetadataSuccess,
      error: updateMetadataError
    }
  ] = useUpdateMetadataMutation();

  const { data: states, isLoading: isStatesLoading } =
    useGetLicensedStatesQuery();

  const validate = (values) => {
    const errors = {};

    if (!validateFieldLength(1, 50, values["athena_provider_id"])) {
      errors["athena_provider_id"] =
        "Please enter a value between 1 and 50 characters.";
    }

    return errors;
  };

  const { setFieldValue, values, errors, handleSubmit, dirty, isValid } =
    useFormik<{
      athena_provider_id: string;
      states: string[];
    }>({
      validate,
      initialValues: {
        athena_provider_id: user?.metadata?.athena_provider_id || "",
        states: user?.metadata?.states || []
      },
      onSubmit: async (values) => {
        const athena_provider_id = values?.athena_provider_id;
        const states = values?.states;
        await updateMetadataMutation({
          email: user.user.email,
          athena_provider_id:
            athena_provider_id?.length > 0 ? athena_provider_id : undefined,
          states
        });
      },
      enableReinitialize: true
    });

  const canEditForm = canUpdateMobileNumber(currentRole);
  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          State Licensure
        </Typography>
        <>
          {canEditForm && (
            <LoadingButton
              onClick={() => handleSubmit()}
              loading={isUpdateMetadataLoading}
              disabled={!dirty || !isValid}
            >
              Update State Licensure
            </LoadingButton>
          )}
        </>
      </Row>
      <Card>
        <ValuesContainer>
          <StyledTextField
            sx={defaultFormStyles}
            disabled={!canEditForm}
            value={values?.athena_provider_id || ""}
            onChange={(e) => {
              setFieldValue("athena_provider_id", e.target.value);
            }}
            label="Athena Provider ID"
            placeholder={"Athena Provider ID"}
            error={errors["athena_provider_id"] !== undefined}
            helperText={errors["athena_provider_id"]}
          />

          {states && (
            <StyledTextField
              sx={defaultFormStyles}
              disabled={!canEditForm}
              select
              label="States"
              placeholder={"States"}
              error={errors["states"] !== undefined}
              helperText={errors["states"]}
              slotProps={{
                select: {
                  variant: "outlined",
                  multiple: true,
                  value: values?.states || [],
                  onChange: (e) => setFieldValue("states", e.target.value)
                }
              }}
            >
              {states?.map(({ state_id }) => (
                <MenuItem key={state_id} value={state_id}>
                  {state_id}
                </MenuItem>
              ))}
            </StyledTextField>
          )}
          {isStatesLoading && <CircularProgress />}
        </ValuesContainer>

        <ErrorComponent error={updateMetadataError} />

        {updateMetadataSuccess && (
          <Typography variant="body1" color="green" margin={"10px"}>
            State Licensure info has been updated successfully
          </Typography>
        )}
      </Card>
    </>
  );
};

const RenderTeams = ({ user }: { user: UserType }) => {
  const { currentRole, user: currentUser } = useSelector(
    (state: RootState) => state.auth
  );

  const [selectedTeam, setSelectedTeam] = useState<TeamType>(user.team);

  const [
    deleteTeamMemberMutation,
    { error: errorDeleteMember, isLoading: isLoadingDeleteMember, reset }
  ] = useDeleteMemberMutation();

  const [
    addTeamMemberMutation,
    {
      isSuccess: isSuccessAddMember,
      error: errorAddMember,
      isLoading: isLoadingAddMember
    }
  ] = useAddMemberMutation();

  const allowedTeamTypes = useMemo(() => {
    const types = [];
    user?.user?.roles?.forEach((role) => {
      const allowedTypes = getAllowedTeamTypesForMemberRole(role);
      types.push(...allowedTypes);
    });
    const dedupe = [...new Set(types)];
    return dedupe;
  }, [user?.user?.roles]);

  const {
    data: allowedTeams,
    isFetching: isFetchingAllowedTeams,
    error: allowedTeamsError
  } = useGetTeamsQuery(
    { types: allowedTeamTypes },
    { skip: allowedTeamTypes?.length === 0 }
  );

  const {
    data: teamLeader,
    isFetching: isFetchingTeamLeader,
    error: teamLeaderError
  } = useGetUserWithUsernameQuery(
    {
      username: selectedTeam?.leader_id,
      linked_entities: [UserLinkedEntitiesEnum.TEAM]
    },
    { skip: selectedTeam === undefined }
  );

  const hasExistingTeam = user?.team?.team_id !== undefined;

  const roleHasPermissionToEdit = canUpdateStaffTeam(currentRole);
  const userCanEditTeam = canEditTeam(
    currentRole,
    TeamTypeEnum[user?.team?.type]
  );

  const canEditForm = roleHasPermissionToEdit && userCanEditTeam;

  const handleAssign = async () => {
    reset();
    if (user.team) {
      await deleteTeamMemberMutation({
        team_id: user.team.team_id,
        member_id: user.user.user_id
      });
    }

    await addTeamMemberMutation({
      team_id: selectedTeam.team_id,
      member_id: user.user.user_id
    });
  };

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Team
        </Typography>
        {allowedTeams && canEditForm && (
          <LoadingButton
            sx={{ alignSelf: "stretch" }}
            onClick={handleAssign}
            loading={isLoadingDeleteMember || isLoadingAddMember}
            disabled={selectedTeam?.team_id === user?.team?.team_id}
          >
            {hasExistingTeam ? "Reassign" : "Assign"}
          </LoadingButton>
        )}
      </Row>
      <Card
        style={{ cursor: "pointer" }}
        //onClick={() => navigate(Routes.TEAM_DETAILS(team.leader_id)}
      >
        <ValuesContainer>
          {allowedTeams && (
            <>
              <StyledTextField
                sx={defaultFormStyles}
                disabled={!canEditForm}
                value={selectedTeam?.team_id ?? ""}
                onChange={(e) => {
                  setSelectedTeam(
                    allowedTeams.find((team) => team.team_id === e.target.value)
                  );
                }}
                select
                label="Team Name"
              >
                {allowedTeams.map((team) => {
                  return (
                    <MenuItem key={team.team_id} value={team.team_id}>
                      {team.name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>

              <StyledTextField
                sx={defaultFormStyles}
                disableinput
                disabled
                value={getNameOrUsername(teamLeader?.user)}
                label="Team Leader"
              />
            </>
          )}
          {(isFetchingAllowedTeams || isFetchingTeamLeader) && (
            <CircularProgress />
          )}
          {allowedTeamsError && <ErrorComponent error={allowedTeamsError} />}
          {teamLeaderError && <ErrorComponent error={teamLeaderError} />}
        </ValuesContainer>

        <ErrorComponent
          style={{ margin: "10px" }}
          error={errorAddMember || errorDeleteMember}
        />
        {isSuccessAddMember && (
          <Typography variant="body1" color="green" margin={"10px"}>
            Member team has been updated successfully
          </Typography>
        )}
      </Card>
    </>
  );
};

const RenderScheduleType = ({
  user,
  currentRole
}: {
  user: UserType;
  currentRole: RolesEnum;
}) => {
  const dispatch = useAppDispatch();
  const {
    data: userScheduleTypes,
    isFetching: userScheduleTypesIsFetching,
    error: userScheduleTypesError
  } = useGetUserScheduleTypesQuery({});

  const [
    updateStaffScheduleTypeMutation,
    {
      isSuccess: isSuccessUpdateStaffScheduleType,
      error: errorUpdateStaffScheduleType,
      isLoading: isLoadingUpdateStaffScheduleType,
      fulfilledTimeStamp: fulfilledTimeStampUpdateStaffScheduleType,
      reset: resetUpdateStaffScheduleType
    }
  ] = useUpdateStaffScheduleTypeMutation();

  const currentScheduleType = useMemo(() => {
    return user?.schedule_type?.id;
  }, [user]);
  const [selectedScheduleType, setSelectedScheduleType] =
    useState(currentScheduleType);

  const mappedScheduleTypes = useMemo(() => {
    return userScheduleTypes?.map((scheduleType) => {
      return {
        value: scheduleType.id,
        label: scheduleType.name
      };
    });
  }, [userScheduleTypes]);

  const submitScheduleTypeChange = async () => {
    await updateStaffScheduleTypeMutation({
      staff_id: user?.user?.user_id,
      schedule_type: selectedScheduleType
    });
  };

  const isLoading = useGetDelayedLoadingBoolean(
    isLoadingUpdateStaffScheduleType,
    fulfilledTimeStampUpdateStaffScheduleType,
    isSuccessUpdateStaffScheduleType,
    DELAY_AFTER_UPDATE_SCHEDULE_REQUEST_COMPLETED
  );

  const onChangeScheduleType = useCallback(
    (e) => {
      if (currentScheduleType) {
        const modalId = "changeTimezoneModal";
        Alert_show({
          dispatch,
          id: modalId,
          title: "Error",
          size: "small",
          hideCloseIcon: true,
          content: (
            <Typography variant="h6" color={"error"}>
              Email eng.support@copilotiq.com to request this to be updated.
            </Typography>
          ),
          buttons: [
            {
              text: "Close",
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id: modalId });
              }
            }
          ]
        });
      } else {
        resetUpdateStaffScheduleType();
        setSelectedScheduleType(e.target.value);
      }
    },
    [currentScheduleType]
  );

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Schedule Type
        </Typography>

        <LoadingButton
          sx={{ alignSelf: "stretch" }}
          onClick={submitScheduleTypeChange}
          loading={isLoading}
          disabled={
            selectedScheduleType === currentScheduleType ||
            !canEditScheduleTypeOnProfileInfo(currentRole) ||
            isLoading
          }
        >
          Save
        </LoadingButton>
      </Row>
      <Card>
        <ValuesContainer>
          {userScheduleTypes && (
            <StyledTextField
              sx={{ width: "calc(50% - 5px)" }}
              disableinput={
                !canEditScheduleTypeOnProfileInfo(currentRole) || isLoading
              }
              disabled={
                !canEditScheduleTypeOnProfileInfo(currentRole) || isLoading
              }
              value={selectedScheduleType ?? ""}
              onChange={onChangeScheduleType}
              select
              label="Schedule Type"
            >
              {mappedScheduleTypes.map((schedule_type) => {
                return (
                  <MenuItem
                    key={schedule_type.value}
                    value={schedule_type.value}
                  >
                    {schedule_type.label}
                  </MenuItem>
                );
              })}
            </StyledTextField>
          )}
          {userScheduleTypesIsFetching && <LoadingFallback count={1} />}
          {userScheduleTypesError && (
            <ErrorComponent error={userScheduleTypesError} />
          )}
          {errorUpdateStaffScheduleType && (
            <ErrorComponent error={errorUpdateStaffScheduleType} />
          )}
          {isSuccessUpdateStaffScheduleType && (
            <DelayedRender
              delay={DELAY_AFTER_UPDATE_SCHEDULE_REQUEST_COMPLETED}
            >
              <Typography variant="body1" color="green" margin={"10px"}>
                Staff schedule type has been updated successfully
              </Typography>
            </DelayedRender>
          )}
        </ValuesContainer>
      </Card>
    </>
  );
};

const RenderSchedule = ({ user }: { user: Partial<UserType> }) => {
  const dispatch = useAppDispatch();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [isIntakeSchedulingChecked, setIsIntakeSchedulingChecked] =
    useState<boolean>(user?.metadata?.excluded_from_intake_flow ?? false);
  const [isIntakeSchedulingLoading, setIsIntakeSchedulingLoading] =
    useState<boolean>(false);

  const [
    updateMetadataMutation,
    {
      isSuccess: updateMetadataSuccess,
      data: updateMetadataData,
      error: updateMetadataError,
      reset: resetUpdateMetadata
    }
  ] = useUpdateMetadataMutation();

  const canEdit = userCanAllowDisallowScheduling(currentRole);

  useEffect(() => {
    if (isIntakeSchedulingLoading && updateMetadataError) {
      setIsIntakeSchedulingLoading(false);
      Alert_show({
        dispatch,
        id: "updateMetadataError",
        title: "Error",
        content: <ErrorComponent error={updateMetadataError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              resetUpdateMetadata();
              Alert_close({ dispatch, id: "updateMetadataError" });
            }
          }
        ]
      });
    } else if (
      isIntakeSchedulingLoading &&
      updateMetadataSuccess &&
      updateMetadataData
    ) {
      setIsIntakeSchedulingLoading(false);
      setIsIntakeSchedulingChecked(
        updateMetadataData?.excluded_from_intake_flow
      );
      resetUpdateMetadata();
    }
  }, [
    updateMetadataSuccess,
    updateMetadataData,
    updateMetadataError,
    isIntakeSchedulingLoading
  ]);

  const handleIntakeSchedulingChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsIntakeSchedulingLoading(true);

    await updateMetadataMutation({
      email: user.user.email,
      excluded_from_intake_flow:
        // event.target.checked is if they are available for intake scheduling
        // the opposite of the value of 'excluded_from_intake_flow'
        !event.target.checked
    });
  };

  const [isSchedulingChecked, setIsSchedulingChecked] = useState<boolean>(
    user?.metadata?.excluded_from_scheduling ?? false
  );
  const [isSchedulingLoading, setIsSchedulingLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSchedulingLoading && updateMetadataError) {
      setIsSchedulingLoading(false);
      Alert_show({
        dispatch,
        id: "updateMetadataError",
        title: "Error",
        content: <ErrorComponent error={updateMetadataError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              resetUpdateMetadata();
              Alert_close({ dispatch, id: "updateMetadataError" });
            }
          }
        ]
      });
    } else if (
      isSchedulingLoading &&
      updateMetadataSuccess &&
      updateMetadataData
    ) {
      setIsSchedulingLoading(false);
      setIsSchedulingChecked(updateMetadataData?.excluded_from_scheduling);
      resetUpdateMetadata();
    }
  }, [
    updateMetadataSuccess,
    updateMetadataData,
    updateMetadataError,
    isSchedulingLoading
  ]);

  const handleSchedulingChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSchedulingLoading(true);

    await updateMetadataMutation({
      email: user.user.email,
      excluded_from_scheduling:
        // event.target.checked is if they are available for intake scheduling
        // the opposite of the value of 'excluded_from_scheduling'
        !event.target.checked
    });
  };

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Calendar
        </Typography>
      </Row>
      <Card>
        <ValuesContainer>
          <Box>
            <Flexbox flexDirection="row" alignItems="center" gap="2px">
              <Typography variant="body1">
                Available for Intake Scheduling{" "}
              </Typography>
              {isIntakeSchedulingLoading ? (
                <>
                  &nbsp;
                  <CircularProgress />
                </>
              ) : (
                <Switch
                  disabled={!canEdit}
                  // show if they are available for intake scheduling, the opposite of the value of 'excluded_from_intake_flow'
                  checked={!isIntakeSchedulingChecked}
                  onChange={handleIntakeSchedulingChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              <CustomTooltip
                title={
                  <>
                    If disabled, this staff member will not be available
                    <br />
                    for scheduling during the new member intake flow
                  </>
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </Flexbox>
            <Flexbox flexDirection="row" alignItems="center" gap="2px">
              <Typography variant="body1">Available for Scheduling </Typography>
              {isSchedulingLoading ? (
                <>
                  &nbsp;
                  <CircularProgress />
                </>
              ) : (
                <Switch
                  disabled={!canEdit}
                  // show if they are available for scheduling, the opposite of the value of 'excluded_from_scheduling'
                  checked={!isSchedulingChecked}
                  onChange={handleSchedulingChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              <CustomTooltip
                title={
                  <>
                    If disabled, this staff member will not be available
                    <br />
                    for scheduling from the member chart scheduling view
                  </>
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </Flexbox>
          </Box>
        </ValuesContainer>
      </Card>
    </>
  );
};

const defaultFormStyles = { flex: "1" };
const getColorByRole = (role: RolesEnum, theme: Theme) => {
  switch (role) {
    case RolesEnum.TECHNICAL_SUPPORT:
    case RolesEnum.ADMIN:
      // @ts-ignore TS complaining not sure why
      return theme.color.grey;
    case RolesEnum.RCM_ADMIN:
      // @ts-ignore TS complaining not sure why
      return theme.color.yellow;
    case RolesEnum.SALES_DIRECTOR:
    case RolesEnum.NPS_MANAGER:
      // @ts-ignore TS complaining not sure why
      return theme.color.blue;
    case RolesEnum.NPS:
    case RolesEnum.RETENTION_SPECIALIST:
      // @ts-ignore TS complaining not sure why
      return theme.color.lightBlue;
    case RolesEnum.TH_NURSE:
    case RolesEnum.NP_NURSE:
    case RolesEnum.NURSE_PROVIDER:
    case RolesEnum.MD_PROVIDER:
    case RolesEnum.MEMBER_CARE_SPECIALIST:
      // @ts-ignore TS complaining not sure why
      return theme.color.green;
    case RolesEnum.NPN_MANAGER:
    case RolesEnum.NURSE_DIRECTOR:
    case RolesEnum.THN_MANAGER:
    case RolesEnum.PROVIDER_MANAGER:
      // @ts-ignore TS complaining not sure why
      return theme.color.darkGreen;
  }
};

const StaffDetailsRender = ({
  user,
  currentRole,
  authenticatedUser,
  tabIndex,
  setTabIndex,
  staffId
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tab } = useParams();

  const canSeeAssignments = useMemo(() => {
    const roles = user?.user?.roles;
    return canSeeAssignmentsOnStaffProfile(roles, currentRole);
  }, [user, currentRole]);

  const canSeeStaffTasks = useMemo(() => {
    if (user === undefined) return;
    const roles = user?.user?.roles;
    return roles.some((role) => canSeeTasks(role)) && canSeeTasks(currentRole);
  }, [user, currentRole]);

  const canSeeSchedule = useMemo(() => {
    const roles = user?.user?.roles;
    return (
      canSeeScheduleOnStaffProfile(currentRole) &&
      staffMemberCanHaveSchedule(roles)
    );
  }, [user, currentRole]);

  const canSeeStaffEncounters = useMemo(() => {
    if (user === undefined) return;

    const roles = user?.user?.roles;
    return (
      canSeeEncountersOnStaffDetailsCurrentRole(currentRole) &&
      staffMemberRoleCanSubmitEncounters(roles)
    );
  }, [user, currentRole, authenticatedUser]);

  const canSeeStaffOrders = useMemo(() => {
    const roles = user?.user?.roles;
    return (
      canSeeOrdersOnStaffDetailsCurrentRole(currentRole) &&
      staffMemberCanHaveOrders(roles)
    );
  }, [user]);

  const canSeeActivity = canSeeActivityLog(currentRole);

  const isProvider = useMemo(() => {
    if (user === undefined) return;
    const roles = user?.user?.roles;

    return roles.some((role) => isProviderRole(role));
  }, [user]);

  const validate = (values) => {
    const errors = {};

    if (!validateFieldLength(2, 50, values["first"])) {
      errors["first"] = "Please enter a value between 2 and 50 characters.";
    }

    if (!validateFieldLength(2, 50, values["last"])) {
      errors["last"] = "Please enter a value between 2 and 50 characters.";
    }

    if (
      values["timezone"] != undefined &&
      values["timezone"] != "" &&
      timezones.find((timezone) => {
        return timezone.value == values["timezone"];
      }) === undefined
    ) {
      errors["timezone"] = "Please select a valid timezone.";
    }

    return errors;
  };

  const [initialValues, setInitialValues] = useState<FormType>(user?.user);

  const onChangeTimezone = useCallback(
    (e) => {
      const modalId = "changeTimezoneModal";
      Alert_show({
        dispatch,
        id: modalId,
        title: "Error",
        size: "small",
        hideCloseIcon: true,
        content: (
          <Typography variant="h6" color={"error"}>
            Email eng.support@copilotiq.com to request this to be updated.
          </Typography>
        ),
        buttons: [
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id: modalId });
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const onSubmit = async (values, initialValues) => {
    const changes: Partial<UserTypeInner> = {};
    const valuesKeys = Object.keys(values);
    valuesKeys.forEach((key) => {
      if (!isEqual(initialValues[key], values[key])) {
        let value = values[key];
        if (key === "phone" || key === "mobile") {
          value = unmaskPhoneNumber(value).trim();
        }
        if (key === "first" || key === "last" || key === "middle") {
          value = value.trim();
        }
        changes[key] = value;
      }
    });

    await updateUserByUsernameMutation({
      email: user?.user?.user_id,
      user: changes
    });
  };

  const { setFieldValue, values, errors, handleSubmit, dirty, isValid } =
    useFormik<FormType>({
      validate,
      initialValues,
      onSubmit: (values) => {
        onSubmit(values, initialValues).catch((error) => {});
      },
      enableReinitialize: true
    });

  const featureFlags = useFeatureFlags();

  const canEditForm = canEditUserDetails(currentRole);

  const showsStateLicensure = useMemo(() => {
    const roles = user?.user?.roles;
    return hasStateLicensure(roles);
  }, [user]);

  const now = DateTime.now().endOf("minute");
  const endDate = useMemo(() => {
    if (hasProviderRole(user?.user?.roles)) {
      return now
        .startOf("day")
        .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });
    }
    return now.startOf("day").plus({ days: DAYS_TO_LOOK_AHEAD });
  }, [now, DAYS_TO_LOOK_AHEAD, user?.user?.roles]);

  useEffect(() => {
    switch (tab) {
      case "assignments":
        setTabIndex(TabPanelIndex.assignments);
        break;
      case "tasks":
        setTabIndex(TabPanelIndex.tasks);
        break;
      case "schedule":
        setTabIndex(TabPanelIndex.schedule);
        break;
      case "profile":
        setTabIndex(TabPanelIndex.profile);
        break;
      case "encounters":
        setTabIndex(TabPanelIndex.encounters);
        break;
      case "orders":
        setTabIndex(TabPanelIndex.orders);
        break;
      case "activity_log":
        setTabIndex(TabPanelIndex.activity_log);
        break;
    }
  }, [currentRole, tab]);

  const pathname = useMemo(() => {
    const paths = location.pathname.split("/");
    if (paths?.length === 5) paths.pop();

    return paths.join("/");
  }, [location]);

  const [changeRoleModalOpen, setChangeRoleModalOpen] =
    useState<boolean>(false);

  const [
    updateUserByUsernameMutation,
    {
      isLoading: isUpdateUserLoading,
      isSuccess: updateUserSuccess,
      error: updateUserError
    }
  ] = useUpdateUserByUsernameMutation();

  const [changeUserStatusMutation, { error: updateStatusError }] =
    useChangeUserStatusMutation();

  const canUpdateRole = currentRole === RolesEnum.ADMIN;

  useEffect(() => {
    // make a list of tabs the user can view
    const canViewTabs = [].concat(
      canSeeAssignments ? "assignments" : [],
      canSeeStaffTasks ? "tasks" : [],
      canSeeSchedule ? "schedule" : [],
      canSeeStaffEncounters ? "encounters" : [],
      canSeeStaffOrders ? "orders" : [],
      canSeeActivity ? "activity_log" : [],
      "profile"
    );

    if (tab === "assignments" && canSeeAssignments)
      setTabIndex(TabPanelIndex.assignments);
    else if (tab === "tasks" && canSeeStaffTasks)
      setTabIndex(TabPanelIndex.tasks);
    else if (tab === "schedule" && canSeeSchedule)
      setTabIndex(TabPanelIndex.schedule);
    else if (tab === "encounters" && canSeeStaffEncounters)
      setTabIndex(TabPanelIndex.encounters);
    else if (tab === "orders" && canSeeStaffOrders)
      setTabIndex(TabPanelIndex.orders);
    else if (tab === "activity_log" && canSeeActivity)
      setTabIndex(TabPanelIndex.activity_log);
    else if (tab === "profile") setTabIndex(TabPanelIndex.profile);
    else {
      // set the tab to the first tab the user can view
      setTabIndex(TabPanelIndex[canViewTabs[0]]);
      navigate(pathname + `/${canViewTabs[0]}`);
    }
  }, [
    pathname,
    tab,
    tabIndex,
    canSeeAssignments,
    canSeeStaffTasks,
    canSeeStaffEncounters,
    currentRole
  ]);

  return (
    <>
      <Container>
        <Card>
          <Row>
            <Row style={{ flex: 1 }}>
              <Column>
                <Typography variant="h3" color="black">
                  {getNameOrUsername(user?.user)}
                </Typography>
                {user?.user.enrolled && (
                  <Typography>
                    Joined{" "}
                    {DateTime.fromISO(user?.user.enrolled).toFormat(
                      "MM/dd/yyyy"
                    )}
                  </Typography>
                )}
              </Column>
              <StatusBadge status={user?.user.status} />
            </Row>
            {canUpdateRole && (
              <>
                <CustomTooltip title="Set Inactive" placement="top">
                  <div>
                    <StyledIconButton
                      iconColor={theme.palette.primary.main}
                      border="square"
                      Icon={Block}
                      disabled={
                        MemberStatusEnum.INACTIVE === user?.user?.status
                      }
                      onClick={() =>
                        changeUserStatusMutation({
                          email: user?.user?.user_id,
                          status: MemberStatusEnum.INACTIVE
                        })
                      }
                    />
                  </div>
                </CustomTooltip>
                <StyledIconButton
                  iconColor={theme.palette.primary.main}
                  border="square"
                  Icon={AssignmentInd}
                  onClick={() => setChangeRoleModalOpen(true)}
                />
              </>
            )}

            <ChipContainer>
              {user?.user?.roles.map((role) => {
                return (
                  <Chip
                    sx={{
                      margin: 0.5,
                      fontWeight: 550,
                      backgroundColor: getColorByRole(role, theme),
                      color: "white"
                    }}
                    key={role}
                    label={getRoleLabel(role)}
                  />
                );
              })}
            </ChipContainer>
          </Row>

          <ErrorComponent
            style={{ margin: "10px" }}
            error={updateStatusError}
          />
        </Card>

        <br />
        <StyledTabs
          value={tabIndex}
          aria-label="Member Details Profile Tabs"
          style={{}}
        >
          {canSeeAssignments && (
            <Tab
              label={"Assignments"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/assignments")}
              value={TabPanelIndex.assignments}
            />
          )}

          {canSeeStaffTasks && (
            <Tab
              label={"Tasks"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/tasks")}
              value={TabPanelIndex.tasks}
            />
          )}

          {canSeeSchedule && (
            <Tab
              label={"Schedule"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/schedule")}
              value={TabPanelIndex.schedule}
            />
          )}

          {canSeeStaffEncounters && (
            <Tab
              label={"Encounters"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/encounters")}
              value={TabPanelIndex.encounters}
            />
          )}

          <Tab
            label={"Profile info"}
            style={{ fontWeight: "600" }}
            onClick={() => navigate(pathname + "/profile")}
            value={TabPanelIndex.profile}
          />

          {canSeeStaffOrders && (
            <Tab
              label={"Orders"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/orders")}
              value={TabPanelIndex.orders}
            />
          )}

          {featureFlags.USER_PRESENCE_MANAGEMENT && canSeeActivity && (
            <Tab
              label={"Activity Log"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/activity_log")}
              value={TabPanelIndex.activity_log}
            />
          )}
        </StyledTabs>
      </Container>

      {canSeeAssignments &&
        tabIndex === TabPanelIndex.assignments && [
          MEMBERS_WITH_NO_ACTIVITY({}),
          ASSIGNED_MEMBERS({}),
          isProvider === false && RECENT_ORDERS()
        ]}

      {tabIndex === TabPanelIndex.tasks && (
        <>
          <Tasks
            tasksStatuses={[TaskStatusEnum.TODO, TaskStatusEnum.IN_PROGRESS]}
            componentHeader={"To Dos"}
            showWhenNoTasks
            noTasksMessage={
              "You have no pending tasks that require imminent action."
            }
            tableHeight={"90%"}
            showResolveButton={true}
            showActionButtons={canSeeCareFlow(currentRole)}
            nurseId={staffId}
            // don't show "View Completed" when viewing staff details because it is shown below
            link={null}
          />

          <Tasks
            tasksStatuses={[TaskStatusEnum.COMPLETED]}
            nurseId={staffId}
            componentHeader={"Completed Tasks"}
            datePickerEnabled={true}
            noTasksMessage={
              "You haven't completed any tasks yet. This is where you’ll see your completed tasks"
            }
            showResolveButton={false}
            showActionButtons={false}
            tableHeight={"90%"}
          />
        </>
      )}

      {tabIndex === TabPanelIndex.schedule && (
        <>
          <NewScheduleToDoContainer nurseId={staffId} />

          <Container>
            <UpcomingAppointments
              staffId={staffId}
              startDate={now}
              endDate={endDate}
              isStaffDetails={true}
              staffTimezone={user?.user?.timezone}
            />

            <PastAppointments
              staffId={staffId}
              isStaffDetails={true}
              staffTimezone={user?.user?.timezone}
            />
          </Container>
        </>
      )}

      {tabIndex === TabPanelIndex.profile && (
        <Container>
          <Row>
            <Typography flex={1} variant={"h3"} color="black">
              Profile Info
            </Typography>
            {canEditForm && (
              <LoadingButton
                onClick={() => handleSubmit()}
                loading={isUpdateUserLoading}
                disabled={!dirty || !isValid}
              >
                Update User
              </LoadingButton>
            )}
          </Row>
          <Card>
            <ValuesContainer style={{ flexDirection: "column" }}>
              <Row>
                <StyledTextField
                  sx={defaultFormStyles}
                  disabled={!canEditForm}
                  value={values?.first || ""}
                  onChange={(e) => {
                    setFieldValue("first", e.target.value);
                  }}
                  label="First Name"
                  placeholder={"Enter first name here"}
                  error={errors["first"] !== undefined}
                  helperText={errors["first"]}
                  autoComplete="given-name"
                />
                <StyledTextField
                  sx={defaultFormStyles}
                  disableinput={true}
                  disabled={true}
                  value={values?.email || ""}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                  label="Email"
                  placeholder={"Enter email here"}
                  error={errors["email"] !== undefined}
                  helperText={errors["email"]}
                  autoComplete="email"
                />
              </Row>

              <Row>
                <StyledTextField
                  sx={defaultFormStyles}
                  disabled={!canEditForm}
                  value={values?.middle || ""}
                  onChange={(e) => {
                    setFieldValue("middle", e.target.value);
                  }}
                  label="Middle Name"
                  placeholder={"Enter middle name here"}
                  error={errors["middle"] !== undefined}
                  helperText={errors["middle"]}
                  autoComplete="additional-name"
                />

                <StyledTextField
                  sx={defaultFormStyles}
                  disabled={!canEditForm}
                  value={values?.timezone || ""}
                  onChange={onChangeTimezone}
                  select
                  label="Timezone"
                  placeholder={"N/A"}
                  error={errors["timezone"] !== undefined}
                  helperText={errors["timezone"]}
                >
                  {timezones.map(({ label, value }) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Row>

              <Row>
                <StyledTextField
                  sx={defaultFormStyles}
                  disabled={!canEditForm}
                  value={values?.last || ""}
                  onChange={(e) => {
                    setFieldValue("last", e.target.value);
                  }}
                  label="Last Name"
                  placeholder={"Enter last name here"}
                  error={errors["last"] !== undefined}
                  helperText={errors["last"]}
                  autoComplete="family-name"
                />

                <div style={{ flex: 1 }} />
              </Row>
            </ValuesContainer>

            <ErrorComponent error={updateUserError} />
            {updateUserSuccess && (
              <Typography variant="body1" color="green" margin={"10px"}>
                User info has been updated successfully
              </Typography>
            )}
          </Card>

          <br />

          {user &&
            canSeeScheduleTypeOnProfileInfo(currentRole) &&
            userCanHaveScheduleType(user?.user?.roles) && (
              <RenderScheduleType user={user} currentRole={currentRole} />
            )}

          {user && canSeeTeamsOnProfileInfo(user?.user?.roles) && (
            <>
              <RenderTeams user={user} />

              <br />
              {userCanBeScheduled(user.user.roles) && (
                <>
                  <RenderSchedule user={user} />

                  <br />
                </>
              )}
              <RenderZoomInfo user={user} />

              {showsStateLicensure && (
                <>
                  <br />
                  <RenderStateLicensure user={user} />
                </>
              )}
            </>
          )}
        </Container>
      )}

      {tabIndex === TabPanelIndex.encounters && (
        <Container>
          <EncountersTab />
        </Container>
      )}

      {tabIndex === TabPanelIndex.orders && (
        <Container>
          <OrdersTab staffId={staffId} />
        </Container>
      )}

      {featureFlags.USER_PRESENCE_MANAGEMENT &&
        canSeeActivity &&
        tabIndex === TabPanelIndex.activity_log && (
          <Container>
            <ActivityLogTab staffId={staffId} />
          </Container>
        )}

      <br />

      {user && (
        <ChangeRoleModal
          isVisible={changeRoleModalOpen}
          onRequestClose={() => setChangeRoleModalOpen(false)}
          user={user}
        />
      )}
    </>
  );
};

const StaffDetails = () => {
  const { staffId } = useSanitizedParams();

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError
  } = useGetUserWithUsernameQuery({
    username: staffId,
    linked_entities: [
      UserLinkedEntitiesEnum.TEAM,
      UserLinkedEntitiesEnum.METADATA,
      UserLinkedEntitiesEnum.SCHEDULE_TYPE
    ]
  });

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [tabIndex, setTabIndex] = useState<number | string>(0);

  const isLoading = isUserLoading;
  const error = userError;

  return (
    <>
      {user && currentRole && authenticatedUser ? (
        <StaffDetailsRender
          user={user}
          currentRole={currentRole}
          authenticatedUser={authenticatedUser}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          staffId={staffId}
        />
      ) : (
        <>
          {isLoading && <LoadingFallback />}
          {error && <ErrorComponent error={error} />}
        </>
      )}
    </>
  );
};

export default StaffDetails;
