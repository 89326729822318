import { useEffect, useMemo, useState } from "react";
import {
  Tab,
  Tabs,
  Button,
  Typography,
  styled,
  InputAdornment,
  Box,
  MenuItem,
  TextField
} from "@mui/material";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import LoadingFallback from "common/helpers/components/LoadingFallback";
import { RootState } from "common/redux";

import RolesEnum, { canEditDeleteTimeOff } from "common/enums/RolesEnum";

import ErrorComponent from "../../../components/ErrorComponent";

import { NURSE_LIST } from "../../../routes/RouteComponents";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";

import { Flexbox } from "../../../styling/NewStyleComponents";

import { useGetTeamNursePtoQuery } from "common/services/CalendarService";
import { FilterAltOutlined, LoopOutlined, Search } from "@mui/icons-material";
import Table from "../../../components/Table/Table";
import { firstLastUsernameSearch } from "../../../helpers/helpers";
import { checkIdValid } from "common/helpers/helpers";
import { useGetTeamsQuery } from "common/services/TeamsService";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import DebouncedInput from "../../../components/Input/DebouncedInput";
import NewAddTimeOffModal from "./NewAddTimeOffModal";
import { TurqoiseButton } from "../../../styling";
import { useGetSortedMembersWithActiveNursesQuery } from "common/services/ReportsService";
import UserStatusEnum from "common/enums/UserStatusEnum";
import { gray } from "common/styling/colors";
import DatePicker from "../../../components/DatePicker";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import Routes from "../../../routes/Routes";

const Container = styled("div")`
  margin: 20px 2.5%;
  overflow: scroll;
`;

const StyledTabs = styled(Tabs)`
  margin: 0px;
`;

export const StyledInput = styled(DebouncedInput)`
  background: white;
  width: auto;
`;

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

enum TabPanelIndex {
  nurse_schedules = 0,
  time_off = 1
}

const NurseSchedulesRender = ({
  currentRole,
  tabIndex,
  setTabIndex,
  teamsData,
  nurses,
  defaultTeamData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tab } = useParams();

  const defaultTeamId = defaultTeamData?.team_id;
  const defaultTeamLeaderId = defaultTeamData?.leader_id;

  const [selectedTeamData, setSelectedTeamData] =
    useState<any>(defaultTeamData);

  const [selectedTeamId, setSelectedTeamId] = useState<string>(defaultTeamId);
  const [selectedTeamLeaderId, setSelectedTeamLeaderId] =
    useState<string>(defaultTeamLeaderId);

  const defaultStartDate = DateTime.now().startOf("day");
  const defaultEndDate = DateTime.now().plus({ days: 6 }).endOf("day");

  const [startDate, setStartDate] = useState<DateTime | null>(defaultStartDate);

  const [endDate, setEndDate] = useState<DateTime | null>(defaultEndDate);

  const {
    data: teamNursePtoData,
    isFetching: isTeamNursePtoFetching,
    error: teamNursePtoError
  } = useGetTeamNursePtoQuery(
    {
      team_id: selectedTeamId,
      startdate: startDate,
      enddate: endDate
    },
    {
      skip:
        tab !== "time_off" ||
        !checkIdValid(selectedTeamId) ||
        !startDate?.isValid ||
        !endDate?.isValid ||
        startDate > endDate
    }
  );

  useEffect(() => {
    switch (tab) {
      case "schedules":
        setTabIndex(TabPanelIndex.nurse_schedules);
        break;
      case "time_off":
        setTabIndex(TabPanelIndex.time_off);
        break;
    }
  }, [currentRole, tab]);

  const pathname = useMemo(() => {
    const paths = location.pathname.split("/");
    if (paths?.length === 3) paths.pop();

    return paths.join("/");
  }, [location]);

  const [addTimeOffModalOpen, setAddTimeOffModalOpen] =
    useState<boolean>(false);
  const [isEditTimeOffModalOpen, setIsEditTimeOffModalOpen] =
    useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>("");
  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const resetDisabled = useMemo(() => {
    return (
      selectedTeamId === defaultTeamId &&
      startDate?.equals(defaultStartDate) &&
      endDate?.equals(defaultEndDate) &&
      searchText === ""
    );
  }, [
    selectedTeamId,
    defaultTeamId,
    startDate,
    endDate,
    defaultStartDate,
    defaultEndDate,
    searchText
  ]);

  const handleReset = () => {
    setSelectedTeamData(defaultTeamData);
    setSelectedTeamId(defaultTeamId);
    setSelectedTeamLeaderId(defaultTeamLeaderId);
    setSearchText("");
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };

  useEffect(() => {
    if (tab === "nurse_schedules") {
      // set the tab to the first tab the user can view
      setTabIndex(TabPanelIndex.nurse_schedules);
      navigate(Routes.NURSE_SCHEDULE_DETAILS("schedules"));
    } else if (tab === "time_off") {
      setTabIndex(TabPanelIndex.time_off);
      navigate(Routes.NURSE_SCHEDULE_DETAILS("time_off"));
    }
  }, [pathname, tab, tabIndex, currentRole]);

  const filteredTeamNursePtoData = useMemo(() => {
    if (teamNursePtoError) return null;
    let data = teamNursePtoData;
    if (searchText?.length > 0) {
      data = firstLastUsernameSearch(
        searchText,
        teamNursePtoData,
        "staff.fullname",
        "staff.fullname"
      );
    }

    return data;
  }, [teamNursePtoData, selectedTeamLeaderId, searchText, teamNursePtoError]);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Container>
        <Typography variant="h3" color="black" paddingLeft="16px">
          Nurses Schedules
        </Typography>

        <br />
        <Flexbox justifyContent="space-between" alignItems="center">
          <StyledTabs
            value={tabIndex}
            aria-label="Member Details Profile Tabs"
            style={{}}
          >
            <Tab
              label={"Schedules"}
              style={{ fontWeight: "600" }}
              onClick={() =>
                navigate(Routes.NURSE_SCHEDULE_DETAILS("schedules"))
              }
              value={TabPanelIndex.nurse_schedules}
            />

            <Tab
              label={"Time Off"}
              aria-label={"Time Off"}
              style={{ fontWeight: "600" }}
              onClick={() =>
                navigate(Routes.NURSE_SCHEDULE_DETAILS("time_off"))
              }
              value={TabPanelIndex.time_off}
            />
          </StyledTabs>
          {canEditDeleteTimeOff(currentRole) && (
            <TurqoiseButton
              aria-label="Add Time Off"
              sx={{ width: "130px" }}
              onClick={() => {
                setAddTimeOffModalOpen(true);
              }}
            >
              Add Time Off
            </TurqoiseButton>
          )}
        </Flexbox>
      </Container>

      {tabIndex === TabPanelIndex.nurse_schedules && (
        <>
          {NURSE_LIST({
            componentHeader: "",
            roles: [RolesEnum.TH_NURSE],
            externalLink: Routes.STAFF_DETAILS(":nurseId", "schedule"),
            tableColumns: [
              {
                name: "fullname",
                accessor: "user.fullname",
                filterEnabled: true,
                filterFn: "fuzzy",
                size: 300
              }
            ]
          })}
        </>
      )}

      {tabIndex === TabPanelIndex.time_off && (
        <>
          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            margin="0 20px"
          >
            <Box>
              <Row>
                <FilterAltOutlined color="primary" />
                <Typography variant="h6" color="primary">
                  Filter by
                </Typography>

                {teamsData && (
                  <TextField
                    value={selectedTeamData}
                    select
                    label={"Team"}
                    sx={{ width: 200, backgroundColor: "white" }}
                    id={"Team"}
                    defaultValue={defaultTeamData}
                    slotProps={{
                      select: {
                        variant: "outlined",
                        value: selectedTeamData,
                        onChange: (event) => {
                          setSelectedTeamData(event.target.value);
                          setSelectedTeamId(
                            // @ts-ignore
                            event.target.value?.team_id as string
                          );
                          setSelectedTeamLeaderId(
                            // @ts-ignore
                            event.target.value?.leader_id as string
                          );
                        }
                      }
                    }}
                  >
                    {teamsData.map((value) => (
                      <MenuItem
                        key={value.team_id + value.name}
                        value={value}
                        id={value.name}
                      >
                        {value.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                <Flexbox alignItems="center" justifyContent="space-between">
                  <DatePicker
                    label={"Start date"}
                    aria-label={"Start date"}
                    name={"StartDate"}
                    value={startDate}
                    onChange={(dateTime: DateTime) => {
                      if (dateTime?.isValid) {
                        setStartDate(dateTime.startOf("day"));
                        setEndDate(dateTime.plus({ days: 6 }).endOf("day"));
                      }
                    }}
                    slotProps={{
                      field: {
                        readOnly: true
                      },
                      textField: {
                        error: startDate && endDate && startDate > endDate,
                        helperText:
                          startDate &&
                          endDate &&
                          startDate > endDate &&
                          "Start date must be less than end date",
                        // prevent user from typing in the date as this can lead to bugs
                        // see ENG-3757
                        // the below code needs to be here instead of in DateTimePicker.tsx
                        // until this PR is merged https://github.com/mui/material-ui/pull/35088
                        onKeyDown: (e) => {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <Typography margin="0 24px" color={gray[500]}>
                    to
                  </Typography>
                  <DatePicker
                    label={"End date"}
                    aria-label={"End date"}
                    name={"EndDate"}
                    value={endDate}
                    onChange={(dateTime: DateTime) => {
                      if (dateTime?.isValid) {
                        setEndDate(dateTime.endOf("day"));
                      }
                    }}
                    slotProps={{
                      field: {
                        readOnly: true
                      },
                      textField: {
                        error: startDate && endDate && startDate > endDate,
                        helperText:
                          startDate &&
                          endDate &&
                          startDate > endDate &&
                          "End date must be greater than start date",
                        // prevent user from typing in the date as this can lead to bugs
                        // see ENG-3757
                        // the below code needs to be here instead of in DateTimePicker.tsx
                        // until this PR is merged https://github.com/mui/material-ui/pull/35088
                        onKeyDown: (e) => {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                </Flexbox>

                <Button
                  startIcon={<LoopOutlined />}
                  onClick={handleReset}
                  disabled={resetDisabled}
                >
                  Reset
                </Button>
              </Row>
            </Box>

            <Box>
              <StyledInput
                sx={{ width: "auto" }}
                debounceTimeout={300}
                value={searchText}
                onChange={handleText}
                type="text"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Flexbox>

          <Box sx={{ margin: "0 18px 0 32px", overflowX: "scroll" }}>
            {isTeamNursePtoFetching && <LoadingFallback />}
            {teamNursePtoError && !isTeamNursePtoFetching && (
              <ErrorComponent
                error={teamNursePtoError}
                showErrorResponseMessage
                hideErrorCode
              />
            )}
            {filteredTeamNursePtoData?.length >= 0 &&
              !isTeamNursePtoFetching && (
                <Table
                  tableColumns={[
                    {
                      name: "ptoNurseName"
                    },
                    {
                      name: "ptoNursePeriod"
                    },
                    {
                      name: "ptoNurseDuration"
                    },
                    {
                      name: "ptoNursePolicy"
                    },
                    {
                      name: "ptoNurseToReassign"
                    }
                  ]}
                  tableProps={{
                    navigate,
                    currentRole,
                    setIsEditTimeOffModalOpen
                  }}
                  noDataText="No data found."
                  data={filteredTeamNursePtoData}
                />
              )}
          </Box>
        </>
      )}

      <br />

      <NewAddTimeOffModal
        modalKey="nurseSchedulesEditTimeOffModal"
        isEdit={true}
        isVisible={isEditTimeOffModalOpen}
        onRequestClose={(refetch: boolean) => {
          if (refetch) {
            // tbd add refetch function
            // refetchCalendarVisits();
          }
          setIsEditTimeOffModalOpen(false);
        }}
      />

      <NewAddTimeOffModal
        modalKey="nurseSchedulesAddTimeOffModal"
        isVisible={addTimeOffModalOpen}
        onRequestClose={() => setAddTimeOffModalOpen(false)}
        nurses={nurses}
        teamId={selectedTeamId}
      />
    </div>
  );
};

const NewNurseSchedules = () => {
  const { tab } = useParams();

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const {
    data: teamsData,
    isFetching: isFetchingTeams,
    error: teamsError
  } = useGetTeamsQuery({ types: [TeamTypeEnum.TH_NURSES] });

  const {
    data: nurses,
    error: nursesError,
    isFetching: isFetchingNurses
  } = useGetSortedMembersWithActiveNursesQuery(
    {
      carer_roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER],
      carer_status: [UserStatusEnum.ACTIVE],
      getNurseListForAppointments: true,
      is_excluded_from_scheduling: false
    },
    {}
  );

  const { data: authenticatedUser } = useGetAuthenticatedUser({
    linkedEntities: [UserLinkedEntitiesEnum.TEAM]
  });

  const [tabIndex, setTabIndex] = useState<number | string>(
    TabPanelIndex[tab] ?? 0
  );

  const isFetching = isFetchingNurses || isFetchingTeams;

  return (
    <>
      {user && currentRole && authenticatedUser && !isFetching ? (
        <NurseSchedulesRender
          teamsData={teamsData}
          defaultTeamData={
            teamsData?.find(
              (item) => item.team_id === authenticatedUser?.team?.team_id
            ) ??
            teamsData?.find(
              (item) => item.leader_id === authenticatedUser?.user?.user_id
            )
          }
          currentRole={currentRole}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          nurses={nurses}
        />
      ) : (
        <>
          {isFetching && <LoadingFallback />}
          {teamsError && <ErrorComponent error={teamsError} />}
          {nursesError && <ErrorComponent error={nursesError} />}
        </>
      )}
    </>
  );
};

export default NewNurseSchedules;
