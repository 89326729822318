import Typography from "@mui/material/Typography";
import GoogleDocLinks from "common/config/GoogleDocLinks";
import { gray } from "common/styling/colors";

export const ProviderFollowupCriteria = () => {
  return (
    <Typography variant="body1" color={gray[900]}>
      Does the member need a provider follow-up based on medical necessity
      according to the
      <Typography
        variant="caption"
        color={"primary"}
        style={{ cursor: "pointer", fontSize: "100%" }}
        onClick={() =>
          window.open(GoogleDocLinks.CLINICAL_ESCALATION_CRITERIA, "_blank")
        }
      >
        <b> provider follow-up criteria?</b>
      </Typography>{" "}
    </Typography>
  );
};
