import styled from "@emotion/styled";
import { BloodPressure, Diabetes } from "../../assets/images/icons";
import { CustomTooltip } from "../../styling/StyleComponents";
import { CarePlanConditionsEnum } from "common/types/Visits/CarePlanType";
import { useNavigate } from "react-router-dom";

const DIMENSION = "32px";

export const StyledDiabetesIcon = styled(Diabetes)`
  height: ${DIMENSION};
  width: ${DIMENSION};
`;

export const StyledHypertensionIcon = styled(BloodPressure)`
  height: ${DIMENSION};
  width: ${DIMENSION};
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 36px;
  align-items: center;
`;

const getTooltip = (condition: string) => `${condition}`;

const ConditionButton = ({
  memberId,
  condition,
  disabled
}: {
  memberId: string;
  condition: CarePlanConditionsEnum;
  disabled: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <CustomTooltip
      placement="top"
      title={getTooltip(
        condition.charAt(0).toUpperCase() + condition.substring(1).toLowerCase()
      )}
    >
      <ButtonWrapper
        onClick={() => {
          if (!disabled) navigate(`/members/memberId/${memberId}/goals`);
        }}
      >
        {condition === CarePlanConditionsEnum.DIABETES && (
          <StyledDiabetesIcon />
        )}
        {condition === CarePlanConditionsEnum.HYPERTENSION && (
          <StyledHypertensionIcon />
        )}
      </ButtonWrapper>
    </CustomTooltip>
  );
};

export const DiabetesButton = ({
  memberId,
  disabled = false
}: {
  memberId: string;
  disabled?: boolean;
}) => {
  return (
    <ConditionButton
      memberId={memberId}
      condition={CarePlanConditionsEnum.DIABETES}
      disabled={disabled}
    />
  );
};

export const HypertensionButton = ({
  memberId,
  disabled = false
}: {
  memberId: string;
  disabled?: boolean;
}) => {
  return (
    <ConditionButton
      memberId={memberId}
      condition={CarePlanConditionsEnum.HYPERTENSION}
      disabled={disabled}
    />
  );
};
