import { Box, Typography, styled } from "@mui/material";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import Table from "../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { getDispositionIndexInVisits, isFalsy } from "common/helpers/helpers";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { gray } from "common/styling/colors";
import { InfoOutlined } from "@mui/icons-material";
import useGetCalendarVisits from "../../../hooks/data_loaders/useGetCalendarVisits";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import DropdownDateRangePicker from "../../../components/DropdownDateRangePicker";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { getCalendarVisitsCreatedModifiedSize } from "../../../components/Table/helpers/TableHelpers";

const Fill = styled("div")`
  display: flex;
  flex: 1;
`;

interface IProps {
  staffId?: string;
  memberId?: string;
  isStaffDetails: boolean;
  staffTimezone?: string;
}

const PastAppointments = ({
  memberId,
  staffId,
  isStaffDetails = false,
  staffTimezone
}: IProps) => {
  const navigate = useNavigate();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [startsAfter, setStartsAfter] = useState<DateTime>(
    DateTime.now().minus({ days: 30 }).startOf("day")
  );
  const [startsBefore, setStartsBefore] = useState<DateTime>(
    DateTime.now().endOf("day")
  );

  /** delete the below lines when backend is updated */

  const {
    data: pastCalendarVisitsData,
    isFetching: pastCalendarVisitsIsFetching,
    error: pastCalendarVisitsError
  } = useGetCalendarVisits({
    patient_id: memberId,
    staff_id: staffId,
    startdate: startsAfter,
    enddate: startsBefore,
    sort: "desc",
    currentRole,
    skip: isFalsy(memberId) && isFalsy(staffId)
  });

  const filteredPastAppointmentsData = useMemo(() => {
    return pastCalendarVisitsData?.filter((item) => {
      const hasCompletedVisit =
        item?.visits?.findIndex(
          (visit) => visit?.status === VisitStatusEnum.COMPLETED
        ) > -1;
      const hasCompletedDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.COMPLETED
        ) > -1;
      const hasNoShowDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.NO_SHOW
        ) > -1;
      const hasTnOooDisposition =
        getDispositionIndexInVisits(item?.visits, VisitDispositionEnum.TN_OOO) >
        -1;
      const hasNoCallDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.NO_CALL
        ) > -1;
      const end = DateTime.fromISO(item.enddate);
      const isMemberAppointment =
        item?.appointment_type === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP ||
        item?.appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP ||
        item?.appointment_type === AppointmentTypeEnum.PROVIDER_FOLLOWUP;
      return (
        // filter out non-member appointments
        isMemberAppointment &&
        // only show appointments that have already ended
        (end.diffNow().milliseconds < 0 ||
          // or that have a completed or no show disposition or status
          hasCompletedVisit ||
          hasCompletedDisposition ||
          hasNoShowDisposition ||
          hasTnOooDisposition ||
          hasNoCallDisposition)
      );
    });
  }, [pastCalendarVisitsData]);

  const appointmentMetWithColumn = isStaffDetails
    ? { name: "appointmentMemberAttendee", size: 165 }
    : { name: "appointmentStaffMember", size: 165 };

  /** delete the above lines when backend is updated */

  // Rolling back to the previous version of the code, comment this back in when backend is updated

  // const {
  //   data: pastPatientAppointmentsData,
  //   isFetching: pastPatientAppointmentsIsFetching,
  //   error: pastPatientAppointmentsError
  // } = useGetPatientAppointmentsQuery(
  //   {
  //     patient_id: memberId,
  //     startdate: startDate,
  //     enddate: endDate,
  //     sort: "desc"
  //   },
  //   { skip: isFalsy(memberId) || !checkIdValid(memberId) }
  // );

  // const {
  //   data: pastStaffAppointmentsData,
  //   isFetching: pastStaffAppointmentsIsFetching,
  //   error: pastStaffAppointmentsError
  // } = useGetCalendarVisits({
  //   staff_id: staffId,
  //   startdate: startDate,
  //   enddate: endDate,
  //   sort: "desc",
  //   skip: isFalsy(staffId) || !checkIdValid(staffId)
  // });

  // const [
  //   nurseProviderUpdateOutcomeModalOpen,
  //   setNurseProviderUpdateOutcomeModalOpen
  // ] = useState<boolean>(false);

  // const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  // const [selectedAppointmentStart, setSelectedAppointmentStart] =
  //   useState<string>("");

  // const filteredPastAppointmentsData = useMemo(() => {
  //   if (pastPatientAppointmentsData) {
  //     return pastPatientAppointmentsData?.filter((item) => {
  //       const start = DateTime.fromISO(item?.event?.startdate);
  //       return (
  //         // only show appointments that have already ended
  //         start.diffNow().milliseconds < 0 ||
  //         // or appointments that have been completed (from the current day)
  //         item?.visit?.status === VisitStatusEnum.COMPLETED ||
  //         item?.visit?.status === VisitStatusEnum.TIME_SUBMITTED
  //       );
  //     });
  //   }

  //   if (pastStaffAppointmentsData) {
  //     return pastStaffAppointmentsData?.filter((item) => {
  //       // change this to item?.event?.enddate when switching back to appointments
  //       const start = DateTime.fromISO(item?.startdate);
  //       return (
  //         // only show appointments that have already ended
  //         start.diffNow().milliseconds < 0 ||
  //         // or appointments that have been completed (from the current day)
  //         // change this to item?.visit?.status when switching back to appointments
  //         item?.visits?.[0]?.status === VisitStatusEnum.COMPLETED ||
  //         item?.visits?.[0]?.status === VisitStatusEnum.TIME_SUBMITTED
  //       );
  //     });
  //   }

  //   return null;
  // }, [pastPatientAppointmentsData, pastStaffAppointmentsData]);

  // const appointmentStartEndColumn = isStaffDetails
  //   ? { name: "staffCalendarStartEnd", size: 130 }
  //   : { name: "appointmentStartEnd", size: 130 };

  // const appointmentMetWithColumn = isStaffDetails
  //   ? { name: "appointmentMemberAttendee", size: 165 }
  //   : { name: "appointmentStaffMember", size: 165 };

  // const appointmentActionsColumn = isStaffDetails
  //   ? { name: "pastCalendarVisitsActions", size: 190 }
  //   : { name: "pastAppointmentActions", size: 190 };

  // const appointmentOutcomeColumn = isStaffDetails
  //   ? { name: "pastCalendarVisitsOutcome", size: 130 }
  //   : { name: "pastAppointmentOutcome", size: 130 };

  // // we can also use a redux slice for this functionality if we need more values
  // const setNurseProviderModalOpen = useCallback(
  //   (shouldOpen: boolean, memberId?: string, encounter_started_on?: string) => {
  //     if (encounter_started_on) {
  //       setSelectedAppointmentStart(encounter_started_on);
  //     }
  //     if (shouldOpen && memberId) {
  //       setSelectedMemberId(memberId);
  //       setNurseProviderUpdateOutcomeModalOpen(shouldOpen);
  //     } else {
  //       setSelectedMemberId("");
  //       setSelectedAppointmentStart("");
  //       setNurseProviderUpdateOutcomeModalOpen(shouldOpen);
  //     }
  //   },
  //   []
  // );

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography variant="h4" color="text.secondary" marginRight={1}>
          Past Appointments
        </Typography>

        <CustomTooltip
          backgroundColor={gray[200]}
          title={
            <Typography variant="body1" color="text.secondary" maxWidth="225px">
              "Past Appointment" - Appointments dated before now or that have
              been updated with an "Outcome"
            </Typography>
          }
        >
          <InfoOutlined color="primary" fontSize="small" />
        </CustomTooltip>

        <Fill />
        <DropdownDateRangePicker
          onEndDateChange={setStartsBefore}
          onStartDateChange={setStartsAfter}
          endDate={startsBefore}
          startDate={startsAfter}
          dateRangeDefault="30_days"
        />
      </Box>
      <Box>
        {/* Delete the below lines when backend is updated */}
        {pastCalendarVisitsIsFetching && <LoadingFallback count={5} />}
        {filteredPastAppointmentsData && !pastCalendarVisitsIsFetching && (
          <Table
            noDataText="There are no past appointments."
            tableColumns={[
              {
                name: "calendarVisitsStartEnd",
                size: 130
              },
              appointmentMetWithColumn,
              {
                name: "calendarVisitsCreatedModified",
                size: 200
              },
              { name: "pastCalendarVisitsOutcome", size: 130 },
              { name: "pastCalendarVisitsActions", size: 190 }
            ]}
            tableProps={{
              navigate,
              staffTimezone,
              currentRole,
              currentUser: user
            }}
            estimateRowSize={(index: number) => {
              const item = filteredPastAppointmentsData[index];
              return getCalendarVisitsCreatedModifiedSize(item);
            }}
            data={filteredPastAppointmentsData}
          />
        )}
        {pastCalendarVisitsError && (
          <ErrorComponent error={pastCalendarVisitsError} />
        )}

        {/* Delete the above lines when backend is updated */}

        {/* Rolling back to the previous version of the code, comment this back in when backend is updated */}
        {/* {(pastPatientAppointmentsIsFetching ||
        pastStaffAppointmentsIsFetching) && <LoadingFallback count={5} />}
      {filteredPastAppointmentsData &&
        !(
          pastPatientAppointmentsIsFetching || pastStaffAppointmentsIsFetching
        ) && (
          <Table
            noDataText="No past appointments found."
            tableColumns={[
              appointmentStartEndColumn,
              appointmentMetWithColumn,
              appointmentActionsColumn,
              appointmentOutcomeColumn
            ]}
            tableProps={{
              navigate,
              currentRole,
              setNurseProviderModalOpen
            }}
            data={filteredPastAppointmentsData}
          />
        )}
      <UpdateOutcomeNurseProviderModal
        key="update-outcome-nurse-provider-modal-past"
        memberId={selectedMemberId}
        modalOpen={nurseProviderUpdateOutcomeModalOpen}
        setModalOpen={setNurseProviderModalOpen}
        encounter_started_on={selectedAppointmentStart}
      />
      {pastPatientAppointmentsError && (
        <ErrorComponent error={pastPatientAppointmentsError} />
      )}
      {pastStaffAppointmentsError && (
        <ErrorComponent error={pastStaffAppointmentsError} />
      )} */}
      </Box>
    </Box>
  );
};

export default PastAppointments;
