import { DateTime } from "luxon";

import EnvVars from "../config/EnvVars";
import RolesEnum from "../enums/RolesEnum";
import APIHeadersEnum from "../enums/APIHeadersEnum";
import {
  axiosLogin,
  axiosMemberRegistration,
  axiosUserPresence
} from "./AxiosService";
import FeatureFlags from "../config/FeatureFlags";

export interface AuthLoginResponseType extends AuthRefreshTokenResponseType {
  user_id: string;
}

export interface AuthRefreshTokenResponseType {
  access_token: string;
  refresh_token: string;
  expires: string;
  roles: RolesEnum[];
  username: string;
  user_id: string;
}

let headers = {};
headers[APIHeadersEnum.CIQFrom] = EnvVars.REACT_APP_PRODUCT;

export const AuthService_LoginWithPassword = (
  username: string,
  password: string
) => {
  const axios = FeatureFlags().USER_PRESENCE_MANAGEMENT
    ? axiosUserPresence
    : axiosLogin;

  return axios.post<AuthLoginResponseType>(
    `/login`,
    {},
    {
      headers,
      auth: {
        username,
        password
      }
    }
  );
};

export const AuthService_RequestSMSCode = (
  birthdate: DateTime,
  mobile: string
) => {
  return axiosMemberRegistration.post<{ pre_login_id: string }>(
    `/pre-login/code`,
    {
      birthdate: birthdate.toFormat("MM/dd/yyyy"),
      mobile
    },
    {
      headers
    }
  );
};

export interface LoginWithSMSProps {
  pre_login_id: string;
  code: string;
}

export const AuthService_LoginWithSMSCode = ({
  pre_login_id,
  code
}: LoginWithSMSProps) => {
  return axiosLogin.post<AuthLoginResponseType>(
    `/login/code/${pre_login_id}`,
    {
      code
    },
    {
      headers
    }
  );
};

export const AuthService_RefreshToken = (skipAuthRefresh = false) => {
  return axiosLogin
    .post<AuthRefreshTokenResponseType>(
      "/refresh-token",
      { skipAuthRefresh },
      {
        headers
      }
    )
    .then((response) => {
      return { response, error: null };
    })
    .catch(function (error) {
      return { error, response: error.response };
    });
};
