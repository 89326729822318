import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  COMMON_ROUTES,
  MEMBER_CHART,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT
} from "../RouteSidebar";
import Routes from "../Routes";

const SALES_DIRECTOR = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    ...MEMBER_CHART(RolesEnum.SALES_DIRECTOR),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default SALES_DIRECTOR;
