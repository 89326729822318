import { Card, Typography } from "@mui/material";

import { CareFlowSubSectionType } from "common/types/Visits/CareFlowResponseType";

import { gray } from "common/styling/colors";
import { RowContainer } from "../../MemberDetails/StartIntake/StyledComponents";
import VisitContentField from "./VisitContentField";
import MemberType from "common/types/MemberType";
import { TRANSITION_TIME } from "../../../components/Layout";

const VisitContentSubSection = ({
  subSection,
  member,
  syncCareFlowToAPI
}: {
  subSection: CareFlowSubSectionType;
  member: MemberType;
  syncCareFlowToAPI: () => void;
}) => {
  return (
    <Card
      sx={{
        padding: 2,
        margin: 0.5,
        border: 1,
        borderColor: `${gray[300]}`,
        boxShadow: 0,
        // have this to make height transition smoother
        transition: `height ${TRANSITION_TIME} ease-out`
      }}
    >
      <RowContainer justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{ textDecoration: "underline" }}
          color={gray[900]}
        >
          {subSection?.name}
        </Typography>
      </RowContainer>

      {subSection?.fields?.map((item) => (
        <VisitContentField
          syncCareFlowToAPI={syncCareFlowToAPI}
          key={item.field_id}
          field={item}
          member={member}
        />
      ))}
    </Card>
  );
};

export default VisitContentSubSection;
