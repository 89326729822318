import {
  GoalTypeEnum,
  ProcessedGoalsType,
  processGoals
} from "common/types/GoalType";
import { Goals } from "../../../../../components/Goals/Goals";
import { Flexbox } from "../../../../../styling/NewStyleComponents";
import MemberType from "common/types/MemberType";
import { useEffect, useState } from "react";

export const GoalManagement = ({ member }: { member: MemberType }) => {
  const [processedGoals, setProcessedGoals] = useState<ProcessedGoalsType>({
    clinical: [],
    member: []
  });
  
  useEffect(() => {
    const process = async () => {
      const newProcessedGoals = processGoals({
        goals: member?.goals,
        splitByType: true
      });
      setProcessedGoals(newProcessedGoals);
    };

    if (member?.goals) process();
  }, [member]);

  return (
    <Flexbox flexDirection={"column"} gap={"20px"}>
      <Goals
        goals={processedGoals.clinical}
        member={member}
        title={"Clinical Goals"}
        showAssessment={true}
      />

      <Goals
        goals={processedGoals.member}
        member={member}
        title={"Member Goals"}
        showAssessment={true}
        goalType={GoalTypeEnum.MEMBER}
      />
    </Flexbox>
  );
};
