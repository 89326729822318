export enum GoalAssessmentStatusEnum {
  ON_TRACK = "ON_TRACK",
  OFF_TRACK = "OFF_TRACK",
  NOT_STARTED = "NOT_STARTED",
  COMPLETED = "COMPLETED"
}

enum GoalAssessmentPrettyEnum {
  OffTrack = "Off Track",
  OnTrack = "On Track"
}

export enum GoalStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum GoalCategoryEnum {
  DIABETES = "DIABETES",
  HYPERTENSION = "HYPERTENSION",
  LIFESTYLE = "LIFESTYLE",
  MEDICATION = "MEDICATION",
  NUTRITION = "NUTRITION",
  EXERCISE = "EXERCISE"
}

export interface GoalAssessmentFormType {
  assessment_status?: GoalAssessmentStatusEnum;
  current_value?: string;
  notes?: string;
}

export interface ProcessedGoalsType {
  clinical?: GoalType[];
  member?: GoalType[];
  diabetes?: GoalType[];
  hypertension?: GoalType[];
}

/**
 * processGoals - splits and alphabetizes an array of goals.
 * If splitByType is true (default) then the goals will be returned with nested clinical[]
 * and member[] arrays where the original array was split by type. If splitByType is false,
 * the goals will be returned with nested category arrays where the original array will be
 * split by category.
 */
export const processGoals = ({
  goals,
  splitByType = true,
  clinicalOnly = false
}: {
  goals: GoalType[];
  splitByType?: boolean;
  clinicalOnly?: boolean;
}): ProcessedGoalsType => {
  let res: ProcessedGoalsType = {
    clinical: [],
    member: [],
    diabetes: [],
    hypertension: []
  };
  for (const goal of goals) {
    if (goal.goal_status === GoalStatusEnum.INACTIVE) continue;
    if (splitByType) {
      if (goal.goal_type === GoalTypeEnum.CLINICAL) res.clinical.push(goal);
      else if (!clinicalOnly) res.member.push(goal);
    } else {
      if (goal.goal_category === GoalCategoryEnum.DIABETES)
        res.diabetes.push(goal);
      if (goal.goal_category === GoalCategoryEnum.HYPERTENSION)
        res.hypertension.push(goal);
    }
  }
  // alphabetize by condition and description
  res.clinical = res.clinical.sort((a, b) =>
    a.goal_category + a.goal_description > b.goal_category + b.goal_description
      ? 1
      : -1
  );
  res.member = res.member.sort((a, b) =>
    a.goal_category + a.goal_description > b.goal_category + b.goal_description
      ? 1
      : -1
  );
  res.diabetes = res.diabetes.sort((a, b) =>
    a.goal_description > b.goal_description ? 1 : -1
  );
  res.hypertension = res.hypertension.sort((a, b) =>
    a.goal_description > b.goal_description ? 1 : -1
  );
  return res;
};

export const getGoalCategoriesByType = (goalType) => {
  if (goalType === GoalTypeEnum.CLINICAL)
    return [GoalCategoryEnum.DIABETES, GoalCategoryEnum.HYPERTENSION];
  else
    return [
      GoalCategoryEnum.LIFESTYLE,
      GoalCategoryEnum.MEDICATION,
      GoalCategoryEnum.NUTRITION,
      GoalCategoryEnum.EXERCISE
    ];
};

export const getGoalTitleByCategory = (goalCategory) => {
  switch (goalCategory) {
    case GoalCategoryEnum.DIABETES:
      return "Manage Blood Sugar";
    case GoalCategoryEnum.HYPERTENSION:
      return "Manage Blood Pressure";
    case GoalCategoryEnum.EXERCISE:
      return "Increase Physical Activity";
    case GoalCategoryEnum.NUTRITION:
      return "Improve Diet";
    case GoalCategoryEnum.LIFESTYLE:
      return "Make Healthier Choices";
    case GoalCategoryEnum.MEDICATION:
      return "Manage Medication";
    default:
      return "";
  }
};

export const getPrettyGoalAssessmentStatus = (goalAssessment) => {
  switch (goalAssessment) {
    case GoalAssessmentStatusEnum.COMPLETED:
      return "Completed";
    case GoalAssessmentStatusEnum.ON_TRACK:
      return "On Track";
    case GoalAssessmentStatusEnum.OFF_TRACK:
      return "Off Track";
    case GoalAssessmentStatusEnum.NOT_STARTED:
      return "No Data";
    default:
      return "";
  }
};

export enum GoalTypeEnum {
  CLINICAL = "CLINICAL",
  MEMBER = "MEMBER"
}

export interface GoalAssessmentType {
  assessment_id?: string;
  goal_id?: string;
  assessed_by?: string;
  assessed_on?: string;
  assessment_status?: GoalAssessmentStatusEnum;
  current_value?: string;
  notes?: string;
}

export interface GoalType {
  goal_description?: string;
  goal_id?: string;
  goal_status?: GoalStatusEnum;
  goal_type: GoalTypeEnum;
  target_value?: string;
  created_by?: string;
  created_on?: string;
  modified_by?: string;
  modified_on?: string;
  goal_category?: GoalCategoryEnum;
  latest_assessments?: GoalAssessmentType[];
}
