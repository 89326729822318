import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

import APIConstants from "common/config/APIConstants";
import { RootState } from "common/redux";
import { axiosMedusaAuth } from "common/services/AxiosService";
import WebSocketMessageType from "common/types/WebSocketMessageType";
import WebSocketMessageTypeEnum from "common/enums/WebSocketMessageTypeEnum";

interface IProps {
  supportedMessageTypes?: WebSocketMessageTypeEnum[];
  onMessageReceived: (message: WebSocketMessageType) => void;
}

const useRIQWebSocket = ({
  supportedMessageTypes,
  onMessageReceived
}: IProps) => {
  const [socketUrl, setSocketUrl] = useState<string>();
  const [messageHistory, setMessageHistory] = useState<WebSocketMessageType[]>(
    []
  );

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const connect = async () => {
    try {
      const response = await axiosMedusaAuth.post("/ticket");

      const { ticket } = response.data;
      setSocketUrl(APIConstants.MEDUSA_WEBSOCKET + "/v1?ticket=" + ticket);
    } catch (e) {
      console.log(e);
    }
  };

  const { sendMessage, sendJsonMessage, lastMessage, readyState } =
    useWebSocket<WebSocketMessageType>(socketUrl);

  useEffect(() => {
    if (
      isLoggedIn &&
      [
        ReadyState.CLOSED,
        ReadyState.UNINSTANTIATED,
        ReadyState.CONNECTING
      ].includes(readyState)
    )
      connect();
  }, [isLoggedIn, readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data) as WebSocketMessageType;
      setMessageHistory((prev) => prev.concat(message));

      if (supportedMessageTypes) {
        if (supportedMessageTypes.includes(message.type))
          onMessageReceived(message);
      } else onMessageReceived(message);
    }
  }, [lastMessage]);

  return { sendMessage, sendJsonMessage, messageHistory };
};

export default useRIQWebSocket;
