import { useUpsertGoalsMutation } from "common/services/MemberGoalsRelationshipService";
import {
  Box,
  Button,
  CircularProgress,
  styled,
  SxProps,
  Typography
} from "@mui/material";
import { blue, gray } from "common/styling/colors";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import {
  getGoalTitleByCategory,
  getPrettyGoalAssessmentStatus,
  GoalAssessmentType,
  GoalStatusEnum,
  GoalType,
  GoalTypeEnum
} from "common/types/GoalType";

import StyledIconButton from "../Button/StyledIconButton";

import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { dispatch } from "common/redux";
import { Flexbox } from "../../styling/NewStyleComponents";
import { GoalAssessment, GoalAssessmentIcon } from "./GoalAssessment";
import { getLocaleShortFromISOString } from "common/helpers/DateTimeHelper";
import { CustomTooltip, StatusBadge } from "../../styling/StyleComponents";
import AddGoalModal from "./AddGoalModal";
import MemberType from "common/types/MemberType";
import { getFallbackTextWithButton } from "../Table/helpers/TableHelpers";
import { isFalsy } from "common/helpers/helpers";

const onDeleteGoalPressed = ({
  goal,
  memberId,
  upsertGoalsMutation
}: {
  goal: GoalType;
  memberId: string;
  upsertGoalsMutation: ({
    goals,
    member_id
  }: {
    goals: GoalType[];
    member_id: string;
  }) => Promise<any>;
}) => {
  Alert_show({
    dispatch,
    id: "delete_goal",
    title: "Confirm",
    content: "Are you sure you want to delete this goal?",
    size: "small",
    type: "warning",
    row: true,
    buttons: [
      {
        text: "Delete",
        style: "destructive",
        onPress: () => {
          upsertGoalsMutation({
            goals: [
              {
                goal_id: goal.goal_id,
                goal_type: goal.goal_type,
                goal_category: goal.goal_category,
                created_by: goal.created_by,
                created_on: goal.created_on,
                goal_description: goal.goal_description,
                target_value: goal.target_value,
                goal_status: GoalStatusEnum.INACTIVE
              }
            ],
            member_id: memberId
          });
          Alert_close({ dispatch, id: "delete_goal" });
        }
      },
      {
        text: "Cancel",
        style: "cancel",
        onPress: () => {
          Alert_close({ dispatch, id: "delete_goal" });
        }
      }
    ]
  });
};

export const GoalStyle = {
  border: 2,
  borderColor: `${gray[300]}`,
  background: "#fff",
  borderRadius: "4px",
  boxShadow: 0,
  padding: "10px",
  width: "320px"
};

const PastGoalAssessmentSpacing = styled("div")`
  width: 20px;
  height: 1px;
  align-self: center;
  margin-top: -20px;
  background-color: ${gray[300]};
`;

const PastGoalAssessmentTooltip = ({
  assessment
}: {
  assessment: GoalAssessmentType;
}) => {
  return (
    <Flexbox flexDirection={"column"} color="text.secondary">
      <Typography variant="body1" maxWidth="225px">
        Status: {getPrettyGoalAssessmentStatus(assessment?.assessment_status)}
      </Typography>

      <Typography variant="body1" maxWidth="225px">
        Value: {assessment?.current_value}
      </Typography>
      {assessment?.notes && (
        <Typography variant="body1" maxWidth="225px">
          Notes: {assessment?.notes}
        </Typography>
      )}
    </Flexbox>
  );
};

const PastGoalAssessments = ({ goal }: { goal: GoalType }) => {
  return (
    <Flexbox flexDirection={"column"} sx={{ marginTop: "10px" }}>
      {goal?.latest_assessments?.length > 0 && (
        <Typography variant={"caption"}>Recent Progress to Goal</Typography>
      )}
      <Flexbox
        flexDirection={"row-reverse"}
        gap={"6px"}
        marginTop={"10px"}
        marginRight={"15px"}
        sx={{ cursor: "pointer" }}
      >
        {goal?.latest_assessments?.map((assessment) => {
          return (
            <Flexbox
              key={assessment.assessment_id}
              flexDirection={"row-reverse"}
            >
              {goal?.latest_assessments?.indexOf(assessment) > 0 && (
                <PastGoalAssessmentSpacing />
              )}
              <CustomTooltip
                placement={"bottom"}
                backgroundColor={gray[200]}
                title={<PastGoalAssessmentTooltip assessment={assessment} />}
              >
                <Flexbox flexDirection={"column"}>
                  <GoalAssessmentIcon
                    status={assessment?.assessment_status}
                    sx={{ alignSelf: "center" }}
                  />
                  <Typography variant={"caption"}>
                    {getLocaleShortFromISOString({
                      s: assessment?.assessed_on
                    })}
                  </Typography>
                </Flexbox>
              </CustomTooltip>
            </Flexbox>
          );
        })}
      </Flexbox>
    </Flexbox>
  );
};

const GoalBody = ({
  goal,
  memberId,
  showProgress = false,
  onEditPressed = () => {}
}: {
  goal: GoalType;
  memberId: string;
  showProgress?: boolean;
  onEditPressed?: (goal: GoalType) => void;
}) => {
  const [upsertGoalsMutation] = useUpsertGoalsMutation();
  const { goal_description, goal_category } = goal;
  return (
    <Flexbox flexDirection={"column"} sx={GoalStyle}>
      <Flexbox
        flexDirection={"row"}
        justifyContent={"space-between"}
        marginBottom={"-15px"}
      >
        <StatusBadge status={goal.goal_category} hideDot={true} />
        <Flexbox>
          <StyledIconButton
            onClick={() => onEditPressed(goal)}
            Icon={Edit}
            iconColor={blue[700]}
            color={"transparent"}
            size={"small"}
          />
          <StyledIconButton
            onClick={() =>
              onDeleteGoalPressed({ goal, memberId, upsertGoalsMutation })
            }
            Icon={Delete}
            iconColor={blue[700]}
            color={"transparent"}
            size={"small"}
          />
        </Flexbox>
      </Flexbox>
      <Flexbox flexDirection={"column"} sx={{ padding: "5px" }}>
        <Flexbox flexDirection={"row"}>
          <div>
            <Typography variant="h4" mt={"15px"}>
              {getGoalTitleByCategory(goal_category)}
            </Typography>
            <Typography variant="body1">{goal_description}</Typography>
          </div>
        </Flexbox>
        {showProgress && <PastGoalAssessments goal={goal} />}
      </Flexbox>
    </Flexbox>
  );
};

const Goal = ({
  goal,
  memberId,
  showAssessment = false,
  onEditPressed = () => {}
}: {
  goal: GoalType;
  memberId: string;
  showAssessment: boolean;
  onEditPressed?: (goal: GoalType) => void;
}) => {
  return (
    <Flexbox sx={{ flexDirection: "row", gap: "20px" }}>
      <GoalBody
        goal={goal}
        memberId={memberId}
        showProgress={true}
        onEditPressed={onEditPressed}
      />
      {showAssessment && <GoalAssessment goal={goal} />}
    </Flexbox>
  );
};

export const Goals = ({
  goals,
  member,
  goalType = GoalTypeEnum.CLINICAL,
  title = "Goals",
  showAssessment = true
}: {
  goals: GoalType[];
  member: MemberType;
  goalType?: GoalTypeEnum;
  title?: string;
  showAssessment?: boolean;
}) => {
  const [addGoalModalVisible, setAddGoalModalVisible] =
    useState<boolean>(false);
  const [selectedGoalForEdit, setSelectedGoalForEdit] =
    useState<GoalType>(null);
  const [newGoalType, setNewGoalType] = useState<GoalTypeEnum>(null);

  const triggerAddGoal = (newGoalType: GoalTypeEnum) => {
    setNewGoalType(newGoalType);
    setAddGoalModalVisible(true);
  };

  const triggerEditGoal = (goal: GoalType) => {
    setAddGoalModalVisible(true);
    setSelectedGoalForEdit(goal);
    setNewGoalType(goal.goal_type);
  };

  if (isFalsy(member)) {
    return <CircularProgress />;
  }

  // if we are showing assessment, show as rows, else as grid
  const style: SxProps = showAssessment
    ? { display: "flex", flexDirection: "column", gap: "20px" }
    : {
        display: "grid",
        gridGap: "20px",
        gridTemplateColumns: "repeat(auto-fit, minmax(345px, 345px))"
      };

  return (
    <>
      <Flexbox
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant={"h6"}>{title}</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            triggerAddGoal(goalType);
          }}
          sx={{ maxWidth: "150px" }}
        >
          Add Goal
        </Button>
      </Flexbox>

      {goals?.length === 0 && (
        <>
          {getFallbackTextWithButton({
            text: "No goals found for member",
            buttonText: "Add Goal",
            onClick: () => {
              triggerAddGoal(goalType);
            }
          })}
        </>
      )}
      <Box sx={style}>
        {goals?.map((goal) => {
          return (
            <Goal
              key={goal.goal_id}
              goal={goal}
              memberId={member?.patient?.patient_id}
              showAssessment={showAssessment}
              onEditPressed={triggerEditGoal}
            />
          );
        })}
      </Box>
      <AddGoalModal
        isOpen={addGoalModalVisible}
        onRequestClose={() => {
          setAddGoalModalVisible(false);
          setSelectedGoalForEdit(null);
          setNewGoalType(null);
        }}
        member={member}
        newGoalType={newGoalType}
        goalForEdit={selectedGoalForEdit}
      />
    </>
  );
};
