import MemberType from "common/types/MemberType";
import { isFalsy, isTruthy } from "common/helpers/helpers";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { CircularProgress, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { Appointment } from "../../../../MemberDetails/Appointments/TodaysAppointments";
import { useNavigate } from "react-router-dom";
import RolesEnum, {
  canScheduleNurses,
  canScheduleProviders
} from "common/enums/RolesEnum";
import Table from "../../../../../components/Table/Table";
import { setAnswers } from "common/redux/VisitsSlice";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { useUpsertCarePlanMutation } from "common/services/VisitsService";

const NextAppt = ({
  member,
  apptType
}: {
  member: MemberType;
  apptType: AppointmentTypeEnum;
}) => {
  const navigate = useNavigate();
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const [updateCarePlan] = useUpsertCarePlanMutation();

  const { title, label, canSchedule, roles, data } = useMemo(() => {
    return apptType === AppointmentTypeEnum.PROVIDER_FOLLOWUP
      ? {
          title: `Next Provider Appointment`,
          label: `provider`,
          canSchedule: canScheduleProviders(currentRole),
          roles: [RolesEnum.NURSE_PROVIDER],
          data: member?.care_summary?.next_provider_appointment ?? null
        }
      : {
          title: `Next Nurse Appointment`,
          label: `nurse`,
          canSchedule: canScheduleNurses(currentRole),
          roles: [RolesEnum.TH_NURSE],
          data: member?.care_summary?.next_nurse_appointment ?? null
        };
  }, [member]);

  const dateDisplay = useMemo(() => {
    const startDate = DateTime.fromISO(data?.start).setZone(
      member?.patient?.timezone
    );
    const endDate = DateTime.fromISO(data?.start)
      .plus({ hour: 1 })
      .setZone(member?.patient?.timezone);
    // If not today, show the date in addition to the start - end
    const secondaryTextDate = startDate.hasSame(DateTime.local(), "day")
      ? ""
      : `${startDate.toFormat("cccc LLL d")} `;
    return `${secondaryTextDate}${startDate.toFormat("h:mm")} - ${endDate.toFormat("h:mm a ZZZZ")}`;
  }, [data]);

  useEffect(() => {
    if (apptType === AppointmentTypeEnum.PROVIDER_FOLLOWUP && isTruthy(data)) {
      dispatch(
        setAnswers([
          {
            id: CareFlowFieldIdsEnum.CALL_REFERRALS_NEXT_NP_APPOINTMENT,
            value: dateDisplay
          },
          {
            id: CareFlowFieldIdsEnum.CALL_REFERRALS_NEXT_NP_NAME,
            value: `${data?.staff_first} ${data?.staff_last}`
          }
        ])
      );
    }
    // If next_provider_appt is set, update care plan with that info
    if (isTruthy(member?.care_summary?.next_provider_appointment?.start)) {
      updateCarePlan({
        body: {
          patient_id: member?.patient?.patient_id,
          medication_reconciliation: {
            next_due: member?.care_summary?.next_provider_appointment?.start
          }
        }
      });
    }
    setLoading(false);
  }, [data]);

  return (
    <>
      {isFalsy(member) || loading ? (
        <CircularProgress />
      ) : isFalsy(data) ? (
        <>
          <Table
            noDataText={`There are no scheduled ${label} appointments.`}
            noDataButtonText={canSchedule && `Schedule ${title}`}
            noDataButtonOnClick={() =>
              navigate(
                `/members/memberId/${member?.patient?.patient_id}/new-${label}-appointment`
              )
            }
            tableColumns={[]}
            data={[]}
          />
        </>
      ) : (
        <>
          <Typography variant="h4" color="text.secondary" mb="12px">
            {title}
          </Typography>
          <Appointment
            primaryText={`${data?.staff_first} ${data?.staff_last}`}
            secondaryText={`${dateDisplay}`}
            showStart={false}
            showReschedule={true}
            visitRequest={{
              patient_id: member?.patient?.patient_id
            }}
            data={{
              // this is the format from useGetCalendarVisits
              event_id: data?.event_id,
              startdate: data?.start,
              appointment_type: apptType,
              enddate: DateTime.fromISO(data?.start).plus({ hour: 1 }).toISO(),
              attendees: [
                {
                  attendee_id: member?.patient?.patient_id
                }
              ],
              staff: {
                id: data?.staff_id,
                first: data?.staff_first,
                last: data?.staff_last,
                roles: roles
              }
            }}
          />
        </>
      )}
    </>
  );
};

export const NextProviderAppt = ({ member }: { member: MemberType }) => {
  return (
    <NextAppt
      member={member}
      apptType={AppointmentTypeEnum.PROVIDER_FOLLOWUP}
    />
  );
};

export const NextNurseAppt = ({ member }: { member: MemberType }) => {
  return (
    <NextAppt member={member} apptType={AppointmentTypeEnum.NURSE_FOLLOWUP} />
  );
};
