import { Ref, forwardRef, useImperativeHandle } from "react";

import { DateTime } from "luxon";

import { useGetQualifiedAcceptedAttestationsByDateRangeQuery } from "common/services/MemberConsentsService";

import ReportRefInterface from "./ReportRefInterface";
import Table from "../../../components/Table/Table";
import { CircularProgress, Typography } from "@mui/material";
import { ErrorText } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";
import Routes from "../../../routes/Routes";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
}

const financialAssistanceColumns = [
  { name: "name", header: "Member Name" },
  { name: "athenaId" },
  { name: "agreementsSigned", header: "Qualified Date" },
  {
    name: "agreementAnnualIncome"
  },
  {
    name: "agreementHouseholdSize"
  }
];

const SummaryComponent = ({ length }: { length: number }) => {
  return (
    <>
      <Typography variant="body1" color="text.secondary">
        {`Total Qualified Members: ${length}`}
      </Typography>
    </>
  );
};

const ReportFinancialAssistance = forwardRef(
  ({ startDate, endDate }: IProps, ref: Ref<ReportRefInterface>) => {
    const { data, isLoading, isError, isSuccess, error } =
      useGetQualifiedAcceptedAttestationsByDateRangeQuery({
        accepted_on_after: startDate,
        accepted_on_before: endDate
      });

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = data?.map((item) => {
          return [
            `${item.patient.first} ${item.patient.last}`,
            `${item.patient.external_accounts.athena}`,
            item.attestation.accepted_on,
            `${item.attestation.attestation.annual_income_category}`,
            `${item.attestation.attestation.household_size}`
          ];
        });

        return {
          filename: "financial_assistance_qualifications",
          columns: [
            "Member Name",
            "Athena ID",
            "Qualified Date",
            "Annual Income",
            "Household Size"
          ],
          data: formattedData
        };
      },
      getReportData: () => {
        const formattedData = data?.map((item) => {
          return [
            `${item.patient?.first} ${item.patient?.last}`,
            `${item.patient?.external_accounts?.athena}`,
            item.attestation?.accepted_on,
            `${item.attestation?.attestation?.annual_income_category}`,
            `${item.attestation?.attestation?.household_size}`
          ];
        });
        return [
          {
            columnNames: [
              "Member Name",
              "Athena ID",
              "Qualified Date",
              "Annual Income",
              "Household Size"
            ],
            data: formattedData,
            reportSummary: [`Total Qualified Members: ${formattedData.length}`]
          }
        ];
      }
    }));

    return (
      <>
        {data && data?.length > 0 && (
          <>
            <Table
              tableColumns={financialAssistanceColumns}
              data={data}
              tableMaxHeight={"none"}
              tableProps={{ externalLink: Routes.MEMBER_DETAILS("") }}
            />
            <br />
            <SummaryComponent length={data.length} />
          </>
        )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </>
    );
  }
);

ReportFinancialAssistance.displayName = "ReportFinancialAssistance";

export default ReportFinancialAssistance;
