import { RootState, useAppDispatch } from "common/redux";
import { useEffect, useMemo, useRef, ReactNode } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Sidebar } from "../components/Sidebar";
import { isFalsy } from "common/helpers/helpers";
import {
  setLayoutNodeRef,
  toggleSidebar
} from "common/redux/webadmin/LayoutSlice";
import { NavBar } from "./Navbar";
import { useLocation } from "react-router-dom";
import { NAV_BAR_HEIGHT } from "./Navbar/NavBar";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import StyledIconButton from "./Button/StyledIconButton";
import { useTheme } from "@mui/material";
import { Flexbox } from "../styling/NewStyleComponents";
import { gray } from "common/styling/colors";
import { CustomTooltip } from "../styling/StyleComponents";
import VisitInProgress from "./VisitInProgress";
import { canSeeCareFlow } from "common/enums/RolesEnum";

export const SIDEBAR_WIDTH = 130;

export const TRANSITION_TIME = 0.33;

const LayoutContainer = styled.div<{ isLoggedIn: boolean }>`
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: ${({ isLoggedIn }) => (isLoggedIn ? "flex" : "block")};
  background: ${({ theme, isLoggedIn }) =>
    isLoggedIn ? theme.color.defaultBackground : theme.color.white};
`;

const ContentContainer = styled.div<{
  isLoggedIn: boolean;
  showSidebar: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: visible;
  overflow-x: hidden;
  width: ${({ isLoggedIn, showSidebar }) => {
    const width = showSidebar ? `${SIDEBAR_WIDTH}px` : "12px";
    return isLoggedIn
      ? `clamp(calc(70% - ${width}), 100%, calc(100vw - ${width}))`
      : "100%";
  }})}`;

const ChildrenContainer = styled.div<{ isLoggedIn: boolean }>`
  margin-top: ${({ isLoggedIn }) => (isLoggedIn ? NAV_BAR_HEIGHT : "0px")};
  height: ${({ isLoggedIn }) => (isLoggedIn ? "auto" : "100vh")};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SidebarContainer = styled.div<{ showSidebar: boolean }>`
  width: ${({ showSidebar }) => (showSidebar ? `${SIDEBAR_WIDTH}px` : "12px")};
  background: ${({ theme }) => theme.color.white};
  transition: width ${TRANSITION_TIME}s ease-out;
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  z-index: 100;
  border-right: solid ${gray[300]};
  border-right-width: 2px;
  margin-top: 30px;
`;

const SidebarPlaceholder = styled.div<{ showSidebar: boolean }>`
  width: ${({ showSidebar }) => (showSidebar ? `${SIDEBAR_WIDTH}px` : "12px")};
  height: 100vh;
  transition: width ${TRANSITION_TIME}s ease-out;
  position: relative;
`;

interface IProps {
  children: ReactNode;
  hideSidebar?: boolean;
}

const Layout = ({ children, hideSidebar = false }: IProps) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isLoggedIn, currentRole } = useSelector(
    (state: RootState) => state.auth
  );
  const { showSidebar, layoutNodeRef } = useSelector(
    (state: RootState) => state.layout
  );

  const nodeRef = useRef(null);

  useEffect(() => {
    if (isFalsy(layoutNodeRef) && nodeRef.current) {
      setLayoutNodeRef(nodeRef);
    }
  }, [layoutNodeRef, nodeRef]);

  const hideNavBar = isLoggedIn && pathname.includes("/video");

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  // useMemo to stop re-renders based on AuthConsumer context changing from token refresh
  return useMemo(() => {
    if (hideSidebar) return <>{children}</>;

    const floatingButtonContainerStyles = {
      position: "absolute",
      left: showSidebar ? SIDEBAR_WIDTH - 11 : 1,
      top: 80,
      zIndex: 101
    };

    const careFlowsEnabled = isLoggedIn && canSeeCareFlow(currentRole);

    return (
      <LayoutContainer
        id="layoutContainer"
        ref={nodeRef}
        isLoggedIn={isLoggedIn}
      >
        {isLoggedIn && !hideNavBar ? <NavBar /> : null}

        {isLoggedIn && (
          <>
            <SidebarContainer
              showSidebar={showSidebar}
              data-testid="Layout-sidebar"
            >
              {showSidebar ? <Sidebar /> : null}
            </SidebarContainer>
            <SidebarPlaceholder showSidebar={showSidebar} />
          </>
        )}
        <ContentContainer isLoggedIn={isLoggedIn} showSidebar={showSidebar}>
          <ChildrenContainer isLoggedIn={isLoggedIn}>
            {careFlowsEnabled && <VisitInProgress />}
            {children}
          </ChildrenContainer>
        </ContentContainer>

        {isLoggedIn && (
          <Flexbox
            sx={floatingButtonContainerStyles}
            transitionTime={TRANSITION_TIME}
          >
            <CustomTooltip
              title={showSidebar ? "Collapse" : "Expand"}
              placement="bottom"
            >
              <div>
                <StyledIconButton
                  Icon={showSidebar ? ChevronLeft : ChevronRight}
                  onClick={handleToggleSidebar}
                  color={"#ffffff"}
                  border="circle"
                  size="small"
                  sx={{ padding: "1px" }}
                  iconColor={theme.palette.primary.main}
                />
              </div>
            </CustomTooltip>
          </Flexbox>
        )}
      </LayoutContainer>
    );
  }, [isLoggedIn, showSidebar, hideSidebar]);
};

export default Layout;
