import { canSeeMemberDetails } from "common/enums/RolesEnum";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { StatusBadge } from "../../styling/StyleComponents";
import { getNameOrUsername } from "common/helpers/helpers";
import CopyPatientLinkToClipboard from "../../styling/CopyPatientLinkToClipboard";
import { blue } from "common/styling/colors";
import replace from "lodash.replace";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import Routes from "../../routes/Routes";

const WIDTH = "clamp(200px, 250%, 414px)";

const UserContainer = styled("div")<{ shouldDisplay: boolean }>`
  visibility: ${(props) => (props.shouldDisplay ? "visible" : "hidden")};
  position: absolute;
  width: ${WIDTH};
  border: 1px solid rgba(233, 233, 233, 1);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 80px;
  box-shadow: 3px 3px 8px #00000088;
  max-height: 300px;
  overflow-y: auto;
`;

const Divider = styled("div")`
  height: 1px;
  width: ${WIDTH};
  background-color: lightgray;
`;

const UserRow = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  height: 50;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
`;

const UserDisplayName = styled("div")`
  margin-left: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 0px;
`;

const UserListNameText = styled("div")`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${blue[700]};
  // color: ${(props) => props.theme.palette.text.primary};
`;

const UserListBirthDateText = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const NameFlexbox = styled("div")`
  display: flex;
  flex-direction: row;
`;

interface RenderUserRowProps {
  // tbd add patient type
  patient: any;
  clearHandler: () => void;
}
interface UserSearchResultContainerProps {
  clearHandler: () => void;
  data: any[];
}

const RenderUserRow = ({ patient, clearHandler }: RenderUserRowProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const patientDetailsEnabled = canSeeMemberDetails(currentRole);
  const { patient_id, birthdate, status, status_reason } = patient;
  if (patientDetailsEnabled) {
    const link = replace(Routes.MEMBER_DETAILS(""), ":memberId", patient_id);
    return (
      <Link
        style={{
          textDecoration: "none",
          pointerEvents: patientDetailsEnabled ? undefined : "none"
        }}
        to={link}
        onClick={clearHandler}
      >
        <UserRow>
          <UserDisplayName>
            <UserListNameText>{getNameOrUsername(patient)}</UserListNameText>
            <UserListBirthDateText>{birthdate}</UserListBirthDateText>
          </UserDisplayName>
          {status && (
            <StatusBadge status={status} statusReason={status_reason} />
          )}
        </UserRow>
      </Link>
    );
  }

  return (
    <UserRow>
      <UserDisplayName>
        <NameFlexbox>
          <UserListNameText>{getNameOrUsername(patient)}</UserListNameText>
          <CopyPatientLinkToClipboard
            memberId={patient_id}
            customLink={Routes.MEMBER_DETAILS("")}
          />
        </NameFlexbox>

        <UserListBirthDateText>{birthdate}</UserListBirthDateText>
      </UserDisplayName>
      {status && <StatusBadge status={status} statusReason={status_reason} />}
    </UserRow>
  );
};

export default function UserSearchResultContainer({
  data,
  clearHandler
}: Readonly<UserSearchResultContainerProps>) {
  const shouldDisplay = data && data.length > 0;
  return (
    <UserContainer
      shouldDisplay={shouldDisplay}
      data-testid="UserSearchResultContainer-searchResults"
    >
      {shouldDisplay &&
        data
          .map(({ patient }) => {
            return (
              <RenderUserRow
                key={patient.patient_id}
                patient={patient}
                clearHandler={() => {
                  clearHandler();
                }}
              />
            );
          })
          .reduce((prev, curr, index) => {
            return (
              <>
                {prev}
                <Divider key={"divider_" + index} />
                {curr}
              </>
            );
          })}
    </UserContainer>
  );
}
