import { Typography } from "@mui/material";
import { gray } from "common/styling/colors";
import GoogleDocLinks from "common/config/GoogleDocLinks";

const VisitFieldDeviceSetupGuide = () => {
  return (
    <Typography variant="body1" color={gray[900]}>
      Please use the
      <Typography
        variant="caption"
        color={"primary"}
        style={{ cursor: "pointer", fontSize: "100%" }}
        onClick={() => window.open(GoogleDocLinks.DEVICE_SETUP_GUIDE, "_blank")}
      >
        <b> Device Setup Guide </b>
      </Typography>{" "}
      to assist the member during their glucometer or blood pressure cuff setup.
    </Typography>
  );
};

export default VisitFieldDeviceSetupGuide;
