import { useEffect } from "react";
import { checkIdValid } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import MemberDetailsReadings from "./Readings/MemberDetailsReadings";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { useFeatureFlags } from "common/config/FeatureFlags";
import { useNavigate } from "react-router-dom";
import { canSeeMemberDetailsOverview } from "common/enums/RolesEnum";

export default function PatientDetailsRedirect() {
  const params = useSanitizedParams();
  const navigate = useNavigate();
  const { memberId } = params;

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const isValidId = checkIdValid(memberId);

  useEffect(() => {
    if (memberId) {
      if (canSeeMemberDetailsOverview(currentRole))
        navigate(`/members/memberId/${memberId}/overview`);
      else
        navigate(`/members/memberId/${memberId}/appointments`);
    }
  }, [memberId, currentRole]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return <MemberDetailsReadings />;
}
